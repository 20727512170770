import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';



export default function HobbiesModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

  
    useEffect(() => {
       axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);


    // STORED IN THE DATABASE 
    const [modeldata, setModaldata] = useState({
        IDNO: '',
        SKILLDETAILS: '', 
        Recognition: '', 
        Organization: '', 
    }) 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }

    // const [error, setError] = useState([]);  
    
    const submitSkill = async (e) => {
        e.preventDefault();

        await axios.post(`/api/StoredSkill`, {

            IDNO: fetchEmpInfo.IDNO,
            SKILLDETAILS: modeldata.SKILLDETAILS,
            Recognition: modeldata.Recognition,
            Organization: modeldata.Organization,
      
        }).then((res) => {

                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.fetchDataSkills(props.unique);
                    setShow(false);
                    setModaldata({
                        IDNO: '',
                        SKILLDETAILS: '', 
                        Recognition: '', 
                        Organization: '',       
                    });
                })
            
            // }
        
        })
    
    
    }

  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
            <i className="nav-icon fas fa-plus" />
        </Button>

         <Modal show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-info-circle"/> Create Other Information </Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitSkill} >

                <Modal.Body>

                    {/* <input type="hidden" name="app_id" id="appId_id" ref={appIdRef} defaultValue="1" /> */}
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Special Skills and Hobbies</label>
                            <input type="text" className="form-control" name="SKILLDETAILS" id="SKILLDETAILS_id" value={modeldata.SKILLDETAILS} onChange={handleChange}/>
                            
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Non-Academic Distinctions/Recognation</label>
                            <input type="text" className="form-control" name="Recognition" id="Recognition_id" value={modeldata.Recognition} onChange={handleChange}/>
                         
                        </div>  
                    </div>

                     <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Membership in Association/Organization </label>
                            <input type="text" className="form-control" name="Organization" id="Organization_id" value={modeldata.Organization} onChange={handleChange}/>

                        </div>       
                    </div>
                     
                </Modal.Body>
                
                  <Modal.Footer>
                      <Button variant="outline-danger" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
