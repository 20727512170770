import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'

// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LayoutPDS from '../components/LayoutPDS'
import axios from '../../../../services/axios';



export default function PrintPDS(props) {


  const [isLoading, setIsLoading] = useState(true);

  const [fetchInfo, setFetchInfo] = useState([]);
  const [fetchSpouse, setFetchSpouse] = useState([]);
  const [fetchParent, setFetchParent] = useState([]);
  const [fetchChildren, setFetchChildren] = useState([]);
  const [fetchEduc, setFetchEduc] = useState([]);
  const [fetchEligi, setFetchEligi] = useState([]);
  const [fetchWorkExp, setFetchWorkExp] = useState([]);
  const [fetchVolExp, setFetchVolExp] = useState([]);
  const [fetchLearnDev, setFetchLearnDev] = useState([]);
  const [fetchSkills, setFetchSkills] = useState([]);
  const [fetchQues, setfetchQues] = useState([]);
  const [fetchRef, setFetchRef] = useState([]);
  const [fetchImage, setFetchImage] = useState('');
  
  const fetchInfoData = async (uniqueId) => {

    setIsLoading(true);

    try {
      const resp1 = await axios.get(`/api/EmpFullInfo/${uniqueId}`);
      const resp2 = await axios.get(`/api/EmpSpouseData/${uniqueId}`);
      const resp3 = await axios.get(`/api/EmpParentData/${uniqueId}`);
      const resp4 = await axios.get(`/api/Empchildren/${uniqueId}`);
      const resp5 = await axios.get(`/api/EmpEduc/${uniqueId}`);
      const resp6 = await axios.get(`/api/EmpEligible/${uniqueId}`);
      const resp7 = await axios.get(`/api/EmpWork/${uniqueId}`);
      const resp8 = await axios.get(`/api/EmpVoluntary/${uniqueId}`);
      const resp9 = await axios.get(`/api/EmpTraining/${uniqueId}`);
      const resp10 = await axios.get(`/api/EmpSkills/${uniqueId}`);
      const resp11 = await axios.get(`/api/EmpQues/${uniqueId}`);
      const resp12 = await axios.get(`/api/EmpRefer/${uniqueId}`);
      const resp13 = await axios.get(`/api/EmpPDFImage/${uniqueId}`);

      setFetchInfo(resp1.data.EmpFullDetails[0]);
      setFetchSpouse(resp2.data.EmpSpouse[0]);
      setFetchParent(resp3.data.EmpParent[0]);
      setFetchChildren(resp4.data.EmpChild);
      setFetchEduc(resp5.data.EmpEduc);
      setFetchEligi(resp6.data.EmpEligible);
      setFetchWorkExp(resp7.data.EmpWork);
      setFetchVolExp(resp8.data.EmpVoluntary);
      setFetchLearnDev(resp9.data.EmpTraining);
      setFetchSkills(resp10.data.EmpSkill);
      setfetchQues(resp11.data.QuestionDisplay[0]);  
      setFetchRef(resp12.data.EmpRefer);
      setFetchImage(resp13.data.file);

    } catch (error) {
        console.log(error);
        
    } finally {
      setTimeout(() => setIsLoading(false), 300);
    }
  };


  useEffect(() => {
    fetchInfoData(props.unique);
  }, [props.unique]);


  
  
  return (
    <>
      
      <Modal show={props.show} onHide={props.handleClosePDSModal}  dialogClassName="modal-90w" >
      
        <Modal.Body>
         
          
         
            {isLoading ? (
              <div style={{ textAlign: 'center', padding: '20px' }}>
                <h4>Loading...</h4>
              </div>
            ) : (
              <PDFViewer style={{ width: "100%", height: "90vh" }}>
                <LayoutPDS
                  fetchInfo={fetchInfo}
                  fetchSpouse={fetchSpouse}
                  fetchParent={fetchParent}
                  fetchChildren={fetchChildren}
                  fetchEduc={fetchEduc}
                  fetchEligi={fetchEligi}
                  fetchWorkExp={fetchWorkExp}
                  fetchVolExp={fetchVolExp}
                  fetchLearnDev={fetchLearnDev}
                  fetchSkills={fetchSkills}
                  fetchQues={fetchQues}
                  fetchRef={fetchRef}
                  fetchImage={fetchImage}
                />
              </PDFViewer>
            )}

            
         
        </Modal.Body>
        
       
      </Modal>

      {/* <div>
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <LayoutPDS />
        </PDFViewer>
      </div> */}

    </>
  // <>
   
  

 
    
 )};


