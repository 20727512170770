import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



export default function ParentModal(props) {

    const [show, setShow] = useState(false);
    // const { unique } = useParams();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
        FSURNAME: "",
        FFIRSTNAME: "",
        FMIDDLENAME: "",
        // FEXTENSION: "",
        MSURNAME: "",
        MFIRSTNAME: "",
        MMIDNAME: "",
    });

    useEffect(() => {
        if (props.parentsData) {
        setEmpInfo({
            IDNO: props.parentsData.employee_no || "",
            FSURNAME: props.parentsData.FSURNAME || "",
            FFIRSTNAME: props.parentsData.FFIRSTNAME || "",
            FMIDDLENAME: props.parentsData.FMIDDLENAME || "",
            // FEXTENSION: props.parentsData.FEXTENSION || "",
            MSURNAME: props.parentsData.MSURNAME || "",
            MFIRSTNAME: props.parentsData.MFIRSTNAME || "",
            MMIDNAME: props.parentsData.MMIDNAME || "",
        });
        }
    }, [props.parentsData]);


    const handleChange = (e) => {
        setEmpInfo(fetchEmpInfo =>({...fetchEmpInfo, [e.target.name]: e.target.value}))
    }

    // useEffect(() => {
    //     axios.get(`http://localhost:8000/api/EmpFam/${unique}`).then((res) => {
    //         setEmpInfo(res.data.EmpFamily[0] ?? "")
    //      })  
    // }, [unique]);



    //Update Function
    const [error, setError] = useState([]); 
    
    const submitInfo = async (e) => {
        e.preventDefault();


        await axios.post(`http://localhost:8000/api/EmpParent`, {
            IDNO: fetchEmpInfo.IDNO,
            FSURNAME: fetchEmpInfo.FSURNAME,
            FFIRSTNAME: fetchEmpInfo.FFIRSTNAME,
            FMIDDLENAME: fetchEmpInfo.FMIDDLENAME,
            // FEXTENSION: fetchEmpInfo.FEXTENSION,
            MSURNAME: fetchEmpInfo.MSURNAME,
            MFIRSTNAME: fetchEmpInfo.MFIRSTNAME,
            MMIDNAME: fetchEmpInfo.MMIDNAME,
          
        }).then((res) => {

        
            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
                }
            else {
                // console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    setShow(false)
                    props.fetchDataFamily(props.unique);
                    setError('');

                })
            }
        
        })

    }

    

  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
           <i className="nav-icon fas fa-edit" />
        </Button>  

          <Modal size="lg" show={show} onHide={handleClose} backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
      
            <Modal.Header>
                <Modal.Title><i className="fas fa-user"/> Parent Details </Modal.Title>
            </Modal.Header>

            <Form onSubmit={submitInfo}>

                <Modal.Body>

                     <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Father's Name</label>
                                <div className="row">
                      
                                    <div className="col-md-4">
                                        <input type="text" className={`form-control ${error.FSURNAME ? 'is-invalid' : ''}`} placeholder="Surname" name="FSURNAME" id="f_surname_id" value={fetchEmpInfo.FSURNAME ?? ""} onChange={handleChange} />
                                        <div className="invalid-feedback"> { error.FSURNAME } </div>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" className={`form-control ${error.FFIRSTNAME ? 'is-invalid' : ''}`}  placeholder="Firstname" name="FFIRSTNAME" id="f_firstname_id" value={fetchEmpInfo.FFIRSTNAME ?? ""} onChange={handleChange}/>
                                        <div className="invalid-feedback"> { error.FFIRSTNAME } </div>
                                    </div>
                                    <div className="col-md-3">
                                        <input type="text" className={`form-control ${error.FMIDDLENAME ? 'is-invalid' : ''}`} placeholder="Middlename" name="FMIDDLENAME" id="f_middlename_id" value={fetchEmpInfo.FMIDDLENAME ?? ""} onChange={handleChange}/>
                                        <div className="invalid-feedback"> { error.FMIDDLENAME } </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <input type="text" className="form-control"  placeholder="Extension Name" name="FEXTENSION" id="f_extension_id" value={fetchEmpInfo.FEXTENSION ?? ""} onChange={handleChange}/> */}
                                        {/* <div className="invalid-feedback"> </div> */}
                                    {/* s */}
                                </div>
                                {/* row */}
                        </div>
                    </div>
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Mother's Maiden Name</label>
                            <div className="row">
                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.MSURNAME ? 'is-invalid' : ''}`} placeholder="Surname" name="MSURNAME" id="m_surname_id" value={fetchEmpInfo.MSURNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.MSURNAME } </div>
                                </div>
                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.MFIRSTNAME ? 'is-invalid' : ''}`} placeholder="Firstname" name="MFIRSTNAME" id="m_firstname_id" value={fetchEmpInfo.MFIRSTNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.MFIRSTNAME } </div>
                                </div>
                                <div className="col-md-3">
                                    <input type="text" className={`form-control ${error.MMIDNAME ? 'is-invalid' : ''}`} placeholder="Middlename" name="MMIDNAME" id="m_middlename_id" value={fetchEmpInfo.MMIDNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.MMIDNAME } </div>
                                </div>
                            </div>
                            {/* row */}
                        </div>
                    </div>

                    </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 
                      
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>



    </>
  )
}
