import React from 'react';
// import axios from '../../../../services/axios';
import { Page, Text, View, Image,  Document, StyleSheet, Font} from '@react-pdf/renderer'
import ArialBold from './fonts/ariblk.ttf'
import ArialBoldItalic from './fonts/Arimo-BoldItalic.ttf'
import ArialItalic from './fonts/Arimo-Italic.ttf'
import checked from '../../../../image/checked.png';
import Unchecked from '../../../../image/checked-box.png';

Font.register({
  family: 'ArielBold',
  src: ArialBold
});

Font.register({
  family: 'ArielBoldItalic',
  src: ArialBoldItalic
});

Font.register({
  family: 'ArialItalic',
  src: ArialItalic
});



const  styles = StyleSheet.create({

 // LAYOUT CSSS
  page: {
       // flexDirection: 'row',
  },
  section: {
    margin: 18,
    marginLeft:18,
    padding: 10,
    height: 900,
    border: "2px solid black",
    fontFamily: "Helvetica-Bold"
  },


  csform: {
    fontSize: "7px", 
    marginTop:"-10px",
    marginLeft:"-9px",
    fontFamily: 'ArielBoldItalic',
  },
  revise: {
    fontSize: "6.5px", 
    marginTop:"-0px",
    marginLeft:"-9px",
    fontFamily: 'ArielBoldItalic',
  }, 
  title: {
    textAlign: "center",
    fontSize: "21.5px",
    fontFamily: "ArielBold", 
    weight: 900,
  }, 
  warning: {
    marginTop:"5px",
    marginLeft:"-10px",
    fontSize: "6.8px",
    fontFamily: 'ArielBoldItalic'
    
  },
  read: {
    marginTop:"6px",
    marginLeft:"-10px",
    fontSize: "6.6px",
    fontWeight: '900', 
    fontFamily: 'ArielBoldItalic',
  },
  print: {
    marginTop:"1px",
    marginLeft:"-10px",
    fontSize: "6.2px",
    fontFamily: "Helvetica"
  },

//end

// table css

table: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px"
  }, 
  tableRowPS: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS: { 
    fontFamily: "ArielBoldItalic",
    fontSize: "10px",
    marginLeft:"2px"
    
  },
//----------------------
  table2: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px",
    marginTop: "-10px",
  }, 

   tableRowPS2: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS2: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS2: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "10px",
    marginLeft:"2px"
    
  },

// -------------
  table3: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px"

  }, 
   tableRowPS3: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS3: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS3: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "10px",
    marginLeft:"2px"
    
  },

  tableCellPS3Desc: { 
    fontFamily: "Helvetica-BoldOblique",
    marginTop: "3px",
    fontSize: "7.3px",
    marginLeft:"2px"
    
  },
   
  // ---------------------

  table4: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px",
    marginTop: "-11px"

  }, 
   tableRowPS4: { 
    height: "16px",
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS4: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS4: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "6.8px",
    marginLeft:"1px",
    marginTop: "2.3px"
    
  },

  // ------------------------

  
  table5: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px"

  }, 
   tableRowPS5: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS5: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS5: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "7px",
    marginLeft: "2px",
    marginTop: "1px",
    marginBottom: "3px"
    
  },

  tableCellPS5Desc: { 
    marginTop: "3px",
    marginBottom: "1px",
    marginLeft: "2px",
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "5.4px"
  },


  // -------------------------

    table5Extended: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px",
    marginTop: "-11px"

  }, 
   tableRowPS5Extended: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS5Extended: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS5Extended: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "7px",
    marginLeft: "2px",
    marginTop: "1px",
    marginBottom: "3px"
    
  },

  tableCellPS5DescExtended: { 
    marginTop: "3px",
    marginBottom: "1px",
    marginLeft: "2px",
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "5.4px"
  },
  



   // ------------------------

  table6: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px"
  }, 

  table6Extended: { 
    display: "table", 
    width: "104%", 
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px",
    marginTop: "-11px"
  }, 

   tableRowPS6: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColPS6: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellPS6: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "7px",
    marginLeft: "2px",
    marginTop: "2px",
    marginBottom: "5px"
    
  },



  //-------- Extended page of Work Experience ---------

  tableSecondPage3: { 
    display: "table", 
    width: "104%",
    borderStyle: "solid",
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 ,
    marginLeft: "-11px",
    marginTop: "-11px"

  }, 
   tableRowSecondPagePS3: { 
    height:"23px", 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColSecondPagePS3: {
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 ,
    backgroundColor: '#A9A9A9'
    
  }, 
  tableCellSecondPagePS3: { 
    fontFamily: "Helvetica-BoldOblique",
    fontSize: "10px",
    marginTop:"1px",
    marginLeft:"2px"
    
  },

  tableCellSecondPagePS3Desc: { 
    fontFamily: "Helvetica-BoldOblique",
    marginTop: "4px",
    fontSize: "7.3px",
    marginLeft:"2px"
    
  },



  //---------------------------------------------------


  // Table footer 
  tableFooter: {
    display: "table", 
    width: "104.2%", 
    marginLeft: "-12px",
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  tableFooterRow: {
    height: "12px",
    margin: "auto", 
    flexDirection: "row"
  },
   tableFooterCol: { 
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableFooterCell: {
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10
  },
//End

// Table footer  with sinature and date
  tableFooter2: {
    display: "table", 
    width: "104.2%",
    marginLeft: "-12px",
    marginBottom: "-2.9px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  tableFooterRow2: {
    height: "15px",
    margin: "auto", 
    flexDirection: "row"
  },

   tableFooterSignatureCol: { 
    width: "18.6%", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterSignatureValueCol: { 
    width: "31%", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

   tableFooterDateCol: { 
    width: "8%", 
     height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterDateValueCol: { 
    width: "20.8%", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

   tableFooterPageCol: { 
    width: "21.5%",
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterSignatureCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterSignatureValueCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterDateCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterDateValueCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterPageCell: {
    marginLeft: "7px",
    marginTop: 7, 
    fontSize: '5.5px'
  },

  // Second page CSC and Work Experience

  tableSecondPageFooter2: {
    display: "table", 
    width: "104%",
    marginLeft: "-12px",
    // marginTop: "10px",
    marginBottom: "-20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  tableSecondPageFooterRow2: {
    height: "22px",
    // margin: "auto", 
    // marginLeft: "-2px", 
    flexDirection: "row"
  },

   tableFooterSecondPageSignatureCol: { 
    width: "95px", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterSecondPageSignatureValueCol: { 
    width: "190px", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

   tableFooterSecondPageDateCol: { 
    width: "60px", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterSecondPageDateValueCol: { 
    width: "95px", 
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

   tableFooterSecondPageCol: { 
    width: "135px",
    height: "22px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableFooterSecondPageSignatureCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterSecondPageSignatureValueCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterSecondPageDateCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterSecondPageDateValueCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 7
  },

  tableFooterSecondPageCell: {
    marginLeft: "15px",
    marginTop: 5, 
    fontSize: 5
  },
    tableFooterlastPageCell: {
    marginLeft: "470px",
    marginTop: 5, 
    fontSize: 5
  },



//End


  
  //Applicant name
  tableRowNames: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 

  tableColName: {
    width: "18.5%", 
    height: "57px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellSurname: { 
    marginTop: "4px",
    marginLeft: "4px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellFirstname: { 
    marginTop: "11px",
    marginLeft: "12px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellMiddlename: { 
    marginTop: "10px",
    marginLeft: "12px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  // end 
 
 
  // Surname fields

  tableRowSurnamefield: { 
    flexDirection: "row" 
  }, 

    tableColSurnamefield: {
    width: "100%", 
    height: "20px",
    marginTop: "-58px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSurname: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  // end

  // Firstname fields
  tableRowFirstnamefield: { 
    flexDirection: "row" 
  }, 


  tableColFirstnamefield: {
    width: "59%", 
    height: "20px",
    marginTop: "-38px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueFirstname: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //end

   // Middlename fields
  tableRowMiddlenamefield: { 
    flexDirection: "row" 
  }, 


  tableColMiddlenamefield: {
    width: "100%", 
    height: "20px",
    marginTop: "-20px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueMiddlename: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //end

  // Extensionename fields
  tableRowExtensionNamefield: { 
    flexDirection: "row" 
  }, 

  tableColExtensionNamefield: {
    width: "23%", 
    height: "20px",
    marginTop: "-38px",
    marginLeft: "444px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableCellExtensionName: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "4px"
  },

  tableCellValueExtensionName: {
    marginTop: "2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end

  // Date Birth fields
  tableRowDatebirth: { 
    flexDirection: "row" 
  }, 
  tableColDatebirth: {
    width: "18.5%", 
    height: "25px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellDatebirth: {
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },
    tableCellFormatDatebirth: {
    marginLeft: "12px ",
    fontFamily: 'Helvetica' ,
    fontSize: "6px"
  },


  tableRowDatebirthfield: { 
    flexDirection: "row" 
  }, 
  tableColDatebirthfield: {
    width: "25%", 
    marginTop: "-25.7px",
    marginLeft: "106px",
    height: "26.5px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueDatebirth: {
    marginTop:"2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  //Place Birth
  tableRowPlacebirth: { 
    flexDirection: "row" 
  }, 
  tableColPlacebirth: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellPlacebirth: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowPlacebirthfield: { 
    flexDirection: "row" 
  }, 
  tableColPlacebirthfield: {
    width: "25%", 
    marginTop: "-22px",
    marginLeft: "106px",
    height: "22.5px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValuePlacebirth: {
    marginTop:"3px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end

  // Sex
  tableRowSex: { 
    flexDirection: "row" 
  }, 
  tableColSex: {
    width: "18.5%", 
    height: "19.6px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellSex: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowSexfield: { 
    flexDirection: "row" 
  }, 
  tableColSexfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.5px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSexMale: {
    marginTop:"6px",
    marginLeft: "12px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

    tableCellValueSexFemale: {
    marginTop:"-8px",
    marginLeft: "90px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },
  //end


  // Civil Status

  tableRowCivilstatus: { 
    flexDirection: "row" 
  }, 
  tableColCivilstatus: {
    width: "18.5%", 
    height: "37px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellCivilstatus: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  
   tableRowCSfield: { 
    flexDirection: "row" 
  }, 
  tableColCSfield: {
    width: "25%", 
    marginTop: "-39px",
    marginLeft: "106px",
    height: "40.4px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueCSSingle: {
    marginTop:"6px",
    marginLeft: "12px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellValueCSWidowed: {
    marginTop:"3px",
    marginLeft: "12px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellValueCSOthers: {
    marginTop:"3px",
    marginLeft: "12px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  }, 
  
  tableCellValueCSMarried: {
    marginTop:"-25px",
    marginLeft: "90px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  }, 

  tableCellValueCSSeparated: {
    marginTop:"2px",
    marginLeft: "90px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  }, 

  //end 


  //Height

  tableRowHeight: { 
    flexDirection: "row" 
  }, 
  tableColHeight: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellHeight: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowHeightfield: { 
    flexDirection: "row" 
  }, 
  tableColHeightfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueHeight: {
    marginTop:"5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  //Weight

  tableRowWeight: { 
    flexDirection: "row" 
  }, 
  tableColWeight: {
    width: "18.5%", 
    height: "21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellWeight: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowWeightfield: { 
    flexDirection: "row" 
  }, 
  tableColWeightfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueWeight: {
    marginTop:"3px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  //Blood Type

  tableRowBloodtype: { 
    flexDirection: "row" 
  }, 
  tableColBloodtype: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellBloodtype: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowBloodtypefield: { 
    flexDirection: "row" 
  }, 
  tableColBloodtypefield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueBloodtype: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  // GSIS ID
  tableRowGSIS: { 
    flexDirection: "row" 
  }, 
  tableColGSIS: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellGSIS: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowGSISfield: { 
    flexDirection: "row" 
  }, 
  tableColGSISfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueGSIS: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


   // PAG IBIG ID
  tableRowPagIbig: { 
    flexDirection: "row" 
  }, 
  tableColPagIbig: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellPagIbig: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowPagIbigfield: { 
    flexDirection: "row" 
  }, 
  tableColPagIbigfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValuePagIbig: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  //Philhealth ID
  tableRowPhilhealth: { 
    flexDirection: "row" 
  }, 
  tableColPhilhealth: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellPhilhealth: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowPhilhealthfield: { 
    flexDirection: "row" 
  }, 
  tableColPhilhealthfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValuePhilhealth: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


    //SSS ID
  tableRowSSS: { 
    flexDirection: "row" 
  }, 
  tableColSSS: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellSSS: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowSSSfield: { 
    flexDirection: "row" 
  }, 
  tableColSSSfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
     height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSSS: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end


  //TIN ID
  tableRowTin: { 
    flexDirection: "row" 
  }, 
  tableColTin: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellTin: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowTinfield: { 
    flexDirection: "row" 
  }, 
  tableColTinfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueTin: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end

//Agency ID
  tableRowAgency: { 
    flexDirection: "row" 
  }, 
  tableColAgency: {
    width: "18.5%", 
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellAgency: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowAgencyfield: { 
    flexDirection: "row" 
  }, 
  tableColAgencyfield: {
    width: "25%", 
    marginTop: "-20px",
    marginLeft: "106px",
    height: "20.7px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAgency: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //end
 

  //Citizenship 
  tableRowCitizenship: { 
    flexDirection: "row" 
  }, 
  tableColCitizenship: {
    marginTop: '-281px',
    marginLeft: 249,
    width: "23.8%", 
    height: "64.6px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellCitizenship: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellDualcitizenship: {
    marginTop: "22px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "6px"
  },

  tableCellCountry: {
    marginTop: "10px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },


  tableRowCitizenshipfield: { 
    flexDirection: "row" 
  }, 
  tableColCitizenshipfield: {
    width: "50%", 
    marginTop: "-285px",
    marginLeft: "385px",
    height: "53px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueCitizenshipFilipino: {
    marginTop:"10px",
    marginLeft: "15px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellValueCitizenshipDualCitizen: {
    marginTop:"-8px",
    marginLeft: "60px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

   tableCellValueBybirth: {
    marginTop:"6px",
    marginLeft: "50px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

   tableCellValueBynaturalization: {
    marginTop:'-8px',
    marginLeft: "100px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableRowCountryfield: { 
    flexDirection: "row" 
  }, 
  tableColCountryfield: {
    marginTop: '-239.5px',
    marginLeft: 385,
    width: "36.4%", 
    height: "23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 


  tableCellIndicateCountry: {
    marginTop: "6px",
    marginLeft: "72px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellValueCountry: {
   marginTop: "12px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  //end


  //Residential Address

   tableRowResidential: { 
    flexDirection: "row" 
  }, 
  tableColResidential: {
    marginTop: '-217.4px',
    marginLeft: 248,
    width: "18.5%", 
    height: "77.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellResidential: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellResidentialZip: {
    marginTop: "52px",
    marginLeft: "35px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowARfield1: { 
    flexDirection: "row" 
  }, 
  tableColARfield1: {
    width: "38.6%", 
    marginTop: "-215px",
    marginLeft: "354px",
    height: "10px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield1: {
    marginTop: "0px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableCellValueARfield11: {
    marginTop:"-8px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableRowARfield1s: { 
    flexDirection: "row" 
  }, 
  tableColARfield1s: {
    width: "38.6%", 
    marginTop: "-208px",
    marginLeft: "354px",
    height: "10px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield1s: {
    marginTop:"3px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueARfield1ss: {
    marginTop:"-5px",
    marginLeft: "150px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },


  // resident address SUBDIVISION AND BARANGAY
   tableRowARfield2: { 
    flexDirection: "row" 
  }, 
  tableColARfield2: {
    width: "38.6%", 
    marginTop: "-203px",
    marginLeft: "354px",
    height: "16px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield2: {
    marginTop:"6px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

    tableCellValueARfield22: {
    marginTop:"-9px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },


  tableRowARfield2s: { 
    flexDirection: "row" 
  }, 
  tableColARfield2s: {
    width: "38.6%", 
    marginTop: "-190px",
    marginLeft: "354px",
    height: "10px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield2s: {
    marginTop:"3.5px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueARfield2ss: {
      marginTop:"-5px",
    marginLeft: "148px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },

// resident address MUNICIPALITY AND PROVINCE
  tableRowARfield3: { 
    flexDirection: "row" 
  }, 
  tableColARfield3: {
    height: "10px",
    width: "38.6%", 
    marginTop: "-178px",
    marginLeft: "354px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield3: {
    marginTop:"-1px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

    tableCellValueARfield33: {
    marginTop:"-8px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableRowARfield3s: { 
    flexDirection: "row" 
  }, 
  tableColARfield3s: {
    width: "38.6%", 
    height: "8px",
    marginTop: "-168px",
    marginLeft: "354px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield3s: {
    marginTop: "1px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueARfield3ss: {
    marginTop:"-4px",
    marginLeft: "148px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },

  // RESIDENTIAL ZIP CODE
  tableRowARfield4: { 
    flexDirection: "row" 
  }, 
  tableColARfield4: {
    height: "18px",
    width: "38.6%", 
    marginTop: "-158px",
    marginLeft: "354px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueARfield4: {
    marginTop:"3px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  

//end

//============================= Permanent Address =================================

tableRowPermanent: { 
    flexDirection: "row" 
  }, 
  tableColPermanent: {
    marginTop: "-141px",
    marginLeft: "248.5px",
    width: "18.4%", 
    height: "81px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellPermanent: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellPermanentZip: {
    marginTop: "55px",
    marginLeft: "35px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

// Permanent address house no. and street
   tableRowAPfield1: { 
    flexDirection: "row" 
  }, 
  tableColAPfield1: {
    width: "38.6%", 
    marginTop: "-137px",
    marginLeft: "354px",
    height: "10px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield1: {
    marginTop: "-1px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableCellValueAPfield11: {
    marginTop: "-8px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },


  tableRowAPfield1s: { 
    flexDirection: "row" 
  }, 
  tableColAPfield1s: {
    width: "38.6%", 
    marginTop: "-128px",
    marginLeft: "354px",
    height: "8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield1s: {
    marginTop:"1px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueAPfield1ss: {
    marginTop:"-5px",
    marginLeft: "150px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  
  // PERMANENT ADDRESS SUBDIVISION AND BARANGAY

   tableRowAPfield2: { 
    flexDirection: "row" 
  }, 
  tableColAPfield2: {
    width: "38.6%", 
    marginTop: "-118px",
    marginLeft: "354px",
    height: "10px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield2: {
    marginTop:"0px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

    tableCellValueAPfield22: {
    marginTop:"-8px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableRowAPfield2s: { 
    flexDirection: "row" 
  }, 
  tableColAPfield2s: {
    width: "38.6%", 
    marginTop: "-108px",
    marginLeft: "354px",
    height: "8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield2s: {
    marginTop:"1px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueAPfield2ss: {
      marginTop:"-5px",
    marginLeft: "148px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },

// PERMANENT ADDRESS MUNICIPALITY AND PROVINCE

  tableRowAPfield3: { 
    flexDirection: "row" 
  }, 
  tableColAPfield3: {
    width: "38.6%", 
    marginTop: "-97px",
    marginLeft: "354px",
    height: "8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield3: {
    marginTop:"-2px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

    tableCellValueAPfield33: {
    marginTop:"-8px",
    marginLeft: "130px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  tableRowAPfield3s: { 
    flexDirection: "row" 
  }, 
  tableColAPfield3s: {
    width: "38.6%", 
    marginTop: "-90px",
    marginLeft: "354px",
    height: "8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield3s: {
    marginTop:"1px",
    marginLeft: "25px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },
  tableCellValueAPfield3ss: {
    marginTop:"-5px",
    marginLeft: "148px ",
    fontFamily: 'Helvetica' ,
    fontSize: "5px"
  },

  // PERMANENT ZIP CODE
  tableRowAPfield4: { 
    flexDirection: "row" 
  }, 
  tableColAPfield4: {
    width: "38.6%", 
    height: "19px",
    marginTop: "-79px",
    marginLeft: "354px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueAPfield4: {
    marginTop:"3px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
//end


// TELEPHONE FIELD
 tableRowTEL: { 
    flexDirection: "row" 
  }, 
  tableColTEL: {
    width: "18.3%", 
    height: "20px",
    marginTop:"-60px",
    marginLeft: "249px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellTEL: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowTELfield: { 
    flexDirection: "row" 
  }, 
  tableColTELfield: {
    width: "38.6%", 
    marginTop: "-60px",
    marginLeft: "353px",
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueTEL: {
   marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //END
  
  // CELLPHONE FIELD
 tableRowCELL: { 
    flexDirection: "row" 
  }, 
  tableColCELL: {
    width: "18.3%", 
    height: "20px",
    marginTop:"-40px",
    marginLeft: "249px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellCELL: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowCELLfield: { 
    flexDirection: "row" 
  }, 
  tableColCELLfield: {
    width: "38.6%", 
    marginTop: "-40px",
    marginLeft: "353px",
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueCELL: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //END


  // EMAIL FIELD
 tableRowEMAIL: { 
    flexDirection: "row" 
  }, 
  tableColEMAIL: {
    width: "18.3%", 
    height: "20px",
    marginTop:"-20px",
    marginLeft: "249px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellEMAIL: {
    marginTop: "6px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowEMAILfield: { 
    flexDirection: "row" 
  }, 
  tableColEMAILfield: {
    width: "38.6%", 
    marginTop: "-20px",
    marginLeft: "353px",
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueEMAIL: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //END


  //========================= FAMILY BACKGOUND CSS =====================================

  // TITLE

 
  tableRowFB: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColFB: {
    marginTop:"-1px",
    color: 'white',
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellFB: { 
    backgroundColor: '#A9A9A9',
    fontSize: "9px"
  },

   //SPOUSE FIELD
  tableRowSpouseNames: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 

  tableColSpouseName: {
    width: "18.6%", 
    height: "60px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellSpouseSurname: { 
    marginTop: "4px",
    marginLeft: "3px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellSpouseFirstname: { 
    marginTop: "11px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellSpouseMiddlename: { 
    marginTop: "13px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },

  
  //SPOUSE SURNAME VALUE

  tableRowSpouseSurnamefield: { 
    flexDirection: "row" 
  }, 
    tableColSpouseSurnamefield: {
    width: "43.2%", 
    height: "20x",
    marginTop: "-58.3px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellValueSpouseSurname: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //SPOUSE FIRSTNAME VALUE

  tableRowSpouseFirstnamefield: { 
    flexDirection: "row" 
  }, 

    tableColSpouseFirstnamefield: {
    width: "25%", 
    height: "20px",
    marginTop: "-39.1px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSpouseFirstname: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  // SPOUSE EXTENSION NAME
  tableRowSpouseExtensionNamefield: { 
    flexDirection: "row" 
  }, 

  tableColSpouseExtensionNamefield: {
    // width: "18.3%",
    width: "104.5px",
    height: "20px",
    marginTop: "-39.1px",
    marginLeft: "249px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableCellSpouseExtensionName: {
    marginTop: "2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "4px"
  },

  tableCellValueSpouseExtensionName: {
    marginTop: "2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },


  // MIDDLE NAME VALUE

  tableRowSpouseMiddlenamefield: { 
    flexDirection: "row" 
  }, 

    tableColSpouseMiddlenamefield: {
    width: "43.2%", 
    height: "20px",
    marginTop: "-20px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSpouseMiddlename: {
    marginTop: "3px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  // end 


  //SPOUSE OCCUPATION FIELD
  tableRowSpouseOccupation: { 
    flexDirection: "row" 
  }, 
  tableColSpouseOccupation: {
    width: "18.6%", 
    height: "19px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellSpouseOccupation: {
    marginTop: "4px",
    marginLeft: "16px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowSpouseOccupationfield: { 
    flexDirection: "row" 
  }, 
  tableColSpouseOccupationfield: {
    width: "43.2%", 
    height: "20.7px",
    marginTop: "-20px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSpouseOccupation: {
    marginTop:"4px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //END 

  //SPOUSE BUSINESS 
 tableRowBusinessName: { 
    flexDirection: "row" 
  }, 
  tableColBusinessName: {
    width: "18.6%", 
    height: "19.5px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellBusinessName: {
    marginTop: "4px",
    marginLeft: "16px ",
    fontFamily: 'Helvetica' ,
    fontSize: "6px"
  },


  tableRowBusinessNamefield: { 
    flexDirection: "row" 
  }, 
  tableColBusinessNamefield: {
    width: "43.2%", 
    height: "20px",
    marginTop: "-19.2px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueBusinessName: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //END

//SPOUSE BUSINESS ADDRESS 
 tableRowBusinessAddress: { 
    flexDirection: "row" 
  }, 
  tableColBusinessAddress: {
    width: "18.6%", 
    height: "19px",
  
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellBusinessAddress: {
    marginTop: "4px",
    marginLeft: "16px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowBusinessAddressfield: { 
    flexDirection: "row" 
  }, 
  tableColBusinessAddressfield: {
    width: "43.2%", 
    height: "20px",
    marginTop: "-19.2px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueBusinessAddress: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //END

//SPOUSE TELLPHONE 
 tableRowSpouseTell: { 
    flexDirection: "row" 
  }, 
  tableColSpouseTell: {
    width: "18.6%", 
    height: "19.5px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellSpouseTell: {
    marginTop: "4px",
    marginLeft: "16px ",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  tableRowSpouseTellfield: { 
    flexDirection: "row" 
  }, 
  tableColSpouseTellfield: {
    width: "43.2%", 
    marginTop: "-19.1px",
    marginLeft: "106px",
    height: "20px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 

  tableCellValueSpouseTell: {
    marginTop:"5px",
    marginLeft: "5px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  //END

// FATHER'S NAME FIELD
  tableRowFatherName: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  
  tableColFatherName: {
    width: "18.6%", 
    height: "57px",
    marginRight: "530px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellFatherSurname: { 
    marginTop: "4px",
    marginLeft: "3px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellFatherFirstname: { 
    marginTop: "11px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
    
  },
  tableCellFatherMiddlename: { 
    marginTop: "13px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  // FATHER SURNAME VALUE
  tableRowFatherSurnamefield: { 
    flexDirection: "row" 
  }, 
    tableColFatherSurnamefield: {
      width: "43.2%", 
      height: "20x",
      marginTop: "-55.2px",
      marginLeft: "106px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 

  }, 
  tableCellValueFatherSurname: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },


  //FATHER FIRSTNAME VALUE

  tableRowFatherFirstnamefield: { 
    flexDirection: "row" 
  }, 

    tableColFatherFirstnamefield: {
      width: "25%", 
      height: "20px",
      marginTop: "-36.3px",
      marginLeft: "106px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
  }, 

  tableCellValueFatherFirstname: {
    marginTop: "4px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  // FATHER EXTENSION NAME
  tableRowFatherExtensionNamefield: { 
    flexDirection: "row" 
  }, 

  tableColFatherExtensionNamefield: {
    // width: "18.2%", 
    width: "104.5px", 
    height: "20.5px",
    marginTop: "-36.9px",
    marginLeft: "249px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableCellFatherExtensionName: {
    marginTop: "2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "4px"
  },

  tableCellValueFatherExtensionName: {
    marginTop: "2px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },


  // FATHER MIDDLE NAME VALUE

  tableRowFatherMiddlenamefield: { 
    flexDirection: "row" 
  }, 

    tableColFatherMiddlenamefield: {
      width: "247.7px", 
      height: "20.1px",
      marginTop: "-17.7px",
      marginLeft: "106px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 

  }, 

  tableCellValueFatherMiddlename: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },
  // end 



// MOTHER NAME FIELD

 tableRowMotherName: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 

  tableColMotherName: {
    width: "18.6%", 
    height: "80.3px",
    marginRight: "530px",
    borderStyle: "solid", 
    borderWidth: 0, 
    borderBottom: 1,
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 

  tableCellMotherFullName: { 
    marginTop: "4px",
    marginLeft: "2px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellMotherSurname: { 
    marginTop: "12px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellMotherFirstname: { 
    marginTop: "12px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },

  tableCellMotherMiddlename: { 
    marginTop: "11px",
    marginLeft: "15px",
    fontFamily: 'Helvetica' ,
    fontSize: "7px"
  },


  // MOTHER FULLNAME
    tableRowMotherFullNamefield: { 
    flexDirection: "row" 
  }, 
    tableColMotherFullNamefield: {
    width: "247.3px", 
    height: "21px",
    marginTop: "-78.3px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    border: 1,
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellValueMotherFullName: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

    // MOTHER SURNAME VALUE
  tableRowMotherSurnamefield: { 
    flexDirection: "row" 
  }, 
    tableColMotherSurnamefield: {
      // width: "43.1%", 
    width: "247.4px", 
    height: "20px",
    marginTop: "-58px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 1, 
    borderTopWidth: 0 

  }, 
  tableCellValueMotherSurname: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  // MOTHER FIRSTNAME VALUE
  tableRowMotherFirstNamefield: { 
    flexDirection: "row" 
  }, 
    tableColMotherFirstNamefield: {
    width: "247.4px",  
    height: "21px",
    marginTop: "-40px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 1,  
    borderTopWidth: 0 

  }, 
  tableCellValueMotherFirstName: {
    marginTop: "5px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  // MOTHER MIDDLENAME VALUE
  tableRowMotherMiddleNamefield: { 
    flexDirection: "row" 
  }, 
    tableColMotherMiddleNamefield: {
    width: "247.4px",  
    height: "21px",
    marginTop: "-19px",
    marginLeft: "106px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 1,  
    borderTopWidth: 0 

  }, 
  tableCellValueMotherMiddleName: {
    marginTop: "3px",
    marginLeft: "3px ",
    fontFamily: 'Helvetica' ,
    fontSize: "8px"
  },

  //END
  


// CHILDREN NAME

tableChildren :{
  display: "table", 
  width: "40%", 
  marginLeft: "342.6px",
  marginTop:"-271.4px",
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0 ,
  position:"relative"
}, 
tableChildrenRow: {
  margin: "0", 
  height: "20px",
  flexDirection: "row" 
}, 
tableChildrenCol: {
  width: "140%", 
  height: "100%",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0 
},
tableChildrenCol2: {
  width: "80%", 
  height: "100%",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableChildrenCell: {
  marginTop: "5px",
  fontFamily: 'Helvetica' ,
  fontSize: "5px"
},
tableChildrenCell2: {
  margin: "auto", 
  fontFamily: 'Helvetica' ,
  fontSize: "4.8px"
},

tableChildrenValueCell: {
  marginLeft: "2px",
  marginTop: "5px",
  fontFamily: 'Helvetica' ,
  fontSize: "8px"
},
tableChildrenValueCell2: {
  margin: "auto", 
  fontFamily: 'Helvetica' ,
  fontSize: "8px"
},

// END

  //========================= EDUCATION BACKGOUND CSS =====================================

  // TITLE

 
  tableRowEB: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableColEB: {
    marginTop:"-1px",
    color: 'white',

    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 

  }, 
  tableCellEB: { 
    backgroundColor: '#A9A9A9',
    fontSize: "9px"
  },


// EDUCATION TABLE 

  tableEducation:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px",
    marginTop: "-1px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableEducationRow: {
    flexDirection: "row",
  },

  tableTextValueCell: {
    marginTop:"4px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },

  tableTextVocationalValueCell: {
    marginTop:"2px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },
  tableTextTradeValueCell: {
  
    marginLeft: "10px",  
    fontSize: "7px" 
  },


  // Level Field
  tableEducationLevelCol: {
    width: "140%",
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLevelFieldCol: {
    width: "18.6%", 
    height:"21px",
     borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: 5,
    marginLeft:2, 
    fontSize: "7px" 
  },
   tableEducationLevelCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: 1, 
    fontSize: "6px" 
  },

  // School Field
  tableEducationSchoolCol: {
    width: "190%", 
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableSchoolFieldCol: {
    width: "25.2%", 
    height:"21px",
     borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableSchoolValueCell: {
    marginTop:"4px",
    marginLeft: "2px",  
    fontSize: "7px" 
  },

  tableEducationSchoolCell:{
    margin: "auto",
    fontFamily: 'Helvetica',
    marginBottom: "-10px",
    marginTop: "12px", 
    fontSize: "6px" 
  },

   tableSchoolfullCell:{
    fontFamily: 'Helvetica',
    margin: "auto",
    fontSize: "5px" 
  },

  // Basic Education Field
  tableBasicEducationCol: {
    width: "158%", 
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableBasicEducFieldCol: {
    width: "20.9%", 
    height:"21px",
     borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableBasicEducValueCell: {
    marginTop:"4px",
    marginLeft: "2px",   
    fontSize: "7px" 
  },

  tableEducationBasicEducCell:{
    margin: "auto", 
    fontFamily: 'Helvetica',
    marginBottom: "-10px",
    marginTop: "12px", 
    fontSize: "5px" 
  },
   tableEducationBasicfullCell:{
    fontFamily: 'Helvetica',
    margin: "auto",
    fontSize: "5px" 
  },


  // Period of Attendance field
  tableEducationAttendanceCol: {
    width: "102%",
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableAttendancePeriodCell: {
    fontFamily: 'Helvetica',
    margin:"auto",
    fontSize: "5px"
  }, 

   // FROM - TO
  tableEducationAttendancePeriod: {
    display: "table",
    marginBottom:"-1px",
    marginLeft:"-1px", 
    width: "102%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  },


  tableEducationAttendancePeriodRow: {
    flexDirection: "row",
  },

  tableEducationFromCol: {
    fontFamily: 'Helvetica',
    width: "50%", 
    height: "12px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableEducationToCol: {
    fontFamily: 'Helvetica',
    height: "12px",
    width: "51.2%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableEducationFromCell:{
    marginTop: "-2px",
    margin: "auto", 
    fontSize: "6px" 
  }, 

  tableEducationToCell:{
     marginTop: "-5px",
    margin: "auto", 
    fontSize: "6px" 
  }, 


  tableEducFromCol: {
    width: "6.7%", 
    height:"21px",
     borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableEducFromValueCell: {
    marginTop:"4px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },

  tableEducToCol: {
    width: "6.8%", 
    height:"21px",
     borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableEducToValueCell: {
    marginTop:"5px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },

  //Highest Level field
  tableHighestEducationCol: {
    width: "59%", 
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableHighestLevelCol: {
    width: "7.9%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableHighestLevelValueCell: {
    marginTop:"4px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },

  tableEducationHighestLevelCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: 5, 
    marginBottom: "-5px",
    fontSize: "5px" 
  },
  tableUnitsEarnedCell: {
    fontFamily: 'Helvetica',
     margin: "auto", 
     marginBottom: "-6px",
    fontSize: "5px"
  },
  tableIfNotGraduatedCell: {
    fontFamily: 'Helvetica',
    margin: "auto", 
    fontSize: "5px"
  },

  // Year Graduated Field
  tableEducationGraduatedCol: {
    width: "45%", 
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

   tableYearGradCol: {
    width: "5.9%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableYearGradValueCell: {
    marginTop:"4px",
    marginLeft: "10px",  
    fontSize: "7px" 
  },

  tableEducationYearCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: "10px",
    marginBottom:"-10px",
    fontSize: "5px" 
  },
  tableEducationGradCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    fontSize: "5px" 
  },

  // Scholarship Field
  tableEducationScholarshipCol: {
    width: "60%", 
    height: "35px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableScholarCol: {
    width: "7.9%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 

  tableScholarValueCell: {
    marginTop:"4px",
    marginLeft: "10px",  
    fontSize: "7px" ,
  },

  tableTextLevelCell: {
    fontWeight: 200, 
    marginTop:"7px",
    marginLeft: "10px",  
    fontSize: "7px",
    textTransform: "uppercase"
  },

  tableTextLevelCells: {
    fontWeight: 200, 
    marginTop:"2px",
    marginLeft: "10px",  
    fontSize: "7px",
    textTransform: "uppercase"
  },
    
  tableScholarCell:{
    fontFamily: 'Helvetica',
    margin: "auto",
    marginBottom:"-5px", 
    marginTop: "9px", 
    fontSize: "4.2px" 
  },
  tableHonorsCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
     marginBottom:"-5px", 
    marginTop: 5, 
    fontSize: "4.2px" 
  },
  tableReceivedCell:{
     fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: 5, 
    fontSize: "4.2px" 
  },



// END
// ============================ CIVIL SERVICE ELIGIBILITY ===========================================

// Eligibility TABLE 

  tableEligibility:{
    marginTop:"-1px",
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableEligibilityRow: {
    flexDirection: "row",
    margin: "0", 
    height: 30,
  },

  tableEligibilityValuesRow: {
    flexDirection: "row",
  
  },


  // Eligibility
  tableEligibilityCol: {
    width: "157%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableEligiNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginBottom: "-8px",
    fontSize: "7px" 
  },
  tableEligiText1Cell:{ 
    fontFamily: 'Helvetica',
    marginTop: "1px",
    marginLeft: "20px", 
    fontSize: "6px",
  },
  tableEligiText2Cell:{
    fontFamily: 'Helvetica',
    marginLeft: "60px", 
    marginTop: "0.9px",
    fontSize: "6px",
  },
  tableEligiText3Cell:{
    fontFamily: 'Helvetica',
    marginLeft: "42px", 
    marginTop: "0.9px",
    fontSize: "6px",
  },
    // Value of text box
  tableEligibilityLicenseCol: {
    width: "31%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableEligibilityLicenseCell:{
    fontFamily: 'Helvetica',
    marginTop: 5,
    marginLeft:2, 
    fontSize: "7px" 
  },

   tableEligibilityRatingCell: {
    fontFamily: 'Helvetica',
    marginTop: 5,
    textAlign: "center",
    fontSize: "7px",
  },   
  
  tableEligibilityDateCell: {
    fontFamily: 'Helvetica',
    marginTop: 5,
    textAlign: "center",
    fontSize: "7px",
  },


  //Rating 

  tableRatingCol: {
    width: "57.1%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableRatingTextCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginBottom: "-5px",
    fontSize: "6px",
  },

 tableIfApplicableCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    fontSize: "6px",
  },

  //value of text box
  tableEligibilityRatingCol: {
    width: "11.3%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },



  //DateExam
  tableDateExamCol: {
    width: "61%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  DateExamText1Cell: {
    fontFamily: 'Helvetica',
    marginLeft: "20px",
    marginTop: "3px",
    fontSize: "6px",
  },
   DateExamText2Cell: {
    fontFamily: 'Helvetica',
    marginTop: "0.9px",
    marginLeft: "11px",
    fontSize: "6px",
  },
  DateExamText3Cell: {
    fontFamily: 'Helvetica',
    marginTop: "0.9px",
     marginLeft: "11px",
    fontSize: "6px",
  },

  // Value of Textbox

  tableEligibilityDateCol: {
    width: "12%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },


  //PlaceExam

  tablePlaceExamCol : {
    width: "152%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tablePlaceExamCell: {
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: "12px", 
    fontSize: "6px" 
  },

  // Place exam

  tableEligibilityPlaceCol: {
    width: "30%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  // License
  tableLicenseCol: {
    width: "83%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  
    tableEligibilityCell:{
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: 1, 
    fontSize: "6px" 
  },
  
  // Eligibility License 
  tableEligibilityLicense: {
    display: "table",
    marginBottom:"-1px",
    marginLeft:"-1px", 
    width: "102%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  },


  tableEligibilityLicenseRow: {
    flexDirection: "row",
  },

  tableEligibilityNumberCol: {
    fontFamily: 'Helvetica',
    width: "50%", 
    height: "18px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableEligibilityDateValidityCol: {
    fontFamily: 'Helvetica',
    height: "18px",
    width: "51.2%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLicenseCell: {
    fontFamily: 'Helvetica',
    margin: "auto", 
    marginTop: "3px", 
    fontSize: "6px" 
  },

  tableEligiLicenseNumberCell:{
    marginTop: "10px",
    margin: "auto", 
    fontSize: "6px" 
  }, 

  tableEligiDateValidCell:{
    marginTop: "-5px",
    margin: "auto", 
    fontSize: "7px" 
  }, 

  // Value of textbox
  tableEligiNumberCol: {
    width: "8.1%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableEligiDateValidCol: {
    width: "8.3%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

// end 

// ============================ WORK EXPERIENCE ===========================================


 tableWorkExperience:{
    display: "table", 
    width: "104%", 
    marginTop:"-1px",
    marginLeft: "-11px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableWorkExpRow: {
    flexDirection: "row",
    margin: "0", 
    height: 40,
  },
  
  //Work Experince Date

   tableWorkExpDateCol: {
    width: "141%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
   tableWorkExpNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginBottom: "-8px",
    fontSize: "7px" 
  },
   tableWorkExpText1Cell:{ 
    fontFamily: 'Helvetica',
    marginTop: "1px",
    marginLeft: "29px", 
    fontSize: "6px",
  },
  tableWorkExpText2Cell:{ 
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginLeft: "36px", 
    fontSize: "6px",
  },

  // Work Experience FROM - TO
  tableWorkExpPeriod: {
    display: "table",
    marginTop:"4px",
    marginLeft:"-1px", 
    width: "102%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
    },
  
  
    tableWorkExpPeriodRow: {
      flexDirection: "row",
    },
  
    tableWorkExpFromCol: {
      fontFamily: 'Helvetica',
      width: "50%", 
      height: "16px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableWorkExpToCol: {
      fontFamily: 'Helvetica',
      height: "16px",
      width: "51.2%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableWorkExpFromCell:{
      marginTop: "-2px",
      margin: "auto", 
      fontSize: "6px" 
    }, 
  
    tableWorkExpToCell:{
       marginTop: "-5px",
      margin: "auto", 
      fontSize: "6px" 
    }, 
  
    // Values of text box
  
    tableWorkExpValuesRow:{
      flexDirection: "row",
    },
    
    tableWorkExpFromValueCol: {
      width: "46.6px", 
      height:"23px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
   },
    tableWorkExpToValueCol: {
      width: "48px", 
      height:"23px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableWorkExpFromValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
    tableWorkExpToValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
 

  // Position Title
  tablePositionTitleCol: {
    width: "220%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tablePositionTitleText1Cell: {
    fontFamily: 'Helvetica',
    fontSize: "6px",
    marginTop: "9px",
    marginLeft:"50px"

  },
   tablePositionTitleText2Cell: {
    fontFamily: 'Helvetica',
    fontSize: "6px",
    marginTop: "2px",
    marginLeft: "32px"
  },
   
  // Value of text box
  tableWorkPositionValueCol: {
    width: "25.8%", 
    height:"23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableWorkPositionValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

// Work Department
  tableWorkDeptCol: {
    width: "230%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableWorkDeptText1Cell: {
    fontFamily: 'Helvetica',
    marginTop: "10px", 
    marginLeft:"10px",
    fontSize: "6px",
  },
  tableWorkDeptText2Cell: {
    fontFamily: 'Helvetica',
    marginTop: "1px", 
    marginLeft:"35px",
    fontSize: "6px",
  },
  // Value of textbox
  tableWorkDeptValueCol: {
    width: "27%", 
    height:"23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableWorkDeptValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
 
  // Monthly Salary
  tableWorkSalaryCol:{
    width: "60%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 
  tableWorkSalaryText1Cell: {
    fontFamily: 'Helvetica',
    marginTop: "12px",
    marginLeft:"5px", 
    fontSize: "6px",
  },
  tableWorkSalaryText2Cell: {
    fontFamily: 'Helvetica',
    marginTop: "1px", 
     marginLeft:"6px", 
    fontSize: "6px",
  },
   // Value of textbox
  tableSalaryValueCol: {
    width: "7%", 
    height:"23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableSalaryValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
  
  //Salary Grade
  tableWorkSalaryGradeCol: {
    width: "66%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  }, 
  tableWorkSGText1Cell: {
    fontFamily: 'Helvetica',
    margin: 'auto', 
    fontSize: "4px",
  },
   // Value of textbox
   tableSalaryGradeValueCol: {
    width: "44.3px", 
    height:"23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableSalaryGradeValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },


  //Status of Appointment
  tableWorkStatusCol:{
    width: "67%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableWorkStatusText1Cell:{
    fontFamily: 'Helvetica',
    margin: 'auto', 
    marginBottom:"-12px",
    fontSize: "5px",
  },
  tableWorkStatusText2Cell:{
    fontFamily: 'Helvetica',
    margin: 'auto', 
    fontSize: "5px",
  },
  // Value of textbox
  tableAppointmentValueCol: {
    width: "45px", 
    height:"23px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAppointmentValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

// Government Service
tableWorkGovServiceCol:{
    width: "69%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
}, 
tableWorkGovServiceText1Cell: {
  fontFamily: 'Helvetica',
  marginTop: "8px",
  marginLeft:'13px',
  fontSize: "6px",
},
tableWorkGovServiceText2Cell: {
  fontFamily: 'Helvetica',
  marginTop: '2px',
  marginLeft:'9px',
  fontSize: "6px",
},
tableWorkGovServiceText3Cell: {
  fontFamily: 'Helvetica',
   marginTop: '1px',
    marginLeft:'13px',
  fontSize: "6px",
  },
 // Value of textbox
 tableGovServiceValueCol: {
  width: "46px", 
  height:"23px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableGovServiceValueCell: {
  margin: "auto", 
  fontSize: "7px"
},


// Extended Value of work Experience Sheet

    tableWorkExpSecondPageValuesRow:{
      flexDirection: "row",
    },
    
    tableWorkExpFromSecondPageValueCol: {
      width: "46.6px", 
      height:"22.8px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
   },
    tableWorkExpToSecondPageValueCol: {
      width: "48px", 
      height:"22.8px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableWorkExpFromSecondPageValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
    tableWorkExpToSecondPageValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
 

  // Position Title
  // Value of text box
  tableWorkPositionSecondPageValueCol: {
    width: "25.8%", 
    height:"22.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableWorkPositionSecondPageValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

// Work Department
  // Value of textbox
  tableWorkDeptSecondPageValueCol: {
    width: "27%", 
    height:"22.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },
  tableWorkDeptSecondPageValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
 
  // Monthly Salary
   // Value of textbox
  tableSalarySecondPageValueCol: {
    width: "7%", 
    height:"22.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableSalarySecondPageValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
  
  //Salary Grade
   // Value of textbox
   tableSalaryGradeSecondPageValueCol: {
    width: "44.3px", 
    height:"22.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableSalaryGradeSecondPageValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },


  //Status of Appointment
  // Value of textbox
  tableAppointmentSecondPageValueCol: {
    width: "45px", 
    height:"22.8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAppointmentSecondPageValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

// Government Service

 // Value of textbox
 tableGovServiceSecondPageValueCol: {
  width: "46px", 
  height:"22.8px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableGovServiceSecondPageValueCell: {
  margin: "auto", 
  fontSize: "7px"
  },
// end 
 
// ========== Voluntatry Work table ===========================
tableVoluntaryWork:{
    display: "table", 
    width: "104%", 
    marginTop:"-1px",
    marginLeft: "-11px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableVoluntaryWorkRow: {
    flexDirection: "row",
    margin: "0", 
    height: 32,
  },

  tableVoluntaryWorkValuesRow: {
     flexDirection: "row"
  },

  // Extended Page
  tableExtendedVolWorkValuesRow: {
      flexDirection: "row"
  },
  
  // Name and Address of organization
  tableVoluntaryWorkNameOrgCol: {
    width: "41.9%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableVoluntaryWorkNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginBottom: "-8px",
    fontSize: "7px" 
  },
   tableVoluntaryWorkNameOrgCell1:{
    fontFamily: 'Helvetica',
    marginTop: "1px",
    marginLeft: "80px", 
    fontSize: "6px",
  },
  tableVoluntaryWorkNameOrgCell2:{
    fontFamily: 'Helvetica',
    marginTop: "1px",
    marginLeft: "118px", 
    fontSize: "6px",
  },

  // Values of textbox
  tableVoluntaryOrganizationValueCol: {
    width: "41.9%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableVoluntaryOrganizationValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

    // Extended Values of textbox
  tableExtendedVolOrganizationValueCol: {
    width: "42%", 
    height:"20.9px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableExtendedVolOrganizationValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

  // Inclusive Dates of Organization 

  tableVoluntaryDatesCol: {
    width: "82px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,

  }, 
  tableVoluntaryDatesText1Cell: {
    fontFamily: 'Helvetica',
    marginLeft: '15px',
    marginTop: '3px',
    fontSize: "6px",

  },
  tableVoluntaryDatesText2Cell: {
    fontFamily: 'Helvetica',
    fontSize: "6px",
     marginLeft: '23px',
     marginTop: '2px'
  }, 

  // Work Experience FROM - TO
  tableVoluntaryDatesPeriod: {
    display: "table",
    marginLeft:"-1px",
    marginTop: "5px",
    width: "102%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 , 

    },
  
    tableVoluntaryDatesPeriodRow: {
      flexDirection: "row",
    },
  
    tableVoluntaryFromCol: {
      fontFamily: 'Helvetica',
      width: "50%", 
      height: "9px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableVoluntaryToCol: {
      fontFamily: 'Helvetica',
      height: "9px",
      width: "51.2%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableVoluntaryFromCell:{
      marginTop: "-2px",
      margin: "auto", 
      fontSize: "6px" 
    }, 
  
    tableVoluntaryToCell:{
       marginTop: "-5px",
      margin: "auto", 
      fontSize: "6px" 
    }, 

    // Table values
    tableVoluntaryWorkFromValueCol: {
      width: "40.6px", 
      height:"21px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
   },
    tableVoluntaryWorkToValueCol: {
      width: "41.6px", 
      height:"21px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableVoluntaryWorkFromValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
    tableVoluntaryWorkToValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },

    // Extended Table values
    tableExtendedVolWorkFromValueCol: {
      width: "40.6px", 
      height:"20.9px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
   },
    tableExtendedVolWorkToValueCol: {
      width: "41.6px", 
      height:"20.9px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
    tableExtendedVolWorkFromValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },
    tableExtendedVolWorkToValueCell: {
      margin: "auto", 
      fontSize: "7px" 
    },



  // Number of Hours
  tableVoluntaryHoursCol: {
    width: "45px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  }, 

  tableVoluntaryHoursText1Cell: {
    fontFamily: 'Helvetica',
    margin:"auto",
    marginTop: "10px",
    marginBottom: "-10px",
    fontSize: "4.8px",
  },

  tableVoluntaryHoursText2Cell: {
    fontFamily: 'Helvetica',
    margin:"auto",
    fontSize: "4.8px",
  },

  // Values of textbox
  tableVoluntaryHoursValueCol: {
    width: "45.2px", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableVoluntaryHoursValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

  // Extended Values of textbox
  tableExtendedVolHoursValueCol: {
    width: "45.2px", 
    height:"20.9px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableExtendedVolHoursValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },


 // Ppsition / Nature of Work
  tableVoluntaryPositionCol: {  
    width: "36%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  }, 
  tableVoluntaryPositionText1Cell:{
    fontFamily: 'Helvetica',
    marginTop: "3px",
    margin:"auto",
    fontSize: "6px",
  },
   // Values of textbox
  tableVoluntaryPositionValueCol: {
    width: "36%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableVoluntaryPositionValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

   // Exteneded Values of textbox
  tableExtendedVolPositionValueCol: {
    width: "36%", 
    height:"20.9px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableExtendedVolPositionValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
  // end


// ========= Learning Development table ===========================

  tableLearningDev:{
    display: "table", 
    width: "104%", 
    marginTop:"-1px",
    marginLeft: "-11px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableLearningDevRow: {
    flexDirection: "row",
    margin: "0", 
    height: 41,
  },

  tableLearningDevValuesRow: {
     flexDirection: "row"
  },

  tableLearningDevValuesExtendedRow: {
     flexDirection: "row"
  },
  
  // Name of Training Programs
  tableLearningDevTrainingCol: {
    width: "42%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableLearningDevNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "12px",
    marginBottom: "-10px",
    marginLeft : "1px",
    fontSize: "6px" 
  },
  tableLearningDevTrainingCell1:{
    fontFamily: 'Helvetica',
    marginTop: "4px",
    marginLeft: "20px", 
    fontSize: "5.4px",
  },
  tableLearningDevTrainingCell2:{
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft: "113px", 
    fontSize: "5.4px",
  },

   // Values of textbox
   tableLearningDevValueCol: {
    width: "41.6%", 
    height: "21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableLearningDevValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

   // Extended Values of textbox
   tableLearningDevValueExtendedCol: {
    width: "41.6%", 
    height: "21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableLearningDevValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },
   
  // Inclusive Dates of Attendance

  tableLearningDevDatesCol: {
    width: "82px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,

  }, 
  tableLearningDevDatesText1Cell: {
    fontFamily: 'Helvetica',
    marginLeft: '15px',
    marginTop: '3px',
    fontSize: "6px",

  },
  tableLearningDevDatesText2Cell: {
    fontFamily: 'Helvetica',
    fontSize: "6px",
     marginLeft: '23px',
     marginTop: '2px'
  },
  tableLearningDevDatesText3Cell: {
    fontFamily: 'Helvetica',
    fontSize: "6px",
     marginLeft: '26px',
     marginTop: '2px'
  }, 

  // Learning Development FROM - TO
  tableLearningDevDatesPeriod: {
    display: "table",
    marginLeft:"-1px",
    marginTop: "6px",
    width: "102%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 , 

    },
  
    tableLearningDevDatesPeriodRow: {
      flexDirection: "row",
    },
  
    tableLearningDevFromCol: {
      fontFamily: 'Helvetica',
      width: "50%", 
      height: "9px",
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableLearningDevToCol: {
      fontFamily: 'Helvetica',
      height: "9px",
      width: "51.2%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0
    },
  
    tableLearningDevFromCell:{
      marginTop: "-2px",
      margin: "auto", 
      fontSize: "6px" 
    }, 
  
    tableLearningDevToCell:{
       marginTop: "-5px",
      margin: "auto", 
      fontSize: "6px" 
  }, 
   
  // Table values
   tableLearningDevFromValueCol: {
    width: "40.1px", 
    height:"21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
 },
 tableLearningDevToValueCol: {
    width: "41.1px", 
    height:"21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableLearningDevFromValueCell: {
    margin: "auto", 
    fontSize: "7px" 
  },
  tableLearningDevToValueCell: {
    margin: "auto", 
    fontSize: "7px" 
  },

  // Extended Value of textbox
   tableLearningDevFromValueExtendedCol: {
    width: "40.1px", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
 },
 tableLearningDevToValueExtendedCol: {
    width: "41.1px", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableLearningDevFromValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px" 
  },
  tableLearningDevToValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px" 
  },

    
    // Number of Hours Learning 
    tableLearningDevHoursCol: {
      width: "45px",
      height: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    }, 

    tableLearningDevHoursText1Cell: {
      fontFamily: 'Helvetica',
      margin:"auto",
      marginTop: "15px",
      marginBottom: "-14px",
      fontSize: "5px",
    },

    tableLearningDevHoursText2Cell: {
      fontFamily: 'Helvetica',
      margin:"auto",
      fontSize: "5px",
  },
    
  // Values of textbox
  tableLearningDevHoursValueCol: {
    width: "45px", 
    height:"21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevHoursValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

   // Extended Values of textbox
  tableLearningDevHoursValueExtendedCol: {
    width: "45px", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevHoursValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },
    
    // Type of Learning Development 
    tableLearningDevTypeLDCol: {
      width: "50px",
      height: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    }, 

    tableLearningDevTypeLDText1Cell: {
      fontFamily: 'Helvetica',
      margin:"auto",
      marginTop: "7px",
      marginBottom: "-2px",
      fontSize: "5.5px",
    },
    tableLearningDevTypeLDText2Cell: {
      fontFamily: 'Helvetica',
      margin: "auto",
      marginBottom: "-2px",
      fontSize: "5.5px",
    },
    tableLearningDevTypeLDText3Cell: {
      fontFamily: 'Helvetica',
      margin: "auto",
      marginBottom: "-2px",
      fontSize: "5.5px",
    },
    tableLearningDevTypeLDText4Cell: {
      fontFamily: 'Helvetica',
      margin: "auto",
      fontSize: "5.5px",
  },
    
    // Values of textbox
  tableLearningDevTypeLDValueCol: {
    width: "49.2px", 
    height:"21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevTypeLDValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

   // Extended Values of textbox
  tableLearningDevTypeLDValueExtendedCol: {
    width: "49.2px", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevTypeLDValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },
    
    // Learning Developement Conducted
    tableLearningDevConductedCol: {
      width: "28%",
      height: "100%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0,
    }, 

    tableLearningDevConductedText1Cell: {
      fontFamily: 'Helvetica',
      margin:"auto",
      marginTop: "15px",
      marginBottom: "-14px",
      fontSize: "5px",
    },

    tableLearningDevConductedText2Cell: {
      fontFamily: 'Helvetica',
      margin:"auto",
      fontSize: "5px",
   },
    // Values of textbox
  tableLearningDevConductedValueCol: {
    width: "27.8%", 
    height:"21.2px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevConductedValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },
  

   // Extended Values of textbox
  tableLearningDevConductedValueExtendedCol: {
    width: "27.8%", 
    height:"21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableLearningDevConductedValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },
  
  // ========= Other Information table ===========================

  tableOtherInformation:{
    display: "table", 
    width: "104%", 
    marginTop:"-1px",
    marginLeft: "-11px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  
  tableOtherInfoRow: {
    flexDirection: "row",
    margin: "0", 
    height: 32,
  },

  tableOtherInfoValuesRow: {
     flexDirection: "row"
  },

  // Name of Hobbies
  tableOtherInfoHobbiesCol: {
    width: "28%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoHobbiesNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "12px",
    marginBottom: "-8px",
    marginLeft : "2px",
    fontSize: "6px" 
  },
  tableOtherInfoHobbiesCell1:{
    fontFamily: 'Helvetica',
    marginTop: "1px",
    marginLeft: "40px", 
    fontSize: "5.4px",
  },

  // Values of textbox
   tableOtherInfoHobbiesValueCol: {
    width: "27.7%", 
    height: "21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoHobbiesValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

  // Extended Values of textbox
   tableOtherInfoHobbiesValueExtendedCol: {
    width: "27.7%", 
    height: "21.4px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoHobbiesValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },

  // Recognation 
  tableOtherInfoRecognationCol: {
    width: "45%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoRecognationNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "12px",
    marginBottom: "-10px",
    marginLeft : "2px",
    fontSize: "6px" 
  },
  tableOtherInfoRecognationCell1:{
    fontFamily: 'Helvetica',
    marginTop: "-1px",
    marginLeft: "60px", 
    fontSize: "5.4px",
  },
  tableOtherInfoRecognationCell2: {
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft: "111px", 
    fontSize: "5.4px",
  },
  // Values of textbox
   tableOtherInfoRecognationValueCol: {
    width: "44.6%", 
    height: "21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoRecognationValueCell: {
    margin: "auto", 
    fontSize: "7px"
  }, 

  // Extended Values of textbox
   tableOtherInfoRecognationValueExtendedCol: {
    width: "44.6%", 
    height: "21.4px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoRecognationValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  }, 

  // Name of Organization 
  tableOtherInfoOrganizationCol: {
    width: "28%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoOrganizationNumberCell:{
    fontFamily: 'Helvetica',
    marginTop: "12px",
    marginBottom: "-8px",
    marginLeft : "2px",
    fontSize: "6px" 
  },
  tableOtherInfoOrganizationCell1:{
     fontFamily: 'Helvetica',
    marginTop: "-2px",
    marginLeft: "20px", 
    fontSize: "5.4px",
  },
   tableOtherInfoOrganizationCell2: {
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft: "65px", 
    fontSize: "5.4px",
  }, 

  // Values of textbox
   tableOtherInfoOrganizationValueCol: {
    width: "27.7%", 
    height: "21px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoOrganizationValueCell: {
    margin: "auto", 
    fontSize: "7px"
  },

  // Extended Values of textbox
   tableOtherInfoOrganizationValueExtendedCol: {
    width: "27.7%", 
    height: "21.4px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableOtherInfoOrganizationValueExtendedCell: {
    margin: "auto", 
    fontSize: "7px"
  },


  // ========== Other Information Question================
  // Question No. 34

  tableQuestions34:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-11px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 

   tableQuestions34Row: {
    flexDirection: "row",
    margin: "0", 
    height: 80,
  },

  tableQuestion34Col: {
    width: "385px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion34Cell:{
    fontFamily: 'Helvetica',
    fontWeight: 'semibold',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-9px",
    fontSize: "8px" 
  },
  tableQuestion34CellSetA:{
    fontFamily: 'Helvetica',
    fontSize: "7.5px", 
    marginTop:"11px",
    marginLeft: "18px"
  },
  tableQuestion34CellSetB:{
    fontFamily: 'Helvetica',
    fontSize: "7.5px", 
    marginTop:"3px",
    marginLeft: "18px"
  },

  tableAnswer34Col: {
    width: "200px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAnswer34CellSetAYes:{
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "30px",
    marginLeft: "10px",
   
  },
  tableAnswer34CellSetANo:{
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-8px",
     marginLeft:"60px",
  },

  tableAnswer34CellSetBYes:{
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
     marginLeft: "10px",
   
  },
  tableAnswer34CellSetBNo:{
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-8px",
    marginLeft:"60px",
  },
  tableAnswer34CellDetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "10px",
  },
  tableDetailsAnswer34Cell: { 
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "5px",
    marginLeft: "12px",
  }, 

   // Question No. 34
  tableQuestions35:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 

   tableQuestions35Row: {
    flexDirection: "row",
    margin: "0", 
    height: 100,
  },

  tableQuestion35Col: {
    width: "390px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion35CellSetA:{
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-8px",
    fontSize: "8px" 
  },
  tableQuestion35CellSetB:{
    fontFamily: 'Helvetica',
    marginTop: "40px",
    marginLeft:"15px",
    marginBottom: "-8px",
    fontSize: "8px" 
  },

  tableAnswer35Col: {
    width: "203px",
    height: "100%",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  
  // css  35 a
  tableAnswer35: {
    display: "table", 
    width: "101%", 
    marginLeft: "-1px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  },
  tableAnswer35Rows: {
    flexDirection: "row",
    margin: "0", 
    height: 62,
  },
  tableAnswerSetA35Cols: {
    width: "100%",
    height: "85%",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

    tableAnswerSetB35Cols: {
    width: "100%",
    height: "102%",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },


  tableAnswer35CellsSetAYes:{
    fontFamily: 'Helvetica',
    marginTop: "6px",
    marginLeft:"10px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },

  tableAnswer35CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
     marginLeft:"60px",
  },
  tableAnswer35CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "9px",
  },
  tableDetailsAnswer35CellSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "4px",
    marginLeft: "12px",
  },
  tableAnswer35CellsSetBYes: {
    fontFamily: 'Helvetica',
    marginTop: "-1px",
    marginLeft:"10px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer35CellsSetBNo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
     marginLeft:"60px",
  },
  tableAnswer35CellSetBDetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "3px",
    marginLeft: "9px",
  },
  tableDetailsAnswer35CellSetBDate : {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "3px",
    marginLeft: "70px",
  },
  tableDetailsAnswer35CellSetBCase: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "4px",
    marginLeft: "50px",
  },


  // Question No. 36
  tableQuestions36:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
   tableQuestions36Row: {
    flexDirection: "row",
    margin: "0", 
    height: 42,
  },
  tableQuestion36Col: {
    width: "388px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion36CellSetA:{
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-15px",
    fontSize: "8px" 
  },
  tableAnswer36Col: {
    width: "202px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAnswer36CellSetAYes: {
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer36CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
     marginLeft:"60px",
  },
  tableAnswer36CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "8px",
  }, 
  tableDetailsAnswer36CellSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "4px",
    marginLeft: "12px",
  },

  // Question No. 37
  tableQuestions37:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
   tableQuestions37Row: {
    flexDirection: "row",
    margin: "0", 
    height: 40,
  },
  tableQuestion37Col: {
     width: "388px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion37CellSetA: {
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-15px",
    fontSize: "8px" 
  },
  tableQuestion37CellSetA1: {
    fontFamily: 'Helvetica',
    marginTop: "16px",
    marginLeft:"19px",
    marginBottom: "-10px",
    fontSize: "8px" 
  },
  tableAnswer37Col: {
   width: "202px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAnswer37CellSetAYes: {
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer37CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"60px",
  },
  tableAnswer37CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "8px",
  }, 
  tableDetailsAnswer37CellSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "12px",
  },
  // Question No. 38
  tableQuestions38:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
   tableQuestions38Row: {
    flexDirection: "row",
    margin: "0", 
    height: 58,
  },
  tableQuestion38Col: {
     width: "388px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion38CellSetA: {
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-15px",
    fontSize: "8px" 
  },
  tableQuestion38CellSetB: {
    fontFamily: 'Helvetica',
    marginTop: "28px",
    marginLeft:"8px",
    marginBottom: "-15px",
    fontSize: "8px" 
  },

  tableAnswer38Col: {
    width: "202px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAnswer38CellSetAYes: {
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer38CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer38CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "8px",
  }, 

  Answer38DetailsSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
  },


  tableDetailsAnswer38CellSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "12px",
  },

  // 
  tableAnswer38CellSetBYes: {
    fontFamily: 'Helvetica',
    marginTop: "8px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer38CellsSetBNo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer38CellSetBDetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "8px",
  }, 
  tableDetailsAnswer38CellSetB: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "12px",
  },
  // Question No. 39
  tableQuestions39:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
   tableQuestions39Row: {
    flexDirection: "row",
    margin: "0", 
    height: 40,
  },
  tableQuestion39Col: {
    width: "388px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion39CellSetA:{
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-15px",
    fontSize: "8px" 
  },

  tableAnswer39Col: {
    width: "202px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableAnswer39CellSetAYes: {
    fontFamily: 'Helvetica',
    marginTop: "3px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer39CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer39CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "8px",
  }, 
  tableDetailsAnswer39CellSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "4px",
    marginLeft: "12px",
  },

  //  // Question No. 40
  tableQuestions40:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
   tableQuestions40Row: {
    flexDirection: "row",
    margin: "0", 
    height: 102,
  },

  tableQuestion40Col: {
    width: "388px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableQuestion40Cell:{
    fontFamily: 'Helvetica',
    marginTop: "2px",
    marginLeft:"2px",
    marginBottom: "-9px",
    fontSize: "8px" 
  },
  tableQuestion40CellSetA:{
    fontFamily: 'Helvetica',
    fontSize: "8px", 
    marginTop:"12px",
    marginLeft: "1px"
  },
  tableQuestion40CellSetB:{
    fontFamily: 'Helvetica',
    fontSize: "8px", 
    marginTop:"18px",
    marginLeft: "1px"
  },

   tableQuestion40CellSetC:{
    fontFamily: 'Helvetica',
    fontSize: "8px", 
    marginTop:"16px",
    marginLeft: "1px"
  },
  tableAnswer40Col: {
    width: "202px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },

  tableAnswer40CellSetAYes: {
    fontFamily: 'Helvetica',
    marginTop: "25px",
    marginLeft:"8px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer40CellsSetANo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer40CellSetADetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "1px",
  }, 

  Answer40DetailsSetA: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginLeft:"110px",
    marginTop: "-4px"
  },
  //
  tableAnswer40CellSetBYes: {
    fontFamily: 'Helvetica',
    marginLeft:"8px",
    marginTop:"2px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer40CellsSetBNo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer40CellSetBDetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "1px",
  }, 

  Answer40DetailsSetB: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginLeft:"110px",
    marginTop: "-4px"
  },
  //
  tableAnswer40CellSetCYes: {
    fontFamily: 'Helvetica',
    marginLeft:"8px",
    marginTop:"6px",
    marginBottom: "-8px",
    fontSize: "7.5px" 
  },
  tableAnswer40CellsSetCNo: {
    fontFamily: 'Helvetica',
    fontSize: "8px" ,
    marginTop: "-0.5px",
    marginLeft:"78px",
  },
  tableAnswer40CellSetCDetails: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "2px",
    marginLeft: "1px",
  }, 

  Answer40DetailsSetC: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginLeft:"110px",
    marginTop: "-8px"
  }, 

  // REFERENCES

  tableReference:{
    display: "table", 
    width: "104%", 
    marginLeft: "-11px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 2, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  }, 
  tableReferenceRow: {
    flexDirection: "row",
    margin: "0", 
    height: 178.8,
  },
  tableReferenceBody: {
    width: "435px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableReferenceHeader: {
    display: "table", 
    width: "103.8%", 
    marginLeft: "-1px", 
    marginTop: "-1px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0  
  },
  tableReferenceHeaderRow: {
    flexDirection: "row",
    margin: "0", 
    height: 25,
  },
  tableReferenceHeaderCol: {
     width: "435px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
  tableReferenceHeaderCell : {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginTop: "6px", 
    marginLeft: "4px"
  },

  // tableReferenceTH: {
  //   display: "table", 
  //   width: "104%", 
  //   marginLeft: "-2px", 
  //   marginTop: "-1px",
  //   borderStyle: "solid", 
  //   borderWidth: 1, 
  //   borderRightWidth: 0, 
  //   borderBottomWidth: 0 
  // },
  tableReferenceTHRow: {
    flexDirection: "row",
    margin: "0", 
    height: 28,
  },
  tableReferenceTHNameCol: {
    width: "47.8%",
    height: "70%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHNameCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginLeft:"110px",
    marginTop: "4px"
  
  },
   tableReferenceTHAddressCol: {
    width: "36%",
    height: "70%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHAddressCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    marginLeft:"70px",
    marginTop: "4px"
  
  },
   tableReferenceTHTellCol: {
    width: "13%",
    height: "70%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHTellCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
     marginLeft:"12px",
    marginTop: "4px"
  },

  //
  tableReferenceTBValue: {
    display: "table", 
    width: "100.4%", 
    marginLeft: "-1px", 
    marginTop: "-8px",
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  },

  tableReferenceTHValueRow: {
    flexDirection: "row",
    margin: "0", 
    height: 26,
  },

   tableReferenceTHNameValueCol: {
    width: "240.8px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHNameValueCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    textAlign:"center",
    textTransform:"uppercase",
    fontWeight: "200px",
    marginTop: "4px"
  
  },
   tableReferenceTHAddressValueCol: {
    width: "41.8%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHAddressValueCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    textAlign:"center",
    textTransform:"uppercase",
    fontWeight: "200px",
    marginTop: "4px"
  
  },
   tableReferenceTHTellValueCol: {
    width: "15%",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },  
  tableReferenceTHTellValueCell: {
    fontFamily: 'Helvetica',
    fontSize: "8px",
    textAlign:"center",
    textTransform:"uppercase",
    fontWeight: "200px",
    marginTop: "4px"
  },

//
tableReferenceDescription: {
  display: "table", 
  width: "100.4%", 
  backgroundColor:"#D3D3D3",
  marginLeft: "-1px", 
  marginTop: "-1px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableReferenceDescRow: {
  flexDirection: "row",
  margin: "0", 
  height: "75px",
},
tableReferenceDescCol: {
  width: "800px",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableReferenceDescCell : {
  fontFamily: 'Helvetica',
  fontSize: "8.5px",
  marginTop: "3px", 
  marginLeft: "4px"
  },
  tableReferenceDesc2Cell : {
    fontFamily: 'Helvetica',
    fontSize: "8.5px",
    marginTop: "2px", 
    marginLeft: "4px"
  },
  tableReferenceDesc3Cell : {
    fontFamily: 'Helvetica',
    fontSize: "8.3px",
    marginTop: "2px", 
    marginLeft: "4px"
  },
  tableReferenceDesc4Cell : {
    fontFamily: 'Helvetica',
    fontSize: "9.2px",
    marginTop: "2px", 
    marginLeft: "4px"
    },

//
tableGovernmentID: {
  display: "table", 
  width: "37%", 
  marginLeft: "-1px", 
  marginTop: "6px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableGovernmentIDRow: {
  flexDirection: "row",
  margin: "0", 
  height: "91px",
},

tableDetailsGovernmentID: {
  display: "table", 
  width: "101%", 
  marginLeft: "-1px", 
   marginTop: "-1px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableDetailsGovernmentIDRow: {
  flexDirection: "row",
  margin: "0", 
  height: "23px",
},
tableGovernmentIDCol: {
  width: "440px",
  height: "102%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableGovernmentIDsCol: {
  width: "440px",
  height: "98%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableGovernmentIDCell : {
  fontFamily: 'Helvetica',
  fontSize: "6.6px",
  marginTop: "3px", 
  marginLeft: "4px"
  },

tableGovIssuedIDCol: {
    width: "440px",
    height: "98%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
tableGovIssuedIDCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "5px", 
  marginLeft: "4px"
},

tableIssuedIDCol: {
    width: "440px",
    height: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
tableIssuedIDCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "5px", 
  marginLeft: "4px"
},
tableDateIssuedIDCol: {
    width: "440px",
    height: "100.3%",
    marginBottom:"-3px", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0
  },
tableDateIssuedIDCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "5px", 
  marginLeft: "4px"
},
  

//   
tableSignature: {
  display: "table", 
  width: "40%", 
  marginLeft: "205px", 
  marginTop: "-92px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableSignatureRow: {
  flexDirection: "row",
  margin: "0", 
   height: "92.9px",
},
tableSignatureCol: {
  width: "440px",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableSignatureCell : {
  fontFamily: 'Helvetica',
  fontSize: "8.5px",
  marginTop: "3px", 
  marginLeft: "4px"
  },


tableSign: {
  display: "table", 
  width: "101%", 
  marginLeft: "-1px", 
  marginTop: "56.4px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0
},

tableSignRow: {
  flexDirection: "row",
  margin: "0", 
  height: "22px",
},

tableSignCol: {
  width: "440px",
  height: "100.2%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableSignCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "1px", 
  marginLeft: "60px"
  },
//
tableSignDate: {
  display: "table", 
  width: "101%", 
  marginLeft: "-1px", 
  marginTop: "56px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0
},


tableSignDateRow: {
  flexDirection: "row",
  margin: "0", 
  height: "23px",
},

tableSignDateCol: {
  width: "440px",
  height: "100.2%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableSignDateCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "1px", 
  marginLeft: "78px"
  },

// 
tablePhoto: {
  display: "table", 
  width: "17.8%", 
  marginLeft: "450px", 
  marginTop: "-258px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tablePhotoRow: {
  flexDirection: "row",
  margin: "0", 
  height: "116px",
},
tablePhotoCol: {
  width: "440px",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},




// 
tableThumbmark: {
  display: "table", 
  width: "22%", 
  marginLeft: "435px", 
  marginTop: "27.5px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableThumbmarkRow: {
  flexDirection: "row",
  margin: "0", 
  height: "112.4px",
},
tableThumbmarkCol: {
  width: "440px",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},

tableRightThumb: {
  display: "table", 
  width: "101.7%", 
  marginLeft: "-1px", 
  marginTop: "98.6px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0
},

tableRightThumbRow: {
  flexDirection: "row",
  margin: "0", 
  height: "13px",
},

tableRightThumbCol: {
  width: "130px",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableRightThumbCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "1px", 
  marginLeft: "35px"
  },

  // 
tableOath: {
  display: "table", 
  width: "104%", 
  marginLeft: "-11px", 
  marginTop: "9px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableOathRow: {
  flexDirection: "row",
  margin: "0", 
  height: "125px",
},
tableOathCol: {
  width: "100%",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableOathCell : {
  fontFamily: 'Helvetica',
  fontSize: "7px",
  marginTop: "10px",
  marginBottom: "-20", 
  marginLeft: "34px"
  },
//
tableInnerOath: {
  display: "table", 
  width: "39.2%", 
  marginLeft: "212px", 
  marginTop: "30px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableInnerOathRow: {
  flexDirection: "row",
  margin: "0", 
  height: "80px",
},
tableInnerOathCol:{
  width: "100%",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},

//
tableAdministeringOath: {
  display: "table", 
  width: "101%", 
  marginLeft: "-1px", 
  marginTop: "60.2px",
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableAdministeringOathRow: {
  flexDirection: "row",
  margin: "0", 
  height: "18px",
},
tableAdministeringOathCol:{
  width: "100%",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},
tableAdministeringOathCell : {
  fontFamily: 'Helvetica',
  fontSize: "9px",
  marginTop: "3px", 
  marginLeft: "60px"
},
//

tableFooterPage4: {
  display: "table", 
  width: "104%",
  marginLeft:"-11px",
  marginTop: "-1px",
  marginBottom: "-30px", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderRightWidth: 0, 
  borderBottomWidth: 0  
},
tableFooterPage4Row: {
  flexDirection: "row",
  margin: "0", 
  height: "20.2px",
},
tableFooterPage4Col:{
  width: "100%",
  height: "100%", 
  borderStyle: "solid", 
  borderWidth: 1, 
  borderLeftWidth: 0, 
  borderTopWidth: 0
},

});



export default function LayoutPDS({fetchInfo, fetchSpouse, fetchParent, fetchChildren, fetchEduc, fetchEligi, fetchWorkExp, fetchVolExp, fetchLearnDev, fetchSkills, fetchQues, fetchRef, fetchImage}) {


  
  
  const levels = ['Elementary', 'Secondary', 'Vocational/Trade Course', 'College', 'Graduate Studies'];

  
  return (
      <Document title="Personal Data Sheet"> 
        <Page size="LEGAL" style={styles.page}>
          <View style={styles.section}>
             <Text style={styles.csform}>
                    CS Form No.212
                </Text>


                <Text style={styles.revise}>
                    Revised 2017
                </Text>

                <Text style={styles.title}>
                    PERSONAL DATA SHEET
                </Text>

                <Text style={styles.warning}>
                    WARNING: Any misrepresentation made in the Personal Data Sheet and the Work Experience Sheet shall cause the filing of administrative/criminal case/s against the person concerned.
                </Text>

                <Text style={styles.read}>
                   READ THE ATTACHED GUIDE TO FILLING OUT THE PERSONAL DATA SHEET(PDS) BEFORE ACCOMPLISHING THE PDS FORM.
                </Text>

                <Text style={styles.print}>
                  Print legibly. Tick appropriate boxes (<Image src={Unchecked} />) and use separate sheet if necessary. Indicate N/A if not applicable. <Text style={{ fontFamily: "Helvetica-Bold" }}>DO NOT ABBREVIATE</Text>
                </Text>
                
                <View style={{border:"1px solid black", width:"40px", height:"10px",flexWrap: "wrap", marginLeft: "386px", marginTop:"-9px", backgroundColor:'#A9A9A9' }}>
                  <Text style={{fontSize: "6px", fontFamily: "Helvetica", marginLeft:"3px" }}>1. CS ID No. </Text>
                </View>

                <View style={{border:"1px solid black", width:"138px", height:"10px", marginLeft: "425px", marginTop:"-10px" }}>
                  <Text style={{ marginTop:"1px" ,fontSize: "5px", fontFamily: "Helvetica", marginLeft:"60px" }}> (Do not fill up. For CSC use only) </Text>
                </View>
                
       


              {/* =========================================== PERSONAL INFORMATION ==================================================================== */}

                <View style={styles.table}>
                    <View style={styles.tableRowPS}>
                        <View style={styles.tableColPS}>
                            <Text style={styles.tableCellPS}>I. PERSONAL INFORMATION</Text>
                        </View>
                    </View>
                    
                    <View style={styles.tableRowName}>
                        <View style={styles.tableColName}>
                            <Text style={styles.tableCellSurname}>
                                2. SURNAME
                            </Text>
                            <Text style={styles.tableCellFirstname}>
                                FIRST NAME
                            </Text>
                             <Text style={styles.tableCellMiddlename}>
                                MIDDLE NAME
                            </Text>
                        </View>
                    </View>

                  <View style={styles.tableRowSurnamefield}>
                      <View style={styles.tableColSurnamefield}>
                          <Text style={styles.tableCellValueSurname} >
                              {fetchInfo.SURNAME ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                   <View style={styles.tableRowFirstnamefield}>
                      <View style={styles.tableColFirstnamefield}>
                          <Text style={styles.tableCellValueFirstname} >
                               {fetchInfo.FINAME ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowExtensionNamefield}>
                      <View style={styles.tableColExtensionNamefield}>
                          <Text style={styles.tableCellExtensionName} >
                              NAME EXTENSION (JR, SR)
                          </Text>

                          <Text style={styles.tableCellValueExtensionName} >
                            {/* {fetchInfo[0]?.extension ?? "N/A"} */}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowMiddlenamefield}>
                      <View style={styles.tableColMiddlenamefield}>
                          <Text style={styles.tableCellValueMiddlename} >
                               {fetchInfo.MIDNAME ?? "N/A"}
                          </Text>
                      </View>
                  </View>
                  
                   <View style={styles.tableRowDatebirth}>
                      <View style={styles.tableColDatebirth}>
                          <Text style={styles.tableCellDatebirth} >
                            3. DATE OF BIRTH
                          </Text>
                           <Text style={styles.tableCellFormatDatebirth} >
                              (mm/dd/yyyy)
                          </Text>
                      </View>
                  </View>

                   <View style={styles.tableRowDatebirthfield}>
                      <View style={styles.tableColDatebirthfield}>
                          <Text style={styles.tableCellValueDatebirth} >
                             {fetchInfo.dbirth ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowPlacebirth}>
                      <View style={styles.tableColPlacebirth}>
                          <Text style={styles.tableCellPlacebirth} >
                              4. PLACE OF BIRTH
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowPlacebirthfield}>
                      <View style={styles.tableColPlacebirthfield}>
                          <Text style={styles.tableCellValuePlacebirth} >
                              {fetchInfo.pbirth ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowSex}>
                      <View style={styles.tableColSex}>
                          <Text style={styles.tableCellSex} >
                             5. SEX
                          </Text>
                      </View>
                  </View>
                  
                   <View style={styles.tableRowSexfield}>
                      <View style={styles.tableColSexfield}>
                          <Text style={styles.tableCellValueSexMale} >
                            {
                                fetchInfo.sex === "Male" ? 
                                <>
                                  <Image src={checked} style={{ width: '6px', height: '6px'  }} />&nbsp;&nbsp;Male
                                </> : 
                                <>
                                <Image src={Unchecked} style={{ width: '6px', height: '6px'  }} />&nbsp;&nbsp;Male
                                </>
                            }

                              
                          </Text>

                           <Text style={styles.tableCellValueSexFemale} >
                            {
                              fetchInfo.sex === "Female" ?  
                             <>
                              <Image src={checked} style={{ width: '6px', height: '6px'}} />&nbsp;&nbsp;Female
                             </> :
                             <>
                              <Image src={Unchecked} style={{ width: '6px', height: '6px'}} />&nbsp;&nbsp;Female
                             </>
                            }
                          </Text>
                      </View>
                  </View>
                  
                  <View style={styles.tableRowCivilstatus}>
                      <View style={styles.tableColCivilstatus}>
                          <Text style={styles.tableCellCivilstatus} >
                             6. CIVIL STATUS
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowCSfield}>
                      <View style={styles.tableColCSfield}>
                          <Text style={styles.tableCellValueCSSingle} >
                              {
                                fetchInfo.civistat === "Single" ?
                                <>
                                  <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Single
                                </> : 
                                <>
                                 <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Single
                                </>
                              }                       
                          </Text>

                          <Text style={styles.tableCellValueCSWidowed} >
                            {
                              fetchInfo.civistat === "Widowed" ? 
                              <>
                                <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Widowed
                              </> : 
                              <>
                                <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Widowed
                              </>
                            }
                          </Text>

                          <Text style={styles.tableCellValueCSOthers} >
                              {
                                fetchInfo.civistat === "Other" ?
                                <>
                                  <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Other/s:
                                </> : 
                                <>
                                  <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Other/s:
                                </>
                              }

                              
                          </Text>

                          <Text style={styles.tableCellValueCSMarried} >
                            {
                              fetchInfo.civistat === "Married" ?
                              <>
                                <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Married
                              </> : 
                              <>
                                <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Married
                              </>
                            }
                          </Text>

                          <Text style={styles.tableCellValueCSSeparated} >
                            {
                              fetchInfo.civistat === "Separated" ?
                              <>
                                 <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Separated
                              </> :
                              <>
                                 <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Separated
                              </>
                            }

                             
                          </Text>
                      </View>
                  </View> 

                  <View style={styles.tableRowHeight}>
                      <View style={styles.tableColHeight}>
                          <Text style={styles.tableCellHeight} >
                            7. HEIGHT (m)
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowHeightfield}>
                      <View style={styles.tableColHeightfield}>
                          <Text style={styles.tableCellValueHeight} >
                              {fetchInfo.height ?? "N/A"}
                          </Text>
                      </View>
                  </View>
                  
                   <View style={styles.tableRowWeight}>
                      <View style={styles.tableColWeight}>
                          <Text style={styles.tableCellWeight} >
                            8. WEIGHT (kg)
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowWeightfield}>
                      <View style={styles.tableColWeightfield}>
                          <Text style={styles.tableCellValueWeight} >
                               {fetchInfo.weight ?? "N/A"}
                          </Text>
                      </View>
                  </View>


                  <View style={styles.tableRowBloodtype}>
                      <View style={styles.tableColBloodtype}>
                          <Text style={styles.tableCellBloodtype} >
                            9. BLOOD TYPE
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowBloodtypefield}>
                      <View style={styles.tableColBloodtypefield}>
                          <Text style={styles.tableCellValueBloodtype} >
                           {fetchInfo.bloodtype ?? "N/A"}
                          </Text>
                      </View>
                  </View>
                  
                    <View style={styles.tableRowGSIS}>
                      <View style={styles.tableColGSIS}>
                          <Text style={styles.tableCellGSIS} >
                            10. GSIS ID NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowGSISfield}>
                      <View style={styles.tableColGSISfield}>
                          <Text style={styles.tableCellValueGSIS} >
                             {fetchInfo.gsis ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowPagIbig}>
                      <View style={styles.tableColPagIbig}>
                          <Text style={styles.tableCellPagIbig} >
                            11. PAG-IBIG NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowPagIbigfield}>
                      <View style={styles.tableColPagIbigfield}>
                          <Text style={styles.tableCellValuePagIbig} >
                              {fetchInfo.pagibig ?? "N/A"}
                          </Text>
                      </View>
                  </View>
                  
                  <View style={styles.tableRowPhilhealth}>
                      <View style={styles.tableColPhilhealth}>
                          <Text style={styles.tableCellPhilhealth} >
                            12. PHILHEALTH NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowPhilhealthfield}>
                      <View style={styles.tableColPhilhealthfield}>
                          <Text style={styles.tableCellValuePhilhealth} >
                              {fetchInfo.philhealthNo ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                   <View style={styles.tableRowSSS}>
                      <View style={styles.tableColSSS}>
                          <Text style={styles.tableCellSSS} >
                            13. SSS NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowSSSfield}>
                      <View style={styles.tableColSSSfield}>
                          <Text style={styles.tableCellValueSSS} >
                             {fetchInfo.SSS ?? "N/A"}
                          </Text>
                      </View>
                  </View>


                   <View style={styles.tableRowTin}>
                      <View style={styles.tableColTin}>
                          <Text style={styles.tableCellTin} >
                            14. TIN NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowTinfield}>
                      <View style={styles.tableColTinfield}>
                          <Text style={styles.tableCellValueTin} >
                           {fetchInfo.TIN ?? "N/A"}
                          </Text>
                      </View>
                  </View>


                  <View style={styles.tableRowAgency}>
                      <View style={styles.tableColAgency}>
                          <Text style={styles.tableCellAgency} >
                            15. AGENCY EMPLOYEE NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowAgencyfield}>
                      <View style={styles.tableColAgencyfield}>
                          <Text style={styles.tableCellValueAgency} >
                              {fetchInfo.IDNO ?? "N/A"}
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowCitizenship}>
                      <View style={styles.tableColCitizenship}>
                          <Text style={styles.tableCellCitizenship} >
                            16. CITIZENSHIP
                          </Text>

                          <Text style={styles.tableCellDualcitizenship} >
                            If holder of dual citizenship
                          </Text>

                           <Text style={styles.tableCellCountry} >
                              please indicate the details
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowCitizenshipfield}>
                      <View style={styles.tableColCitizenshipfield}>
                          <Text style={styles.tableCellValueCitizenshipFilipino} >
                            {
                              fetchInfo.citizen === "filipino" ? 
                              <>
                               <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Filipino
                              </> : 
                              <>
                               <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Filipino
                              </>

                            }
                              
                          </Text>

                          <Text style={styles.tableCellValueCitizenshipDualCitizen} >
                             {
                              fetchInfo.citizen === "dual citizenship" ? 
                              <>
                               <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Dual Citizenship
                              </> : 
                              <>
                               <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;Dual Citizenship
                              </>
                            }
                              
                          </Text>

                          <Text style={styles.tableCellValueBybirth} >
                              {
                                fetchInfo.citizenby === "by Birth" ? 
                                <>
                                   <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;by birth
                                </> : 
                                <>
                                   <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;by birth
                                </>

                              }
                             
                          </Text>
                          <Text style={styles.tableCellValueBynaturalization} >
                             {
                                fetchInfo.citizenby=== "by Naturalization" ? 
                                <>
                                  <Image src={checked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;by naturalization
                                </> : 
                                <>
                                  <Image src={Unchecked} style={{ width: '6px', height: '6px' }} />&nbsp;&nbsp;by naturalization
                                </>
                              }
                          </Text>



                           <Text style={styles.tableCellIndicateCountry} >
                              Pls. indicate country:
                          </Text>
                      </View>
                  </View>


                   <View style={styles.tableRowCountryfield}>
                      <View style={styles.tableColCountryfield}>
                          <Text style={styles.tableCellValueCountry} >
                              {fetchInfo.citizencountry ?? "N/A"}
                          </Text> 
                      </View>
                  </View>
                  
                               {/*=============================================  RESIDENTIAL ADDRESS ========================================================================*/}
                  <View style={styles.tableRowResidential}>
                      <View style={styles.tableColResidential}>
                          <Text style={styles.tableCellResidential} >
                            17. RESIDENTIAL ADDRESS
                          </Text>

                          <Text style={styles.tableCellResidentialZip} >
                              ZIP CODE 
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowARfield1}>
                      <View style={styles.tableColARfield1}>
                          <Text style={styles.tableCellValueARfield1} >
                            {fetchInfo.houseno ?? "N/A"}
                          </Text> 

                          <Text style={styles.tableCellValueARfield11} >
                           {fetchInfo.street ?? "N/A"}
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowARfield1s}>
                      <View style={styles.tableColARfield1s}>
                          <Text style={styles.tableCellValueARfield1s} >
                              House/Block/Lot No.
                          </Text> 
                          <Text style={styles.tableCellValueARfield1ss} >
                              Street
                          </Text> 
                      </View>
                  </View>


                  <View style={styles.tableRowARfield2}>
                      <View style={styles.tableColARfield2}>
                          <Text style={styles.tableCellValueARfield2} >
                           {fetchInfo.subdivision ?? "N/A"}
                          </Text> 

                        <Text style={styles.tableCellValueARfield22} >
                          {fetchInfo.baranagay ?? "N/A"}      
                        </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowARfield2s}>
                      <View style={styles.tableColARfield2s}>
                          <Text style={styles.tableCellValueARfield2s} >
                            Subdivision/Village
                          </Text> 
                          <Text style={styles.tableCellValueARfield2ss} >
                              Barangay
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowARfield3}>
                    <View style={styles.tableColARfield3}>
                        <Text style={styles.tableCellValueARfield3} >
                          {fetchInfo.city ?? "N/A"}    
                        </Text> 

                        <Text style={styles.tableCellValueARfield33} >
                           {fetchInfo.province ?? "N/A"}    
                        </Text> 
                    </View>
                  </View>
                  
                  <View style={styles.tableRowARfield3s}>
                      <View style={styles.tableColARfield3s}>
                          <Text style={styles.tableCellValueARfield3s} >
                            City/Municipality
                          </Text> 
                          <Text style={styles.tableCellValueARfield3ss} >
                              Province
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowARfield4}>
                      <View style={styles.tableColARfield4}>
                          <Text style={styles.tableCellValueARfield4} >
                              {fetchInfo.zip ?? "N/A"}    
                          </Text> 
                      </View>
                  </View> 

                    {/*============================================= PERMANENT ADDRESS ========================================================================*/}

                   <View style={styles.tableRowPermanent}>
                      <View style={styles.tableColPermanent}>
                          <Text style={styles.tableCellPermanent} >
                            18. PERMANENT ADDRESS
                          </Text>

                          <Text style={styles.tableCellPermanentZip} >
                              ZIP CODE 
                          </Text>
                      </View>
                  </View>
                  

                  <View style={styles.tableRowAPfield1}>
                      <View style={styles.tableColAPfield1}>
                          <Text style={styles.tableCellValueAPfield1} >
                            {fetchInfo.houseno2 ?? "N/A"}    
                          </Text> 

                          <Text style={styles.tableCellValueAPfield11} >
                            {fetchInfo.street2 ?? "N/A"}    
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowAPfield1s}>
                      <View style={styles.tableColAPfield1s}>
                          <Text style={styles.tableCellValueAPfield1s} >
                              House/Block/Lot No.
                          </Text> 
                          <Text style={styles.tableCellValueAPfield1ss} >
                              Street
                          </Text> 
                      </View>
                  </View>


                  <View style={styles.tableRowAPfield2}>
                      <View style={styles.tableColAPfield2}>
                          <Text style={styles.tableCellValueAPfield2} >
                            {fetchInfo.subdivision2 ?? "N/A"}    
                          </Text> 

                          <Text style={styles.tableCellValueAPfield22} >
                            {fetchInfo.baranagay2 ?? "N/A"}    
                          </Text> 
                      </View>
                  </View>

                 <View style={styles.tableRowAPfield2s}>
                      <View style={styles.tableColAPfield2s}>
                          <Text style={styles.tableCellValueAPfield2s} >
                            Subdivision/Village
                          </Text> 
                          <Text style={styles.tableCellValueAPfield2ss} >
                              Barangay
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowAPfield3}>
                      <View style={styles.tableColAPfield3}>
                        <Text style={styles.tableCellValueAPfield3} >
                          {fetchInfo.city2 ?? "N/A"}    
                         </Text> 
                            
                        <Text style={styles.tableCellValueAPfield33} >
                           {fetchInfo.province2 ?? "N/A"}    
                         </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowAPfield3s}>
                      <View style={styles.tableColAPfield3s}>
                          <Text style={styles.tableCellValueAPfield3s} >
                            City/Municipality
                          </Text> 
                          <Text style={styles.tableCellValueAPfield3ss} >
                              Province
                          </Text> 
                      </View>
                  </View>

                  <View style={styles.tableRowAPfield4}>
                      <View style={styles.tableColAPfield4}>
                          <Text style={styles.tableCellValueAPfield4} >
                            {fetchInfo.zip2 ?? "N/A"} 
                          </Text> 
                      </View>
                  </View>   
            {/* end  */}
            
                  <View style={styles.tableRowTEL}>
                      <View style={styles.tableColTEL}>
                          <Text style={styles.tableCellTEL} >
                            19. TELEPHONE NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowTELfield}>
                      <View style={styles.tableColTELfield}>
                          <Text style={styles.tableCellValueTEL} >
                           {fetchInfo.telephone2 ?? "N/A"} 
                          </Text>
                      </View>
                  </View>


                  <View style={styles.tableRowCELL}>
                      <View style={styles.tableColCELL}>
                          <Text style={styles.tableCellCELL} >
                            20. MOBILE NO.
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowCELLfield}>
                      <View style={styles.tableColCELLfield}>
                          <Text style={styles.tableCellValueCELL} >
                            {fetchInfo.cellno ?? "N/A"} 
                          </Text>
                      </View>
                  </View> 
                  
                  <View style={styles.tableRowEMAIL}>
                      <View style={styles.tableColEMAIL}>
                          <Text style={styles.tableCellEMAIL} >
                            21. E-MAIL ADDRESS (if any)
                          </Text>
                      </View>
                  </View>

                  <View style={styles.tableRowEMAILfield}>
                      <View style={styles.tableColEMAILfield}>
                          <Text style={styles.tableCellValueEMAIL} >
                             {fetchInfo.email ?? "N/A"} 
                          </Text>
                      </View>
                  </View>    

                  

                  {/* row */}
                </View>
                
                      {/* ================================================ FAMILY BACKGROUND   =================================================================== */}

            <View style={styles.table}>
              <View style={styles.tableRowPS}>
                  <View style={styles.tableColPS}>
                      <Text style={styles.tableCellPS}>II. FAMILY BACKGROUND</Text>
                  </View>
              </View>

              <View style={styles.tableRowSpouseName}>
                  <View style={styles.tableColSpouseName}>
                      <Text style={styles.tableCellSpouseSurname}>
                          22. SPOUSE'S SURNAME
                      </Text>
                      <Text style={styles.tableCellSpouseFirstname}>
                          FIRST NAME
                      </Text>
                        <Text style={styles.tableCellSpouseMiddlename}>
                          MIDDLE NAME
                      </Text>
                  </View>
              </View>

             <View style={styles.tableRowSpouseSurnamefield}>
                  <View style={styles.tableColSpouseSurnamefield}>
                      <Text style={styles.tableCellValueSpouseSurname} >
                          {fetchSpouse.SSURNAME ?? "N/A"}  
                      </Text>
                  </View>
              </View>
              

              <View style={styles.tableRowSpouseFirstnamefield}>
                  <View style={styles.tableColSpouseFirstnamefield}>
                      <Text style={styles.tableCellValueSpouseFirstname} >
                          {fetchSpouse.SFIRSTNAME ?? "N/A"}
                      </Text>
                  </View>
              </View>
 
              <View style={styles.tableRowSpouseExtensionNamefield}>
                    <View style={styles.tableColSpouseExtensionNamefield}>
                        <Text style={styles.tableCellSpouseExtensionName} >
                            NAME EXTENSION (JR, SR)
                        </Text>

                        <Text style={styles.tableCellValueSpouseExtensionName} >
                           {/* {fetchFam[0]?.s_extension ?? "N/A"} */}
                        </Text>
                    </View>
                </View>
           
                <View style={styles.tableRowSpouseMiddlenamefield}>
                  <View style={styles.tableColSpouseMiddlenamefield}>
                      <Text style={styles.tableCellValueSpouseMiddlename} >
                          {fetchSpouse.SMIDNAME ?? "N/A"}
                      </Text>
                  </View>
                </View>

            <View style={styles.tableRowSpouseOccupation}>
                  <View style={styles.tableColSpouseOccupation}>
                      <Text style={styles.tableCellSpouseOccupation} >
                          OCCUPATION
                      </Text>
                  </View>
              </View>

               <View style={styles.tableRowSpouseOccupationfield}>
                  <View style={styles.tableColSpouseOccupationfield}>
                      <Text style={styles.tableCellValueSpouseOccupation} >
                         {fetchSpouse.OCCUPATION ?? "N/A"}
                      </Text>
                  </View>
              </View>

              <View style={styles.tableRowBusinessName}>
                  <View style={styles.tableColBusinessName}>
                      <Text style={styles.tableCellBusinessName} >
                         EMPLOYER/BUSINESS NAME
                      </Text>
                  </View>
              </View>
   
              <View style={styles.tableRowBusinessNamefield}>
                  <View style={styles.tableColBusinessNamefield}>
                      <Text style={styles.tableCellValueBusinessName} >
                          {fetchSpouse.EMPLOYNAME ?? "N/A"}
                      </Text>
                  </View>
              </View>

              <View style={styles.tableRowBusinessAddress}>
                  <View style={styles.tableColBusinessAddress}>
                      <Text style={styles.tableCellBusinessAddress} >
                         BUSINESS ADDRESS
                      </Text>
                  </View>
              </View>
 
              <View style={styles.tableRowBusinessAddressfield}>
                  <View style={styles.tableColBusinessAddressfield}>
                      <Text style={styles.tableCellValueBusinessAddress} >
                          {fetchSpouse.BUSADRS ?? "N/A"}
                      </Text>
                  </View>
              </View>
 
              <View style={styles.tableRowSpouseTell}>
                  <View style={styles.tableColSpouseTell}>
                      <Text style={styles.tableCellSpouseTell} >
                         TELEPHONE NO.
                      </Text>
                  </View>
              </View>

              <View style={styles.tableRowSpouseTellfield}>
                  <View style={styles.tableColSpouseTellfield}>
                      <Text style={styles.tableCellValueSpouseTell} >
                          {fetchSpouse.BUSTEL ?? "N/A"}
                      </Text>
                  </View>
              </View>  
              {/* ========================= FATHER'S NAME ========================================================= */}

              <View style={styles.tableRowFatherName}>
                  <View style={styles.tableColFatherName}>
                      <Text style={styles.tableCellFatherSurname}>
                          24. FATHER'S SURNAME
                      </Text>
                      <Text style={styles.tableCellFatherFirstname}>
                          FIRST NAME
                      </Text>
                        <Text style={styles.tableCellFatherMiddlename}>
                          MIDDLE NAME
                      </Text>
                  </View>
              </View>

              <View style={styles.tableRowFatherSurnamefield}>
                  <View style={styles.tableColFatherSurnamefield}>
                      <Text style={styles.tableCellValueFatherSurname} >
                          {fetchParent.FSURNAME ?? "N/A" }
                      </Text>
                  </View>
              </View>

              <View style={styles.tableRowFatherFirstnamefield}>
                  <View style={styles.tableColFatherFirstnamefield}>
                      <Text style={styles.tableCellValueFatherFirstname} >
                           {fetchParent.FSURNAME ?? "N/A" }
                      </Text>
                  </View>
              </View>
            
              <View style={styles.tableRowFatherExtensionNamefield}>
                    <View style={styles.tableColFatherExtensionNamefield}>
                        <Text style={styles.tableCellFatherExtensionName} >
                            NAME EXTENSION (JR, SR)
                        </Text>

                        <Text style={styles.tableCellValueFatherExtensionName} >
                            {/* {fetchFam[0]?.FFIRSTNAME ?? "N/A"} */}
                        </Text>
                    </View>
                </View> 

                <View style={styles.tableRowFatherMiddlenamefield}>
                  <View style={styles.tableColFatherMiddlenamefield}>
                      <Text style={styles.tableCellValueFatherMiddlename} >
                           {fetchParent.FMIDDLENAME ?? "N/A" }
                      </Text>
                  </View>
                </View>  
            
            {/* ======================================================================= */}

             <View style={styles.tableRowMotherName}>
                <View style={styles.tableColMotherName}>
                    <Text style={styles.tableCellMotherFullName}>
                        25. MOTHER'S MAIDEN NAME
                    </Text>
                    <Text style={styles.tableCellMotherSurname}>
                        SURNAME
                    </Text>
                    <Text style={styles.tableCellMotherFirstname}>
                        FIRST NAME
                    </Text>
                      <Text style={styles.tableCellMotherMiddlename}>
                        MIDDLE NAME
                    </Text>
                </View>
              </View>
              
              <View style={styles.tableRowMotherFullNamefield}>
                  <View style={styles.tableColMotherFullNamefield}>
                      <Text style={styles.tableCellValueMotherFullName} >
                       
                      </Text>
                  </View>
              </View>

             <View style={styles.tableRowMotherSurnamefield}>
                  <View style={styles.tableColMotherSurnamefield}>
                      <Text style={styles.tableCellValueMotherSurname} >
                         {fetchParent.MSURNAME ?? "N/A" }
                      </Text>
                  </View>
              </View>
 
               <View style={styles.tableRowMotherFirstNamefield}>
                  <View style={styles.tableColMotherFirstNamefield}>
                      <Text style={styles.tableCellValueMotherFirstName} >
                          {fetchParent.MFIRSTNAME ?? "N/A" }
                      </Text>
                  </View>
              </View>

             <View style={styles.tableRowMotherMiddleNamefield}>
                  <View style={styles.tableColMotherMiddleNamefield}>
                      <Text style={styles.tableCellValueMotherMiddleName} >
                         {fetchParent.MMIDNAME ?? "N/A" }
                      </Text>
                  </View>
              </View>   
              
            
          </View>  

             {/* =======================================  CHILDREN TABLE  ===================================================== */}
          <View style={styles.tableChildren}>

              <View style={styles.tableChildrenRow}>
                  <View style={styles.tableChildrenCol}>
                      <Text style={styles.tableChildrenCell}>
                          23. NAME OF CHILDREN (Write full name and list all)
                      </Text>
                  </View>

                  <View style={styles.tableChildrenCol2}>
                      <Text style={styles.tableChildrenCell2}>
                          DATE OF BIRTH (mm/dd/yyyy)
                      </Text>
                  </View>
              </View>   


              {/* table value */}
                              
               {

                fetchChildren.slice(0,14).map((data) => {

                  const { 
                    unique,
                    CHILDNAME,
                    CBIRTHDATE,
                  } = data;

                  return(
                  <View style={styles.tableChildrenRow} key={unique}>
                    
                    <View style={styles.tableChildrenCol}>
                        <Text style={styles.tableChildrenValueCell}>
                            {CHILDNAME}
                        </Text>
                    </View>

                    <View style={styles.tableChildrenCol2}>
                        <Text style={styles.tableChildrenValueCell2}>
                            {CBIRTHDATE}
                        </Text>
                    </View>
                    
                 </View>


                  )
                })

               }               

               {  
                (() => {
                    const childrows = [];
                    
                    for (let i = 1 ; i < (14-fetchChildren.length); i++) {

                      const child_rows = i;
                      
                      childrows.push(
                      <View style={styles.tableChildrenRow} key={child_rows} >
                        <View style={styles.tableChildrenCol}>
                          <Text style={styles.tableChildrenValueCell}>
                            
                          </Text>
                        </View>
                        <View style={styles.tableChildrenCol2}>
                          <Text style={styles.tableChildrenValueCell2}>
                          
                          </Text>
                        </View>
                      </View>
                      )
                      
                    }
                    return childrows;
                })()

               }               

              {/*  End of Value */}
            </View> 
            
          
           {/* Education Background */}
              
             <View style={styles.table}>
                    <View style={styles.tableRowPS}>
                        <View style={styles.tableColPS}>
                            <Text style={styles.tableCellPS}>III. EDUCATION BACKGOUND</Text>
                        </View>
                    </View>                    
              </View>

              <View style={styles.tableEducation}>
                
                <View style={styles.tableEducationRow}>

                  <View style={styles.tableEducationLevelCol}>
                    <Text style={styles.tableNumberCell}>
                      26.
                    </Text> 
                    <Text style={styles.tableEducationLevelCell}>
                        LEVEL
                    </Text> 
                  </View>

                  <View style={styles.tableEducationSchoolCol}> 
                    <Text style={styles.tableEducationSchoolCell}>
                      NAME OF SCHOOL 
                    </Text> 
                    <Text style={styles.tableSchoolfullCell}>
                      (Write in full)
                    </Text> 
                  </View>

                  <View style={styles.tableBasicEducationCol}> 
                    <Text style={styles.tableEducationBasicEducCell}>
                      BASIC EDUCATION/DEGREE/COURSE
                    </Text> 
                    <Text style={styles.tableSchoolfullCell}>
                      (Write in full)
                    </Text>
                  </View> 

                
                  <View style={styles.tableEducationAttendanceCol}> 
                    <Text style={styles.tableAttendancePeriodCell}>
                      PERIOD OF ATTENDANCE
                    </Text> 

                    <View style={styles.tableEducationAttendancePeriod}>
                        <View style={styles.tableEducationAttendancePeriodRow}>
                            <View style={styles.tableEducationFromCol}>
                                <Text style={styles.tableEducationFromCell}>
                                      From
                                </Text>
                            </View>

                            <View style={styles.tableEducationToCol}>
                                <Text style={styles.tableEducationToCell}>
                                      To
                                </Text>
                            </View>
                        </View>
                    </View>

                  </View> 

                  <View style={styles.tableHighestEducationCol}> 
                    <Text style={styles.tableEducationHighestLevelCell}>
                      HIGHEST LEVEL/
                    </Text>
                    <Text style={styles.tableUnitsEarnedCell}>
                     UNITS EARNED
                    </Text> 
                    <Text style={styles.tableIfNotGraduatedCell}>
                      (If not graduated)
                    </Text>
                  </View> 

                   <View style={styles.tableEducationGraduatedCol}> 
                    <Text style={styles.tableEducationYearCell}>
                     YEAR 
                    </Text> 
                    <Text style={styles.tableEducationGradCell}>
                     GRADUATED
                    </Text> 
                  </View>

                  <View style={styles.tableEducationScholarshipCol}> 
                    <Text style={styles.tableScholarCell}>
                     SCHOLARHIP/
                    </Text> 
                     <Text style={styles.tableHonorsCell}>
                     ACADEMIC HONORS
                    </Text> 
                     <Text style={styles.tableReceivedCell}>
                      RECIEVED
                    </Text> 
                  </View> 
                  
                </View>
                
            {

              levels.map((level, index) => {
                
                const levelData = fetchEduc.filter(item => item.LEVEL === level);


                if (levelData.length === 0) {
                  return (

                  <View style={styles.tableEducationAttendancePeriodRow} key={index}>
                    <View style={styles.tableLevelFieldCol}> 
                      { level === 'Vocational/Trade Course' ? (
                        <>
                          <Text style={ level === 'Vocational/Trade Course' ? styles.tableTextLevelCells : styles.tableTextLevelCell }>
                            VOCATIONAL /
                          </Text>
                            <Text style={ level === 'Vocational/Trade Course' ? styles.tableTextLevelCells : styles.tableTextLevelCell }>
                            TRADE COURSE
                          </Text>
                        </>
                          
                        ) : (
                        <Text style={styles.tableTextLevelCell}>
                          {level}
                        </Text>
                      )}    
                    </View>

                    <View style={styles.tableSchoolFieldCol}>
                      <Text style={styles.tableSchoolValueCell}>
                       
                      </Text>
                    </View>

                    <View style={styles.tableBasicEducFieldCol}>
                      <Text style={styles.tableBasicEducValueCell}>
                      
                      </Text>
                    </View>

                    <View style={styles.tableEducFromCol}>
                      <Text style={styles.tableEducFromValueCell}>
                       
                      </Text>
                    </View>

                    <View style={styles.tableEducToCol}>
                      <Text style={styles.tableEducToValueCell}>
                        
                      </Text>
                    </View>

                    <View style={styles.tableHighestLevelCol}>
                      <Text style={styles.tableHighestLevelValueCell}>
                       
                      </Text>
                    </View>

                    <View style={styles.tableYearGradCol}>
                      <Text style={styles.tableYearGradValueCell}>
                       
                      </Text>
                    </View>

                    <View style={styles.tableScholarCol}>
                      <Text style={styles.tableScholarValueCell}>
                       
                      </Text>
                    </View>

                   </View>

                  )

                }
                
      

                return levelData.map((row, index) => (

    
                  <View style={styles.tableEducationAttendancePeriodRow} key={index}>

                          <View style={styles.tableLevelFieldCol}> 
                            { row.LEVEL === 'Vocational/Trade Course' ? (
                              <>
                                <Text style={ row.LEVEL === 'Vocational/Trade Course' ? styles.tableTextLevelCells : styles.tableTextLevelCell }>
                                  VOCATIONAL /
                                </Text>
                                  <Text style={ row.LEVEL === 'Vocational/Trade Course' ? styles.tableTextLevelCells : styles.tableTextLevelCell }>
                                  TRADE COURSE
                                </Text>
                              </>
                                
                              ) : (
                              <Text style={styles.tableTextLevelCell}>
                                { row.LEVEL }
                              </Text>
                            )}    
                          </View>

                           <View style={styles.tableSchoolFieldCol}>
                             <Text style={styles.tableSchoolValueCell}>
                               {row.SCHOOL}
                             </Text>
                           </View>

                           <View style={styles.tableBasicEducFieldCol}>
                             <Text style={styles.tableBasicEducValueCell}>
                               {row.DEGREE}
                             </Text>
                           </View>

                           <View style={styles.tableEducFromCol}>
                             <Text style={styles.tableEducFromValueCell}>
                               {row.TOATT}
                             </Text>
                           </View>

                           <View style={styles.tableEducToCol}>
                             <Text style={styles.tableEducToValueCell}>
                               {row.FROMATT}
                             </Text>
                           </View>

                           <View style={styles.tableHighestLevelCol}>
                             <Text style={styles.tableHighestLevelValueCell}>
                               {row.UEARNED}
                             </Text>
                           </View>

                           <View style={styles.tableYearGradCol}>
                             <Text style={styles.tableYearGradValueCell}>
                               {row.YRGRAD}
                             </Text>
                           </View>

                           <View style={styles.tableScholarCol}>
                             <Text style={styles.tableScholarValueCell}>
                               {row.HONORS}
                             </Text>
                           </View>


                         </View>


                ));

              })
              
                  //   fetchEduc.length > 0 ? 

                  //   fetchEduc.map((data) => {

                  //     const {
                  //       unique, 
                  //       LEVEL,
                  //       SCHOOL,
                  //       DEGREE, 
                  //       FROMATT, 
                  //       TOATT, 
                  //       UEARNED,
                  //       YRGRAD, 
                  //       HONORS,
                  //     } = data;

                  //     return (
                          
                  //       <View style={styles.tableEducationAttendancePeriodRow} key={unique}>

                  //         <View style={styles.tableLevelFieldCol}>
                  //           <Text style={styles.tableTextLevelCell}>
                  //             {LEVEL}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableSchoolFieldCol}>
                  //           <Text style={styles.tableSchoolValueCell}>
                  //             {SCHOOL}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableBasicEducFieldCol}>
                  //           <Text style={styles.tableBasicEducValueCell}>
                  //             {DEGREE}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableEducFromCol}>
                  //           <Text style={styles.tableEducFromValueCell}>
                  //             {TOATT}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableEducToCol}>
                  //           <Text style={styles.tableEducToValueCell}>
                  //             {FROMATT}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableHighestLevelCol}>
                  //           <Text style={styles.tableHighestLevelValueCell}>
                  //             {UEARNED}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableYearGradCol}>
                  //           <Text style={styles.tableYearGradValueCell}>
                  //             {YRGRAD}
                  //           </Text>
                  //         </View>

                  //         <View style={styles.tableScholarCol}>
                  //           <Text style={styles.tableScholarValueCell}>
                  //             {HONORS}
                  //           </Text>
                  //         </View>


                  //       </View>
                  //     )

                  //   }) :
                  // <View style={styles.tableEducationAttendancePeriodRow}>

                  //   <View style={styles.tableLevelFieldCol}>
                  //     <Text style={styles.tableTextValueCell}>
                  //       ELEMENTARY
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableSchoolFieldCol}>
                  //     <Text style={styles.tableSchoolValueCell}>
                       
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableBasicEducFieldCol}>
                  //     <Text style={styles.tableBasicEducValueCell}>
                      
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableEducFromCol}>
                  //     <Text style={styles.tableEducFromValueCell}>
                       
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableEducToCol}>
                  //     <Text style={styles.tableEducToValueCell}>
                        
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableHighestLevelCol}>
                  //     <Text style={styles.tableHighestLevelValueCell}>
                       
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableYearGradCol}>
                  //     <Text style={styles.tableYearGradValueCell}>
                       
                  //     </Text>
                  //   </View>

                  //   <View style={styles.tableScholarCol}>
                  //     <Text style={styles.tableScholarValueCell}>
                       
                  //     </Text>
                  //   </View>

                  //  </View>

               }


                

                {/* <View style={styles.tableEducationAttendancePeriodRow}>

                  <View style={styles.tableLevelFieldCol}>
                    <Text style={styles.tableTextValueCell}>
                      SECONDARY
                    </Text>
                  </View>

                  <View style={styles.tableSchoolFieldCol}>
                    <Text style={styles.tableSchoolValueCell}></Text>
                  </View>

                  <View style={styles.tableBasicEducFieldCol}>
                    <Text style={styles.tableBasicEducValueCell}></Text>
                  </View>

                  <View style={styles.tableEducFromCol}>
                    <Text style={styles.tableEducFromValueCell}></Text>
                  </View>

                  <View style={styles.tableEducToCol}>
                    <Text style={styles.tableEducToValueCell}></Text>
                  </View>

                  <View style={styles.tableHighestLevelCol}>
                    <Text style={styles.tableHighestLevelToValueCell}></Text>
                  </View>

                  <View style={styles.tableYearGradCol}>
                    <Text style={styles.tableYearGradValueCell}></Text>
                  </View>

                   <View style={styles.tableScholarCol}>
                    <Text style={styles.tableScholarValueCell}></Text>
                  </View>


                </View>

                <View style={styles.tableEducationAttendancePeriodRow}>

                  <View style={styles.tableLevelFieldCol}>
                    <Text style={styles.tableTextVocationalValueCell}>
                      VOCATIONAL/ 
                    </Text>
                    <Text style={styles.tableTextTradeValueCell}>
                      TRADE COURSE
                    </Text>

                  </View>

                  <View style={styles.tableSchoolFieldCol}>
                    <Text style={styles.tableSchoolValueCell}></Text>
                  </View>

                  <View style={styles.tableBasicEducFieldCol}>
                    <Text style={styles.tableBasicEducValueCell}></Text>
                  </View>

                  <View style={styles.tableEducFromCol}>
                    <Text style={styles.tableEducFromValueCell}></Text>
                  </View>

                  <View style={styles.tableEducToCol}>
                    <Text style={styles.tableEducToValueCell}></Text>
                  </View>

                  <View style={styles.tableHighestLevelCol}>
                    <Text style={styles.tableHighestLevelToValueCell}></Text>
                  </View>

                  <View style={styles.tableYearGradCol}>
                    <Text style={styles.tableYearGradValueCell}></Text>
                  </View>

                   <View style={styles.tableScholarCol}>
                    <Text style={styles.tableScholarValueCell}></Text>
                  </View>


                </View>

                <View style={styles.tableEducationAttendancePeriodRow}>

                  <View style={styles.tableLevelFieldCol}>
                    <Text style={styles.tableTextValueCell}>
                      COLLEGE
                    </Text>
                  </View>

                  <View style={styles.tableSchoolFieldCol}>
                    <Text style={styles.tableSchoolValueCell}></Text>
                  </View>

                  <View style={styles.tableBasicEducFieldCol}>
                    <Text style={styles.tableBasicEducValueCell}></Text>
                  </View>

                  <View style={styles.tableEducFromCol}>
                    <Text style={styles.tableEducFromValueCell}></Text>
                  </View>

                  <View style={styles.tableEducToCol}>
                    <Text style={styles.tableEducToValueCell}></Text>
                  </View>

                  <View style={styles.tableHighestLevelCol}>
                    <Text style={styles.tableHighestLevelValueCell}></Text>
                  </View>

                  <View style={styles.tableYearGradCol}>
                    <Text style={styles.tableYearGradValueCell}></Text>
                  </View>

                   <View style={styles.tableScholarCol}>
                    <Text style={styles.tableScholarValueCell}></Text>
                  </View>


                </View>

                <View style={styles.tableEducationAttendancePeriodRow}>

                  <View style={styles.tableLevelFieldCol}>
                    <Text style={styles.tableTextValueCell}>
                        GRADUATED STUDIES
                    </Text>
                  </View>

                  <View style={styles.tableSchoolFieldCol}>
                    <Text style={styles.tableSchoolValueCell}></Text>
                  </View>

                  <View style={styles.tableBasicEducFieldCol}>
                    <Text style={styles.tableBasicEducValueCell}></Text>
                  </View>

                  <View style={styles.tableEducFromCol}>
                    <Text style={styles.tableEducFromValueCell}></Text>
                  </View>

                  <View style={styles.tableEducToCol}>
                    <Text style={styles.tableEducToValueCell}></Text>
                  </View>

                  <View style={styles.tableHighestLevelCol}>
                    <Text style={styles.tableHighestLevelValueCell}></Text>
                  </View>

                  <View style={styles.tableYearGradCol}>
                    <Text style={styles.tableYearGradValueCell}></Text>
                  </View>

                   <View style={styles.tableScholarCol}>
                    <Text style={styles.tableScholarValueCell}></Text>
                  </View>
                </View>               */}
              </View>
               
          
               {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableFooter2}>
                
                  <View style={styles.tableFooterRow2}>
                    
                    <View style={styles.tableFooterSignatureCol}>
                      <Text style={styles.tableFooterSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSignatureValueCol}>
                      <Text style={styles.tableFooterSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterDateCol}>
                      <Text style={styles.tableFooterDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterDateValueCol}>
                      <Text style={styles.tableFooterDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterPageCol}>
                      <Text style={styles.tableFooterPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} >  
                        {/* 1 */}
                      </Text>
                    </View>
                  
                  </View>

              </View> 

            {/* end Footer */}



               
        </View>
        {/* end of section */}
      
            
                       

            
        </Page>

        {/* ----------------------------- first page Back --------------------------------------------------- */}

        {/* ----------------------------- CIVIL SERVICE ----------------------------  */}
                
        <Page size="LEGAL" style={styles.page}>
          <View style={styles.section}>

            <View style={styles.table2}>
                <View style={styles.tableRowPS2}>
                    <View style={styles.tableColPS2}>
                        <Text style={styles.tableCellPS2}>IV. CIVIL SERVICE ELIGIBILITY</Text>
                    </View>
                </View>                    
            </View>

            <View style={styles.tableEligibility}>
                <View style={styles.tableEligibilityRow}>

                  <View style={styles.tableEligibilityCol}>

                    <Text style={styles.tableEligiNumberCell}>
                      27.
                    </Text> 
                    
                    <Text style={styles.tableEligiText1Cell}>
                        CAREER SERVICE / RA 10180 (BOARD/ BAR) UNDER
                    </Text>
                    <Text style={styles.tableEligiText2Cell}>
                        SPECIAL LAWS/ CES/CSEE
                    </Text>
                    <Text style={styles.tableEligiText3Cell}>
                        BARANGAY ELIGIBILITY / DRIVER'S LICENSE
                    </Text> 

                  </View>

                  <View style={styles.tableRatingCol}>
                    <Text style={styles.tableRatingTextCell}>
                        RATING
                    </Text>
                    <Text style={styles.tableIfApplicableCell}>
                        (if Applicable)
                    </Text> 
                  </View>

                  <View style={styles.tableDateExamCol}>
                    <Text style={styles.DateExamText1Cell}>
                        DATE OF
                    </Text>
                    <Text style={styles.DateExamText2Cell}>
                        EXAMINATION / 
                    </Text>
                    <Text style={styles.DateExamText3Cell}>
                        CONFERMENT 
                    </Text> 
                  </View>

                   <View style={styles.tablePlaceExamCol}>
                    <Text style={styles.tablePlaceExamCell}>
                         PLACE OF EXAMINATION / CONFERMENT
                    </Text> 
                  </View>

                  <View style={styles.tableLicenseCol}>
                    <Text style={styles.tableLicenseCell}>
                        LICENSE (if applicable)
                    </Text> 

                    <View style={styles.tableEligibilityLicense}>
                        <View style={styles.tableEligibilityLicenseRow}>
                            <View style={styles.tableEligibilityNumberCol}>
                                <Text style={styles.tableEligiLicenseNumberCell}>
                                     NUMBER
                                </Text>
                            </View>

                            <View style={styles.tableEligibilityDateValidityCol}>
                                <Text style={styles.tableEligiDateValidCell}>
                                     Date of 
                                </Text>
                                <Text style={styles.tableEligiDateValidCell}>
                                     Validity
                                </Text>
                            </View>
                        </View>
                    </View>
                    
                  </View>  
                
                </View>


                {/* list of Eligibility */}
                {
                  fetchEligi.slice(0,7).map((data) => {
                     const {
                      unique, 
                      TITLE,
                      ELIRATE,
                      EXDATE, 
                      EXPL, 
                      LICENSENO, 
                      DATEREL
                     }  = data;

                     return (
                       <View style={styles.tableEligibilityValuesRow} key={unique}>
                          <View style={styles.tableEligibilityLicenseCol}>
                            <Text style={styles.tableEligibilityLicenseCell}>
                              {TITLE}
                            </Text>
                          </View>

                          <View style={styles.tableEligibilityRatingCol}>
                            <Text style={styles.tableEligibilityRatingCell}>
                              {ELIRATE}
                            </Text>
                          </View>

                          <View style={styles.tableEligibilityDateCol}>
                            <Text style={styles.tableEligibilityDateCell}>
                              {EXDATE}
                            </Text>
                          </View>

                          <View style={styles.tableEligibilityPlaceCol}>
                            <Text style={styles.tableEligibilityLicenseCell}>
                              {EXPL}
                            </Text>
                          </View>

                          <View style={styles.tableEligiNumberCol}>
                            <Text style={styles.tableEligibilityLicenseCell}>
                                {LICENSENO}
                            </Text>
                          </View>

                          <View style={styles.tableEligiDateValidCol}>
                            <Text style={styles.tableEligibilityLicenseCell}>
                              {DATEREL}
                          </Text>
                        </View>

                        </View>
                     )
                  })
                }
                {
                  (() => {
                      const csc = [];
                      for (let i = 0; i < (7 - fetchEligi.length); i++) {
                        const Eligirows = i;

                        csc.push(

                          <View style={styles.tableEligibilityValuesRow} key={Eligirows}>
                            <View style={styles.tableEligibilityLicenseCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                              
                              </Text>
                            </View>

                            <View style={styles.tableEligibilityRatingCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                            
                              </Text>
                            </View>

                            <View style={styles.tableEligibilityDateCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                              
                              </Text>
                            </View>

                            <View style={styles.tableEligibilityPlaceCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                            
                              </Text>
                            </View>

                            <View style={styles.tableEligiNumberCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                              
                              </Text>
                            </View>

                            <View style={styles.tableEligiDateValidCol}>
                              <Text style={styles.tableEligibilityLicenseCell}>
                               
                            </Text>
                          </View>

                        </View>
                        )
                      }
                      return csc;
                  })()
                }
            </View>
            <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

            {/* ----------------------------- WORK EXPERIENCE ----------------------------  */}
          
            <View style={styles.table3}>
                <View style={styles.tableRowPS3}>
                    <View style={styles.tableColPS3}>
                        <Text style={styles.tableCellPS3}>V. WORK EXPERIENCE</Text>
                        <Text style={styles.tableCellPS3Desc}>(Include private employment.  Start from your recent work) Description of duties should be indicated in the attached Work Experience sheet.</Text>
                    </View>
                </View>                    
            </View>

            <View style={styles.tableWorkExperience}>
              <View style={styles.tableWorkExpRow}>

                <View style={styles.tableWorkExpDateCol}>
                  <Text style={styles.tableWorkExpNumberCell}>
                    28.
                  </Text> 
                  
                  <Text style={styles.tableWorkExpText1Cell}>
                     INCLUSIVE DATES
                  </Text>
                  <Text style={styles.tableWorkExpText2Cell}>
                     (mm/dd/yyyy)
                  </Text>

                  <View style={styles.tableWorkExpPeriod}>
                        <View style={styles.tableWorkExpPeriodRow}>
                            <View style={styles.tableWorkExpFromCol}>
                                <Text style={styles.tableWorkExpFromCell}>
                                      From
                                </Text>
                            </View>

                            <View style={styles.tableWorkExpToCol}>
                                <Text style={styles.tableWorkExpToCell}>
                                      To
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.tablePositionTitleCol}>
                  <Text style={styles.tablePositionTitleText1Cell}>
                      POSITION TITLE
                  </Text>
                  <Text style={styles.tablePositionTitleText2Cell}>
                      (Write in full/Do not abbreviate)
                  </Text>
                </View>

                <View style={styles.tableWorkDeptCol}>
                  <Text style={styles.tableWorkDeptText1Cell}>
                      DEPARTMENT / AGENCY / OFFICE/ COMPANY 
                  </Text>
                  <Text style={styles.tableWorkDeptText2Cell}>
                      (Write in full/Do not abbreviate)
                  </Text>
                </View>

                <View style={styles.tableWorkSalaryCol}>
                  <Text style={styles.tableWorkSalaryText1Cell}>
                      MONTHLY
                  </Text>
                  <Text style={styles.tableWorkSalaryText2Cell}>
                      SALARY
                  </Text>
                </View>


                <View style={styles.tableWorkSalaryGradeCol}>
                  <Text style={styles.tableWorkSGText1Cell}>
                      SALARY/ JOB/ PAY
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                      GRADE(if
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                      applicable)& STEP
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                     (Format *00-0*)/
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                     INCREMENT
                  </Text>
                </View>


                <View style={styles.tableWorkStatusCol}>
                  <Text style={styles.tableWorkStatusText1Cell}>
                      STATUS OF
                  </Text>
                   <Text style={styles.tableWorkStatusText2Cell}>
                      APPOINTMENT
                  </Text>
                </View>


                <View style={styles.tableWorkGovServiceCol}>
                  <Text style={styles.tableWorkGovServiceText1Cell}>
                      GOV'T
                  </Text>
                  <Text style={styles.tableWorkGovServiceText2Cell}>
                      SERVICE
                  </Text>
                  <Text style={styles.tableWorkGovServiceText3Cell}>
                      (Y/N)
                  </Text>
                </View>

              </View>

              {/* list of Work Exp */}
              {
                fetchWorkExp.slice(0,26).map((data) => {
                  const {
                    unique, 
                    from,
                    to,
                    position, 
                    department, 
                    monthly, 
                    sgrade, 
                    status, 
                    government 
                   }  = data;

                  return (
                    <View style={styles.tableWorkExpValuesRow} key={unique}>
                      <View style={styles.tableWorkExpFromValueCol}>
                        <Text style={styles.tableWorkExpFromValueCell}>
                           {from}
                        </Text>
                      </View>

                      <View style={styles.tableWorkExpToValueCol}>
                        <Text style={styles.tableWorkExpToValueCell}>
                          {to}
                        </Text>  
                      </View>
                        
                      <View style={styles.tableWorkPositionValueCol}>
                        <Text style={styles.tableWorkPositionValueCell}>
                          {position}
                        </Text>  
                      </View>

                      <View style={styles.tableWorkDeptValueCol}>
                        <Text style={styles.tableWorkDeptValueCell}>
                         {department}
                        </Text>  
                      </View>
                      
                      <View style={styles.tableSalaryValueCol}>
                        <Text style={styles.tableSalaryValueCell}>
                         {monthly}
                        </Text>  
                      </View>
                      
                      <View style={styles.tableSalaryGradeValueCol}>
                        <Text style={styles.tableSalaryGradeValueCell}>
                          {sgrade}
                        </Text>  
                      </View> 
                      
                      <View style={styles.tableAppointmentValueCol}>
                        <Text style={styles.tableAppointmentValueCell}>
                         {status}
                        </Text>  
                      </View>
                      
                      <View style={styles.tableGovServiceValueCol}>
                        <Text style={styles.tableGovServiceValueCell}>
                         {government}  
                        </Text>  
                      </View>
                    </View>
                  )

                })
            }
            {
              (() => { 
                const workExp = [];
                for (let i = 0; i < (26 - fetchWorkExp.length); i++) {
                  const WorkExprows = i;

                  workExp.push(
                    <View style={styles.tableWorkExpValuesRow} key={WorkExprows}>
                      <View style={styles.tableWorkExpFromValueCol}>
                        <Text style={styles.tableWorkExpFromValueCell}>
                            
                        </Text>
                      </View>

                      <View style={styles.tableWorkExpToValueCol}>
                        <Text style={styles.tableWorkExpToValueCell}>
                          
                        </Text>  
                      </View>
                        
                      <View style={styles.tableWorkPositionValueCol}>
                        <Text style={styles.tableWorkPositionValueCell}>
                          
                        </Text>  
                      </View>

                      <View style={styles.tableWorkDeptValueCol}>
                        <Text style={styles.tableWorkDeptValueCell}>
                          
                        </Text>  
                      </View>
                      
                      <View style={styles.tableSalaryValueCol}>
                        <Text style={styles.tableSalaryValueCell}>
                          
                        </Text>  
                      </View>
                      
                      <View style={styles.tableSalaryGradeValueCol}>
                        <Text style={styles.tableSalaryGradeValueCell}>
                          
                        </Text>  
                      </View> 
                      
                      <View style={styles.tableAppointmentValueCol}>
                        <Text style={styles.tableAppointmentValueCell}>
                          
                        </Text>  
                      </View>
                      
                      <View style={styles.tableGovServiceValueCol}>
                        <Text style={styles.tableGovServiceValueCell}>
                          
                        </Text>  
                      </View>
                    </View>

                   )
                  }
                  return workExp;
              })()
            }
          </View>

          {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableSecondPageFooter2}>
                
                  <View style={styles.tableSecondPageFooterRow2}>
                    
                    <View style={styles.tableFooterSecondPageSignatureCol}>
                      <Text style={styles.tableFooterSecondPageSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageSignatureValueCol}>
                      <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateCol}>
                      <Text style={styles.tableFooterSecondPageDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateValueCol}>
                      <Text style={styles.tableFooterSecondPageDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageCol}>
                      <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                    
                      </Text>
                    </View>
                  
                  </View>

              </View> 

            {/* end Footer */}

          </View>
        </Page>
          
        {/* =================================== extended civil service ========================================= */}
    
    

        {
        fetchWorkExp.length > 26  ?

        <Page size="LEGAL" style={styles.page}>
          <View style={styles.section}>

            <View style={styles.tableSecondPage3}>
                <View style={styles.tableRowSecondPagePS3}>
                    <View style={styles.tableColSecondPagePS3}>
                        <Text style={styles.tableCellSecondPagePS3}>V. WORK EXPERIENCE</Text>
                        <Text style={styles.tableCellSecondPagePS3Desc}>(Include private employment.  Start from your recent work) Description of duties should be indicated in the attached Work Experience sheet.</Text>
                    </View>
                </View>                    
            </View>

            <View style={styles.tableWorkExperience}>

              <View style={styles.tableWorkExpRow}>

                <View style={styles.tableWorkExpDateCol}>
                  <Text style={styles.tableWorkExpNumberCell}>
                    28.
                  </Text> 
                  
                  <Text style={styles.tableWorkExpText1Cell}>
                     INCLUSIVE DATES
                  </Text>
                  <Text style={styles.tableWorkExpText2Cell}>
                     (mm/dd/yyyy)
                  </Text>

                  <View style={styles.tableWorkExpPeriod}>
                        <View style={styles.tableWorkExpPeriodRow}>
                            <View style={styles.tableWorkExpFromCol}>
                                <Text style={styles.tableWorkExpFromCell}>
                                      From
                                </Text>
                            </View>

                            <View style={styles.tableWorkExpToCol}>
                                <Text style={styles.tableWorkExpToCell}>
                                      To
                                </Text>
                            </View>
                        </View>
                    </View>
                  </View>

                <View style={styles.tablePositionTitleCol}>
                  <Text style={styles.tablePositionTitleText1Cell}>
                      POSITION TITLE
                  </Text>
                  <Text style={styles.tablePositionTitleText2Cell}>
                      (Write in full/Do not abbreviate)
                  </Text>
                </View>

                <View style={styles.tableWorkDeptCol}>
                  <Text style={styles.tableWorkDeptText1Cell}>
                      DEPARTMENT / AGENCY / OFFICE/ COMPANY 
                  </Text>
                  <Text style={styles.tableWorkDeptText2Cell}>
                      (Write in full/Do not abbreviate)
                  </Text>
                </View>

                <View style={styles.tableWorkSalaryCol}>
                  <Text style={styles.tableWorkSalaryText1Cell}>
                      MONTHLY
                  </Text>
                  <Text style={styles.tableWorkSalaryText2Cell}>
                      SALARY
                  </Text>
                </View>


                <View style={styles.tableWorkSalaryGradeCol}>
                  <Text style={styles.tableWorkSGText1Cell}>
                      SALARY/ JOB/ PAY
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                      GRADE(if
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                      applicable)& STEP
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                     (Format *00-0*)/
                  </Text>
                  <Text style={styles.tableWorkSGText1Cell}>
                     INCREMENT
                  </Text>
                </View>


                <View style={styles.tableWorkStatusCol}>
                  <Text style={styles.tableWorkStatusText1Cell}>
                      STATUS OF
                  </Text>
                   <Text style={styles.tableWorkStatusText2Cell}>
                      APPOINTMENT
                  </Text>
                </View>


                <View style={styles.tableWorkGovServiceCol}>
                  <Text style={styles.tableWorkGovServiceText1Cell}>
                      GOV'T
                  </Text>
                  <Text style={styles.tableWorkGovServiceText2Cell}>
                      SERVICE
                  </Text>
                  <Text style={styles.tableWorkGovServiceText3Cell}>
                      (Y/N)
                  </Text>
                </View>

              </View>

              {/* List of extended Work Experience Sheet */}
            {
              fetchWorkExp.map((data) => {
                const {
                   unique, 
                    from,
                    to,
                    position, 
                    department, 
                    monthly, 
                    sgrade, 
                    status, 
                    government 
                  }  = data;

                  return (
                        
                    <View style={styles.tableWorkExpSecondPageValuesRow} key={unique}>
                      <View style={styles.tableWorkExpFromSecondPageValueCol}>
                        <Text style={styles.tableWorkExpFromSecondPageValueCell}>
                            {from}
                        </Text>
                      </View>

                      <View style={styles.tableWorkExpToSecondPageValueCol}>
                        <Text style={styles.tableWorkExpToSecondPageValueCell}>
                          {to}
                        </Text>  
                      </View>
                        
                      <View style={styles.tableWorkPositionSecondPageValueCol}>
                        <Text style={styles.tableWorkPositionSecondPageValueCell}>
                            {position}
                        </Text>  
                      </View>

                      <View style={styles.tableWorkDeptSecondPageValueCol}>
                        <Text style={styles.tableWorkDeptSecondPageValueCell}>
                            {department}
                        </Text>  
                      </View>
                      
                      <View style={styles.tableSalarySecondPageValueCol}>
                        <Text style={styles.tableSalarySecondPageValueCell}>
                            {monthly}
                        </Text>  
                      </View>
                
                      <View style={styles.tableSalaryGradeSecondPageValueCol}>
                        <Text style={styles.tableSalaryGradeSecondPageValueCell}>
                            {sgrade}
                        </Text>  
                      </View> 
                
                      <View style={styles.tableAppointmentSecondPageValueCol}>
                        <Text style={styles.tableAppointmentSecondPageValueCell}>
                            {status}
                        </Text>  
                      </View>
                
                      <View style={styles.tableGovServiceSecondPageValueCol}>
                        <Text style={styles.tableGovServiceSecondPageValueCell}>
                          {government}
                        </Text>  
                      </View>
                    </View>
                  )
              })
            }
            {
                (() => {
                      const workexp2 = [];
                      for (let i = 0; i < (35 - fetchWorkExp.length); i++) {
                        const WorkExprows2 = i;

                        workexp2.push(

                            <View style={styles.tableWorkExpSecondPageValuesRow} key={WorkExprows2}>
                              <View style={styles.tableWorkExpFromSecondPageValueCol}>
                                <Text style={styles.tableWorkExpFromSecondPageValueCell}>
                              
                                </Text>
                              </View>

                              <View style={styles.tableWorkExpToSecondPageValueCol}>
                                <Text style={styles.tableWorkExpToSecondPageValueCell}>
                                  
                                </Text>  
                              </View>
                                
                              <View style={styles.tableWorkPositionSecondPageValueCol}>
                                <Text style={styles.tableWorkPositionSecondPageValueCell}>
                                  
                                </Text>  
                              </View>

                              <View style={styles.tableWorkDeptSecondPageValueCol}>
                                <Text style={styles.tableWorkDeptSecondPageValueCell}>
                                  
                                </Text>  
                              </View>
                              
                              <View style={styles.tableSalarySecondPageValueCol}>
                                <Text style={styles.tableSalarySecondPageValueCell}>
                                  
                                </Text>  
                              </View>
                              
                              <View style={styles.tableSalaryGradeSecondPageValueCol}>
                                <Text style={styles.tableSalaryGradeSecondPageValueCell}>
                                  
                                </Text>  
                              </View> 
                              
                              <View style={styles.tableAppointmentSecondPageValueCol}>
                                <Text style={styles.tableAppointmentSecondPageValueCell}>
                                  
                                </Text>  
                              </View>
                              
                              <View style={styles.tableGovServiceSecondPageValueCol}>
                                <Text style={styles.tableGovServiceSecondPageValueCell}>
                                  
                                </Text>  
                              </View>
                            </View>

                        
                          )
                      }
                      return workexp2;
                  })()
                }
            </View>
            {/* footer */}
            <View style={styles.tableFooter}>
                <View style={styles.tableFooterRow}>
                  <View style={styles.tableFooterCol}>
                    <Text style={styles.tableFooterCell}> </Text>
                  </View>
                </View>
            </View>

            <View style={styles.tableSecondPageFooter2}>
              
                <View style={styles.tableSecondPageFooterRow2}>
                  
                  <View style={styles.tableFooterSecondPageSignatureCol}>
                    <Text style={styles.tableFooterSecondPageSignatureCell}> 
                      SIGNATURE
                    </Text>
                  </View>

                  <View style={styles.tableFooterSecondPageSignatureValueCol}>
                    <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                    
                    </Text>
                  </View>

                  <View style={styles.tableFooterSecondPageDateCol}>
                    <Text style={styles.tableFooterSecondPageDateCell}> 
                    DATE
                    </Text>
                  </View>

                  <View style={styles.tableFooterSecondPageDateValueCol}>
                    <Text style={styles.tableFooterSecondPageDateValueCell}> 
                    
                    </Text>
                  </View>

                  <View style={styles.tableFooterSecondPageCol}>
                    <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                     
                    </Text>
                  </View>
                
                </View>

            </View> 

            {/* end Footer */}   
              
          </View>
        </Page>
        :
         <>
        </>
        }
{/* ------------------------------------------------------------ second page --------------------------------------------------- */}

{/* ----------------------------- Voluntary Work --------------------------------------- */}
        <Page size="LEGAL" style={styles.page}>

            <View style={styles.section}>
               
                <View style={styles.table4}>
                    <View style={styles.tableRowPS4}>
                        <View style={styles.tableColPS4}>
                            <Text style={styles.tableCellPS4}>VI.VOLUNTARY WORK OR INVOLVEMENT IN CIVIC / NON-GOVERNMENT / PEOPLE / VOLUNTARY ORGANIZATION/S</Text>
                        </View>
                    </View>                    
                </View>

              <View style={styles.tableVoluntaryWork}>
                <View style={styles.tableVoluntaryWorkRow}>

                  <View style={styles.tableVoluntaryWorkNameOrgCol}>
                    <Text style={styles.tableVoluntaryWorkNumberCell}>
                      29.
                    </Text> 
                    
                    <Text style={styles.tableVoluntaryWorkNameOrgCell1}>
                      NAME & ADDRESS OF ORGANIZATION
                    </Text>
                    <Text style={styles.tableVoluntaryWorkNameOrgCell2}>
                        (Write in full)
                    </Text>
                  </View>

                  <View style={styles.tableVoluntaryDatesCol}>
                    <Text style={styles.tableVoluntaryDatesText1Cell}>
                        INCLUSIVE DATES
                    </Text>
                    <Text style={styles.tableVoluntaryDatesText2Cell}>
                        (mm/dd/yyyy)
                    </Text>
                    
                    <View style={styles.tableVoluntaryDatesPeriod}>
                        <View style={styles.tableVoluntaryDatesPeriodRow}>

                            <View style={styles.tableVoluntaryFromCol}>
                                <Text style={styles.tableVoluntaryFromCell}>
                                      From
                                </Text>
                            </View>

                            <View style={styles.tableVoluntaryToCol}>
                                <Text style={styles.tableVoluntaryToCell}>
                                      To
                                </Text>
                            </View>
                        </View>
                    </View> 
        
                  </View>

                  <View style={styles.tableVoluntaryHoursCol}>
                    <Text style={styles.tableVoluntaryHoursText1Cell}>
                        NUMBER OF
                    </Text>
                     <Text style={styles.tableVoluntaryHoursText2Cell}>
                        HOURS
                    </Text>
                  </View>

                  <View style={styles.tableVoluntaryPositionCol}>
                    <Text style={styles.tableVoluntaryPositionText1Cell}>
                        POSITION / NATURE OF WORK
                    </Text>
                  </View>

                </View>

                {/* list of Voluntary */}

                {fetchVolExp.slice(0,7).map((data) => {
                   const {
                      unique, 
                      ORGANIZATION,
                      FROMDATE,
                      TODATE, 
                      HOURSE, 
                      POSITION
                     }  = data;

                     return (

                      <View style={styles.tableVoluntaryWorkValuesRow} key={unique}>
                          <View style={styles.tableVoluntaryOrganizationValueCol}>
                            <Text style={styles.tableVoluntaryOrganizationValueCell}>
                                  {ORGANIZATION}
                            </Text>
                          </View>

                          <View style={styles.tableVoluntaryWorkFromValueCol}>
                              <Text style={styles.tableVoluntaryWorkFromValueCell}>
                                  {FROMDATE}
                              </Text>
                            </View>

                            <View style={styles.tableVoluntaryWorkToValueCol}>
                              <Text style={styles.tableVoluntaryWorkToValueCell}>
                                  {TODATE}
                              </Text>  
                            </View>


                            <View style={styles.tableVoluntaryHoursValueCol}>
                              <Text style={styles.tableVoluntaryHoursValueCell}>
                                  {HOURSE}
                              </Text> 
                            </View>

                            <View style={styles.tableVoluntaryPositionValueCol}>
                              <Text style={styles.tableVoluntaryPositionValueCell}>
                                  {POSITION}
                              </Text>
                            </View>
                        </View>

                       )
                    })
                  }

                  {
                  (() => {
                      const vol = [];
                      for (let i = 0; i < (7 - fetchVolExp.length); i++) {
                        const Volrows = i;

                        vol.push(
                          
                          <View style={styles.tableVoluntaryWorkValuesRow} key={Volrows}>
                          <View style={styles.tableVoluntaryOrganizationValueCol}>
                            <Text style={styles.tableVoluntaryOrganizationValueCell}>
                                 
                            </Text>
                          </View>

                          <View style={styles.tableVoluntaryWorkFromValueCol}>
                              <Text style={styles.tableVoluntaryWorkFromValueCell}>
                                 
                              </Text>
                            </View>

                            <View style={styles.tableVoluntaryWorkToValueCol}>
                              <Text style={styles.tableVoluntaryWorkToValueCell}>
                                 
                              </Text>  
                            </View>


                            <View style={styles.tableVoluntaryHoursValueCol}>
                              <Text style={styles.tableVoluntaryHoursValueCell}>
                                 
                              </Text> 
                            </View>

                            <View style={styles.tableVoluntaryPositionValueCol}>
                              <Text style={styles.tableVoluntaryPositionValueCell}>
                               
                              </Text>
                            </View>
                        </View>
                         
                        )
                      }
                      return vol;
                  })()
                }
                {/* footer */}
                
              </View>
              <View style={styles.tableFooter}>
                <View style={styles.tableFooterRow}>
                  <View style={styles.tableFooterCol}>
                    <Text style={styles.tableFooterCell}> </Text>
                  </View>
                </View>
              </View>
              
          {/* ----------------------------- Learning and Development ----------------------------  */}
          
          <View style={styles.table5}>
              <View style={styles.tableRowPS5}>
                  <View style={styles.tableColPS5}>
                      <Text style={styles.tableCellPS5}>VII. LEARNING AND DEVELOPMENT (L&D) INTERVENTIONS/TRAINING PROGRAM ATTENDED</Text>
                      <Text style={styles.tableCellPS5Desc}>(Start from the most recent L&D/training program and include only the relevant L&D/training taken for the last five (5) years for Division Chief/Executive/Managerial positions)</Text>
                  </View>
              </View>                    
          </View>
            
          
          {/* Header Learning and Development */}
          <View style={styles.tableLearningDev}>
            
            <View style={styles.tableLearningDevRow}>
              
              <View style={styles.tableLearningDevTrainingCol}>
                  <Text style={styles.tableLearningDevNumberCell}>
                    30.
                  </Text> 
                  
                  <Text style={styles.tableLearningDevTrainingCell1}>
                     TITLE OF LEARNING AND DEVELOPMENT INTERVENTIONS/TRAINING PROGRAMS   
                  </Text>
                  <Text style={styles.tableLearningDevTrainingCell2}>
                      (Write in full)
                  </Text>
              </View>

                <View style={styles.tableLearningDevDatesCol}>
                  <Text style={styles.tableLearningDevDatesText1Cell}>
                      NCLUSIVE DATES OF
                  </Text>
                  <Text style={styles.tableLearningDevDatesText2Cell}>
                        ATTENDANCE
                  </Text>
                  <Text style={styles.tableLearningDevDatesText3Cell}>
                      (mm/dd/yyyy)
                  </Text>
                    
                  <View style={styles.tableLearningDevDatesPeriod}>
                      <View style={styles.tableLearningDevDatesPeriodRow}>

                          <View style={styles.tableLearningDevFromCol}>
                              <Text style={styles.tableLearningDevFromCell}>
                                    From
                              </Text>
                          </View>

                          <View style={styles.tableLearningDevToCol}>
                              <Text style={styles.tableLearningDevToCell}>
                                    To
                              </Text>
                          </View>
                      </View>
                  </View> 
      
                 </View>
                 
                 <View style={styles.tableLearningDevHoursCol}>
                    <Text style={styles.tableLearningDevHoursText1Cell}>
                        NUMBER OF
                    </Text>
                     <Text style={styles.tableLearningDevHoursText2Cell}>
                        HOURS
                    </Text>
                  </View>
                  
                  <View style={styles.tableLearningDevTypeLDCol}>
                    <Text style={styles.tableLearningDevTypeLDText1Cell}>
                      Type of LD
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText2Cell}>
                      (Managerial/ 
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText3Cell}>
                      Supervisory/
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText4Cell}>
                      Technical/etc)
                    </Text>
                  </View>

                  <View style={styles.tableLearningDevConductedCol}>
                    <Text style={styles.tableLearningDevConductedText1Cell}>
                      CONDUCTED/ SPONSORED BY   
                    </Text>
                     <Text style={styles.tableLearningDevConductedText2Cell}>
                      (Write in full)
                    </Text>
                  </View>

            </View>
            
            {/* List of Trainings */}
            
            {
              fetchLearnDev.slice(0,20).map((data) => {
                const {
                  unique,
                  TRCORS,
                  TRNFROM,
                  TRNTO,
                  TRNHRS,
                  LD,
                  CONDUCT
                } = data;

                return (
                  <View style={styles.tableLearningDevValuesRow} key={unique}>
             
                      <View style={styles.tableLearningDevValueCol}>
                        <Text style={styles.tableLearningDevValueCell}>
                          {TRCORS}
                        </Text>
                      </View>

                      <View style={styles.tableLearningDevFromValueCol}>
                        <Text style={styles.tableLearningDevFromValueCell}>
                          {TRNFROM}  
                        </Text>
                      </View>
                        
                      <View style={styles.tableLearningDevToValueCol}>
                        <Text style={styles.tableLearningDevToValueCell}>
                          {TRNTO}
                        </Text>
                      </View>

                      <View style={styles.tableLearningDevHoursValueCol}>
                        <Text style={styles.tableLearningDevHoursValueCell}>
                         {TRNHRS}
                        </Text> 
                      </View>

                      <View style={styles.tableLearningDevTypeLDValueCol}>
                        <Text style={styles.tableLearningDevTypeLDValueCell}>
                        {LD}
                        </Text> 
                      </View>

                      <View style={styles.tableLearningDevConductedValueCol}>
                        <Text style={styles.tableLearningDevConductedValueCell}>
                        {CONDUCT}
                        </Text> 
                      </View>

                  </View>
                )
              })
            }
            
            {
              (() => {

                const emptyrows = 20 - fetchLearnDev.slice(0, 20).length;
                const learn = [];

                for (let i = 0; i < emptyrows; i++) {

                const LDrows = i;

                learn.push(
                  <View style={styles.tableLearningDevValuesRow} key={LDrows}>
             
                    <View style={styles.tableLearningDevValueCol}>
                      <Text style={styles.tableLearningDevValueCell}>
                  
                      </Text>
                    </View>

                    <View style={styles.tableLearningDevFromValueCol}>
                      <Text style={styles.tableLearningDevFromValueCell}>
                       
                      </Text>
                    </View>
                      
                    <View style={styles.tableLearningDevToValueCol}>
                      <Text style={styles.tableLearningDevToValueCell}>
                    
                      </Text>
                    </View>

                    <View style={styles.tableLearningDevHoursValueCol}>
                      <Text style={styles.tableLearningDevHoursValueCell}>
                    
                      </Text> 
                    </View>

                    <View style={styles.tableLearningDevTypeLDValueCol}>
                      <Text style={styles.tableLearningDevTypeLDValueCell}>
                    
                      </Text> 
                    </View>

                    <View style={styles.tableLearningDevConductedValueCol}>
                      <Text style={styles.tableLearningDevConductedValueCell}>
                   
                      </Text> 
                    </View>

                  </View>
                 )
                }
                  return learn;
              })()
            }
          </View>
          <View style={styles.tableFooter}>
            <View style={styles.tableFooterRow}>
              <View style={styles.tableFooterCol}>
                <Text style={styles.tableFooterCell}> </Text>
              </View>
            </View>
          </View>

           {/* ----------------------------- Other Information  ----------------------------  */}
          
            <View style={styles.table6}>
                <View style={styles.tableRowPS6}>
                    <View style={styles.tableColPS6}>
                        <Text style={styles.tableCellPS6}>VIII. OTHER INFORMATION </Text>
                    </View>
                </View>                    
            </View>
            
          {/* Header Other Information  */} 
          <View style={styles.tableOtherInformation}>
               <View style={styles.tableOtherInfoRow}>

                <View style={styles.tableOtherInfoHobbiesCol}>
                    <Text style={styles.tableOtherInfoHobbiesNumberCell}>
                      31.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoHobbiesCell1}>
                      SPECIAL SKILLS and HOBBIESS
                    </Text>
                </View>

                <View style={styles.tableOtherInfoRecognationCol}>
                    <Text style={styles.tableOtherInfoRecognationNumberCell}>
                      32.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoRecognationCell1}>
                      NON-ACADEMIC DISTINCTIONS / RECOGNITION      
                    </Text>
                    <Text style={styles.tableOtherInfoRecognationCell2}>
                      (Write in full)      
                    </Text>
                </View>

                <View style={styles.tableOtherInfoOrganizationCol}>
                    <Text style={styles.tableOtherInfoOrganizationNumberCell}>
                      33.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoOrganizationCell1}>
                      MEMBERSHIP IN ASSOCIATION/ORGANIZATION
                    </Text>
                    <Text style={styles.tableOtherInfoOrganizationCell2}>
                      (Write in full)      
                    </Text>
                </View>
              </View>

              {/* List of Other Information */}

              {
                 fetchSkills.slice(0,5).map((data) => {
                const {
                  unique,
                  SKILLDETAILS,
                  Recognation ,
                  Organization,

                } = data;

                return (
                  <View style={styles.tableOtherInfoValuesRow} key={unique}>

                    <View style={styles.tableOtherInfoHobbiesValueCol}>
                      <Text style={styles.tableOtherInfoHobbiesValueCell}>
                          {SKILLDETAILS}
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoRecognationValueCol}>
                      <Text style={styles.tableOtherInfoRecognationValueCell}>
                        {Recognation}
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoOrganizationValueCol}>
                      <Text style={styles.tableOtherInfoOrganizationValueCell}>
                        {Organization}
                      </Text>
                    </View>
                    
                  </View>
                )

                 })
              }

              {
                (() => {
                const otherinfo = [];
                for (let i = 0; i < (5 - fetchSkills.length); i++) {
                  const OtherInforows = i;

                otherinfo.push(
                  <View style={styles.tableOtherInfoValuesRow} key={OtherInforows}>

                    <View style={styles.tableOtherInfoHobbiesValueCol}>
                      <Text style={styles.tableOtherInfoHobbiesValueCell}>
                         
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoRecognationValueCol}>
                      <Text style={styles.tableOtherInfoRecognationValueCell}>
                      
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoOrganizationValueCol}>
                      <Text style={styles.tableOtherInfoOrganizationValueCell}>
                    
                      </Text>
                    </View>
                    
                  </View>

               )
                }
                  return otherinfo;
              })()
            }
             


              
            </View>
            {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableSecondPageFooter2}>
                
                  <View style={styles.tableSecondPageFooterRow2}>
                    
                    <View style={styles.tableFooterSecondPageSignatureCol}>
                      <Text style={styles.tableFooterSecondPageSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageSignatureValueCol}>
                      <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateCol}>
                      <Text style={styles.tableFooterSecondPageDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateValueCol}>
                      <Text style={styles.tableFooterSecondPageDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageCol}>
                      <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                       
                      </Text>
                    </View>
                  
                  </View>

              </View> 

            {/* end Footer */}
        
         </View>
          {/*section*/}
          
        </Page>

    {/* ------------------------------------------------------------ Extend Page of voluntary work  --------------------------------------------------- */}
          
   { fetchVolExp.length > 7 ? 

        <Page size="LEGAL" style={styles.page}>

          <View style={styles.section}>
            
              <View style={styles.table4}>
                  <View style={styles.tableRowPS4}>
                      <View style={styles.tableColPS4}>
                          <Text style={styles.tableCellPS4}>VI.VOLUNTARY WORK OR INVOLVEMENT IN CIVIC / NON-GOVERNMENT / PEOPLE / VOLUNTARY ORGANIZATION/S</Text>
                      </View>
                  </View>                    
              </View>

              <View style={styles.tableVoluntaryWork}>
                <View style={styles.tableVoluntaryWorkRow}>

                  <View style={styles.tableVoluntaryWorkNameOrgCol}>
                    <Text style={styles.tableVoluntaryWorkNumberCbell}>
                      29. 
                    </Text> 
                    
                    <Text style={styles.tableVoluntaryWorkNameOrgCell1}>
                      NAME & ADDRESS OF ORGANIZATION
                    </Text>
                    <Text style={styles.tableVoluntaryWorkNameOrgCell2}>
                        (Write in full)
                    </Text>
                  </View>

                  <View style={styles.tableVoluntaryDatesCol}>
                    <Text style={styles.tableVoluntaryDatesText1Cell}>
                        INCLUSIVE DATES
                    </Text>
                    <Text style={styles.tableVoluntaryDatesText2Cell}>
                        (mm/dd/yyyy)
                    </Text>
                    
                    <View style={styles.tableVoluntaryDatesPeriod}>
                        <View style={styles.tableVoluntaryDatesPeriodRow}>

                            <View style={styles.tableVoluntaryFromCol}>
                                <Text style={styles.tableVoluntaryFromCell}>
                                      From
                                </Text>
                            </View>

                            <View style={styles.tableVoluntaryToCol}>
                                <Text style={styles.tableVoluntaryToCell}>
                                      To
                                </Text>
                            </View>
                        </View>
                    </View> 
        
                  </View>

                  <View style={styles.tableVoluntaryHoursCol}>
                    <Text style={styles.tableVoluntaryHoursText1Cell}>
                        NUMBER OF
                    </Text>
                     <Text style={styles.tableVoluntaryHoursText2Cell}>
                        HOURS
                    </Text>
                  </View>

                  <View style={styles.tableVoluntaryPositionCol}>
                    <Text style={styles.tableVoluntaryPositionText1Cell}>
                        POSITION / NATURE OF WORK
                    </Text>
                  </View>

                </View>

                {/* list of Voluntary */}

                {fetchVolExp.map((data) => {
                   const {
                      unique, 
                      ORGANIZATION,
                      FROMDATE,
                      TODATE, 
                      HOURSE, 
                      POSITION
                     }  = data;

                     return (

                      <View style={styles.tableExtendedVolWorkValuesRow} key={unique}>
                          <View style={styles.tableExtendedVolOrganizationValueCol}>
                            <Text style={styles.tableExtendedVolOrganizationValueCell}>
                                  {ORGANIZATION}
                            </Text>
                          </View>

                          <View style={styles.tableExtendedVolWorkFromValueCol}>
                              <Text style={styles.tableExtendedVolWorkFromValueCell}>
                                  {FROMDATE}
                              </Text>
                            </View>

                            <View style={styles.tableExtendedVolWorkToValueCol}>
                              <Text style={styles.tableExtendedVolWorkToValueCell}>
                                  {TODATE}
                              </Text>  
                            </View>


                            <View style={styles.tableExtendedVolHoursValueCol}>
                              <Text style={styles.tableExtendedVolHoursValueCell}>
                                  {HOURSE}
                              </Text> 
                            </View>

                            <View style={styles.tableExtendedVolPositionValueCol}>
                              <Text style={styles.tableExtendedVolPositionValueCell}>
                                  {POSITION}
                              </Text>
                            </View>
                        </View>

                       )
                    })
                  }

              {
                    (() => {
                      const volwork2 = [];
                      for (let i = 0; i < (39 - fetchVolExp.length); i++) {
                        const volworkrows2 = i;

                        volwork2.push(
                          <View style={styles.tableExtendedVolWorkValuesRow} key={volworkrows2}>
                          <View style={styles.tableExtendedVolOrganizationValueCol}>
                            <Text style={styles.tableExtendedVolOrganizationValueCell}>
                                 
                            </Text>
                          </View>

                          <View style={styles.tableExtendedVolWorkFromValueCol}>
                              <Text style={styles.tableExtendedVolWorkFromValueCell}>
                                 
                              </Text>
                            </View>

                            <View style={styles.tableExtendedVolWorkToValueCol}>
                              <Text style={styles.tableExtendedVolWorkToValueCell}>
                                 
                              </Text>  
                            </View>


                            <View style={styles.tableExtendedVolHoursValueCol}>
                              <Text style={styles.tableExtendedVolHoursValueCell}>
                                 
                              </Text> 
                            </View>

                            <View style={styles.tableExtendedVolPositionValueCol}>
                              <Text style={styles.tableExtendedVolPositionValueCell}>
                                 
                              </Text>
                            </View>
                        </View>
                        )
                      }
                      return volwork2;
                  })()
                } 


              </View>
               {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableSecondPageFooter2}>
                
                  <View style={styles.tableSecondPageFooterRow2}>
                    
                    <View style={styles.tableFooterSecondPageSignatureCol}>
                      <Text style={styles.tableFooterSecondPageSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageSignatureValueCol}>
                      <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateCol}>
                      <Text style={styles.tableFooterSecondPageDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateValueCol}>
                      <Text style={styles.tableFooterSecondPageDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageCol}>
                      <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                       
                      </Text>
                    </View>
                  
                  </View>

              </View> 
              
            {/* end Footer */}   
          
          </View>

        </Page>

    :
        <>
        </>
      }  
      {/* --------------------------------------------- End of Voluntary Work ------------------------------------------------------ */}

      {/* --------------------------------------------- Extend Page of learning and Developement  ----------------------------------- */}

      {
        fetchLearnDev.length > 20 ?
          
        

        <Page size="LEGAL" style={styles.page}>

          <View style={styles.section}>
            
            <View style={styles.table5Extended}>
              <View style={styles.tableRowPS5Extended}>
                  <View style={styles.tableColPS5Extended}>
                      <Text style={styles.tableCellPS5Extended}>VII. LEARNING AND DEVELOPMENT (L&D) INTERVENTIONS/TRAINING PROGRAM ATTENDED</Text>
                      <Text style={styles.tableCellPS5DescExtended}>(Start from the most recent L&D/training program and include only the relevant L&D/training taken for the last five (5) years for Division Chief/Executive/Managerial positions)</Text>
                  </View>
              </View>                    
            </View>
            
            {/* Header Learning and Development */}
          <View style={styles.tableLearningDev}>
            
            <View style={styles.tableLearningDevRow}>
              
              <View style={styles.tableLearningDevTrainingCol}>
                  <Text style={styles.tableLearningDevNumberCell}>
                    30.
                  </Text> 
                  
                  <Text style={styles.tableLearningDevTrainingCell1}>
                     TITLE OF LEARNING AND DEVELOPMENT INTERVENTIONS/TRAINING PROGRAMS   
                  </Text>
                  <Text style={styles.tableLearningDevTrainingCell2}>
                      (Write in full)
                  </Text>
              </View>

                <View style={styles.tableLearningDevDatesCol}>
                  <Text style={styles.tableLearningDevDatesText1Cell}>
                      NCLUSIVE DATES OF
                  </Text>
                  <Text style={styles.tableLearningDevDatesText2Cell}>
                        ATTENDANCE
                  </Text>
                  <Text style={styles.tableLearningDevDatesText3Cell}>
                      (mm/dd/yyyy)
                  </Text>
                    
                  <View style={styles.tableLearningDevDatesPeriod}>
                      <View style={styles.tableLearningDevDatesPeriodRow}>

                          <View style={styles.tableLearningDevFromCol}>
                              <Text style={styles.tableLearningDevFromCell}>
                                    From
                              </Text>
                          </View>

                          <View style={styles.tableLearningDevToCol}>
                              <Text style={styles.tableLearningDevToCell}>
                                    To
                              </Text>
                          </View>
                      </View>
                  </View> 
      
                 </View>
                 
                 <View style={styles.tableLearningDevHoursCol}>
                    <Text style={styles.tableLearningDevHoursText1Cell}>
                        NUMBER OF
                    </Text>
                     <Text style={styles.tableLearningDevHoursText2Cell}>
                        HOURS
                    </Text>
                  </View>
                  
                  <View style={styles.tableLearningDevTypeLDCol}>
                    <Text style={styles.tableLearningDevTypeLDText1Cell}>
                      Type of LD
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText2Cell}>
                      (Managerial/ 
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText3Cell}>
                      Supervisory/
                    </Text>
                    <Text style={styles.tableLearningDevTypeLDText4Cell}>
                      Technical/etc)
                    </Text>
                  </View>

                  <View style={styles.tableLearningDevConductedCol}>
                    <Text style={styles.tableLearningDevConductedText1Cell}>
                      CONDUCTED/ SPONSORED BY   
                    </Text>
                     <Text style={styles.tableLearningDevConductedText2Cell}>
                      (Write in full)
                    </Text>
                  </View>

            </View>

            {/* List of Extended Trainings */}
          {
            fetchLearnDev.slice(20).map((data) => {
                const {
                  unique,
                  TRCORS,
                  TRNFROM,
                  TRNTO,
                  TRNHRS,
                  LD,
                  CONDUCT
                } = data;

                return (
                    
                  <View style={styles.tableLearningDevValuesExtendedRow} key={unique}>
             
                      <View style={styles.tableLearningDevValueExtendedCol}>
                        <Text style={styles.tableLearningDevValueExtendedCell}>
                            {TRCORS}
                        </Text>
                      </View>

                      <View style={styles.tableLearningDevFromValueExtendedCol}>
                        <Text style={styles.tableLearningDevFromValueExtendedCell}>
                          {TRNFROM}
                        </Text>
                      </View>
                        
                      <View style={styles.tableLearningDevToValueExtendedCol}>
                        <Text style={styles.tableLearningDevToValueExtendedCell}>
                          {TRNTO}
                        </Text>
                      </View>

                      <View style={styles.tableLearningDevHoursValueExtendedCol}>
                        <Text style={styles.tableLearningDevHoursValueExtendedCell}>
                          {TRNHRS}
                        </Text> 
                      </View>

                      <View style={styles.tableLearningDevTypeLDValueExtendedCol}>
                        <Text style={styles.tableLearningDevTypeLDValueExtendedCell}>
                          {LD}
                        </Text> 
                      </View>

                      <View style={styles.tableLearningDevConductedValueExtendedCol}>
                        <Text style={styles.tableLearningDevConductedValueExtendedCell}>
                          {CONDUCT}
                        </Text> 
                      </View>

                  </View>
                
                  )
              })
            }

            {
                  (() => {
                    
                    const totalRows = 58; // Minimum total rows required
                    const currentRows = fetchLearnDev.length; // Current number of rows
                    const remainingRows = totalRows - currentRows; // Calculate remaining empty rows

                        const emptyRows = [];
                    
                      for (let i = 0; i < remainingRows; i++) {
                        const learnrows2 = i;

                        emptyRows.push(
                          <View style={styles.tableLearningDevValuesExtendedRow} key={learnrows2}>
                
                            <View style={styles.tableLearningDevValueExtendedCol}>
                              <Text style={styles.tableLearningDevValueExtendedCell}>
                                
                              </Text>
                            </View>

                            <View style={styles.tableLearningDevFromValueExtendedCol}>
                              <Text style={styles.tableLearningDevFromValueExtendedCell}>
                             
                              </Text>
                            </View>
                            
                            <View style={styles.tableLearningDevToValueExtendedCol}>
                              <Text style={styles.tableLearningDevToValueExtendedCell}>
                             
                              </Text>
                            </View>

                            <View style={styles.tableLearningDevHoursValueExtendedCol}>
                              <Text style={styles.tableLearningDevHoursValueExtendedCell}>
                             
                              </Text>
                            </View>

                            <View style={styles.tableLearningDevTypeLDValueExtendedCol}>
                              <Text style={styles.tableLearningDevTypeLDValueExtendedCell}>
                             
                              </Text>
                            </View>

                            <View style={styles.tableLearningDevConductedValueExtendedCol}>
                              <Text style={styles.tableLearningDevConductedValueExtendedCell}>
                             
                              </Text>
                            </View>

                          </View>
                    
                    
                        );
                      }
                      return emptyRows;
                  })()
                } 

          </View>
          
           {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableSecondPageFooter2}>
                
                  <View style={styles.tableSecondPageFooterRow2}>
                    
                    <View style={styles.tableFooterSecondPageSignatureCol}>
                      <Text style={styles.tableFooterSecondPageSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageSignatureValueCol}>
                      <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateCol}>
                      <Text style={styles.tableFooterSecondPageDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateValueCol}>
                      <Text style={styles.tableFooterSecondPageDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageCol}>
                      <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                    
                      </Text>
                    </View>
                  
                  </View>

              </View>
            {/* end Footer */}   

          </View>
          {/* section */}
        </Page>
        
        : 
        <>
        </>
      }


      {
        fetchSkills.length > 5 ? 
        <Page size="LEGAL" style={styles.page}>

          <View style={styles.section}>

            <View style={styles.table6Extended}>
                <View style={styles.tableRowPS6}>
                    <View style={styles.tableColPS6}>
                        <Text style={styles.tableCellPS6}>VIII. OTHER INFORMATION </Text>
                    </View>
                </View>                    
            </View>

            {/* Header Other Information  */} 
          <View style={styles.tableOtherInformation}>
               <View style={styles.tableOtherInfoRow}>

                <View style={styles.tableOtherInfoHobbiesCol}>
                    <Text style={styles.tableOtherInfoHobbiesNumberCell}>
                      31.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoHobbiesCell1}>
                      SPECIAL SKILLS and HOBBIESS
                    </Text>
                </View>

                <View style={styles.tableOtherInfoRecognationCol}>
                    <Text style={styles.tableOtherInfoRecognationNumberCell}>
                      32.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoRecognationCell1}>
                      NON-ACADEMIC DISTINCTIONS / RECOGNITION      
                    </Text>
                    <Text style={styles.tableOtherInfoRecognationCell2}>
                      (Write in full)      
                    </Text>
                </View>

                <View style={styles.tableOtherInfoOrganizationCol}>
                    <Text style={styles.tableOtherInfoOrganizationNumberCell}>
                      33.
                    </Text> 
                    
                    <Text style={styles.tableOtherInfoOrganizationCell1}>
                      MEMBERSHIP IN ASSOCIATION/ORGANIZATION
                    </Text>
                    <Text style={styles.tableOtherInfoOrganizationCell2}>
                      (Write in full)      
                    </Text>
                </View>
              </View>

              {
                 fetchSkills.slice(0,5).map((data) => {
                const {
                  unique,
                  SKILLDETAILS,
                  Recognation ,
                  Organization,

                } = data;

                return (
                  <View style={styles.tableOtherInfoValuesRow} key={unique}>

                    <View style={styles.tableOtherInfoHobbiesValueExtendedCol}>
                      <Text style={styles.tableOtherInfoHobbiesValueExtendedCell}>
                          {SKILLDETAILS}
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoRecognationValueExtendedCol}>
                      <Text style={styles.tableOtherInfoRecognationValueExtendedCell}>
                        {Recognation}
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoOrganizationValueExtendedCol}>
                      <Text style={styles.tableOtherInfoOrganizationValueExtendedCell}>
                        {Organization}
                      </Text>
                    </View>
                    
                  </View>
                )

                 })
              }

              {
                (() => {
                const otherinfo2 = [];
                for (let i = 0; i < (58 - fetchSkills.length); i++) {
                  const OtherInforows2 = i;

                otherinfo2.push(
                  <View style={styles.tableOtherInfoValuesRow} key={OtherInforows2}>

                    <View style={styles.tableOtherInfoHobbiesValueExtendedCol}>
                      <Text style={styles.tableOtherInfoHobbiesValueExtendedCell}>
                         
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoRecognationValueExtendedCol}>
                      <Text style={styles.tableOtherInfoRecognationValueExtendedCell}>
                      
                      </Text>
                    </View>

                    <View style={styles.tableOtherInfoOrganizationValueExtendedCol}>
                      <Text style={styles.tableOtherInfoOrganizationValueExtendedCell}>
                    
                      </Text>
                    </View>
                    
                  </View>

               )
                }
                  return otherinfo2;
              })()
            }
            </View>

            {/* footer */}
              <View style={styles.tableFooter}>
                  <View style={styles.tableFooterRow}>
                    <View style={styles.tableFooterCol}>
                      <Text style={styles.tableFooterCell}> </Text>
                    </View>
                  </View>
              </View>

              <View style={styles.tableSecondPageFooter2}>
                
                  <View style={styles.tableSecondPageFooterRow2}>
                    
                    <View style={styles.tableFooterSecondPageSignatureCol}>
                      <Text style={styles.tableFooterSecondPageSignatureCell}> 
                        SIGNATURE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageSignatureValueCol}>
                      <Text style={styles.tableFooterSecondPageSignatureValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateCol}>
                      <Text style={styles.tableFooterSecondPageDateCell}> 
                       DATE
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageDateValueCol}>
                      <Text style={styles.tableFooterSecondPageDateValueCell}> 
                      
                      </Text>
                    </View>

                    <View style={styles.tableFooterSecondPageCol}>
                      <Text style={styles.tableFooterSecondPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                       
                      </Text>
                    </View>
                  
                  </View>

              </View>
            {/* end Footer */}
          </View>
          {/* Section */}
        </Page>
        :
        <>
        </>
      }

        <Page size="LEGAL" style={styles.page}>

          <View style={styles.section}>
   
            <View style={styles.tableQuestions34}>

              <View style={styles.tableQuestions34Row}>

                <View style={styles.tableQuestion34Col}>


                  <Text style={styles.tableQuestion34Cell}>
                    34. &nbsp;&nbsp;Are you related by consanguinity or affinity to the appointing or recommending authority, or to the
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;chief of bureau or office or to the person who has immediate supervision over you in the Office, 
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bureau or Department where you will be apppointed,
                  </Text>
                  
                  <Text style={styles.tableQuestion34CellSetA}>
                    a. within the third degree?
                  </Text> 

                  <Text style={styles.tableQuestion34CellSetB}>
                    b. within the fourth degree (for Local Government Unit - Career Employees)?
                  </Text> 
                  
                  
                </View>

                <View style={styles.tableAnswer34Col}>

                  <Text style={styles.tableAnswer34CellSetAYes}>
                    {
                      fetchQues.thirddegreec === 1 ? 
                      <>
                         <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                      </>
                      :
                      <>
                         <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                      </>
                    }
                  </Text> 

                  <Text style={styles.tableAnswer34CellSetANo}>
                    {
                       fetchQues.thirddegreec === 0 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                    }
                
                    
                  </Text>

                  <Text style={styles.tableAnswer34CellSetBYes}>
                    {
                       fetchQues.fourthdegreec === 1 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                        </>
                    }
                  </Text> 

                  <Text style={styles.tableAnswer34CellSetBNo}>
                   {
                       fetchQues.fourthdegreec === 0 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                    }
                  </Text> 

                  <Text style={styles.tableAnswer34CellDetails}>
                      If YES, give details:
                  </Text> 

                  <Text style={styles.tableDetailsAnswer34Cell}>

                {fetchQues.fourthdegree ?? "________________________________________"}
                         
                
                  </Text>
          
                </View>
    
              </View>
            </View>

            {/* tableQuestions35 */}
             <View style={styles.tableQuestions35}>

              <View style={styles.tableQuestions35Row}>

                <View style={styles.tableQuestion35Col}>
                  <Text style={styles.tableQuestion35CellSetA}>
                    35. &nbsp;&nbsp;a. Have you ever been found guilty of any administrative offense?
                  </Text>

                  <Text style={styles.tableQuestion35CellSetB}>
                     &nbsp;&nbsp;b. Have you been criminally charged before any court?
                  </Text>              
                </View>

                <View style={styles.tableAnswer35Col}>

                    <View style={styles.tableAnswer35}>
                      <View style={styles.tableAnswer35Rows}>

                          <View style={styles.tableAnswerSetA35Cols}>
                              <Text style={styles.tableAnswer35CellsSetAYes}>
                                {
                                  fetchQues.guiltyc === 1 ? 
                                    <>
                                      <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                                    </>
                                    : 

                                    <>
                                      <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                                    </>
                                }
                              </Text>

                              <Text style={styles.tableAnswer35CellsSetANo}>
                                  {
                                    fetchQues.guiltyc === 0 ? 
                                      <>
                                        <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                                      </>
                                      : 

                                      <>
                                        <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                                      </>
                                  }
                              </Text>

                              <Text style={styles.tableAnswer35CellSetADetails}>
                                  If YES, give details:
                              </Text>

                              <Text style={styles.tableDetailsAnswer35CellSetA}>
                               {fetchQues.guiltyc ?? " ________________________________________"}
                              </Text> 

                          </View>
                      </View>

                     <View style={styles.tableAnswer35Rows}>

                          <View style={styles.tableAnswerSetB35Cols}>

                              <Text style={styles.tableAnswer35CellsSetBYes}>
                                 {
                                  fetchQues.chargec === 1 ? 
                                    <>
                                      <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                                    </>
                                    : 

                                    <>
                                      <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                                    </>
                                }
                              </Text>

                              <Text style={styles.tableAnswer35CellsSetBNo}>
                               {
                                  fetchQues.chargec === 0 ? 
                                    <>
                                      <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                                    </>
                                    : 

                                    <>
                                      <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                                    </>
                                }
                              </Text>
                              
                              <Text style={styles.tableAnswer35CellSetBDetails}>
                                  If YES, give details:
                              </Text>

                              <Text style={styles.tableDetailsAnswer35CellSetBDate}>
                                Date Filed: {fetchQues.chargedate ?? " __________________"}
                              </Text>

                              <Text style={styles.tableDetailsAnswer35CellSetBCase}>
                                Staus of Case/s:{fetchQues.chargestatus ?? " __________________"}
                              </Text>  

                          </View>

                      </View>

                   </View> 
                </View>
       
               </View>
            </View> 
            {/* tableQuestions35 */}

            {/* tableQuestions36 */}
            <View style={styles.tableQuestions36}>

              <View style={styles.tableQuestions36Row}>

                <View style={styles.tableQuestion36Col}>
                  <Text style={styles.tableQuestion36CellSetA}>
                    36. &nbsp;&nbsp;Have you ever been convicted of any crime or violation of any law, decree, ordinance or regulation by
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;any court or tribunal?
                  </Text>
                </View>

                 <View style={styles.tableAnswer36Col}>

                  <Text style={styles.tableAnswer36CellSetAYes}>
                   {
                      fetchQues.convicc === 1 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                        </>
                    }
                  </Text>
                  <Text style={styles.tableAnswer36CellsSetANo}>
                       {
                      fetchQues.convicc === 0 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                    }
                  </Text>

                  <Text style={styles.tableAnswer36CellSetADetails}>
                      If YES, give details:
                  </Text>

                  <Text style={styles.tableDetailsAnswer36CellSetA}>
                   {fetchQues.convic ?? " ________________________________________"} 
                  </Text>

                </View>

              </View>
            </View> 
            {/*tableQuestions36*/}

            {/* tableQuestions37 */}
             <View style={styles.tableQuestions37}>

              <View style={styles.tableQuestions37Row}>

                <View style={styles.tableQuestion37Col}>
                  <Text style={styles.tableQuestion37CellSetA}>
                    37. &nbsp;&nbsp;Have you ever been separated from the service in any of the following modes: resignation,  
                  </Text>
                  <Text style={styles.tableQuestion37CellSetA1}>
                       retirement, dropped from the rolls, dismissal, termination, end of term, finished contract or phased out
                       (abolition) in the public or private sector?
                  </Text>
                </View>

                <View style={styles.tableAnswer37Col}>

                  <Text style={styles.tableAnswer37CellSetAYes}>
                      {
                        fetchQues.separatec === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer37CellsSetANo}>
                     {
                      fetchQues.separatec === 0 ? 
                        <>
                          <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                        : 

                        <>
                          <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                        </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer37CellSetADetails}>
                      If YES, give details:
                  </Text>

                  <Text style={styles.tableDetailsAnswer37CellSetA}>
                    {fetchQues.separate ?? " ________________________________________"} 
                  </Text>

                </View>
              
              </View>
            </View> 
            {/* tableQuestions37 */}

            {/* tableQuestions38 */}
           <View style={styles.tableQuestions38}>

              <View style={styles.tableQuestions38Row}>

                 <View style={styles.tableQuestion38Col}>
                  <Text style={styles.tableQuestion38CellSetA}>
                    38. &nbsp;&nbsp;a. Have you ever been a candidate in a national or local election held within the last year (except 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Barangay election)?
                  </Text>

                  <Text style={styles.tableQuestion38CellSetB}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b. Have you resigned from the government service during the three (3)-month period before the last
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;election to promote/actively campaign for a national or local candidate?
                  </Text>
                </View>

                <View style={styles.tableAnswer38Col}>

                  <Text style={styles.tableAnswer38CellSetAYes}>
                    {
                        fetchQues.candidatec === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer38CellsSetANo}>
                     {
                        fetchQues.candidatec === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer38CellSetADetails}>
                      If YES, give details: <Text style={styles.Answer38DetailsSetA}>{fetchQues.candidate ?? " _________________________"}</Text>
                  </Text>

                  <Text style={styles.tableAnswer38CellSetBYes}>
                    {
                        fetchQues.resignedc === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer38CellsSetBNo}>
                      {
                        fetchQues.resignedc === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer38CellSetBDetails}>
                      If YES, give details: <Text style={styles.Answer38DetailsSetA}>{fetchQues.resigned ?? " _________________________"}</Text>
                  </Text>

                </View> 
              
              </View>
            </View> 
            {/* tableQuestions38 */}

            {/* tableQuestions39 */}
           <View style={styles.tableQuestions39}>

              <View style={styles.tableQuestions39Row}>
                  
                <View style={styles.tableQuestion39Col}>
                  <Text style={styles.tableQuestion39CellSetA}>
                    39. &nbsp;&nbsp;Have you acquired the status of an immigrant or permanent resident of another country?
                   
                  </Text>
                </View>
                <View style={styles.tableAnswer39Col}>

                  <Text style={styles.tableAnswer39CellSetAYes}>
                    {
                        fetchQues.immigrantc === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer39CellsSetANo}>
                      {
                        fetchQues.immigrantc === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer39CellSetADetails}>
                      If YES, give details (country):
                  </Text>

                  <Text style={styles.tableDetailsAnswer39CellSetA}>
                   {fetchQues.immigrant ?? " ________________________________________"}
                  </Text>

                </View>
                


              </View>
            </View> 
            {/* tableQuestions39 */}

          

            {/* tableQuestions40 */}
            <View style={styles.tableQuestions40}>
              <View style={styles.tableQuestions40Row}>

                <View style={styles.tableQuestion40Col}>
                  <Text style={styles.tableQuestion40Cell}>
                    40. &nbsp;&nbsp;Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for Disabled Persons (RA
                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7277); and (c) Solo Parents Welfare Act of 2000 (RA 8972), please answer the following items:
                  </Text>
                  
                  <Text style={styles.tableQuestion40CellSetA}>
                    a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Are you a member of any indigenous group?
                  </Text> 
             
                  <Text style={styles.tableQuestion40CellSetB}>
                    b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Are you a person with disability?
                  </Text> 

                  <Text style={styles.tableQuestion40CellSetC}>
                    c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Are you a solo parent?
                  </Text>  
                </View>    
                
                <View style={styles.tableAnswer40Col}>
                    
                  <Text style={styles.tableAnswer40CellSetAYes}>
                  {
                        fetchQues.indegneousc === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer40CellsSetANo}>
                     {
                        fetchQues.indegneousc === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer40CellSetADetails}>
                      If YES, please specify:
                  </Text> 
                  <Text style={styles.Answer40DetailsSetA}>
                     {fetchQues.indegneousc ?? " __________________"}
                  </Text>


                  <Text style={styles.tableAnswer40CellSetBYes}>
                   {
                        fetchQues.unablec === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer40CellsSetBNo}>
                      {
                        fetchQues.unablec === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer40CellSetBDetails}>
                      If YES, please specify ID No:
                  </Text> 
                  <Text style={styles.Answer40DetailsSetB}>
                 {fetchQues.unable ?? " __________________"}
                  </Text>

                  
                  <Text style={styles.tableAnswer40CellSetCYes}>
                  {
                        fetchQues.soloc === 1 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;YES
                          </>
                      }
                  </Text>
                  <Text style={styles.tableAnswer40CellsSetCNo}>
                      {
                        fetchQues.soloc === 0 ? 
                          <>
                            <Image src={checked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                          : 

                          <>
                            <Image src={Unchecked} style={{ width: '7px', height: '7px'  }} />&nbsp;&nbsp;NO
                          </>
                      }
                  </Text>

                  <Text style={styles.tableAnswer40CellSetCDetails}>
                      If YES, please specify ID No:
                  </Text> 
                  <Text style={styles.Answer40DetailsSetC}>
                  {fetchQues.solo ?? " __________________"}
                  </Text>


                </View>

              </View>
            </View> 
            {/* tableQuestions40 */}

                    

            <View style={styles.tableReference}>
              <View style={styles.tableReferenceRow}>
                
                  <View style={styles.tableReferenceBody}>

                    <View style={styles.tableReferenceHeader}>

                      <View style={styles.tableReferenceHeaderRow}>
                          <View style={styles.tableReferenceHeaderCol}>
                              <Text style={styles.tableReferenceHeaderCell}>
                                  41.&nbsp;&nbsp;&nbsp;REFERENCES <Text style={{ color:"red",fontSize:"6px"}}>(Person not related by consanguinity or affinity to applicant /appointee)</Text>
                              </Text> 
                          </View>
                      </View>
                    
                      <View style={styles.tableReferenceHeaderRow}>
                        <View style={styles.tableReferenceTHNameCol}>
                            <Text style={styles.tableReferenceTHNameCell}>
                                NAME
                            </Text> 
                        </View> 

                        <View style={styles.tableReferenceTHAddressCol}>
                            <Text style={styles.tableReferenceTHAddressCell}>
                                ADDRESS
                            </Text> 
                        </View> 

                        <View style={styles.tableReferenceTHTellCol}>
                            <Text style={styles.tableReferenceTHTellCell}>
                                TEL NO.
                            </Text> 
                        </View> 
                      </View>
                
                  </View>
                  {/* tableReferenceHeader */}
                
                  {/* tableReferenceBody */}
                  

                  <View style={styles.tableReferenceTBValue}>
                  { 
                    fetchRef.slice(0,3).map((data) => {
                      
                      const {
                        id,
                        name,
                        contact,
                        address
                      } = data;

                      return (
                          <View style={styles.tableReferenceTHValueRow} key={id}>
                              <View style={styles.tableReferenceTHNameValueCol}>
                                  <Text style={styles.tableReferenceTHNameValueCell}>
                                      {name ?? "N/A"}
                                  </Text> 
                              </View> 

                              <View style={styles.tableReferenceTHAddressValueCol}>
                                  <Text style={styles.tableReferenceTHAddressValueCell}>
                                      {address ?? "N/A"}
                                  </Text> 
                              </View> 

                              <View style={styles.tableReferenceTHTellValueCol}>
                                  <Text style={styles.tableReferenceTHTellValueCell}>
                                      {contact ?? "N/A"}
                                  </Text> 
                              </View> 
                          </View>

                        );
                      
                          
                      })
                    }
                    {
                    (() => {
                      const emptyRows = 3 - fetchRef.slice(0, 3).length
                      const reference = [];
                      
                      for (let i = 0; i < emptyRows; i++) {

                        const refRows = i;
                        
                        reference.push(
                          <View style={styles.tableReferenceTHValueRow} key={refRows}>
                              <View style={styles.tableReferenceTHNameValueCol}>
                                  <Text style={styles.tableReferenceTHNameValueCell}>
                                     
                                  </Text> 
                              </View> 

                              <View style={styles.tableReferenceTHAddressValueCol}>
                                  <Text style={styles.tableReferenceTHAddressValueCell}>
                           
                                  </Text> 
                              </View> 

                              <View style={styles.tableReferenceTHTellValueCol}>
                                  <Text style={styles.tableReferenceTHTellValueCell}>
                                    
                                  </Text> 
                              </View> 
                          </View>
                        )
                      }

                      return reference;

                      })()
                    }
                    </View>
              
                      
                 
                  {/* tableReferenceTBValue */}
                  

                   <View style={styles.tableReferenceDescription}>
                      <View style={styles.tableReferenceDescRow}>

                          <View style={styles.tableReferenceDescCol}>
                              <Text style={styles.tableReferenceDescCell}>
                              42.&nbsp;&nbsp;&nbsp;&nbsp;I declare under oath that I have personally accomplished this Personal Data Sheet which is a true, correct and
                            
                              </Text> 
                              <Text style={styles.tableReferenceDesc2Cell}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;complete statement pursuant to the provisions of pertinent laws, rules and  regulations of the Republic of the
                      
                              </Text>
                              <Text style={styles.tableReferenceDesc3Cell}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Philippines. I authorize the agency head / authorized representative to verify/validate the contents stated herein. I
                              </Text>
                              <Text style={styles.tableReferenceDesc4Cell}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;agree that any misrepresentation made in this document and its attachments shall cause the filing of
                              </Text>        
                              <Text style={styles.tableReferenceDesc4Cell}>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;administrative/criminal case/s against me.
                              </Text> 
                            </View>
                      </View>
                    </View>

              </View>
              {/* tableReferenceBody */}

            </View>    
            {/*tableReferenceRow  */}
    
          </View>
          {/* tableReference */}  
                    
          
          <View style={styles.tableGovernmentID}>
            <View style={styles.tableGovernmentIDRow}>
                  
                <View style={styles.tableGovernmentIDCol}>
                  
                  <View style={styles.tableDetailsGovernmentID}>

                    <View style={styles.tableDetailsGovernmentIDRow}>
                       <View style={styles.tableGovernmentIDsCol}>
                          <Text style={styles.tableGovernmentIDCell}>
                           Government Issued ID (i.e.Passpo10, GSIS, SSS, PRC, 
                           Driver's License, etc.)  PLEASE INDICATE ID Number and Date of Issuance   
                          </Text> 
                        </View>
                    </View>


                    <View style={styles.tableDetailsGovernmentIDRow}>
                       <View style={styles.tableGovIssuedIDCol}>
                          <Text style={styles.tableGovIssuedIDCell}>
                            Government Issued ID:&nbsp;&nbsp;&nbsp;&nbsp; {fetchRef.issuedid ?? "N/A"}
                          </Text> 
                        </View>
                    </View>

                    <View style={styles.tableDetailsGovernmentIDRow}>
                       <View style={styles.tableIssuedIDCol}>
                          <Text style={styles.tableIssuedIDCell}>
                            ID/License/Passport No.:&nbsp;&nbsp;&nbsp;&nbsp; {fetchRef.issueno ?? "N/A"}
                          </Text> 
                        </View>
                    </View>

                    <View style={styles.tableDetailsGovernmentIDRow}>
                       <View style={styles.tableDateIssuedIDCol}>
                          <Text style={styles.tableDateIssuedIDCell}>
                            Date/Place of Issuance.:&nbsp;&nbsp;&nbsp;&nbsp; {fetchRef.issuedat ?? "N/A"}
                          </Text> 
                        </View>
                    </View>

                    

                  </View>
                </View> 

            </View>
          </View> 
          {/* tableGovernmentID  */}


          <View style={styles.tableSignature}>
            <View style={styles.tableSignatureRow}>

                  <View style={styles.tableSignatureCol}>
                

                          
                      <View style={styles.tableSign}>
                        <View style={styles.tableSignRow}>
                            <View style={styles.tableSignCol}>
                              <Text style={styles.tableSignCell}>
                                  Signature (Sign inside the box)
                              </Text> 
                            </View>
                        </View>

                         <View style={styles.tableSignDateRow}>
                            <View style={styles.tableSignDateCol}>
                              <Text style={styles.tableSignDateCell}>
                                 
                              </Text> 
                            </View>
                        </View>

                         <View style={styles.tableSignDateRow}>
                            <View style={styles.tableSignDateCol}>
                              <Text style={styles.tableSignDateCell}>
                                  Date Accomplished
                              </Text> 
                            </View>
                        </View>
                      </View>

                     
                       
                     

                  
                  </View>
            </View>
          </View>
           {/* Signature  */}

            <View style={styles.tablePhoto}>
              <View style={styles.tablePhotoRow}>

                <View style={styles.tablePhotoCol}>

                  
                {
                  fetchImage ? (
                    <Image
                      style={{ width: '97px', height: '140px' }}
                      source={{ uri: `data:image/png;base64,${fetchImage}` }} 
                    />
                  ) : null 
                }
                  
                  
                </View>
              
        
              </View>
            </View>
           {/* Photo  */}

         <View style={styles.tableThumbmark}>
            <View style={styles.tableThumbmarkRow}>
                <View style={styles.tableThumbmarkCol}>
                    
                     <View style={styles.tableRightThumb}>
                        <View style={styles.tableRightThumbRow}>
                            <View style={styles.tableRightThumbCol}>
                              <Text style={styles.tableRightThumbCell}>
                                  Right Thumbmark
                                  
                              </Text> 
                            </View>
                        </View>
                      </View> 

                  
                </View>
            </View>
          </View> 
           {/* Thumbmark  */}



          <View style={styles.tableOath}>
            <View style={styles.tableOathRow}>
                <View style={styles.tableOathCol}>
                      <Text style={styles.tableOathCell}>
                            SUBSCRIBED AND SWORN to before me this ______________________________ , affiant exhibiting his/her issued government ID as include above. 
                        </Text> 

                    <View style={styles.tableInnerOath}>
                      <View style={styles.tableInnerOathRow}>
                        <View style={styles.tableInnerOathCol}>

                      

                            <View style={styles.tableAdministeringOath}>
                              <View style={styles.tableAdministeringOathRow}> 
                                <View style={styles.tableAdministeringOathCol}>

                                  <Text style={styles.tableAdministeringOathCell}>
                                      Person Adminitering Oath
                                  </Text>
                                  
                                </View>
                              </View>
                            </View>



                            
                          
                        </View>
                      </View>
                    </View>
                     {/* Inner Oath  */}

                     
                             
                </View>
            </View>
          </View> 
           {/* Oath  */}   
           <View style={styles.tableFooterPage4}>
            <View style={styles.tableFooterPage4Row}>
              <View style={styles.tableFooterPage4Col}>
                  <Text style={styles.tableFooterlastPageCell} render={({ pageNumber, totalPages }) => (` CS FORM 212 (Revised 2017), Page ${pageNumber} of ${totalPages}`)} > 
                      
                  </Text>
              </View>
            </View>
          </View>                   
          </View>
          {/* Section */}

           
        </Page>




      {/* --------------------------------------------- End of learning and developement ---------------------------------------------- */}
      </Document>
  )
}
