import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import { useParams } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import { format } from 'date-fns';

// import EligibilityTable from '../components/EligibilityTable'
import EligibilityModal from '../components/EligibilityModal';




export default function EmployeeEligibility() {

    const { unique } = useParams();
    const [isLoading, setisLoading] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState([]);
    const [fetchEligibility, setEligibility] = useState([]);
    //Edit Function
    const [modeldata, setModaldata] = useState({
        unique:'',
        TITLE: '',
        ELIRATE: '',
        EXDATE: '',
        EXPL: '',
        LICENSENO: '',
        DATEREL: ''
    });

    // const getFormattedDate = (dateStr) => {
    //     if (!dateStr) return "";
    //     const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
    //     return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : "";
    // };

    //display data
    const fetchDataEligibility = async (unique) => {
   
        setisLoading(true);

        try {
            const resp = await axios.get(`/api/EmpEligible/${unique}`);
            setEligibility(resp.data.EmpEligible);
   

        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    };

    useEffect(() => {
        const debouncedFetch = debounce(fetchDataEligibility, 1000); // Adjust the delay time as needed
            // Invoke the debounced function
        debouncedFetch(unique);

        // Return a cleanup function
        return () => debouncedFetch.cancel();
            // fetchDataSpouse()
     }, [unique]);



    
    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }

    const handleButtonClick = (data) => {
        setModaldata(data);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // format of date
//    const formatDate = (dateStr) => {
//     if (!dateStr) {
//       return ''; // Handle undefined or empty input
//     }

//     // Parse the input string (MM-DD-YYYY) to a Date object
//     const parsedDate = parse(dateStr, 'MM-dd-yyyy', new Date());

//     // Check if the parsed date is valid
//     if (isNaN(parsedDate)) {
//       return ''; // Handle invalid date
//     }

//     // Format the date to 'MMM d, yyyy' (e.g., Jan. 4, 1968)
//     return format(parsedDate, 'MMM d, yyyy');
//   };
    

    // update data
    
    
    const updateData = async (e) => {
         e.preventDefault();
         
        
        const formData = new FormData()
        formData.append('id', modeldata.unique);
        formData.append('TITLE', modeldata.TITLE ?? "");
        formData.append('ELIRATE', modeldata.ELIRATE ?? "");
        formData.append('EXDATE', modeldata.EXDATE ?? "");
        formData.append('EXPL', modeldata.EXPL ?? "");
        formData.append('LICENSENO', modeldata.LICENSENO ?? "");
        formData.append('DATEREL', modeldata.DATEREL ?? "");
;

         await axios.post(`/api/UpdatedEligible`, formData).then((res) => {
            
            if (res.data.status === 422) {
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                })
            setError(res.data.errors);

            }
            else {
                
                
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    fetchDataEligibility(unique);
                    setShowModal(false);
                    setError('');
                })
            }
            
        });
    }

    return (
      
      <div>
           <div className="row">
                <div className="col-sm-6">
                    <h4 className="m-2"><i className="fas fa-address-card"/> Civil Service Eligbility</h4>
                </div>{/* /.col */}   
            <div className="col-sm-6">
                    <div className="float-right">
                        <EligibilityModal unique={unique} fetchDataEligibility={fetchDataEligibility} />
                </div>
            </div>{/* /.col */} 
        </div>{/* /.row */}

        <hr />
      

        <br />

          {
            isLoading ? (
                 <div className='overlay-wrapper'>
                     <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                            <div className="mx-1">
                                <div className="float-right">

                                    <Button variant="outline-primary btn-sm"  > <i className="nav-icon fas fa-edit" /></Button>
                                
                                </div>
                            </div>
                
                            <br />



                            <div className="form-row">
            
                                <label className="col-sm-2 col-form-label">
                             
                                </label>
        
                                <div className="col-md-7">
                                    <span> 
                                        <label></label> | <span></span> <br /> 
                                        <span>
                                       
                                        </span>
        
                                    </span>
                                </div>
    
                            </div>
                             <hr/> 
                        </div>    
                ) :
                    
            fetchEligibility.length > 0 ?
                  fetchEligibility.map((data) => {
                      const {
                          TITLE,
                          EXDATE, 
                          EXPL, 
                          LICENSENO, 
                          unique, 
                      } = data;
                    
                    return (
                        <div key={unique}>

                            <div className="mx-1">
                                <div className="float-right">

                                    <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                                
                                </div>
                            </div>
                
                            <br />



                            <div className="form-row">
            
                                <label className="col-sm-2 col-form-label">
                                {
                                        // formatDate(EXDATE)
                                       format(new Date(EXDATE), 'MMMM d, yyyy')
                                }
                                </label>
        
                                <div className="col-md-7">
                                    <span> 
                                        <label>{TITLE}</label> | <span>({ LICENSENO === "" || LICENSENO === 'null'? "N/A" : LICENSENO })</span> <br /> 
                                        <span>
                                         {EXPL}  
                                        </span>
        
                                    </span>
                                </div>
    
                            </div>
                             <hr/> 
                        </div>    
                          
                    )
                      
                })

            
            :
            ""
         
              
          }



        {/* Update Modal */}
    
          
        <Modal show={showModal} size="lg">
        
            <Modal.Header>
                <Modal.Title><i className="fas fa-address-card"/> Edit Civil Service Eligibility </Modal.Title>
            </Modal.Header>
            <Form onSubmit={updateData}>
                <Modal.Body>
                   
                    <input type="hidden" name="id" value={modeldata.id ?? ""} onChange={(e) => setModaldata({...modeldata, id: e.target.value}) }/>            
                    <div className="form-row">
                    
                        <div className="form-group col-md-9">
                            <label className="text-sm">CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE</label>
                            <input type="text" className={`form-control ${error.TITLE ? 'is-invalid' : ''}`} name="TITLE" id="TITLE_id"  value={modeldata.TITLE ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.TITLE } </div>
                        </div>
                        
                        <div className="form-group col-md-2">
                            <label className="text-sm">Rating <br/> (if Applicable)</label>
                            <input type="text" className={`form-control ${error.ELIRATE ? 'is-invalid' : ''}`} name="ELIRATE" id="ELIRATE_id"  value={modeldata.ELIRATE ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.ELIRATE } </div>
                        </div>        

                    </div>
                    {/*  form-row */}
                    <div className="form-row">
                        
                        <div className="form-group col-md-4">
                            <label className="text-sm">Date of Examination/Conferment</label>
                            <input type="date" className={`form-control ${error.EXDATE ? 'is-invalid' : ''}`} name="EXDATE" id="EXDATE_id" value={modeldata.EXDATE ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.EXDATE } </div>
                        </div>
                        
                        <div className="form-group col-md-7">
                            <label className="text-sm">Place of Examination/Conferment</label>
                            <input type="text" className={`form-control ${error.EXPL ? 'is-invalid' : ''}`} name="EXPL" id="EXPL_id" value={modeldata.EXPL ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.EXPL } </div>
                        </div>   

                    </div>
                    {/*  form-row */}
                    <div className="form-row">
                    
                        <div className="form-group col-md-4">
                            <label className="text-sm">License Number</label>
                            <input type="text" className={`form-control ${error.LICENSENO ? 'is-invalid' : ''}`} name="LICENSENO" id="LICENSENO_id" value={modeldata.LICENSENO ?? ""}  onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.LICENSENO } </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label className="text-sm">Date of Validity</label>
                            <input type="text" className={`form-control ${error.DATEREL ? 'is-invalid' : ''}`} name="DATEREL" id="DATEREL_id" value={modeldata.DATEREL ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.DATEREL } </div>
                        </div>  

                    </div>  
                     {/*  form-row */}
                </Modal.Body>
            
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => handleCloseModal()}>
                        Close
                    </Button>
                    <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
        </Modal>
        

      
    </div>
  )
}
