import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';


export default function GovModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // IDNO
    // const { unique } = useParams();
    
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

  
    useEffect(() => {
       axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);


    // Update
    const [issueUniqueID, setissueUniqueID] = useState('');
    const [issuedID, setIssuedID] = useState('');
    const [issueNO, setIssueNO] = useState('');
    const [issueDate, setIssuedDate] = useState('');

    
    useEffect(() => {
        if (props.fetchGovtID[0])
        {
            setissueUniqueID(props.fetchGovtID[0].unique); 
            setIssuedID(props.fetchGovtID[0].issuedid);
            setIssueNO(props.fetchGovtID[0].issueno);
            setIssuedDate(props.fetchGovtID[0].issuedat);
        }
    }, [props.fetchGovtID]);

    
    const [error, setError] = useState([]);
    
    const updateData = async (e) => {
         e.preventDefault();
         
    
        const formData = new FormData()
        formData.append('id', issueUniqueID?? "");
        formData.append('IDNO', fetchEmpInfo.IDNO ?? "");
        formData.append('issuedid', issuedID ?? "");
        formData.append('issueno', issueNO ?? "");
        formData.append('issuedat', issueDate ?? "");
      


         await axios.post(`http://localhost:8000/api/UpdatedGov`, formData).then((res) => {
            
            if (res.data.status === 422) {
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                })
            setError(res.data.errors);

            }
            else {
               
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                  
                    setShow(false);
                    props.fetchDataRefer(props.unique);
                    setError('');
                 
                })
            }
            
        });
    }





  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
           <i className="nav-icon fas fa-edit" />
        </Button>

        <Modal show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-id-card" /> Updates Goverment Issued ID's </Modal.Title>
        </Modal.Header>
            <Form onSubmit={updateData}>

                <Modal.Body>
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Government Issued ID:</label>
                            <input type="text" className={`form-control ${error.issuedid ? 'is-invalid' : ''}`}  name="issuedid" id="governmentId_id" value={issuedID === null ? '' : issuedID} onChange={(e) => setIssuedID(e.target.value)} />
                            <div  className="invalid-feedback"> {error.issuedid} </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">ID/License/Passport No.:</label>
                            <input type="text" className={`form-control ${error.issueno ? 'is-invalid' : ''}`} name="issueno" id="license_passport_no_id" value={issueNO === null ? '' : issueNO} onChange={(e) => setIssueNO(e.target.value)}/>
                             <div  className="invalid-feedback"> {error.issueno} </div>
                        </div>  
                    </div>

                     <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Date/Place of Issuance:</label>
                            <input type="text" className={`form-control ${error.issuedat ? 'is-invalid' : ''}`}  name="issuedat" id="date_issuance_id" value={issueDate === null ? '' : issueDate} onChange={(e) => setIssuedDate(e.target.value)}/>
                             <div  className="invalid-feedback"> {error.issuedat} </div>
                        </div>       
                    </div>
                 
                </Modal.Body>
                
                  <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button variant="success" type="submit" >
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
