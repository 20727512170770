// import React  from 'react'

import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';



export default function AddServiceRecord(props) {

  //fetch data
  

 const [error, setError] = useState([]);  
  const [dates, setDates] = useState({
    fromDate: '',
    toDate: '',
  });
  const [fetchDesignation, setFetchDesignation] = useState('');
  const [fetchEsta, setEsta] = useState('');
  const [fetchOffcode, setOffcode] = useState('');
  const [getPlantpostUnique, setGePlantpostUnique] = useState('');
  
  const [fetchSequence, setFetchSequence] = useState('');
  const [fetchSgrade, setFetchSgrade] = useState('');
  const [fetchstep, setFetchStep] = useState('');
  const [fetchContract, setFetchContract] = useState('');
  const [fetchStation, setFetchStation] = useState('');
  const [fetchBranch, setFetchBranch] = useState('');
  const [fetchPostNo, setPostNo] = useState('');
  const [fetchPositionStatus, setPositionStatus] = useState('');
  const [fetchRA, setFetchRA] = useState('');
  const [fetchTA, setFetchTA] = useState('');
  const [fetchPERA, setFetchPERA] = useState('');
  const [fetchClothing, setFetchClothing] = useState('');
  const [fetchSubsitence, setFetchSubsitence] = useState('');



  //displaying data in the dropdown 
  const [fetchSalary, setFetchSalary] = useState('');
  const [fetchOffices, setOffices] = useState([]);
  const [OptionPosition, setOptionPosition] = useState([]);

  const handleChangeDate = (e) => {
    setDates(dates => ({ ...dates, [e.target.name]: e.target.value }))
  };


  // fetch the data of the user
  useEffect(() => { 
    if (props.data) {
      setEsta(props.data.ESTA || "")
      setOffcode(props.data.offcode || "");
      setGePlantpostUnique(props.data.POSTNO || "");
      setFetchStep(props.data.STEP || "");
      setFetchSgrade(props.data.SGRADE || "");
  
      
    }

  }, [props.data]);
   

  // onChange for status
  const handleStatusChange = (e) => {
    setEsta(e.target.value);
    setOffcode('');
    setGePlantpostUnique('');
    setFetchSalary('');
    setFetchStep('');
    setFetchStation('');
  };

  // onChange office
  const handleOfficeChange = (e) => {
    setOffcode(e.target.value);
  };

  // onChange position
  const handlePositionChange = (e) => {
    setGePlantpostUnique(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setFetchSalary(e.target.value);
  };
  
  const handleStepChange = (e) => {
      setFetchStep(e.target.value);
  };

  const handleContractChange = (e) => {
    setFetchContract(e.target.value);
  };


  const handlePositionStatusChange = (e) => {
    setPositionStatus(e.target.value);
  };





  // fetching of list of Office
   useEffect(() => {
      const getOffice = async () => {
        try {
          const response = await axios.get('http://localhost:8000/api/Listoffices');
          setOffices(response.data.offices);
          
        } catch (error) {
          // Handle error
        }
      };
      getOffice();
   }, []);
  
  // fetching details of office
  useEffect(() => {
      if (fetchOffcode !== '') 
      {
        const SelectedDept = fetchOffcode;

          const Department = fetchOffices.find((option) => option.offcode === SelectedDept);
    
          if (Department) {
            setFetchStation(Department.servicerecordname);
          }
          else {
            setFetchStation('');
        }
      }
      else {

      }
        
    }, [fetchOffcode, fetchOffices]);

  // fetch if list of postion based on the employeement office
    useEffect(() => {
    if (fetchEsta === 'Permanent' || fetchEsta === 'Co-Terminous') {

      if (fetchOffcode ) {
        
        const getPosition = async () => {
          
          let urlVcantPositionReg = `http://localhost:8000/api/vcantPositionReg/${fetchOffcode}`;
          if (getPlantpostUnique) {
              urlVcantPositionReg += `/${getPlantpostUnique}`;
          }
          if (fetchstep) {
              urlVcantPositionReg += `/${fetchstep}`;
          }

          try {
            const response = await axios.get(urlVcantPositionReg);
              
              setOptionPosition(response.data.VcantItem); // set the diffault position with the selected position
            
          } catch (error) {
            
          }
        
        }
        getPosition();
        }
        else {
            setOptionPosition([]);

        }

    } else if (fetchEsta === 'JO' || fetchEsta === 'CA'|| fetchEsta === 'CON') {
      
      const getPosition = async () => {
          await axios.get(`http://localhost:8000/api/vcantPositionNonReg`).then((res) => {
  
          setOptionPosition(res.data.vcantPositionNonReg)
         
          })
        }
        getPosition()
    } else {
      setOptionPosition([]);

  }
    
    
    }, [fetchOffcode, fetchEsta, getPlantpostUnique, fetchstep]);
  
  
  
  // fetching details of position
   useEffect(() => {
     // This effect will run when the component mounts (initial load)
    
    if (getPlantpostUnique !== '') {

      if (fetchEsta === 'Permanent' || fetchEsta === 'Co-Terminous') {
    
        const selectedPostNO = parseInt(getPlantpostUnique);
        const selectedPosition = OptionPosition.find((option) => option.postno === selectedPostNO);
  
        if (selectedPosition) {
       
          setFetchDesignation(selectedPosition.Designation  || "");
          setPostNo(selectedPosition.postno || "");
          setFetchSgrade(selectedPosition.SGRADE || "");
          setFetchSequence(selectedPosition.SEQUENCE || "");
        } 


      }
      else if (fetchEsta === 'JO' || fetchEsta === 'CA' || fetchEsta === 'CON') {
        
        const selectedPostNO = parseInt(getPlantpostUnique);
        const selectedPosition = OptionPosition.find((option) => option.pp_unique === selectedPostNO);
       
        // 
        if (selectedPosition) {
   
          setFetchDesignation(selectedPosition.Position || "");
          setPostNo(selectedPosition.postno || "");
          setFetchSgrade("");
        }
     

      }
    }
  }, [getPlantpostUnique, OptionPosition, fetchEsta]);
  
  
  //fetch of salary
  useEffect(() => { 

    const getSalary = async () => {
      if (fetchSgrade !== '' && fetchstep !== '' ) {
        try {
          const response = await axios.get(`http://localhost:8000/api/salaryRegular/${fetchSgrade}/${fetchstep}`);
          setFetchSalary(response.data.salaryPosition[0].AMOUNT);
       
        } catch (error) {
          console.log(error);
        }
      }
        
       
      }
    getSalary();
  }, [fetchSgrade, fetchstep]);
  

  // Add Service Record 
  const submitData = async (e) => {
    e.preventDefault();

    await axios.post(`http://localhost:8000/api/UpdatePositionOffices`, {

      unique_id: props.unique, 
      emp_idno: props.IDNO, 

      fromDate: dates.fromDate,
      toDate: dates.toDate,
      designation: fetchDesignation,
      status: fetchPositionStatus,
      salary: fetchSalary,
      contract: fetchContract, 
      station: fetchStation, 
      branch: fetchBranch,

      offcode: fetchOffcode,
      postno: fetchPostNo, 
      sequence: fetchSequence,
      step: fetchstep, 
      esta: fetchEsta,
      adcom: fetchRA, 
      pera: fetchPERA, 
      rata: fetchTA,
      clothing: fetchClothing,
      subsitence: fetchSubsitence


   

    
    }).then((res) => {

      if (res.data.status === 422) {
        Swal.fire({
            icon: 'error',
            title: 'All fields are required',
        })
        setError(res.data.errors);
      } else {
        Swal.fire({
          icon: "success",
          title: res.data.message,
        }).then((res) => {
       
          props.fetchEmpData(props.unique);
          props.fetchRecord(props.unique);
          setDates({
            fromDate: '',
            toDate: '',
          });
          setFetchSalary("");
          setFetchContract("");
          setPositionStatus("");
          setFetchBranch("");
          setFetchRA("");
          setFetchPERA("");
          setFetchTA("");
          setFetchClothing("");
          setFetchSubsitence("");
          setError("");

          props.handleClose(false);
      
        });
      }


    });

  };

  return (
    <div>
        <>

        <Modal show={props.show} onHide={props.handleClose} size="lg" >
            <Modal.Header >
                <Modal.Title>Edit Service Record</Modal.Title>
            </Modal.Header>

            <Form onSubmit={submitData}>
              <Modal.Body>
                  {/* <div className="row"> */}
                    {/* <div className="col-md-6"> */}
                        
                      <div className="form-row">

                        <div className="form-group col-md-4">
                          <label>From: </label>
                            <input type="date" className={`form-control ${error.fromDate ? 'is-invalid' : ''}`} id="from_id" name="fromDate" values={dates.fromDate} onChange={handleChangeDate}  />
                            <div  className="invalid-feedback"> {error.fromDate} </div>
                        </div>
                        
                        <div className="form-group col-md-4">
                          <label>To: </label>
                            <input type="date" className={`form-control ${error.toDate ? 'is-invalid' : ''}`} id="to_id" name="toDate" values={dates.toDate} onChange={handleChangeDate} />
                            <div  className="invalid-feedback"> {error.toDate} </div>
                        </div>
                        
                      </div>    
                      {/* form-row */}
                    
                      <div className="form-row">
                      
                        <div className="form-group col-md-4">
                           <label >Employment Status: </label>
                                <select className={`form-control ${error.esta ? 'is-invalid' : ''}`}  name="ESTA" value={fetchEsta} onChange={handleStatusChange}>
                                  <option value="">Choose...</option>
                                  <option value="Permanent">Permanent</option>
                                  <option value="CA">Casual</option>
                                  <option value="JO">Job Order</option>
                                  <option value="Co-Terminous">Co-Terminous</option> 
                                  <option value="CON">Consultant</option>
                                </select>
                            <div  className="invalid-feedback">{error.esta} </div>
                        </div> 
                
                        <div className="form-group col-md-8">
                            <label>Select Office: </label>
                            <select className={`form-control ${error.offcode ? 'is-invalid' : ''}`} name="OFFCODE" value={fetchOffcode} onChange={handleOfficeChange}>
                              <option value="">Choose...</option>
                                {
                                fetchOffices.map((option, index) => (
                                  <option key={index} value={option.offcode} >{option.offdesc }</option>
                                ))

                                }
                            </select>
                             <div  className="invalid-feedback">{error.offcode} </div>
                          {/* <input type="text" className={`form-control ${error.FROMATT ? 'is-invalid' : ''}`} id="office_id" name='offdesc' value={fetchOffice ?? ""}  onChange={(e) => setOffice(e.target.value)}  disabled={true} /> */}
                        </div>
              
                      </div> 
                      
                     
                      <div className="form-row">
                        <div className="form-group col-md-7">
                          <label>Position: </label>
                          <select className={`form-control ${error.postno ? 'is-invalid' : ''}`} name="position" value={getPlantpostUnique} onChange={handlePositionChange} >
                            <option value="">Choose...</option>
                            {
                            
                                OptionPosition.map((option, index) => (     
                                  <option key={index} value={option.postno} >{option.Position}</option>
                                )) 
                              }
                          </select>
                           <div  className="invalid-feedback"> {error.postno} </div>
                        </div>   
                        
                        <div className="form-group col-md-2">
                          <label>Step:</label>
                           <select className={`form-control ${error.step ? 'is-invalid' : ''}`} name="step" value={fetchstep} onChange={handleStepChange} readOnly={fetchEsta === 'JO' || fetchEsta === 'CA'|| fetchEsta === 'CON' ? true : false } >
                                <option value="">Choose...</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option> 
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                          </select>
                          <div  className="invalid-feedback"> {error.step} </div>
                        </div> 
                        
                          <div className="form-group col-md-3">
                             <label>Salary:</label>
                             <input type="text" className={`form-control ${error.salary ? 'is-invalid' : ''}`} id="salary_id" name='salary' value={fetchSalary} onChange={handleSalaryChange} readOnly={fetchEsta === 'JO' || fetchEsta === 'CA'|| fetchEsta === 'CON' ? false : true }  />
                             <div className="invalid-feedback"> {error.salary} </div> 
                          </div>
                      </div>    
                      {/* form-row */}

                      <div className="form-row">
              
                          <div className="form-group col-md-2">
                              <label>Contract</label>
                              <select className={`form-control ${error.contract ? 'is-invalid' : ''}`} id="id_contract" name="contract" value={fetchContract} onChange={handleContractChange} >
                                  <option value="">Choose...</option>
                                  <option value="A">Annually</option>
                                  <option value="M">Monthly</option>
                                  <option value="D">Daily</option>
                              </select>
                              <div className="invalid-feedback"> {error.contract} </div> 
                          </div>
                          
                          <div className="form-group col-md-3">
                              <label>Position Status</label>
                              <select className={`form-control ${error.status ? 'is-invalid' : ''}`} id="id_contract" name="contract" value={fetchPositionStatus} onChange={handlePositionStatusChange} >
                                  <option value="">Choose...</option>
                                  <option value="Consultant">Consultant</option>
                                  <option value="Contract of Service">Contract of Service</option>
                                  <option value="Contractual">Contractual</option>
                                  <option value="Co-Terminous">Co-Terminous</option>
                                  <option value="Casual">Casual</option>
                                  <option value="Elective">Elective</option>
                                  <option value="Job Order">Job Order</option>
                                  <option value="Permanent">Permanent</option>
                                  <option value="Probationary">Probationary</option>
                                  <option value="Temporary">Temporary</option>
                              </select>
                               <div className="invalid-feedback"> {error.status} </div> 
                          </div>
                          
                          <div className="form-group col-md-7">
                             <label>Station:</label>
                            <input type="text" className={`form-control ${error.station ? 'is-invalid' : ''}`} id="station_id" name="station" value={fetchStation} readOnly/>
                              <div className="invalid-feedback"> {error.station} </div> 
                          </div>
                          
                          <div className="form-group col-md-3">
                             <label>branch:</label>
                               <input type="text" className={`form-control ${error.branch ? 'is-invalid' : ''}`} id="branch_id" name="branch" value={fetchBranch} onChange={(e) => setFetchBranch(e.target.value)} />
                                 <div className="invalid-feedback"> {error.branch} </div> 
                          </div>
                        
                 
                  
                      </div> 
                       {/* form-row */}

                    {/* </div> */}

                    {/* <div className="col-md-6"> */}
                    <hr/>
                    {(fetchEsta === 'Permanent' || fetchEsta === 'Co-Terminous') && (
                      <>
                        <div className="form-row">
                            
                           <div className="form-group col-md-4">
                             <label>RA:</label>
                               <input type="text" className={`form-control ${error.adcom ? 'is-invalid' : ''}`} id="RA_id" name="RA" placeholder='0.00' value={fetchRA} onChange={(e) => setFetchRA(e.target.value)} />
                            </div>

                              <div className="form-group col-md-4">
                                <label>TA:</label>
                                  <input type="text" className={`form-control ${error.rata ? 'is-invalid' : ''}`} id="TA_id" name="TA" placeholder='0.00' value={fetchTA} onChange={(e) => setFetchTA(e.target.value)} />
                              </div>
                              
                              <div className="form-group col-md-4">
                                <label>PERA:</label>
                                  <input type="text" className={`form-control ${error.pera ? 'is-invalid' : ''}`} id="Pera_id" name="Pera" placeholder='0.00' value={fetchPERA} onChange={(e) => setFetchPERA(e.target.value)} />
                              </div>
                          
                      </div>
                       <div className="form-row">
                            
                           <div className="form-group col-md-4">
                             <label>Clothing Allowance:</label>
                               <input type="text" className={`form-control ${error.clothing ? 'is-invalid' : ''}`} id="Clothing_id" name="Clothing" placeholder='0.00' value={fetchClothing} onChange={(e) => setFetchClothing(e.target.value)} />
                            </div>

                            <div className="form-group col-md-4">
                              <label>Subsitence:</label>
                                <input type="text" className={`form-control ${error.subsitence ? 'is-invalid' : ''}`} id="Subsitence_id" name="Subsitence" placeholder='0.00' value={fetchSubsitence} onChange={(e) => setFetchSubsitence(e.target.value)} />
                            </div>
                      </div>
                      </>
                    )}
                      

                    {/* </div> */}


                

                  {/* </div> */}
                    
              

              </Modal.Body>
                    
              <Modal.Footer>
                  <Button variant="outline-danger" onClick={props.handleClose}>
                      Close
                  </Button>
                  <Button type="submit" variant="success">Save Changes</Button>
              </Modal.Footer>
           </Form> 
        </Modal>



        </>    
    
    </div>
  )
}
