import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Modal from 'react-bootstrap/Modal';
import { ListGroup } from 'react-bootstrap';
import LeaveLedger from '../HRIS/Reports/LeaveLedger';

import Comments from './Comments.component';

export default function LeaveApplicationModal(props) {
    //console.log(props.leave);
    const navigate = useNavigate();
    const [leaveReq, setLeaveReq] = useState({
        0: [],
        1: [
            "It shall be filed five (5) days in advance, whenever possible, of the effective date of such leave. Vacation leave within in the Philippines or abroad shall be indicated in the form for purposes of securing travel authority and completing clearance from money and work accountabilities.",
            [
                "If Travelling abroad:",
                "- Request Letter",
                "- Endorsement Letter",
                "- PTO Clearance",
                "- GSO Clearance",
                "- Accounting Clearance",
                "- PGO Clearance"
            ]
        ],
        2: [
            "Annual five-day vacation leave shall be forfeited if not taken during the year. In case the scheduled leave has been cancelled in the exigency of the service by the head of agency, it shall no longer be deducted from the accumulated vacation leave. Availment of one (1) day or more Vacation Leave (VL) shall be considered for complying the mandatory/forced leave subject to the conditions under Section 25, Rule XVI of the Omnibus Rules Implementing E.O. No. 292."
        ],
        3: [
            "It shall be filed immediately upon employee's return from such leave.",
            "If filed in advance or exceeding five (5) days, application shall be accompanied by a medical certificate. In case medical consultation was not availed of, an affidavit should be executed by an applicant."
        ],
        4: [
            "Proof of pregnancy e.g. ultrasound, doctor’s certificate on the expected date of delivery",
            "Accomplished Notice of Allocation of Maternity Leave Credits (CS Form No. 6a), if needed",
            "Seconded female employees shall enjoy maternity leave with full pay in the recipient agency."
        ],
        5: [
            "Proof of child’s delivery e.g. birth certificate, medical certificate and marriage contract"
        ],
        6: [
            "It shall be filed/approved for at least one (1) week prior to availment, except on emergency cases. Special privilege leave within the Philippines or abroad shall be indicated in the form for purposes of securing travel authority and completing clearance from money and work accountabilities."
        ],
        7: [
            "It shall be filed in advance or whenever possible five (5) days before going on such leave with updated Solo Parent Identification Card."
        ],
        8: [
            "Shall meet the agency’s internal requirements, if any;",
            "Contract between the agency head or authorized representative and the employee concerned."
        ],
        9: [
            "It shall be filed in advance or immediately upon the woman employee’s return from such leave.",
            "It shall be accompanied by any of the following supporting documents:",
            [
                "a. Barangay Protection Order (BPO) obtained from the barangay;",
                "b. Temporary/Permanent Protection Order (TPO/PPO) obtained from the court;",
                "c. If the protection order is not yet issued by the barangay or the court, a certification issued by the Punong Barangay/Kagawad or Prosecutor or the Clerk of Court that the application for the BPO, TPO or PPO has been filed with the said office shall be sufficient to support the application for the ten-day leave; or",
                "In the absence of the BPO/TPO/PPO or the certification, a police report specifying the details of the occurrence of violence on the victim and a medical certificate may be considered, at the discretion of the immediate supervisor of the woman employee concerned."
            ]
        ],
        10: [
            "The application may be filed in advance, that is, at least five (5) days prior to the scheduled date of the gynecological surgery that will be undergone by the employee. In case of emergency, the application for special leave shall be filed immediately upon employee’s return but during confinement the agency shall be notified of said surgery.",
            "The application shall be accompanied by a medical certificate filled out by the proper medical authorities, e.g. the attending surgeon accompanied by a clinical summary reflecting the gynecological disorder which shall be addressed or was addressed by the said surgery; the histopathological report; the operative technique used for the surgery; the duration of the surgery including the peri-operative period (period of confinement around surgery); as well as the employees estimated period of recuperation for the same."
        ],
        11: [
            "Application shall be made within one (1) week from the time of the accident except when a longer period is warranted.",
            "Letter request supported by relevant reports such as the policereport, if any.",
            "Medical certificate on the nature of the injuries, the course of treatment involved, and the need to undergo rest, recuperation, and rehabilitation, as the case may be.",
            "Written concurrence of a government physician should be obtained relative to the recommendation for rehabilitation if the attending physician is a private practitioner, particularly on the duration of the period of rehabilitation."
        ],
        12: [
            "The special emergency leave can be applied for a maximum of five (5) straight working days or staggered basis within thirty (30) days from the actual occurrence of the natural calamity/disaster. Said privilege shall be enjoyed once a year, not in every instance of calamity or disaster.",
            "The head of office shall take full responsibility for the grant of special emergency leave and verification of the employee’s eligibility to be granted thereof. Said verification shall include: validation of place of residence based on latest available records of the affected employee; verification that the place of residence is covered in the declaration of calamity area by the proper government agency; and such other proofs as may be necessary."
        ],
        13: [
            "Application for adoption leave shall be filed with an authenticated copy of the Pre-Adoptive Placement Authority issued by the Department of Social Welfare and Development (DSWD)."
        ],
        14: [],
        15: [],
        16: [],
        17: [
            "Application for monetization of fifty percent (50%) or more of the accumulated leave credits shall be accompanied by letter request to the head of the agency stating the valid and justifiable reasons."
        ],
        18: [
            "Filled up application for Terminal Leave signed by the employees and head of office.",
            [
                "HRMDO:",
                "- Certified Service Record",
                "- Certification of No Pending Administrative Case",
                "- Clearance Form signed by concerned head (clearance from work, money and property accountabilities)",
                "- Copy of latest Appointment / NOSA / NOSI",
                "- Copy of latest Assets & Liabilities",
                "- Copy of updated Leave Card",
                "- Exit Interview Report"
            ],
            [
                "RESIGNATION:",
                "- Acceptance letter signed by the Governor/ Vice Governor",
                "- Employee's Resignation Letter (Casual Employee)"
            ],
            [
                "TRANSFER:",
                "- Approval letter signed by the Governor/Vice Governor",
                "- Employee's Request for Transfer",
                "- Certification of Last Salary Received",
                "- Certification of Leave Credits"
            ],
            [
                "GSIS:",
                "- Application Form for Retirement",
                "- Certified Service Record"
            ],
            [
                "PAcO:",
                "Certification of no accountabilities for suspension/s and/or disallowances from the Accounting Office based on the certification from the following financial institutions:",
                "- Veterans - Lingayen Branch (Regular Employee)",
                "- Landbank - Lingayen Branch (Regular Employee)",
                "- PGEMPCOP",
                "- PPEMCO"
            ],
            [
                "PTO:",
                "- Certification of no money accountability"
            ],
            [
                "GSO:",
                "- Certification of no property responsibility"
            ],
            [
                "Library:",
                "- Certification of no property accountability on any other book/library material"
            ],
            [
                "Housing:",
                "- Certification of no financial obligation on housing loans"
            ],
            [
                "For Hospital Employees:",
                "- Clearance from money and property responsibilities signed by concerned Chiefs of Service and Chief of Hospital"
            ],
            [
                "Office of the Provincial Prosecutor:",
                "- Certification of no criminal records, no pending complaint/case filed against the employee"
            ]
        ],
        19: []
    });

    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const [leaveDetailOptions, setLeaveDetailOptions] = useState([]);

    const [selectedType, setSelectedType] = useState(0);
    const [selectedDetail, setSelectedDetail] = useState(0);
    const [detailDescription, setDetailDescription] = useState("");
    const [noDays, setNoDays] = useState(0);
    const [inclusiveDates, setInclusiveDates] = useState([]);
    const [commutation, setCommutation] = useState(0);
    const [meridiem, setMeridiem] = useState("");

    const [status, setStatus] = useState(0);

    const [aoApprove, setAoApprove] = useState(null);
    const [offApprove, setOffApprove] = useState(null);
    const [offDisReason, setOffDisReason] = useState(props.leave.off_dis_reason || "");
    const [hrApprove, setHrApprove] = useState(null);
    const [pgoDisReason, setPgoDisReason] = useState(props.leave.pgo_dis_reason || "");
    const [signed, setSigned] = useState(null);
    const [hrDisReason, setHrDisReason] = useState("");

    const [lessVL, setLessVL] = useState(props.leave.less_vl);
    const [lessSL, setLessSL] = useState(props.leave.less_sl);
    const [vlWO, setVLWO] = useState(props.leave.vl_wo);
    const [slWO, setSLWO] = useState(props.leave.sl_wo);
    const [leaveSummary, setLeaveSummary] = useState(null);
    const [leaveSummaryYear, setLeaveSummaryYear] = useState(new DateObject());

    //const [vlBal, setVlBal] = useState(props.leave.signed ? (parseFloat(props.leave.vacationTotal.vacationbalance) + parseFloat(props.leave.less_vl)).toFixed(4) : props.leave.vacationTotal.vacationbalance);
    //const [slBal, setSlBal] = useState(props.leave.signed ? (parseFloat(props.leave.sickTotal.sickbalance) + parseFloat(props.leave.less_sl)).toFixed(4) : props.leave.sickTotal.sickbalance);

    const [vlBal, setVlBal] = useState(props.leave.vl_total);
    const [slBal, setSlBal] = useState(props.leave.sl_total);

    const [daysWPay, setDaysWPay] = useState(props.leave.days_w_pay);
    const [daysWOPay, setDaysWOPay] = useState(props.leave.days_wo_pay);
    const [othersPay, setOthersPay] = useState(props.leave.others_pay);
    const [othersPaySpec, setOthersPaySpec] = useState(props.leave.others_pay_spec);

    //const [disReason, setDisReason] = useState("");
    const disReasonOptions = [
        { id: 1, reason: "Late Filing" },
        { id: 2, reason: "Incomplete Attachments" },
        { id: 3, reason: "Invalid Attachment" },
        { id: 4, reason: "Insufficient manpower for selected date" },
    ];
    const [hasUpdate, setHasUpdate] = useState(false);

    const [updates, setUpdates] = useState({});

    //attachments
    const [leaveAttachments, setLeaveAttachments] = useState(props.leave.leave_attachment);
    const [delAttachments, setDelAttachments] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileChkFail, setFileChkFail] = useState([]);
    const fileInputRef = useRef();
    const handleFileChange = (event) => {
        let files = Array.from(event.target.files);

        const allowedFileType = ["jpg", "jpeg", "png", "pdf", "docx", "doc"];
        const maxSize = 2 * 1024 * 1024;

        if (files.length > 0) {
            let newSelectedFiles = [...selectedFiles];
            let newFileChkFail = [];
            files.map((file, key) => {
                let ext = file.name.split('.').pop();
                let size = file.size;
                let dupe = newSelectedFiles.filter(ff => { return ff.name === file.name }).length ? true : false;
                dupe = dupe ? true : leaveAttachments.filter(ff => { return ff.document_name === file.name }).length ? true : false;
                if (allowedFileType.includes(ext) && size <= maxSize && !dupe) {
                    newSelectedFiles.push(file);
                } else {
                    newFileChkFail.push(`${file.name} : ${allowedFileType.includes(ext) ? "" : `${ext} file type is not allowed${(size <= maxSize || dupe) ? "" : ", "}`}${size <= maxSize ? "" : `file size is larger than 2mb${dupe ? ", " : ""}`}${dupe ? "duplicate file name was chosen, remove existing file or rename the current file" : ""}`);
                }
            })
            setSelectedFiles(newSelectedFiles);
            setFileChkFail(newFileChkFail);
        }
        event.target.value = "";
    }

    function limitDecimals(num, maxDecimals) {
        const decimalPart = num.toString().split('.')[1];
        if (decimalPart && decimalPart.length > maxDecimals) {
            const factor = Math.pow(10, maxDecimals);
            return Math.round(num * factor) / factor;
        }
        return num;
    }

    useEffect(() => {
        //console.log(delAttachments);
        let changed = false;
        if (delAttachments.length !== 0) {
            changed = true;
        }
        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["del_attachment"] = delAttachments;
        } else {
            delete newUpdate["del_attachment"];
        }
        setUpdates(newUpdate);

    }, [delAttachments]);

    useEffect(() => {
        let changed = false;
        if (selectedFiles.length > 0) {
            changed = true;
        }
        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["leave_attachment"] = selectedFiles.length;
        } else {
            delete newUpdate["leave_attachment"];
        }
        setUpdates(newUpdate);
    }, [selectedFiles])

    const downloadDocument = async (fileLink) => {
        try {
            const response = await axios.get(fileLink);
            const fileUrl = response.data.fileUrl;
            window.open(fileUrl, '_blank');
        } catch (error) {
            console.error('Error opening file:', error);
        }
    }

    useEffect(() => {
        fetchLeaveApplicationOptions()
        setEditable(checkEditable())
    }, [])

    useEffect(() => {
        if (props.showLeaveSummary) {
            fetchLeaveSummary();
        }
    }, [leaveSummaryYear])

    // useEffect((e) => {
    //     console.log("has Update", hasUpdate, updates);
    // }, [hasUpdate])

    const validateFields = () => {
        let invalid = false;
        let requiredFields = document.getElementsByClassName("leave-application-view-modal-body-leave")[0].querySelectorAll("[required]");
        requiredFields.forEach((el) => {

            if (el.value == 0) {
                invalid = true;
                el.classList.add("is-invalid");
            } else {
                el.classList.remove("is-invalid");
            }
        })

        let dates = document.getElementById("dates-list");
        if (inclusiveDates.length === 0 && selectedType != 18) {
            invalid = true;
            dates.classList.add("text-danger");
        } else {
            dates.classList.remove("text-danger");
        }

        return invalid;
    }

    useEffect(() => {
        //console.log(updates, hasUpdate);
        if (Object.keys(updates).length === 0) {
            setHasUpdate(false);
        } else {
            setHasUpdate(true);
        }
    }, [updates])

    const fetchLeaveApplicationOptions = async () => {
        await axios.get(`/api/leaveapplication/options`).then(({ data }) => {
            setLeaveTypeOptions(data.types);
            setLeaveDetailOptions(data.details);
            setLeaveValues(props.leave);
        })
    }

    const summarizeDates = (dates) => {
        let dStr = "";
        let curM = null;
        let curD = null;
        let curY = null;
        let fD = null;
        let m, d, y = null;
        dates.map(date => {
            let dtC = new DateObject(date);
            m = dtC.month.shortName;
            d = dtC.day;
            y = dtC.year;

            if (curM !== m) {
                if (curM !== null) {
                    if (curD === null) {
                        dStr += d;
                        fD = d;
                    }
                    if ((curD !== (d - 1)) && (curD !== null)) {
                        if (curD !== fD && curD !== (d - 1)) {
                            dStr += `-${curD}`;
                        }
                        fD = d;
                    }
                }

                if (curM !== null) {
                    dStr += `/${curY} | `;
                }

                dStr += `${m}/`;
                dStr += `${d}`;

                curD = d;
                curM = m;
                curY = y;
                if (fD === null) {
                    fD = d;
                }
            } else {
                if (curD === null) {
                    dStr += d;
                    fD = d;
                }

                if ((curD !== (d - 1)) && curD !== null) {
                    if (curD !== fD) {
                        dStr += `-${curD}`;
                    }
                    dStr += `,${d}`;
                    fD = d;
                }
                curD = d;
            }
        });

        if (curD !== fD && fD !== null) {
            dStr += `-${d}`;
        }
        dStr += `/${y}`;

        return dStr;
    }

    const fetchLeaveSummary = async () => {
        await axios.post(`/api/leaveapplication/leavesummary`, { 'employee': props.leave.plantilla.unique, year: leaveSummaryYear.year }).then(({ data }) => {
            //console.log(data.summary);
            let summary = {};
            data.summary.forEach(lv => {
                let lvT = lv.leave_type_id;
                if (!(lvT in summary)) {
                    // summary[lvT] = {
                    //     name : lv.leave_type.type,
                    //     days : 0,
                    //     dates : []
                    // };
                    summary[lvT] = {
                        totalDays: 0,
                        name: lv.leave_type.type,
                        leaves: []
                    };
                }
                summary[lvT].totalDays += parseFloat(lv.no_days);
                summary[lvT].leaves.push(
                    {
                        days: lv.no_days,
                        dates: summarizeDates(lv.leave_date)
                        // lv.leave_date.map(lvDt => new DateObject(lvDt.date).format("MMMM DD, YYYY"))
                    }
                );
                // summary[lvT].days += parseFloat(lv.no_days);
                // lv.leave_date.forEach(lvDt => {
                //     summary[lvT].dates.push(lvDt.date);
                // });
            });
            //console.log(summary);
            setLeaveSummary(summary);
        })
    }

    const setLeaveValues = (lv) => {
        setSelectedType(lv.leave_type_id);
        setSelectedDetail(lv.leave_detail_id);
        setDetailDescription(lv.detail_description);
        setNoDays(lv.no_days);
        setCommutation(lv.commutation);
        setMeridiem(lv.meridiem);
        let dates = lv.leave_date.map((leave) => {
            return new DateObject(leave.date);
        });
        setInclusiveDates(dates);
        setAoApprove(lv.ao_approved);
        setOffApprove(lv.office_approved);
        setOffDisReason(lv.off_dis_reason);
        setHrApprove(lv.hr_approved);
        setPgoDisReason(lv.pgo_dis_reason);
        setSigned(lv.signed);
        setStatus(lv.status);
    }

    const handleChanges = (e, setVal, otherVal = null) => {
        let type = e.target.type;
        let val = type === "text" ? e.target.value : type === "radio" ? otherVal : typeof e.target.value !== "string" ? parseInt(e.target.value) : e.target.value;
        let name = e.target.name;
        setVal(val);
        let newUpdate = { ...updates };
        if ((val !== props.leave[name] && name !== "hr_dis_reason") || (name === "hr_dis_reason" && val !== "")) {
            newUpdate[name] = val;
        } else {
            delete newUpdate[name];
        }
        setUpdates(newUpdate);
    }

    const handleDateChanges = (dates) => {
        setInclusiveDates(dates);

        let inclusiveDatesArr = [];
        let changed = false;

        if (dates.length !== props.leave.leave_date.length) {
            changed = true;
        } else {
            changed = false;
        }

        dates.forEach((date) => {
            let dateF = date.format("YYYY-MM-DD");
            inclusiveDatesArr.push(dateF);
            let exists = props.leave.leave_date.find(date => date.date === dateF);
            if (!exists) {
                changed = true;
            }
        });


        let newUpdate = { ...updates };
        if (changed) {
            newUpdate["leave_date"] = inclusiveDatesArr;
        } else {
            delete newUpdate["leave_date"];
        }
        setUpdates(newUpdate);
    }

    const saveUpdates = async () => {

        if (!validateFields()) {
            const formData = new FormData();
            //console.log(updates);
            formData.append('leave_id', props.leave.id);
            //formData.append('user', props.user.unique);
            formData.append('updates', JSON.stringify(updates));
            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append('document[]', selectedFiles[i]);
            }
            Swal.fire({
                showConfirmButton: false,
                text: 'Saving...'
            })

            await axios.post(`/api/leaveapplication/update`, formData).then(({ data, status }) => {
                if (status === 202) {
                    Swal.update({
                        icon: "warning",
                        html: `<div>
                            <p>Unable to update, DTR has already been approved for the following dates.</p>
                            <p>${Object.values(data.dates).join(", ")}</p>
                        </div>`
                    })
                } else {
                    Swal.update({
                        icon: "success",
                        text: data.message
                    })
                    props.closeFetchEdit();
                }
            }).catch(({ response }) => {
                Swal.update({
                    icon: "error",
                    text: response.data.message,
                });
            })
        }
    }

    const cancelLeaveConfirm = async () => {
        Swal.fire({
            icon: "warning",
            text: "Are you sure you want to cancel your leave? This action is permanent.",
            showCancelButton: true,
            confirmButtonText: "Proceed",
            confirmButtonColor: "red"
        }).then((result) => {
            //console.log(result);
            if (result.isConfirmed) {
                cancelLeave();
            }
        })
    }

    const [editable, setEditable] = useState(false);

    const checkEditable = () => {
        let offApp = props.leave.office_approved;
        let pgoApp = props.leave.signed
        let ewOffApp = props.editWhenOffApp;
        let ewPGOApp = props.editWhenPgoApp;

        if (offApp === null) {
            return true;
        }

        if (offApp !== null && ewOffApp && pgoApp === null) {
            return true;
        }

        if ((pgoApp !== null && pgoApp !== 0) && ewPGOApp) {
            return true;
        }

        return false;
    }

    const cancelLeave = async () => {
        const formData = new FormData();
        formData.append('leave_id', props.leave.id);
        //formData.append('user', props.user.unique);
        formData.append('updates', JSON.stringify({ status: 0 }));
        Swal.fire({
            icon: 'info',
            text: 'Saving changes...',
            showConfirmButton: false
        })
        await axios.post(`/api/leaveapplication/update`, formData).then(({ data, status }) => {
            if (status === 202) {
                Swal.update({
                    icon: "warning",
                    html: `<div>
                        <p>Unable to update, DTR has already been approved for the following dates.</p>
                        <p>${Object.values(data.dates).join(", ")}</p>
                    </div>`
                })
            } else {
                Swal.update({
                    icon: "success",
                    text: data.message
                })
                props.closeFetchEdit();
            }
        }).catch(({ response }) => {
            Swal.update({
                icon: "error",
                text: response.data.message,
            })
        })
    }

    const checkAssigned = (assFor) => {
        return props.user.assignatory.some(assigned => (assigned.office.some(off => off === props.leave.office.offcode) || assigned.office === null) && assigned.for === assFor);
    }

    const [showLedger, setShowLedger] = useState(false);
    const closeLedger = () => setShowLedger(false);
    const openledger = async () => {
        await getledger();
        setShowLedger(true);
    }

    const getledger = async () => {
        const response = await axios.get(`/api/LeaveLedger/${props.leave.plantilla.unique}`)
        if (response.data.result === 0) {
            Swal.fire({
                text: "Data not found",
                icon: "error",
            });
        }
        else {
            if (response.data.result.ledger.length === 0) {
                Swal.fire({
                    text: "There is no leave ledger record found.",
                    icon: "error",
                });
            }
            else {
                setLedger(response.data.result);

            }
        }
    }

    const [ledger, setLedger] = useState(null);

    return (
        <>
            <Modal show={showLedger} onHide={closeLedger} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <div className="fw-bold">Leave Ledger</div>
                </Modal.Header>
                <Modal.Body>
                    <LeaveLedger
                        leaveData={ledger}
                    />
                </Modal.Body>
            </Modal>

            <Modal.Header closeButton>
                <Modal.Title>Leave Application</Modal.Title>
            </Modal.Header>
            <Modal.Body className="leave-application-view-modal-body">
                <Row className="leave-application-view-modal-body-leave overflow-auto">
                    <Col className="" xs={12} md={7}>
                        {/* employee details */}
                        {props.showEmpDetails ? <Row>
                            <Col className="">
                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">DATE SUBMITTED</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {new DateObject(props.leave.created_at).format("MM/DD/YYYY")}
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">EMPLOYEE NAME</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {`${props.leave.plantilla.FINAME} ${props.leave.plantilla.MIDNAME} ${props.leave.plantilla.SURNAME}`}
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">OFFICE</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {props.leave.office.offdesc}
                                    </Col>
                                </Row>

                                <hr />

                            </Col>
                        </Row> : <></>}

                        {/* leave details */}
                        <Row className="mt-2">
                            <Col className="">
                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">TYPE OF LEAVE TO BE AVAILED</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {editable && props.editable.type && props.leave.status ? <Form.Select name="leave_type_id" required value={selectedType} onChange={(event) => {
                                            handleChanges(event, setSelectedType);
                                        }}>
                                            <option value={0}>Select</option>
                                            {leaveTypeOptions.map((type, key) => (
                                                <option key={key} value={type.id}>{type.type}</option>
                                            ))}
                                        </Form.Select> :
                                            leaveTypeOptions.find(opt => opt.id === selectedType) ? leaveTypeOptions.find(opt => opt.id === selectedType).type : ""}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-normal">INSTRUCTIONS & REQUIREMENTS</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <ul className="mb-0">
                                            {leaveReq[selectedType].map((requ, key) => {
                                                return <li key={key}>
                                                    {typeof requ === 'string' ? requ :
                                                        <ul className="m-0 p-0" style={{ listStyleType: "none" }}>
                                                            {requ.map(((req, key2) => {
                                                                return <li key={key2}>
                                                                    {req}
                                                                </li>
                                                            }))}
                                                        </ul>
                                                    }
                                                </li>
                                            })}
                                        </ul>
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">ATTACHMENTS (If needed)</Form.Label>
                                    </Col>
                                    <Col className="" sm={8}>

                                        {editable && props.editable.attachment && props.leave.status ? <Row className="mb-2">
                                            <Col>
                                                <Form.Control name="attachments" as="input" type="file" ref={fileInputRef} hidden onChange={handleFileChange} multiple={true} />
                                                <Button className="" variant="primary" size="sm" onClick={() => fileInputRef.current.click()}>
                                                    Upload File
                                                </Button>
                                            </Col>
                                        </Row> : null}

                                        <Row>
                                            <Col>
                                                {selectedFiles.map((file, key) => {
                                                    return (
                                                        <Row className="mb-1" key={key}>
                                                            <Col className="pe-1" col={10}>
                                                                <div className="card p-0 m-0">
                                                                    <div className="card-body pt-1 pb-1">
                                                                        <Form.Label className="fw-normal p-0 m-0">{file.name}</Form.Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className="p-0" col={2}>
                                                                {editable && props.editable.attachment && props.leave.status ? <Button className="" variant="danger" size="sm" title={`Remove "${file.name}"`} onClick={(e) => {
                                                                    let newSelectedFiles = [...selectedFiles];
                                                                    newSelectedFiles.splice(key, 1);
                                                                    setSelectedFiles(newSelectedFiles);
                                                                }}>X</Button> : null}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                {leaveAttachments.map((file, key) => {
                                                    return (
                                                        <Row className="mb-1" key={key}>
                                                            <Col className="pe-1" sm={10}>
                                                                <div className={`card border border-2 p-0 m-0 ${delAttachments.find(att => att === file.id) ? 'border-danger' : "border-success"}`} style={{ 'cursor': 'pointer' }} title={`Download "${file.document_name}"`} onClick={(e) => {
                                                                    downloadDocument(file.link);
                                                                }}>
                                                                    <div className="card-body pt-1 pb-1">
                                                                        <Form.Label className="fw-normal p-0 m-0" style={{ 'cursor': 'pointer' }}>{file.document_name}</Form.Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col className="p-0" sm={2}>
                                                                {editable && props.editable.attachment && props.leave.status ? delAttachments.find(att => att === file.id) ? <Button className="" variant="danger" size="sm" title={`Remove "${file.document_name}"`} onClick={(e) => {
                                                                    let upDelAttachments = delAttachments.filter(att => att !== file.id);
                                                                    setDelAttachments(upDelAttachments);
                                                                }}>Undo</Button> : <Button className="" variant="danger" size="sm" title={`Remove "${file.document_name}"`} onClick={(e) => {
                                                                    let upDelAttachments = [...delAttachments];
                                                                    upDelAttachments.push(file.id);
                                                                    setDelAttachments(upDelAttachments);
                                                                }}>X</Button> : null}
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                            </Col>
                                        </Row>

                                        {editable && props.editable.attachment && props.leave.status ? <Row>
                                            <Col>
                                                <ListGroup className="" variant="flush">
                                                    {fileChkFail.map((file, key) => {
                                                        return (
                                                            <ListGroup.Item className="p-1" key={key}>
                                                                <Form.Label className="fw-normal p-0 m-0 me-2 text-danger">{file}</Form.Label>
                                                            </ListGroup.Item>
                                                        );
                                                    })}
                                                </ListGroup>
                                            </Col>
                                        </Row> : null}

                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">DETAILS OF LEAVE</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        <Row>
                                            <Col>
                                                {editable && props.editable.details && props.leave.status ? <Form.Select name="leave_detail_id" required value={selectedDetail} onChange={(event) => {
                                                    handleChanges(event, setSelectedDetail);
                                                }}>
                                                    <option value={0}>Select</option>
                                                    {leaveDetailOptions.map((detail) => (
                                                        <option key={detail.id} value={detail.id}>{`${detail.detail}${detail.otherDetail}`}</option>
                                                    ))}
                                                </Form.Select> :
                                                    leaveDetailOptions.find(opt => opt.id === selectedDetail) ? leaveDetailOptions.find(opt => opt.id === selectedDetail).detail : ""}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {editable && props.editable.details && props.leave.status ? <Form.Control className="mt-2" name="detail_description" as="input" placeholder="Specify when needed" value={detailDescription} onChange={(event) => {
                                                    handleChanges(event, setDetailDescription);
                                                }} /> : detailDescription
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">NUMBER OF WORKING DAYS</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {editable && props.editable.noDays && props.leave.status ? <Form.Control name="no_days" as="input" type="number" min="0" step="0.0001" pattern="\d+(\.\d{1})?" value={noDays} required onChange={(event) => {
                                            handleChanges(event, setNoDays);
                                        }} /> : noDays}
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">Time of Day</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {editable && props.editable.meridiem && props.leave.status ? <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" className="btn-check" name="meridiem" id="meridiem1" autoComplete="off" checked={meridiem === ""}
                                                onChange={(event) => handleChanges(event, setMeridiem, "")}
                                            />
                                            <label className="btn btn-outline-primary toggle-button-first-item" htmlFor="meridiem1">Whole Day</label>
                                            <input type="radio" className="btn-check" name="meridiem" id="meridiem2" autoComplete="off" checked={meridiem === "am"}
                                                onChange={(event) => handleChanges(event, setMeridiem, "am")}
                                            />
                                            <label className="btn btn-outline-primary" htmlFor="meridiem2">AM</label>
                                            <input type="radio" className="btn-check" name="meridiem" id="meridiem3" autoComplete="off" checked={meridiem === "pm"}
                                                onChange={(event) => handleChanges(event, setMeridiem, "pm")}
                                            />
                                            <label className="btn btn-outline-primary" htmlFor="meridiem3">PM</label>
                                        </div> : meridiem === "" ? "Whole Day" : meridiem === "am" ? "AM" : "PM"}
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left">
                                        <Form.Label className="fw-bold" id="dates-list">INCLUSIVE DATES</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Calendar className="m-auto"
                                            multiple
                                            disabled={!(editable && props.editable.incDates && props.leave.status)}
                                            value={inclusiveDates}
                                            onChange={handleDateChanges}
                                            plugins={[
                                                <DatePanel sort="date" />
                                            ]}
                                        />
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col className="text-left" sm={4}>
                                        <Form.Label className="fw-bold">Commutation</Form.Label>
                                    </Col>
                                    <Col sm={8}>
                                        {editable && props.editable.commutation && props.leave.status ? <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                            <input type="radio" className="btn-check" name="commutation" id="commutation1" autoComplete="off" checked={!commutation}
                                                onChange={(event) => handleChanges(event, setCommutation, 0)}
                                            />
                                            <label className="btn btn-outline-primary toggle-button-first-item" htmlFor="commutation1">Not Requested</label>
                                            <input type="radio" className="btn-check" name="commutation" id="commutation2" autoComplete="off" checked={commutation}
                                                onChange={(event) => handleChanges(event, setCommutation, 1)}
                                            />
                                            <label className="btn btn-outline-primary" htmlFor="commutation2">Requested</label>
                                        </div> : commutation === 0 ? "Not Requested" : "Requested"}
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        {props.showLeaveSummary ? <Row>
                            <Col>
                                <hr />
                                <Row className="mb-2">
                                    <Col className="text-left" sm="auto">
                                        <Form.Label className="fw-bold">Approved Leave Summary</Form.Label>
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            value={leaveSummaryYear}
                                            onChange={(date) => {
                                                setLeaveSummaryYear(date);
                                            }}
                                            onlyYearPicker
                                            inputClass="form-control query-input"
                                        />
                                    </Col>
                                </Row>
                                <Row className="">
                                    {leaveSummary ? <Col>
                                        {Object.keys(leaveSummary).length === 0 ? <Col>No leave found</Col>
                                            : <Col>
                                                {Object.values(leaveSummary).map((summaryItem, index) => (
                                                    <Row key={index}>
                                                        <Col>
                                                            <Row className="fw-bold ms-2">
                                                                <Col>
                                                                    {summaryItem.name}
                                                                </Col>
                                                                <Col>
                                                                    Total Days: {summaryItem.totalDays}
                                                                </Col>
                                                            </Row>
                                                            {summaryItem.leaves.map((leave, index) => (
                                                                <Row className="ms-4" key={index}>
                                                                    <Col>Dates: {leave.dates}</Col>
                                                                    <Col className="ms-4">Days: {parseFloat(leave.days)}</Col>
                                                                </Row>
                                                            ))}
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>}
                                    </Col> : <Col>Loading...</Col>}
                                </Row>
                            </Col>
                        </Row> : <></>}

                        {props.showLeaveBalance ? <Row>
                            <Col className="">
                                <hr />
                                <Row>
                                    <Col className="text-left">
                                        <Form.Label className="fw-bold">Leave Deduction</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">

                                            </Col>
                                            <Col className="m-0 p-0">
                                                Vacation Leave
                                            </Col>
                                            <Col className="m-0 p-0">
                                                Sick Leave
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                Total Earned
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">{limitDecimals(vlBal, 3)}</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">{limitDecimals(slBal, 3)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0 fst-italic">
                                                Less this application
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control style={{ fontSize: "12pt" }} className="text-center ms-2" size="sm" name="less_vl" as="input" type="number" min="0" value={limitDecimals(lessVL, 3)} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        if (parseFloat(val) <= parseFloat(vlBal)) {
                                                            setLessVL(val);
                                                            let newUpdate = { ...updates };
                                                            if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                                newUpdate[name] = val;
                                                            } else {
                                                                delete newUpdate[name];
                                                            }
                                                            setUpdates(newUpdate);
                                                        }
                                                    }} /> : limitDecimals(lessVL, 3)}
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control style={{ fontSize: "12pt" }} className="text-center ms-2" size="sm" name="less_sl" as="input" type="number" min="0" value={limitDecimals(lessSL, 3)} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        if (parseFloat(val) <= parseFloat(slBal)) {
                                                            setLessSL(val);
                                                            let newUpdate = { ...updates };
                                                            if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                                newUpdate[name] = val;
                                                            } else {
                                                                delete newUpdate[name];
                                                            }
                                                            setUpdates(newUpdate);
                                                        }
                                                    }} /> : limitDecimals(lessSL, 3)}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                Balance
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">{limitDecimals(parseFloat(vlBal - lessVL) >= 0 ? parseFloat(vlBal - lessVL) : 0, 3)}</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">{limitDecimals(parseFloat(slBal - lessSL) >= 0 ? parseFloat(slBal - lessSL) : 0, 3)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0 fst-italic">
                                                Without Pay
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control style={{ fontSize: "12pt" }} className="text-center ms-2" size="sm" name="vl_wo" as="input" type="number" min="0" value={limitDecimals(vlWO, 3)} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        if ((parseFloat(val) + parseFloat(slWO)) <= (parseFloat(noDays) - (parseFloat(lessSL) + parseFloat(lessVL)))) {
                                                            setVLWO(val);
                                                            let newUpdate = { ...updates };
                                                            if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                                newUpdate[name] = val;
                                                            } else {
                                                                delete newUpdate[name];
                                                            }
                                                            setUpdates(newUpdate);
                                                        }
                                                    }} /> : limitDecimals(vlWO, 3)}
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control style={{ fontSize: "12pt" }} className="text-center ms-2" size="sm" name="sl_wo" as="input" type="number" min="0" value={limitDecimals(slWO, 3)} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        if (parseFloat(val) + parseFloat(vlWO) <= (parseFloat(noDays) - (parseFloat(lessSL) + parseFloat(lessVL)))) {
                                                            setSLWO(val);
                                                            let newUpdate = { ...updates };
                                                            if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                                newUpdate[name] = val;
                                                            } else {
                                                                delete newUpdate[name];
                                                            }
                                                            setUpdates(newUpdate);
                                                        }
                                                    }} /> : limitDecimals(slWO, 3)}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="text-center">
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                Type
                                            </Col>
                                            <Col className="m-0 p-0">
                                                Balance
                                            </Col>
                                            <Col className="m-0 p-0">
                                                Tentative
                                            </Col>
                                            <Col className="m-0 p-0">
                                                Recommended
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">Vacation</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">{vlBal}</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {
                                                        (selectedType === 1 || selectedType === 2) ?
                                                            (parseFloat(vlBal - noDays).toFixed(4)) >= 0 ? noDays :
                                                                (parseFloat(noDays - (noDays - vlBal)).toFixed(4)) : 0
                                                    }
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control className="text-center" size="sm" name="less_vl" as="input" type="number" min="0" value={lessVL} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        setLessVL(val);
                                                        let newUpdate = { ...updates };
                                                        if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                            newUpdate[name] = val;
                                                        } else {
                                                            delete newUpdate[name];
                                                        }
                                                        setUpdates(newUpdate);
                                                    }} /> : lessVL}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">Sick</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {slBal}
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {
                                                        (selectedType === 3) ?
                                                            (parseFloat(slBal - noDays).toFixed(4)) >= 0 ? noDays :
                                                                (parseFloat(noDays - (noDays - slBal)).toFixed(4)) : 0
                                                    }
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control className="text-center" size="sm" name="less_sl" as="input" type="number" min="0" value={lessSL} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        setLessSL(val);
                                                        let newUpdate = { ...updates };
                                                        if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                            newUpdate[name] = val;
                                                        } else {
                                                            delete newUpdate[name];
                                                        }
                                                        setUpdates(newUpdate);
                                                    }} /> : lessSL}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">Vacation w/o Pay</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                &nbsp;
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {
                                                        (selectedType === 1 || selectedType === 2) ?
                                                            (parseFloat(vlBal - noDays).toFixed(4)) < 0 ? parseFloat(noDays - vlBal).toFixed(4) : 0 : 0
                                                    }
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control className="text-center" size="sm" name="vl_wo" as="input" type="number" min="0" value={vlWO} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        setVLWO(val);
                                                        let newUpdate = { ...updates };
                                                        if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                            newUpdate[name] = val;
                                                        } else {
                                                            delete newUpdate[name];
                                                        }
                                                        setUpdates(newUpdate);
                                                    }} /> : vlWO}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row className="border-bottom border-1 m-0 p-0">
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">Sick w/o Pay</span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                &nbsp;
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {
                                                        (selectedType === 3) ?
                                                            (parseFloat(slBal - noDays).toFixed(4)) < 0 ? parseFloat(noDays - slBal).toFixed(4) : 0 : 0
                                                    }
                                                </span>
                                            </Col>
                                            <Col className="m-0 p-0">
                                                <span className="align-middle">
                                                    {editable && props.editable.lvBalance && props.leave.status ? <Form.Control className="text-center" size="sm" name="sl_wo" as="input" type="number" min="0" value={slWO} onChange={(event) => {
                                                        let val = event.target.value;
                                                        let name = event.target.name;
                                                        setSLWO(val);
                                                        let newUpdate = { ...updates };
                                                        if (parseFloat(val).toFixed(4) !== props.leave[name]) {
                                                            newUpdate[name] = val;
                                                        } else {
                                                            delete newUpdate[name];
                                                        }
                                                        setUpdates(newUpdate);
                                                    }} /> : slWO}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row> : <></>}

                        {props.showAppFor ? <Row>
                            <Col className="">
                                <hr />
                                <Row>
                                    <Col className="text-left">
                                        <Form.Label className="fw-bold">Approved for:</Form.Label>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="" sm={3}></Col>
                                    <Col className="" sm={7}>
                                        <Row className="text-center mb-2">
                                            <Col className="form-inline">
                                                {editable && props.editable.appFor && props.leave.status ? <Form.Control className="leave-day-input" name="days_w_pay" as="input" type="number" min="0" value={daysWPay} onChange={(event) => {
                                                    handleChanges(event, setDaysWPay);
                                                }} /> : <label className="fw-bold me-1">{daysWPay}</label>}
                                                days with pay
                                            </Col>
                                        </Row>
                                        <Row className="text-center mb-2">
                                            <Col className="form-inline">
                                                {editable && props.editable.appFor && props.leave.status ? <Form.Control className="leave-day-input mb-2 me-2" name="days_wo_pay" as="input" type="number" min="0" value={daysWOPay} onChange={(event) => {
                                                    handleChanges(event, setDaysWOPay);
                                                }} /> : <label className="fw-bold me-1">{daysWOPay}</label>}
                                                days without pay
                                            </Col>
                                        </Row>
                                        <Row className="text-center mb-2">
                                            <Col className="form-inline">
                                                {editable && props.editable.appFor && props.leave.status ? <Form.Control className="leave-day-input mb-2 me-2" name="others_pay" as="input" type="number" min="0" value={othersPay} onChange={(event) => {
                                                    handleChanges(event, setOthersPay);
                                                }} /> : <label className="fw-bold me-1">{othersPay}</label>}
                                                others
                                            </Col>
                                        </Row><Row className="text-center">
                                            <Col className="form-inline">
                                                {editable && props.editable.appFor && props.leave.status ? <Form.Control className="" name="others_pay_spec" as="input" placeholder="Specify others" value={othersPaySpec} onChange={(event) => {
                                                    handleChanges(event, setOthersPaySpec);
                                                }} /> : <label className="fw-bold me-1">&nbsp;{othersPaySpec}</label>}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> : <></>}

                        <hr />

                        <Row>
                            <Col>
                                <Row>
                                    <Col className="text-left">
                                        <Form.Label className="fw-bold">Status</Form.Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="">
                                        <div className="progress mt-1 fw-bold" style={{ height: "30px" }}>
                                            {props.leave.status ? <>
                                                <div className={`progress-bar ${props.leave.ao_approved !== 1 ? props.leave.ao_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>AO</div>
                                                <div className={`progress-bar ${props.leave.office_approved !== 1 ? props.leave.office_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>Chief</div>
                                                <div className={`progress-bar ${props.leave.hr_approved !== 1 ? props.leave.hr_approved === null ? "bg-warning" : props.leave.hr_approved === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>HR</div>
                                                <div className={`progress-bar ${props.leave.signed !== 1 ? props.leave.signed === null ? "bg-warning" : props.leave.signed === 2 ? "bg-secondary" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>PGO</div>
                                            </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                        </div>
                                    </Col>
                                </Row>

                                {editable && props.editable.aoApp && props.leave.status && checkAssigned("leave_application_ao") ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">Admin Officer Approval</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="Admin Officer Approval">
                                                <input type="radio" className="btn-check" name="ao_approved" id="aoApproval1" autoComplete="off" checked={aoApprove === 1}
                                                    onChange={(event) => handleChanges(event, setAoApprove, 1)}
                                                />
                                                <label className="btn btn-outline-success toggle-button-first-item" htmlFor="aoApproval1">Approved</label>
                                                <input type="radio" className="btn-check" name="ao_approved" id="aoApproval2" autoComplete="off" checked={aoApprove === 0}
                                                    onChange={(event) => handleChanges(event, setAoApprove, 0)}
                                                />
                                                <label className="btn btn-outline-danger" htmlFor="aoApproval2">Disapproved</label>
                                                <input type="radio" className="btn-check" name="ao_approved" id="aoApproval3" autoComplete="off" checked={aoApprove === null}
                                                    onChange={(event) => handleChanges(event, setAoApprove, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="aoApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </> : <></>}

                                {editable && props.editable.offApp && props.leave.status && checkAssigned("off_att_app") ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">Office Head Approval</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="Office Head Approval">
                                                <input type="radio" className="btn-check" name="office_approved" id="officeApproval1" autoComplete="off" checked={offApprove === 1}
                                                    onChange={(event) => handleChanges(event, setOffApprove, 1)}
                                                />
                                                <label className="btn btn-outline-success toggle-button-first-item" htmlFor="officeApproval1">Approved</label>
                                                <input type="radio" className="btn-check" name="office_approved" id="officeApproval2" autoComplete="off" checked={offApprove === 0}
                                                    onChange={(event) => handleChanges(event, setOffApprove, 0)}
                                                />
                                                <label className="btn btn-outline-danger" htmlFor="officeApproval2">Disapproved</label>
                                                <input type="radio" className="btn-check" name="office_approved" id="officeApproval3" autoComplete="off" checked={offApprove === null}
                                                    onChange={(event) => handleChanges(event, setOffApprove, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="officeApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>

                                    {editable && props.editable.offDisReason && props.leave.status && checkAssigned("off_att_app") ? <>
                                        <hr />
                                        <Row>
                                            <Col className="text-left" sm={4}>
                                                <Form.Label className="fw-bold">Reason for disapproval</Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Select name="off_dis_reason" required value={offDisReason} onChange={(event) => {
                                                    handleChanges(event, setOffDisReason);
                                                }}>
                                                    <option value={""}>Select</option>
                                                    {disReasonOptions.map((type, key) => (
                                                        <option key={key} value={type.reason}>{type.reason}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row></> : <></>}
                                </> : <></>}

                                {editable && props.editable.hrApp && props.leave.status ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">HR Validation</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="HR Approval">
                                                <input type="radio" className="btn-check" name="hr_approved" id="hrApproval1" autoComplete="off" checked={hrApprove === 1}
                                                    onChange={(event) => handleChanges(event, setHrApprove, 1)}
                                                />
                                                <label className="btn btn-outline-primary toggle-button-first-item" htmlFor="hrApproval1">Validated</label>
                                                <input type="radio" className="btn-check" name="hr_approved" id="hrApproval3" autoComplete="off" checked={hrApprove === null}
                                                    onChange={(event) => handleChanges(event, setHrApprove, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="hrApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {(editable && props.editable.hrDisReason) ? <>
                                        <hr />
                                        <Row>
                                            <Col className="text-left" sm={4}>
                                                <Form.Label className="fw-bold">HR Remark</Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Select name="hr_dis_reason" value={hrDisReason} onChange={(event) => {
                                                    handleChanges(event, setHrDisReason);
                                                }}>
                                                    <option value={""}>Select</option>
                                                    {disReasonOptions.map((type, key) => (
                                                        <option key={key} value={type.reason}>{type.reason}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row></> : <></>}
                                </> : <></>}

                                {editable && props.editable.pgoApp && props.leave.status && checkAssigned("pgo_app") ? <>
                                    <hr />
                                    <Row>
                                        <Col className="text-left" sm={4}>
                                            <Form.Label className="fw-bold">PGO Approval</Form.Label>
                                        </Col>
                                        <Col sm={8}>
                                            <div className="btn-group" role="group" aria-label="PGO Approval">
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval1" autoComplete="off" checked={signed === 1}
                                                    onChange={(event) => handleChanges(event, setSigned, 1)}
                                                />
                                                <label className="btn btn-outline-success toggle-button-first-item" htmlFor="pgoApproval1">Approved</label>
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval2" autoComplete="off" checked={signed === 0}
                                                    onChange={(event) => handleChanges(event, setSigned, 0)}
                                                />
                                                <label className="btn btn-outline-danger" htmlFor="pgoApproval2">Disapproved</label>
                                                <input type="radio" className="btn-check" name="signed" id="pgoApproval3" autoComplete="off" checked={signed === null}
                                                    onChange={(event) => handleChanges(event, setSigned, null)}
                                                />
                                                <label className="btn btn-outline-warning" htmlFor="pgoApproval3">Pending</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {editable && props.editable.pgoDisReason && props.leave.status && checkAssigned("pgo_app") ? <>
                                        <hr />
                                        <Row>
                                            <Col className="text-left" sm={4}>
                                                <Form.Label className="fw-bold">Reason for disapproval</Form.Label>
                                            </Col>
                                            <Col sm={8}>
                                                <Form.Select name="pgo_dis_reason" required value={pgoDisReason} onChange={(event) => {
                                                    handleChanges(event, setPgoDisReason);
                                                }}>
                                                    <option value={""}>Select</option>
                                                    {disReasonOptions.map((type, key) => (
                                                        <option key={key} value={type.reason}>{type.reason}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        </Row></> : <></>}
                                </> : <></>}

                            </Col>
                        </Row>
                    </Col>
                    <Col className="" xs={12} md={5}>
                        <Comments
                            comments_id={props.leave.id}
                            user={props.user}
                            module={'leave_application'}
                            postID={props.leave.leave_post_id}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {props.leave.status ? <Row className="me-auto">
                    <Col>
                        <Button variant="primary" onClick={(e) => {
                            saveUpdates();
                        }} disabled={!hasUpdate}>Update</Button>
                        {props.editable.cancel && props.leave.status ? <Button variant="danger" className="ms-1" onClick={(e) => {
                            cancelLeaveConfirm();
                        }}>Cancel</Button> : null}
                        {props.showLeaveSummary && props.leave.status ? <Button variant="primary" className="ms-1" onClick={(e) => {
                            openledger();
                        }}>View Leave Ledger</Button> : null}
                    </Col>
                </Row> :
                    <Row className="me-auto">
                        <Col>
                            <Form.Label className="text-danger m-0 p-0">Leave has been cancelled.</Form.Label>
                        </Col>
                    </Row>
                }
            </Modal.Footer>
        </>
    )
}