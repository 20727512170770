import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

const ChildrenModal = (props) => {


    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });
    const [fetchData, setFetchData] = useState({
        child_name: "",
        child_bday: "", 
        child_age: "",
    });
  const [error, setError] = useState([]);
      
    useEffect(() => {
       axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
         setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let age = "";
        

        // field child_bday auto compute of age
        if (name === "child_bday" && value !== "") {
        // Calculate age based on the entered birthday
        const birthdate = new Date(value);
        const today = new Date();
        const ageDiff = today.getFullYear() - birthdate.getFullYear();
        age = ageDiff.toString();
        }

        setFetchData({ ...fetchData, [name]: value, child_age: age });
  };
  

    const submitData = async (e) => {
        e.preventDefault();

    
      await axios.post(`/api/storedUnmarriedChild`, {
        IDNO: fetchEmpInfo.IDNO,
        plantilla_id: props.unique,
        children_name: fetchData.child_name,
        children_bday: fetchData.child_bday,

      }).then((res) => {

       
        if (res.data.status === 422) {
      
          Swal.fire({
            icon: 'error',
            title: 'All fields are required',
          })
          setError(res.data.errors);
    
          } else {
            
            Swal.fire({
              icon: "success",
              title: res.data.message,
            }).then((res) => {
              props.handleClose(false);
              props.fetchDataSALN(props.unique)
              setFetchData({
                child_name: '',
                child_bday: '',
                child_age: '',
              });
            })
              
            }

        });


    };

  return (
    <div>

    <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Create Children Details </Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitData}>
        <Modal.Body>
                     
                <div className="form-row">
                    <div className="form-group col-md-12">
                        <label>Children Name</label>
                          <input type="text" className={`form-control ${error.children_name ? 'is-invalid' : ''}`} name="child_name" value={fetchData.child_name ?? " "} onChange={handleChange}/>
                        <div  className="invalid-feedback">{error.children_name}</div>
                    </div>    

                     <div className="form-group col-md-6">
                        <label>Date of Birth</label>
                          <input type="date" className={`form-control ${error.children_bday ? 'is-invalid' : ''}`} name="child_bday" value={fetchData.child_bday ?? " "} onChange={handleChange}/>
                        <div  className="invalid-feedback">{error.children_bday}</div>
                          </div>    
                          
                    <div className="form-group col-md-6">
                        <label>Age</label>
                          <input type="text" className="form-control" name="child_age" value={fetchData.child_age ?? " "} readOnly/>
                    </div>    
                          
                </div>
                {/* form-row */}
                
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={props.handleClose}>
              Close
          </Button>
          <Button type="submit" variant="success" >
            Save
          </Button>
          
        </Modal.Footer>
        </Form>
    </Modal>  

    </div>
  )
}

export default ChildrenModal