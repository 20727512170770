import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import EmployeeListSearchable from '../../Global/EmployeeListSearchable.component';

export default function Page(props) {

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [empAbilities, setEmpAbilities] = useState(null);
    const [empAssigns, setEmpAssigns] = useState(null);
    const [abilityList, setAbilityList] = useState([]);

    

    const setCheckSelectedEmployee = (emp) => {
        if (selectedEmployee !== emp) {
            setSelectedEmployee(emp);
        }
    }

    useEffect(() => {
        if (selectedEmployee.length > 0) {
            fetchEmployeeAbilities();
        }
    }, [selectedEmployee])

    useEffect(() => {
        if (abilityList.length === 0) {
            fetchAbilityList();
        }
    }, [])

    const fetchAbilityList = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading data...',
            showConfirmButton: false
        });
        await axios.get(`/api/ability/list`).then(({ data }) => {
            //console.log('get ability list', data);
            if (data.result) {
                Swal.close();
                let abilities = {};
                data.abilities.map((abi) => {
                    (abilities[abi.application_id+""+abi.application] ??= []).push(abi);
                });
                setAbilityList(abilities);
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const fetchEmployeeAbilities = async () => {
        let emp = selectedEmployee[0];
        if (emp !== null && emp.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Loading Ability...',
                showConfirmButton: false
            });
            await axios.get(`/api/ability/user/${emp.id}`).then(({ data }) => {
                // console.log('get user ability', data);
                if (data.result) {
                    Swal.close();
                    setEmpAssigns(data.assign);
                    setEmpAbilities(data.abilities);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const setAbility = (abi) => {
        let updateAbi = [...empAbilities];
        if (updateAbi.includes(abi)) {
            var index = updateAbi.indexOf(abi);
            if (index !== -1) {
                updateAbi.splice(index, 1);
            }
        } else {
            updateAbi.push(abi);
        }
        setEmpAbilities(updateAbi);
    }

    const setAssign = (e, ass, val=null) => {
        if(val === null){
            val = e.target.value;
        }
        let name = e.target.name;
        let updateAssigns = [...empAssigns];
        let assTU = updateAssigns.find(assign => assign.assignatory_for === ass);
        if(assTU){
            assTU[name] = val;
        }else{
            updateAssigns.push({
                id : null,
                assignatory_for : ass,
                offices_n_p_unique : null,
                position_title : "",
                post_honorific : "",
                active : 0
                //user_id : selectedEmployee[0].unique,
                //[name] : val
            });
            assTU = updateAssigns.find(assign => assign.assignatory_for === ass);
            assTU[name] = val;
        }
        setEmpAssigns(updateAssigns);
    }

    const saveAbilities = async () => {
        let emp = selectedEmployee[0];
        if (emp !== null && emp.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Saving Changes...',
                showConfirmButton: false
            });
            // console.log(empAssigns);
            await axios.post(`/api/ability/user/${emp.id}`, { 'abilities': empAbilities, 'assignatories' : empAssigns }).then(({ data }) => {
                
                if (data.result) {
                    fetchEmployeeAbilities();
                    Swal.close();
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    return (
        <>
            <Row className="m-0 p-2" style={{ height: "100%" }}>
                <Col className="ps-0 pe-1 pt-0 pb-0" sm="2" style={{ height: "100%" }}>
                    <EmployeeListSearchable
                        selectedEmployee={selectedEmployee}
                        setCheckSelectedEmployee={setCheckSelectedEmployee}
                        allowMultiple={false}
                        showUnique={true}
                    />
                </Col>
                <Col className="m-0 ps-0 pe-1 pt-0 pb-0" sm="10" style={{ height: "100%" }}>
                    {(selectedEmployee.length !== 0 && empAbilities !== null) ? <div className="card overflow-auto">
                        <div className="card-header">
                            <Row>
                                <Col sm="auto">
                                    {selectedEmployee[0].name}
                                </Col>
                                <Col>
                                    <Button size="sm" onClick={() => {
                                        saveAbilities();
                                    }}>Save</Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="card-body m-0">
                            <>
                            {Object.entries(abilityList).map(([app, abiList]) => (
                                <React.Fragment key={app}>
                                <Row className="border-top border-dark" key={app}>
                                    <Col>
                                        <Form.Label>
                                            {app.replace(/[0-9]/g, "")}
                                        </Form.Label>
                                    </Col>
                                </Row>
                                <Row className="ms-3 mb-3">
                                    {abiList.map((abi, index) => (
                                        <Col className="" sm={4} key={index}>
                                            <Row>
                                                <Col>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id={abi.code} checked={(empAbilities.includes(abi.code)) ? true : false} onChange={() => {
                                                            setAbility(abi.code);
                                                        }} />
                                                        <label className="form-check-label" htmlFor={abi.code}>
                                                            {abi.description}
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {abi.assignatory ? 
                                            <>
                                                {abi.assignatory.map((assign, aindex) => {
                                                    let assVal = empAssigns.find(ass => ass.assignatory_for === assign);
                                                    return (
                                                        <React.Fragment key={aindex}>
                                                            <Row className="ms-4 mb-1">
                                                                <Col sm={"auto"} className="ps-1 pe-0 pt-0 pb-0">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" name="active" id={assign} checked={assVal?.active ? true : false} onChange={(e) => {
                                                                            setAssign(e, assign, assVal?.active ? 0 : 1);
                                                                        }} />
                                                                        <label className="form-check-label" htmlFor={assign}>
                                                                            <b><i>{assign}</i></b>
                                                                        </label>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="ms-4 mb-1">
                                                                <Col className="ps-1 pe-0 pt-0 pb-0">
                                                                    <Form.Control
                                                                        name="offices_n_p_unique"
                                                                        type="text"
                                                                        placeholder="Offcodes"
                                                                        value={assVal?.offices_n_p_unique ?? ""}
                                                                        onChange={(e) => setAssign(e, assign)}
                                                                    />
                                                                </Col>
                                                                <Col className="ps-1 pe-0 pt-0 pb-0">
                                                                    <Form.Control
                                                                        name="position_title"
                                                                        type="text"
                                                                        placeholder="Position"
                                                                        value={assVal?.position_title ?? ""}
                                                                        onChange={(e) => setAssign(e, assign)}
                                                                    />
                                                                </Col>
                                                                <Col className="ps-1 pe-0 pt-0 pb-0">
                                                                    <Form.Control
                                                                        name="post_honorific"
                                                                        type="text"
                                                                        placeholder="Post Honorific"
                                                                        value={assVal?.post_honorific ?? ""}
                                                                        onChange={(e) => setAssign(e, assign)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </>
                                            : <></>}
                                        </Col>
                                    ))}
                                </Row>
                                </React.Fragment>
                            ))}
                            </>
                        </div>
                    </div> : null}
                </Col>
            </Row>
        </>
    )
}