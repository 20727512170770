import React, { useState } from 'react';
import axios from '../../../../services/axios';
import { Button } from 'react-bootstrap';
import RelativesModal from './RelativesModal';
import RelativesEditModal from './RelativesEditModal';
import Swal from 'sweetalert2';



const RelativesTable = (props) => {

    // const [isChecked, setIsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    const [showEditModal, setShowEditModal] = useState(false);
    const [EditDataModal, setEditDataModal] = useState([]);
    
    
    // const handleCheckboxChange = (e) => {
    //     setIsChecked(e.target.checked);
    // }

    const handleRelativeModale = () => {
        setShowModal(true);
    }
     
    const handleClose = () => {
        setShowModal(false);
    }

    const handleEditModal = (data) => {
        setEditDataModal(data)
        setShowEditModal(true);
    }

    const handleEditClose = () => {
        setShowEditModal(false);
    }


    // Delete function

    const handleDelete = (id) => {

       Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async  (result) => {
  
        if (result.isConfirmed) {
          try {
            await axios.delete(`/api/delRelatives/${id}`).then((res) => {

              Swal.fire({
                title: "Deleted!",
                text: res.data.message,
                icon: "success"
              }).then((res) => {
                
                props.fetchDataSALN(props.unique);
              });

            });

           
          } catch (error) {
            console.error("There was an error deleting the record", error);
          }
  
    }
    
      });  
    }

    return (
      <>
         <div className="card">
            <div className="card-body">
                
                <div className="row mb-2">
                        <div className="col-sm-8">
{/* 
                        <div className="form-check">
                                <input className="form-check-input" type="checkbox" disabled={isChecked} onChange={handleCheckboxChange} />
                            <label className="form-check-labe fw-boldl" htmlFor="flexCheckChecked">
                                I/We do not know of any relative/s in the government service
                            </label>
                        </div>   */}
            
                        </div>{/* /.col */}   
                        <div className="col-sm-4">
                            <div className="float-right">
                                <Button type="button" onClick={handleRelativeModale} variant="outline-primary"><i className="nav-icon fas fa-plus" /> Add</Button>        
                            </div>
                        </div>{/* /.col */} 
                    </div>{/* /.row */}
                        
                    <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                        
                        <thead>
                            <tr>
                                <th className="text-center">Name of Relatives</th>
                                <th className="text-center">Relationship</th>
                                <th className="text-center">Position</th>
                                <th className="text-center">Name of Agency/Office and Address</th>
                                <th className="text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>      
                        <tbody>

                            {
                                props.dataGovRelatives.length > 0 ? 
                                    props.dataGovRelatives.map((data) => {
                                        const {
                                            id, 
                                            name_relatives, 
                                            relationship,
                                            position, 
                                            name_agency
                                        } = data;

                                        return (
                                             <tr key={id}>
                                                <td>{ name_relatives }</td>
                                                <td>{ relationship }</td>
                                                <td>{ position }</td>
                                                <td>{ name_agency }</td>
                                                <td align="center">
                                                    <div className="row justify-content-center align-items-center">
                                                        <div className="col-auto">
                                                        <Button
                                                            variant="outline-danger btn-sm"
                                                            title="Delete"
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            <i className="nav-icon fas fa-trash" />
                                                        </Button>
                                                        </div>
                                                        <div className="col-auto">
                                                        <Button
                                                            variant="outline-primary btn-sm"
                                                            title="Edit"
                                                            onClick={() => handleEditModal(data)}
                                                        >
                                                            <i className="nav-icon fas fa-edit" />
                                                        </Button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr> 

                                        )

                                     })

                                
                                :
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr> 
                            }
                              
                        </tbody>
                        
                    </table>          

                


            </div> 
        </div>
        
            <RelativesModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
            <RelativesEditModal show={showEditModal} handleEditClose={handleEditClose} data={EditDataModal} fetchDataSALN={props.fetchDataSALN} unique={props.unique}/>                  
      </>

  )
}

export default RelativesTable