import React from 'react'
import Table from 'react-bootstrap/Table';
import '../../../Styles.css'


import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import LeaveAppModal from './components/LeaveAppModal.component';
import { DateObject } from "react-multi-date-picker";

export default function Leavetable({ listLeaveApplication, setListLeaveApplication, actor, userIsMobile }) {

    const [showModal, setShowModal] = useState(false);
    const [dataModal, setDataModal] = useState('');

    const [selectAll, setSelectAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);


    const OpenModalDetails = (data) => {
        setShowModal(true);
        setDataModal(data);
    }


    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedCheckboxes(selectAll ? [] : listLeaveApplication.map(data => data.leave_id));
    };

    const handleCheckboxChange = (leave_id) => {
        setSelectedCheckboxes(prevState => {
            if (prevState.includes(leave_id)) {
                return prevState.filter(item => item !== leave_id);
            } else {
                return [...prevState, leave_id];
            }
        });
    };

    const BtnApproved = async (e, decision) => {
        e.preventDefault();
        if (!selectedCheckboxes.length) {
            Swal.fire({
                title: "Ooops.!",
                text: "Please select at least one checkbox",
                icon: "info"
            });
        } else {
            if(decision){
                processDecision(decision)
            }else{
                Swal.fire({
                    title: 'Reason for Disapproval',
                    input: 'select',
                    inputOptions: {
                        "Late Filing" : "Late Filing",
                        "Incomplete Attachments" : "Incomplete Attachments",
                        "Invalid Attachment" : "Invalid Attachment",
                        "Insufficient manpower for selected date" : "Insufficient manpower for selected date"
                    },
                    inputPlaceholder: 'Select reason for disapproval',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    confirmButtonColor: "red"
                }).then((result) => {
                    if (result.value) {
                        processDecision(decision, result.value)
                    }
                });
            }
        }
    }

    const processDecision = async (decision, reason = "") => {
        Swal.fire({
            icon:'info',
            text:'Processing approval...',
            didOpen: () => {
                Swal.showLoading();
            },
            showConfirmButton:false
        });
        try {
            const response = await axios.post(`/api/office/approve_application`, {
                selectchkbox: selectedCheckboxes,
                decision: decision,
                reason : reason,
                actor: actor
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: response.data.message,
                }).then((result) => {
                    const updatedList = listLeaveApplication.filter(app => !selectedCheckboxes.includes(app.leave_id));
                    setListLeaveApplication(updatedList);
                    setSelectedCheckboxes([]);
                });
            } else {
                Swal.update({
                    icon:"error",
                    text:"Something went wrong",
                    showConfirmButton:true
                });
                // Handle other status codes or conditions if necessary
            }
        } catch (error) {
            Swal.update({
                icon:"error",
                text:"Something went wrong",
                showConfirmButton:true
            });
            console.error("Error:", error);
            // Handle errors here
        }

    }

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        overflowY: 'auto',
        ...(listLeaveApplication.length) > 6 ? { height: '240px' } : {}
    };

    return (

        <div >
            <div style={tableStyle}>
                <Table className="approvalTbl" responsive striped bordered hover style={{ margin: '0px' }}>
                    {!userIsMobile ? <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff', fontSize: '10pt' }}>
                        <tr>
                            <th style={{ padding: '3px' }}>
                                <div className="form-check d-flex flex-column align-items-center justify-content-center">
                                    <input className="form-check-input" type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                                </div>
                            </th>
                            {actor === 'pgo' ? <th style={{ padding: '3px', verticalAlign: 'middle' }}>Department</th> : <></>}
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Date</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Type</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Employee Name</th>
                            <th style={{ padding: '3px', verticalAlign: 'middle' }}>Inclusive</th>
                            <th style={{ padding: '3px', textAlign: 'center', verticalAlign: 'middle' }}>Action</th>
                        </tr>
                    </thead> : null }
                    <tbody >
                        {userIsMobile ? <tr>
                            <td>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All
                                </div>
                            </td>
                        </tr> : null}
                        {
                            listLeaveApplication.map((data) => {
                                const {
                                    created_at,
                                    leave_id,
                                    SURNAME,
                                    MIDNAME,
                                    FINAME,
                                    type,
                                    start_date,
                                    end_date,
                                    short
                                } = data;

                                const fullname = SURNAME + ', ' + FINAME + ' ' + MIDNAME;
                                const dateapplied = new DateObject(created_at).format("YYYY/MM/DD");
                                const startdate = new DateObject(start_date).format("YYYY/MM/DD");
                                const enddate = new DateObject(end_date).format("YYYY/MM/DD");

                                return !userIsMobile ? (<tr key={leave_id} style={{ fontSize: '10pt' }}>
                                            <td style={{ width: '3%', padding: '0px', verticalAlign: 'middle' }}>
                                                <div className="form-check d-flex flex-column align-items-center">
                                                    <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(leave_id)} onChange={() => handleCheckboxChange(leave_id)} />
                                                </div>
                                            </td>
                                            {actor === 'pgo' ?
                                                <td style={{ padding: '0px', verticalAlign: 'middle', width: '10%' }}>
                                                    {short}
                                                </td>
                                                : <></>}
                                            <td style={{ padding: '0px', verticalAlign: 'middle', width: '8%' }}>{dateapplied ?? ""}</td>
                                            <td style={{ padding: '0px', verticalAlign: 'middle', width: '15%' }}>{type}</td>
                                            <td style={{ padding: '0px', verticalAlign: 'middle' }}>{fullname}</td>
                                            <td style={{ padding: '0px', verticalAlign: 'middle', width: '20%' }}>
                                                {
                                                    startdate === enddate ? enddate : startdate + " - " + enddate
                                                }
                                            </td>
                                            <td className="text-center" style={{ padding: '1px', width: '5%' }}>
                                                <Button size="sm" type="button" variant="primary" onClick={() => OpenModalDetails(data)}>View</Button>
                                            </td>
                                        </tr>) :
                                        (<tr key={leave_id}>
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" checked={selectedCheckboxes.includes(leave_id)} onChange={() => handleCheckboxChange(leave_id)} />
                                                    <b>Name:</b> {fullname}
                                                    <br />
                                                    {actor === "pgo" ? <><b>Office: </b>{short}
                                                    <br /></> : null}
                                                    <b>Type:</b> {type}
                                                    <br />
                                                    <b>Date Applied:</b> {dateapplied ?? ""}
                                                    <br />
                                                    <b>Inclusive Dates:</b> {startdate === enddate ? enddate : startdate + " - " + enddate}
                                                    <br />
                                                    <Button size="sm" type="button" variant="primary" onClick={() => OpenModalDetails(data)}>View</Button>
                                                </div>
                                            </td>
                                        </tr>)
                            })
                        }
                    </tbody>
                </Table>
            </div>
            <div className="d-flex flex-row-reverse p-1">
                <Button size="sm" variant="danger" onClick={(e) => BtnApproved(e, 0)}>
                    Decline
                </Button>
                &nbsp;
                <Button size="sm" variant="success" onClick={(e) => BtnApproved(e, 1)}>
                    Approve
                </Button>
            </div>

            <LeaveAppModal showModal={showModal} setShowModal={setShowModal} dataModal={dataModal} />
        </div>

    )

}
//css


const thTdStyle = {
    padding: '3px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',

};

