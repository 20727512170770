import React, { useEffect, useState } from 'react';
import axios from '../../../../services/axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

const RelativesEditModal = (props) => {

  const [modalData, setModalData] = useState({
      name_relatives: "",
      relationship: "",
      position: "",
      name_agency: "",
  });
  const [error, setError] = useState([]);

  useEffect(() => {
    if (props.data) {
      setModalData({
        id: props.data.id,
        name_relatives: props.data.name_relatives,
        relationship: props.data.relationship,
        position: props.data.position,
        name_agency: props.data.name_agency,

      });
    }
  }, [props.data]);


  const handleInputChange = (e) => {
    setModalData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };


  const updateData = async (e) => {
    e.preventDefault();

    await axios.post(`/api/updatedRelatives`, {
      id: modalData.id,
      name_relatives: modalData.name_relatives,
      relationship: modalData.relationship,
      position: modalData.position,
      name_agency: modalData.name_agency,

    }).then((res) => {

      
       if (res.data.status === 422) {
            
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
            })
            setError(res.data.errors);

        } else {
              
            Swal.fire({
                icon: "success",
                title: res.data.message,
            }).then((res) => {
                    
                props.handleEditClose();
                props.fetchDataSALN(props.unique);
              
            })

        }

    });

  }


  return (
    <>
        <Modal show={props.show} onHide={props.handleEditClose} size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Create Relatives Interests and Financial Connections</Modal.Title>
            </Modal.Header>
            <Form onSubmit={updateData}>
                <Modal.Body>

                    <div className="row  g-3">
                        
                        <div className="col-md-6">
                            <label>Name of Relatives</label>
                              <input type="text" className={`form-control ${error.name_relatives ? 'is-invalid' : ''}`} name="name_relatives" value={modalData.name_relatives ?? ""} onChange={handleInputChange} />
                              <div className="invalid-feedback">{error.name_relatives}</div>
                        </div>
                        <div className="col-md-6">
                            <label>Relationship</label>
                            <input type="text" className={`form-control ${error.relationship ? 'is-invalid' : ''}`} name="relationship"  value={modalData.relationship ?? ""} onChange={handleInputChange} />
                            <div  className="invalid-feedback">{error.relationship}</div>
                        </div>
                        
                        <div className="col-md-4">
                             <label>Position</label>
                            <input type="text" className={`form-control ${error.position ? 'is-invalid' : ''}`} name="position" value={modalData.position ?? ""} onChange={handleInputChange} />
                            <div  className="invalid-feedback">{error.position}</div>
                        </div> 
                        
                        <div className="col-md-8">
                            <label>Name of Agency / Office and Address</label>
                            <input type="text" className={`form-control ${error.name_agency ? 'is-invalid' : ''}`} name="name_agency"  value={modalData.name_agency ?? ""} onChange={handleInputChange} />
                           <div  className="invalid-feedback">{error.name_agency}</div>
                        </div>
                        
                    </div>

                </Modal.Body>
            <Modal.Footer>
            
            <Button type="submit" variant="success" >
                Save 
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>
    </>
  )
}

export default RelativesEditModal