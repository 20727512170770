import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from '../../../../services/axios';
import { debounce } from 'lodash';

export default function EducModal(props) {
    
    const [show, setShow] = useState(false);
    const [EmpInfo, setEmpInfo] = useState([]);
    const [error, setError] = useState([]);  

    // // Fetching EDUCATION LEVEL 
    // const [fetchEducLevel, setEducLevel] = useState();
    // // Fetching Course List
    // const [fetchCourse, setFetchCourse] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    // display the Emp_IDNUMBER
    
    useEffect(() => {
 
        const fetchEmpData = async () => {
            try {
                const response = await axios.get(`/api/EmpFullInfo/${props.unique}`);
                setEmpInfo(response.data.EmpFullDetails[0]);
              
            } catch (error) {
                console.error("Error fetching employee data:", error); 

            } 
        };

        const debouncedFetch = debounce(fetchEmpData, 1000); // Adjust the delay time as needed

        debouncedFetch();

        // Return a cleanup function
        return () => debouncedFetch.cancel();
    }, [props.unique]);


    //fetching Education in Database
    // const fetchData = async () => {
    //     try {
    //         const respEducLevel = await axios.get(`/api/EducLevel`);
    //         const respEducCourse = await axios.get(`/api/EducCourse`);

    //         setEducLevel(respEducLevel.data.levelEduc);
    //         setFetchCourse(respEducCourse.data.courselist);

    //     } catch (error) {
    //         console.error("Error fetching employee data:", error);
    //     }
           
    
    // }

    // useEffect(() => {
    //     fetchData()
    // }, [])
    


    
// FETCHING DATA
     const [modeldata, setModaldata] = useState({

        SCHOOL: '', 
        DEGREE: '', 
        YRGRAD: '', 
        UEARNED: '', 
        FROMATT	: '', 
        TOATT: '',
        HONORS: '',
        levelcode: '',
    }) 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }



// STORED IN THE DATABASE 
    const submitEduc = async (e) => {
        e.preventDefault();

        const Level = modeldata.levelcode === '1' || modeldata.levelcode === '2' ? "Elementary" : modeldata.levelcode === '3' || modeldata.levelcode === '4' ? "Secondary" : modeldata.levelcode === '5' ? "Vocational/Trade Course" : modeldata.levelcode === '6' || modeldata.levelcode === '7' ? "Vocational/Trade Course" : modeldata.levelcode === '8' || modeldata.levelcode === '9' || modeldata.levelcode === '10' || modeldata.levelcode === '11' ? "Graduate Studies" : '';

        await axios.post(`/api/StoredEduc`, {
            IDNO: EmpInfo.IDNO,
            LEVEL: Level,
            SCHOOL: modeldata.SCHOOL,
            DEGREE: modeldata.DEGREE,
            YRGRAD: modeldata.YRGRAD,
            UEARNED: modeldata.UEARNED,
            FROMATT: modeldata.FROMATT,
            TOATT: modeldata.TOATT,
            HONORS: modeldata.HONORS,
            levelcode: modeldata.levelcode,
      
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                })
                setError(res.data.errors);
    
            }
            else {
                // console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    
                    props.fetchDataEduc(props.unique);
                    setShow(false);
                    setModaldata({
                        LEVEL: '',
                        SCHOOL: '',
                        DEGREE: '',
                        YRGRAD: '',
                        UEARNED: '',
                        FROMATT: '',
                        TOATT: '',
                        levelcode: ''
                    });

                });
            }
        
        })
    
    
    };

  return (
    <>
    
        <Button variant="primary" onClick={handleShow}>
            <i className="nav-icon fas fa-plus" />
        </Button>

        <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-book"/> Create Education Background</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitEduc}>

                <Modal.Body>
                   
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Level</label>
                            <select className={`custom-select ${error.levelcode ? 'is-invalid' : ''}`} name="levelcode" id="level_id" value={modeldata.levelcode} onChange={handleChange}> 
                                <option value="">Select</option>
                                {
                                        props.fetchEducLevel?.map((educlevel, index) => (
                                        <option key={index} value={educlevel.id}>{educlevel.description}</option>
                                    ))
                                }
                            </select>   
                            <div  className="invalid-feedback"> { error.levelcode } </div>      
                        </div>

                        <div className="form-group col-md-7">
                            <label>Name of School</label>
                            <input type="text" className={`form-control ${error.SCHOOL ? 'is-invalid' : ''}`} name="SCHOOL" id="school_id" value={modeldata.SCHOOL} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.SCHOOL } </div>
                        </div>              

                    </div>  
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-7">
                            <label>Basic Education/Degree/Courses</label>
                              <input list="educCourse" type="text" className={`form-control ${error.DEGREE ? 'is-invalid' : ''}`} name="DEGREE" id="basic_educ_id" value={modeldata.DEGREE} onChange={handleChange} />
                              <datalist id='educCourse'>
                                  <option value="">Select</option>
                                   {
                                        props.fetchCourse?.map((educCourses, index) => (
                                        <option key={index} value={educCourses.Description}>{educCourses.Description}</option>
                                    ))
                                }
                              </datalist>
                            <div  className="invalid-feedback"> { error.DEGREE } </div>
                        </div>
                        
                        <div className="form-group col-md-2">
                            <label>From</label>
                            <input type="text" className={`form-control ${error.FROMATT ? 'is-invalid' : ''}`} name="FROMATT" id="from_id" value={modeldata.FROMATT} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.FROMATT } </div>
                        </div>        

                        <div className="form-group col-md-2">
                            <label>To</label>
                            <input type="text" className={`form-control ${error.TOATT ? 'is-invalid' : ''}`} name="TOATT" id="to_id" value={modeldata.TOATT} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.TOATT } </div>
                        </div>
                                
                    </div>
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label>Highest Level/Units Earned</label>
                            <input type="text" className={`form-control ${error.UEARNED ? 'is-invalid' : ''}`} name="UEARNED" id="units_id" value={modeldata.UEARNED} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.UEARNED } </div>
                        </div>
                        
                        <div className="form-group col-md-2">
                            <label>Year Graduated</label>
                            <input type="text" className={`form-control ${error.YRGRAD ? 'is-invalid' : ''}`} name="YRGRAD" id="year_grad_id" value={modeldata.YRGRAD} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.YRGRAD } </div>
                        </div>        

                            <div className="form-group col-md-5">
                            <label>Scholarship/Academic Honors Recieved</label>
                            <input type="text" className="form-control" name="HONORS" id="honors_id" value={modeldata.HONORS} onChange={handleChange} />
                            {/* <div  className="invalid-feedback">  </div>   */}
                        </div> 
                                
                    </div>
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 
                   
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>



    </>
  )
}
