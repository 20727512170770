import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import EmployeeListSearchable from '../../Global/EmployeeListSearchable.component';
import ScheduleEditModal from '../../Office/EmployeeSchedule/EditModal.component';

import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export default function Page(props) {

    //let userIsMobile = window.innerWidth <= 768;
    const [userIsMobile, setUserIsMobile] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new DateObject());
    //const [selectedSchedule, setSelectedSchedule] = useState(1);

    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [employeeSchedule, setEmployeeSchedule] = useState([]);
    const [employeeRecords, setEmployeeRecords] = useState([]);
    const [dtrApproved, setDTRApproved] = useState(null);

    const [editSchedID, setEditSchedID] = useState(null);
    //const [editKeyID, setEditKeyID] = useState(null);

    const [showEdit, setShowEdit] = useState(false);
    const openEdit = () => setShowEdit(true);
    const closeEdit = () => setShowEdit(false);
    const closeFetchEdit = () => {
        fetchEmployeeSchedule(selectedEmployee);
        setShowEdit(false);
    }

    useEffect(() => {
        fetchEmployeeSchedule(selectedEmployee);
    }, [selectedEmployee, selectedDate]);

    useEffect(() => {
        setUserIsMobile(window.innerWidth <= 768);
    }, []);

    const setCheckSelectedEmployee = (emp) => {
        if (selectedEmployee !== emp) {
            setSelectedEmployee(emp);
        }
    }

    const fetchEmployeeSchedule = async (id) => {
        let emp = id[0];
        if (id !== null && id.length !== 0) {
            Swal.fire({
                icon: 'info',
                text: 'Loading Schedule...',
                showConfirmButton: false
            });
            await axios.get(`/api/employeeschedule/${emp.id}`, { params: { month: selectedDate.month.number, year: selectedDate.year } }).then(({ data }) => {
                if (data.result) {
                    setDTRApproved(data.approved);
                    setEmployeeSchedule(data.sched);
                    let recordsTemp = {};
                    data.records.forEach((rec) => {
                        let recTemp = new DateObject(rec.attendance);
                        let hours = recTemp.hour;
                        let minutes = recTemp.minute;
                        let ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = hours ? hours : 12;
                        hours = (hours < 10 ? '0' : '') + hours;
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        let recStr = recTemp.month.number + "/" + recTemp.day + "/" + recTemp.year + " " + hours + ":" + minutes + " " + ampm;
                        recordsTemp[' ' + rec.id] = recStr;
                    })
                    Swal.close();
                    setEmployeeRecords(recordsTemp);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const refreshDTR = async () => {
        //var start = new Date().getTime();
        if (employeeSchedule.length !== 0 && selectedEmployee !== null) {//&& employeeRecords !== 0){
            const formData = new FormData()
            formData.append('emp_id', selectedEmployee[0].id)
            formData.append('month', selectedDate.month.number)
            formData.append('year', selectedDate.year)
            Swal.fire({
                icon: 'info',
                text: 'Refreshing...',
                showConfirmButton: false
            });
            await axios.post(`/api/employeeschedule/refreshdtr`, formData).then(({ data }) => {
                // console.log('generate emp dtr', data);
                if (data.result) {
                    // Swal.update({
                    //     icon:"success",
                    //     text:data.message,
                    //     showConfirmButton:true
                    // });
                    Swal.close();
                    setEmployeeSchedule(data.sched);
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
                // var end = new Date().getTime();
                // var time = end - start;
                // alert('Execution time: ' + time);
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const scheduleRow = (sched, i) => {
        const date = new DateObject(sched.date);
        let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "HL", "WS", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];
        const checkDT = (dt, ms = false, scDt = sched.date) => {
            if (dt === "" || dt === null) {
                return "";
            }
            if (legends.includes(dt)) {
                return dt;
            }
            let res = ms ? new DateObject(parseInt(dt)).format("hh:mm") : new DateObject(`${scDt} ${dt}`).format("hh:mm");
            //console.log(dt, ms, scDt, res);
            return res;
        }

        let schedAmIn = checkDT(sched.sched_am_in);
        let schedAmOut = checkDT(sched.sched_am_out);
        let schedPmIn = checkDT(sched.sched_pm_in);
        let schedPmOut = checkDT(sched.sched_pm_out);
        let attAmIn = checkDT(sched.attend_am_in, true);
        let attAmOut = checkDT(sched.attend_am_out, true);
        let attPmIn = checkDT(sched.attend_pm_in, true);
        let attPmOut = checkDT(sched.attend_pm_out, true);
        let otCutOff = checkDT(sched.ot_cutoff);
        let otIn = checkDT(sched.ot_in, true);
        let otOut = checkDT(sched.ot_out, true);

        let otIndicatorMap = {
            0 : "",
            1 : "",
            2 : "N",
            3 : "D/N",
        }

        let utTot = 0;
        if (sched.undertime) {
            let ut = sched.undertime.split(",").map(Number);
            utTot = ut[0] + ut[1] + ut[2] + ut[3];
        }

        let empApp = null;
        let offApp = null;

        if (!Array.isArray(dtrApproved)) {
            if (dtrApproved) {
                empApp = dtrApproved.emp_approved;
                offApp = dtrApproved.off_approved;
            }
        } else {
            if (i < 15) {
                if (dtrApproved[0]) {
                    empApp = dtrApproved[0].emp_approved;
                    offApp = dtrApproved[0].off_approved;
                }
            } else {
                if (dtrApproved[1]) {
                    empApp = dtrApproved[1].emp_approved;
                    offApp = dtrApproved[1].off_approved;
                }
            }
        }

        return (
            <Row key={i} className="border-bottom border-1 border-dark text-center">
                <Col className={`border-end border-2 border-dark text-end ${empApp || offApp ? "" : "editSchedButton"}`} xs="2" onClick={empApp || offApp ? undefined
                    : (e) => {
                        setEditSchedID(sched.id);
                        openEdit();
                    }}>
                    {empApp ? offApp ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                    <Form.Label className={`p-0 m-0 ${empApp ? offApp ? "text-success" : "text-primary" : ""}`}>{`${date.weekDay.shortName}, ${date.month.name} ${date.day}`}</Form.Label>
                </Col>
                <Col className={`border-end border-2 border-dark ${sched.sched_approved === 1 ? "" : "text-danger"}`} xs="4">
                    <Row>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedAmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedAmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedPmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedPmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{otIndicatorMap[sched.ot_indicator]}
                        </Col>
                        <Col className="p-0" xs="2">
                            &nbsp;{otCutOff}
                        </Col>
                    </Row>
                </Col>
                <Col className="border-end border-2 border-dark" xs="4">
                    <Row>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.am_in_entry === 0 ? "" : sched.am_in_entry === 1 ? " text-primary" : sched.am_in_entry === 2 ? " text-danger" : sched.am_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark bg-light" + (sched.am_out_entry === 0 ? "" : sched.am_out_entry === 1 ? " text-primary" : sched.am_out_entry === 2 ? " text-danger" : sched.am_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.pm_in_entry === 0 ? "" : sched.pm_in_entry === 1 ? " text-primary" : sched.pm_in_entry === 2 ? " text-danger" : sched.pm_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark p-0 bg-light" + (sched.pm_out_entry === 0 ? "" : sched.pm_out_entry === 1 ? " text-primary" : sched.pm_out_entry === 2 ? " text-danger" : sched.pm_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.ot_in_entry === 0 ? "" : sched.ot_in_entry === 1 ? " text-primary" : sched.ot_in_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otIn}
                        </Col>
                        <Col className={"p-0 bg-light" + (sched.ot_out_entry === 0 ? "" : sched.ot_out_entry === 1 ? " text-primary" : sched.ot_out_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otOut}
                        </Col>
                    </Row>
                </Col>
                <Col className="p-0 border-end border-2 border-dark" xs="1">
                    &nbsp;{utTot}
                </Col>
                <Col className="border-end border-2 border-dark" xs="1">
                    &nbsp;{sched.overtime}
                </Col>
            </Row>
        )
    }

    const renderScheds = () => {
        //let start = performance.now();
        let result = employeeSchedule.map((sched, i) => (
            scheduleRow(sched, i)
        ));
        //let end = performance.now();
        //let time = end - start;
        //console.log("Execution time: " + time + " milliseconds");
        return result;
    }

    return (
        <div className="container-fluid page-container h-100 w-100 p-0">

            <Modal dialogClassName="edit-schedule-modal" show={showEdit} onHide={closeEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ScheduleEditModal
                        closeFetchEdit={closeFetchEdit}
                        sched={employeeSchedule.find(sched => sched.id === editSchedID)}
                        records={employeeRecords}
                    //schedID={editSchedID}
                    //keyID={editKeyID}
                    />
                </Modal.Body>
            </Modal>

            <Row className="h-100 w-100 p-0 m-0">
                {!userIsMobile ? <Col className="h-100 p-0" xs={12} sm="2">
                    <EmployeeListSearchable
                        selectedEmployee={selectedEmployee}
                        setCheckSelectedEmployee={setCheckSelectedEmployee}
                        allowMultiple={false}
                        showUnique={true}
                    />
                </Col> : null}
                <Col className="p-0 h-100" xs={12} sm={10}>
                    <Row className={`page-index-controls m-0 w-100`} style={userIsMobile ? {} : { height: '50px' }}>
                        <Col className="p-0" xs={12}>
                            <Row className="m-0">
                                <Col className="p-0" xs={12} md={"auto"}>
                                    <div style={{ paddingLeft: 3 }} className="d-flex flex-column align-items-left justify-content-center h-100">
                                        <div style={{ height: '30%', fontSize: '8pt', fontWeight: 'bold', paddingBottom: 4 }}>ATTENDANCE PERIOD:</div>
                                        <DatePicker className="m-auto"
                                            style={{ height: `${userIsMobile ? "100%" : "70%"}` }}
                                            format="MMMM YYYY"
                                            value={selectedDate}
                                            onChange={setSelectedDate}
                                            onlyMonthPicker
                                            inputClass="form-control text-center" />
                                    </div>
                                </Col>
                                <Col className="mt-1 mb-1 p-0" xs={12} md={true}>
                                    <Row className="m-0">
                                        <Col className={`${!userIsMobile ? "ms-auto " : "ms-1 me-1"}mt-1 mb-1 ps-0 pe-1`} xs={"auto"} >
                                            <Button className="" variant="primary" size={userIsMobile ? "sm" : "md"} onClick={refreshDTR}>Refresh DTR</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {userIsMobile ? <Row className="h-50 p-1"><Col className="h-100" xs={12} sm="auto">
                        <EmployeeListSearchable
                            selectedEmployee={selectedEmployee}
                            setCheckSelectedEmployee={setCheckSelectedEmployee}
                            allowMultiple={false}
                            showUnique={true}
                        />
                    </Col></Row> : null}
                    <Row className="m-0 p-1" style={userIsMobile ? {} : { height: 'calc(100% - 50px)' }}>
                        <Col className="h-100 p-0">
                            <div className="overflow-auto" style={userIsMobile ? {} : { height: '100%' }}>
                                <div className="card overflow-auto">
                                    <div className="card-body" style={{ minWidth: "1100px" }}>
                                        <Row>
                                            <Col>
                                                <Row className="border border-2 border-bottom-2 border-dark text-center">
                                                    <Col className="border-end border-2 border-dark d-flex align-items-center" xs="2">
                                                        <Form.Label className="m-auto">Employee Schedule</Form.Label>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark" xs="4">
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col>
                                                                <Form.Label className="m-auto">Schedule</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col className="border-end border-1 border-dark" >
                                                                <Form.Label className="m-auto">AM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark" >
                                                                <Form.Label className="m-auto">PM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1">
                                                                <Form.Label className="m-auto">OT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IND</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>CUT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark" xs="4">
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col>
                                                                <Form.Label className="m-auto">Attendance</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row className="border-bottom border-1 border-dark" >
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto">AM</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto">PM</Form.Label>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label className="m-auto">OT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                            <Col className="border-end border-1 border-dark">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                            </Col>
                                                            <Col className="bg-light">
                                                                <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Undertime</Form.Label>
                                                    </Col>

                                                    <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                                        <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Overtime</Form.Label>
                                                    </Col>

                                                    {/* <Col className="d-flex align-items-center" xs="2">
                                                    <Form.Label className="m-auto">Remarks</Form.Label>
                                                </Col> */}

                                                </Row>

                                                {/* employeeSchedules */}
                                                <Row className="border-end border-start border-2 border-dark">
                                                    <Col>
                                                        {renderScheds()}
                                                    </Col>
                                                </Row>

                                                {employeeSchedule.length !== 0 ? ""
                                                    // <Row className="border border-2 border-dark">
                                                    //     <Col className="border-end border-2 border-dark" xs="2">
                                                    //         {`Total:  work days`}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="4">
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="4">
                                                    //         {'Actual:  work days'}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="1">
                                                    //         {` min`}
                                                    //     </Col>
                                                    //     <Col className="border-end border-2 border-dark" xs="1">
                                                    //         {` min`}
                                                    //     </Col>
                                                    //     {/* <Col className="" xs="2">

                                                    //     </Col> */}
                                                    // </Row>
                                                    :
                                                    <Row className="border border-2 border-dark">
                                                        <Col className="text-center">
                                                            <Form.Label className="text-danger">
                                                                No Schedule Found
                                                            </Form.Label>
                                                        </Col>
                                                    </Row>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}