import axios from '../../../../services/axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

export default function QuestionsForm() {


    const { unique } = useParams('');
    const [isLoading, setisLoading] = useState(false);
    const initialValues = {
        thirddegreec: 0, 
        fourthdegreec: 0, 
        guiltyc: 0,
        chargec: 0,
        convicc: 0,
        separatec: 0,
        candidatec: 0,
        resignedc: 0,
        immigrantc: 0,
        indegneousc: 0,
        unablec: 0,
        soloc: 0, 
    };
    const [fetchQuestioner, setQuestioner] = useState(initialValues);
    const [fetchInputVal, setInputVal] = useState({
        unique: "",
        idno: "", 
        fourthdegree: "",
        guilty: "",
        charge: "",
        chargedate: "",
        chargestatus: "",
        convic: "",
        separate: "",
        candidate: "",
        resigned: "",
        immigrant: "",
        indegneous: "",
        unable: "",
        solo: "",
    });



    
const fetchDataQuestioner = async (unique) => {
  
    setisLoading(true);    
  try {
    const response = await axios.get(`/api/EmpQues/${unique}`);
    
    setQuestioner(response.data.QuestionDisplay[0] || "");
    setInputVal(response.data.QuestionDisplay[0] || "");
      
  } catch (error) {
      console.log(error);
      
  } finally {
      setisLoading(false)
  }
};

useEffect(() => {
    const debouncedFetch = debounce(fetchDataQuestioner, 1000); // Adjust the delay time as needed
    // Invoke the debounced function
    debouncedFetch(unique);

    // Return a cleanup function
    return () => debouncedFetch.cancel();
}, [unique]);


    
// handle for radio button 
const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestioner({ ...fetchQuestioner, [name]: parseInt(value) });
};
    
const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputVal({ ...fetchInputVal, [name]: value });
}
    

// changing the data

    const submitData = async (e) => {
        e.preventDefault();

        await axios.post(`/api/storeQuestion`, {
            id: fetchInputVal.unique,
            idno: fetchInputVal.idno,
            thirddegreec: fetchQuestioner.thirddegreec,
            fourthdegreec: fetchQuestioner.fourthdegreec,
            fourthdegree: fetchQuestioner.fourthdegreec === 0 ? " " : fetchInputVal.fourthdegree,
            guiltyc: fetchQuestioner.guiltyc,
            guilty: fetchQuestioner.guiltyc === 0 ? " " : fetchInputVal.guilty,
            chargec: fetchQuestioner.chargec,
            chargedate: fetchQuestioner.chargec === 0 ? " " : fetchInputVal.chargedate,
            chargestatus: fetchQuestioner.chargec === 0 ? " " : fetchInputVal.chargestatus,
            convicc: fetchQuestioner.convicc,
            convic: fetchQuestioner.convicc === 0 ? " " : fetchInputVal.convic,
            separatec: fetchQuestioner.separatec,
            separate: fetchQuestioner.separatec === 0 ? " " : fetchInputVal.separate,
            candidatec: fetchQuestioner.candidatec,
            candidate: fetchQuestioner.candidatec === 0 ? " " : fetchInputVal.candidate,
            resignedc: fetchQuestioner.resignedc,
            resigned: fetchQuestioner.resignedc === 0 ? " " : fetchInputVal.resigned,
            immigrantc: fetchQuestioner.immigrantc,
            immigrant: fetchQuestioner.immigrantc === 0 ? " " : fetchInputVal.immigrant,
            indegneousc: fetchQuestioner.indegneousc,
            indegneous: fetchQuestioner.indegneousc === 0 ? " " : fetchInputVal.indegneous,
            unablec: fetchQuestioner.unablec,
            unable: fetchQuestioner.unablec === 0 ? " " : fetchInputVal.unable,
            soloc: fetchQuestioner.soloc,
            solo: fetchQuestioner.soloc === 0 ? " " : fetchInputVal.solo,
            
        }).then((res) => {
           
            Swal.fire({
                icon: "success",
                title: res.data.message,
            }).then(() => {
                fetchDataQuestioner(unique);
            });
        });

    }
    
    
    

  return (
    <div>
        {
            isLoading ? (
                <div className='overlay-wrapper'>
                     <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                    <form >
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Are you related by consanguinity or affinity to the appointing or recommending authority,
                                or to the chief of bureau or office or to the person who has immediate supervision over you in
                                the Office, Bureau or Department where you will be apppointed,</label>
                                
                                <label>a. within the third degree?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="thirddegreec" id="ques1_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques1_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="thirddegreec" id="ques1_id_no" value="0"/>
                                        <label className="form-check-label" htmlFor="ques1_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    


                                <label>b. within the fourth degree (for Local Government Unit - Career Employees)?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="fourthdegreec" id="ques2_id_yes" value="1"  />
                                        <label className="form-check-label" htmlFor="ques2_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="fourthdegreec" id="ques2_id_no" value="0"  />
                                        <label className="form-check-label" htmlFor="ques2_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                            <input type="text" className="form-control col-md-5" name="fourthdegree" /> 
                                
                                <div className="invalid-feedback"> </div>
                            </div>
                        </div>
                        {/* end form-row */}
                            <hr /> 
                            

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>a.Have you ever been found guilty of any administrative offense?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="guiltyc" id="ques3_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques3_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="guiltyc" id="ques3_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques3_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="guilty"  />            
                                    <div className="invalid-feedback"></div>
                                
                                <label>b. Have you been criminally charged before any court?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="chargec" id="ques4_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques4_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="chargec" id="ques4_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques4_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                            </div>   

                            <div className="form-group col-md-2">
                                <label>Date Filed</label> 
                                <input type="date" className="form-control" name="chargedate"/>  
                                <div className="invalid-feedback"> </div>
                            </div>
                            <div className="form-group col-md-5">
                                <label>Status of Case/s:</label> 
                                <input type="text" className="form-control" name="chargestatus" /> 
                                <div className="invalid-feedback"> </div>
                            </div>      
                
                        </div>        
                        {/* end form-row */}
                            <hr /> 

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Have you ever been convicted of any crime or violation of any law, decree,
                                ordinance or regulation by any court or tribunal?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="convicc" id="ques5_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques5_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input " type="radio" name="convicc" id="ques5_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques5_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="convic" />            
                                    <div className="invalid-feedback"> </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Have you ever been separated from the service in any of the following modes: resignation, retirement, dropped from the rolls,
                                    dismissal, termination, end of term, finished contract or phased out (abolition) in the public or private sector?
                                </label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="separatec" id="ques6_id_yes" value="1"/>
                                        <label className="form-check-label" htmlFor="ques6_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="separatec" id="ques6_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques6_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="separate" />            
                                    <div className="invalid-feedback"> </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                a. Have you ever been a candidate in a national or local election held within the last year
                                    (except Barangay election)?
                                </label>
                                    
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="candidatec" id="ques7_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques7_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="candidatec" id="ques7_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques7_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5 " name="candidate" />            
                                <div className="invalid-feedback"></div>
                                
                                <label>
                                    b. Have you resigned from the government service during the three (3)-month period before the last election to promote/actively campaign
                                    for a national or local candidate?
                                </label>
                                    
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="resignedc" id="ques8_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques8_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="resignedc" id="ques8_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques8_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    
                                <label>If YES, give Details</label> 
                                <input type="text" className="form-control col-md-5 " name="resigned" />
                                <div className="invalid-feedback">  </div>
                            </div>   

                        
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Have you acquired the status of an immigrant or permanent resident of another country?
                                </label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="immigrantc" id="ques9_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques9_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="immigrantc" id="ques9_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques9_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details(country)</label>
                                    <input type="text" className="form-control col-md-5" name="immigrant" />            
                                    <div className="invalid-feedback">  </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />
                            
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for Disabled Persons (RA 7277); and (c) 
                                    Solo Parents Welfare Act of 2000 (RA 8972), 
                                    please answer the following items:
                                </label>
                                    
                                <label>a. Are you a member of any indigenous group?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="indegneousc" id="ques10_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques10_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="indegneousc" id="ques10_id_no" value="0" />
                                        <label className="form-check-label" htmlFor="ques10_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="indegneous" />
                                <div className="invalid-feedback"> </div>
                                
                                <label>b. Are you a person with disability?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="unablec" id="ques11_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques11_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="unablec" id="ques11_id_no" value="0"  />
                                        <label className="form-check-label" htmlFor="ques11_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="unable" />
                                <div className="invalid-feedback"> </div>
                                
                                <label>c. Are you a solo parent?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="soloc" id="ques12_id_yes" value="1" />
                                        <label className="form-check-label" htmlFor="ques12_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="soloc" id="ques12_id_no" value="0"/>
                                        <label className="form-check-label" htmlFor="ques12_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="solo"  />
                                <div className="invalid-feedback">  </div>
                            </div>
                        </div>
                        {/* end form-row */}
                        <div className="card-footer">
                            <div className="float-right">
                                <button type="submit" className="btn btn-success" >Save Changes</button>    
                            </div>       
                        </div>  
                        <hr />

                                
                    </form>
                    
                </div>   
            ) : (
                <div>
                    <form onSubmit={submitData}>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Are you related by consanguinity or affinity to the appointing or recommending authority,
                                or to the chief of bureau or office or to the person who has immediate supervision over you in
                                the Office, Bureau or Department where you will be apppointed,</label>
                                
                                <label>a. within the third degree?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="thirddegreec" id="ques1_id_yes" value="1" checked={fetchQuestioner.thirddegreec === 1} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="ques1_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="thirddegreec" id="ques1_id_no" value="0" checked={fetchQuestioner.thirddegreec === 0}  onChange={ handleChange }  />
                                        <label className="form-check-label" htmlFor="ques1_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    


                                <label>b. within the fourth degree (for Local Government Unit - Career Employees)?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="fourthdegreec" id="ques2_id_yes" value="1" checked={fetchQuestioner.fourthdegreec === 1} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="ques2_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="fourthdegreec" id="ques2_id_no" value="0" checked={fetchQuestioner.fourthdegreec === 0} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="ques2_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                            <input type="text" className="form-control col-md-5" name="fourthdegree" value={ fetchQuestioner.fourthdegreec === 1 ? fetchInputVal.fourthdegree || '' : '' } onChange={handleChangeInput} disabled={fetchQuestioner.fourthdegreec !== 1} /> 
                                
                                <div className="invalid-feedback"> </div>
                            </div>
                        </div>
                        {/* end form-row */}
                         <hr /> 
                  

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>a.Have you ever been found guilty of any administrative offense?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="guiltyc" id="ques3_id_yes" value="1" checked={fetchQuestioner.guiltyc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques3_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="guiltyc" id="ques3_id_no" value="0" checked={fetchQuestioner.guiltyc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques3_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="guilty" value={fetchQuestioner.guiltyc === 1 ? fetchInputVal.guilty || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.guiltyc !== 1 } />            
                                    <div className="invalid-feedback"></div>
                                
                                <label>b. Have you been criminally charged before any court?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="chargec" id="ques4_id_yes" value="1" checked={fetchQuestioner.chargec === 1} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="ques4_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="chargec" id="ques4_id_no" value="0" checked={fetchQuestioner.chargec === 0} onChange={handleChange} />
                                        <label className="form-check-label" htmlFor="ques4_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                            </div>   

                            <div className="form-group col-md-2">
                                <label>Date Filed</label> 
                                <input type="date" className="form-control" name="chargedate" value={fetchQuestioner.chargec === 1 ? fetchInputVal.chargedate || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.chargec !== 1 }/>  
                                <div className="invalid-feedback"> </div>
                            </div>
                            <div className="form-group col-md-5">
                                <label>Status of Case/s:</label> 
                                <input type="text" className="form-control" name="chargestatus" value={fetchQuestioner.chargec === 1 ? fetchInputVal.chargestatus || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.chargec !== 1 }/> 
                                <div className="invalid-feedback"> </div>
                            </div>      
                
                        </div>        
                        {/* end form-row */}
                            <hr /> 

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>Have you ever been convicted of any crime or violation of any law, decree,
                                ordinance or regulation by any court or tribunal?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="convicc" id="ques5_id_yes" value="1" checked={fetchQuestioner.convicc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques5_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input " type="radio" name="convicc" id="ques5_id_no" value="0" checked={fetchQuestioner.convicc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques5_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="convic" value={fetchQuestioner.convicc === 1 ? fetchInputVal.convic || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.convicc !== 1 }/>            
                                    <div className="invalid-feedback"> </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Have you ever been separated from the service in any of the following modes: resignation, retirement, dropped from the rolls,
                                    dismissal, termination, end of term, finished contract or phased out (abolition) in the public or private sector?
                                </label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="separatec" id="ques6_id_yes" value="1" checked={fetchQuestioner.separatec === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques6_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="separatec" id="ques6_id_no" value="0" checked={fetchQuestioner.separatec === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques6_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                    <input type="text" className="form-control col-md-5" name="separate" value={fetchQuestioner.separatec === 1 ? fetchInputVal.separate || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.separatec !== 1 }/>            
                                    <div className="invalid-feedback"> </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                a. Have you ever been a candidate in a national or local election held within the last year
                                    (except Barangay election)?
                                </label>
                                    
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="candidatec" id="ques7_id_yes" value="1" checked={fetchQuestioner.candidatec === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques7_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="candidatec" id="ques7_id_no" value="0" checked={fetchQuestioner.candidatec === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques7_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5 " name="candidate" value={fetchQuestioner.candidatec === 1 ? fetchInputVal.candidate || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.candidatec !== 1 }/>            
                                <div className="invalid-feedback"></div>
                                
                                <label>
                                    b. Have you resigned from the government service during the three (3)-month period before the last election to promote/actively campaign
                                    for a national or local candidate?
                                </label>
                                    
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="resignedc" id="ques8_id_yes" value="1" checked={fetchQuestioner.resignedc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques8_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="resignedc" id="ques8_id_no" value="0" checked={fetchQuestioner.resignedc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques8_id_no">NO</label>
                                    </div> 
                                    <div className="text-sm text-danger"></div>
                                </div>
                                    
                                <label>If YES, give Details</label> 
                                <input type="text" className="form-control col-md-5 " name="resigned" value={fetchQuestioner.resignedc === 1 ? fetchInputVal.resigned || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.resignedc !== 1 }/>
                                <div className="invalid-feedback">  </div>
                            </div>   

                        
                        </div>        
                        {/* end form-row */}
                            <hr />

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Have you acquired the status of an immigrant or permanent resident of another country?
                                </label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="immigrantc" id="ques9_id_yes" value="1" checked={fetchQuestioner.immigrantc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques9_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="immigrantc" id="ques9_id_no" value="0" checked={fetchQuestioner.immigrantc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques9_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details(country)</label>
                                    <input type="text" className="form-control col-md-5" name="immigrant" value={fetchQuestioner.immigrantc === 1 ? fetchInputVal.immigrant || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.immigrantc !== 1 }/>            
                                    <div className="invalid-feedback">  </div>
                            </div>   
                        </div>        
                        {/* end form-row */}
                            <hr />
                        
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label>
                                    Pursuant to: (a) Indigenous People's Act (RA 8371); (b) Magna Carta for Disabled Persons (RA 7277); and (c) 
                                    Solo Parents Welfare Act of 2000 (RA 8972), 
                                    please answer the following items:
                                </label>
                                    
                                <label>a. Are you a member of any indigenous group?</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="indegneousc" id="ques10_id_yes" value="1" checked={fetchQuestioner.indegneousc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques10_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="indegneousc" id="ques10_id_no" value="0" checked={fetchQuestioner.indegneousc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques10_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="indegneous" value={fetchQuestioner.indegneousc === 1 ? fetchInputVal.indegneous || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.indegneousc !== 1 }/>
                                <div className="invalid-feedback"> </div>
                                
                                <label>b. Are you a person with disability?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="unablec" id="ques11_id_yes" value="1" checked={fetchQuestioner.unablec === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques11_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="unablec" id="ques11_id_no" value="0"  checked={fetchQuestioner.unablec === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques11_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="unable" value={fetchQuestioner.unablec === 1 ? fetchInputVal.unable || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.unablec !== 1 }/>
                                <div className="invalid-feedback"> </div>
                                
                                <label>c. Are you a solo parent?</label>      
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="soloc" id="ques12_id_yes" value="1" checked={fetchQuestioner.soloc === 1} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques12_id_yes">YES</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="soloc" id="ques12_id_no" value="0" checked={fetchQuestioner.soloc === 0} onChange={handleChange}/>
                                        <label className="form-check-label" htmlFor="ques12_id_no">NO</label>
                                    </div>
                                    <div className="text-sm text-danger"></div>
                                </div>
                                <label>If YES, give Details</label>
                                <input type="text" className="form-control col-md-5" name="solo"  value={fetchQuestioner.soloc === 1 ? fetchInputVal.solo || '' : ''} onChange={ handleChangeInput } disabled={fetchQuestioner.soloc !== 1 }/>
                                <div className="invalid-feedback">  </div>
                            </div>
                        </div>
                        
                        {/* end form-row */}
                        <div className="card-footer">
                            <div className="float-right">
                                <button type="submit" className="btn btn-success" >Save Changes</button>    
                            </div>       
                        </div>  
                        <hr />

                    
                    </form>
                </div>
            ) 
            
        }

    </div>
  )
}
