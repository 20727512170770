import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

const ChildrenEditModal = (props) => {


    const [modalData, setModalData] = useState({
        id: '',
        IDNO: '',
        child_name: '',
        child_bday: '',
    });

    const [error, setError] = useState([]);
    

    useEffect(() => {
        if (props.data) {
            
            setModalData({
                id: props.data.id,
                IDNO: props.data.IDNO,
                child_name: props.data.children_name,
                child_bday: props.data.children_bday,
            });
        }
    }, [props.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let age = ""

        if (name === "child_bday" && value !== "") {
            // Calculate age based on the entered birthday
            const birthdate = new Date(value);
            const today = new Date();
            const ageDiff = today.getFullYear() - birthdate.getFullYear();
            age = ageDiff.toString();
        }

        setModalData((prevData) => ({ ...prevData, [name]: value, child_age: age }));
    };


    const updateData = async (e) => {
        e.preventDefault();

        await axios.post(`/api/updateUnmarriedChild`, {
            id: modalData.id,
            IDNO: modalData.IDNO,
            children_name: modalData.child_name,
            children_bday: modalData.child_bday,

        }).then((res) => {

            if (res.data.status === 422) {
                
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                })
                setError(res.data.errors);

            } else {
                 
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                        
                    props.handleEditClose(false);
                    props.fetchDataSALN(props.unique);
                 
                })

            }


        });
    }
    
  return (
    <div>
        
        <Modal show={props.show} onHide={props.handleEditClose}>
            <Modal.Header>
            <Modal.Title>Edit Children Details </Modal.Title>
            </Modal.Header>
            <Form onSubmit={updateData}>
            <Modal.Body>
                        
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Children Name</label>
                            <input type="text" className={`form-control ${error.children_name ? 'is-invalid' : ''}`} name="child_name" value={modalData.child_name ?? " "} onChange={handleChange}/>
                            <div  className="invalid-feedback">{error.children_name}</div>
                        </div>    

                        <div className="form-group col-md-6">
                            <label>Date of Birth</label>
                            <input type="date" className={`form-control ${error.children_bday ? 'is-invalid' : ''}`} name="child_bday" value={modalData.child_bday ?? " "} onChange={handleChange}/>
                            <div  className="invalid-feedback">{error.children_bday}</div>
                            </div>    
                            
                        <div className="form-group col-md-6">
                            <label>Age</label>
                              <input type="text" className="form-control" name="child_age"
                                  value={
                                    modalData.child_bday
                                        ? new Date().getFullYear() - new Date(modalData.child_bday).getFullYear()
                                        : ''
                                }
                            
                            readOnly/>
                        </div>    
                            
                    </div>
                    {/* form-row */}
                    
            </Modal.Body>
            <Modal.Footer>
            <Button variant="outline-danger" onClick={props.handleEditClose}>
                Close
            </Button>
            <Button type="submit" variant="success" >
                Save Changes
            </Button>
            
            </Modal.Footer>
            </Form>
        </Modal>  
    
    </div>
  )
}

export default ChildrenEditModal