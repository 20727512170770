import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/form'
import { debounce } from 'lodash';
import Swal from 'sweetalert2';


export default function UpdateEmployeeID(props) {
  
  
  const [fetchIDNO, setFetchIDNO] = useState('');
  const [NewIDNO, setNewIDNO] = useState('');
  const [error, setError] = useState([]); 
  
  const fetchData = async (uniqueID) => {
    try {
      const resp = await axios.get(`/api/EmpFullInfo/${uniqueID}`);
      setFetchIDNO(resp.data.EmpFullDetails[0].IDNO);
    
    } catch (error) {
      console.log(error);
      
    }
  }

    useEffect(() => {
        const debouncedFetch = debounce(fetchData, 1000); // Adjust the delay time as needed
        // Invoke the debounced function
        if (props.unique) {
          debouncedFetch(props.unique);
        }
        // Return a cleanup function
        return () => debouncedFetch.cancel();
    }, [props.unique]);
  
  
  const UpdateData = async (e) => {
    e.preventDefault();

    await axios.post(`/api/updateIDNO`, {
      IDNO: fetchIDNO, 
      newIDNO: NewIDNO,
    }).then((res) => {
             if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'New Employee IDNO is required',
                    })
                setError(res.data.errors);
                }
            else {
                // console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.handleClosedModal()
                    window.location.reload();
                    setError('');
                })
            }
        
    })
  }


  return (
    <>
       <Modal show={props.show} onHide={props.handleClosedModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Employee ID</Modal.Title>
        </Modal.Header>
        <Form onSubmit={UpdateData}>
        <Modal.Body>
            
        <div className="input-group mb-3">
          <span className="input-group-text">Current Employee IDNO:</span>
              <input type="text" className="form-control" value={fetchIDNO} readOnly/>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text">New Employee IDNO:</span>
          <input type="text" className={`form-control ${error.newIDNO ? 'is-invalid' : ''}`} name="newIDNO" value={NewIDNO} onChange={(e) => setNewIDNO(e.target.value)}/>
         <div className="invalid-feedback"> { error.newIDNO } </div>
        </div>

        </Modal.Body>
          <Modal.Footer>
    
            <Button type="submit"  variant="success">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}
