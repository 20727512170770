import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import Button from 'react-bootstrap/Button';
import HealthModal from '../components/HealthModal';
import HealthEditModal from '../components/HealthEditModal';

import { format } from 'date-fns';
import { debounce } from 'lodash';

export default function EmployeeHealth(props) {


    
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    
    const [fetchHealth, setfetchHealth] = useState([]);
    const [editDataModal, setEditDataModal] = useState([]);
    
    const [isLoading, setisLoading] = useState(false);

    // create
    const handleCreateModal = () => {
        setShowModal(true);
    };
    const handleClose = () => { 
        setShowModal(false);
    };

    // edit modal
    const handleEditModal = (data) => {
        setEditDataModal(data);
        setShowEditModal(true);
    };
    const handleEditClose = () => {
         setShowEditModal(false);
    };

    const fetchDataHealth = async (uniqueId) => {
        
        setisLoading(true);

        try {
            const resp = await axios.get(`/api/EmpHealth/${uniqueId}`);
          
            setfetchHealth(resp.data.EmpHealth);
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }  
    };
    

     useEffect(() => {
        const debouncedFetch = debounce(fetchDataHealth, 1000); // Adjust the delay time as needed
        // Invoke the debounced function
        if (props.unique) {
          debouncedFetch(props.unique);
        }
        // Return a cleanup function
        return () => debouncedFetch.cancel();
     }, [props.unique]);
    
    

  return (
    <div>
         <div className="row">
            <div className="col-sm-6">
                <h4 className="m-2"><i className="nav-icon fa fa-file-medical"/> Health Record</h4>
            </div>{/* /.col */}   
            <div className="col-sm-6">
                  <div className="float-right">
                      <Button onClick={handleCreateModal}> <i className="nav-icon fas fa-plus" /></Button>
                      <HealthModal show={showModal} handleClose={handleClose} fetchDataHealth={fetchDataHealth} unique={props.unique} />
                </div>
            </div>{/* /.col */} 
        </div>{/* /.row */}
        
        
        <hr /> 
          
          {
              isLoading ? (
                  <div className='overlay-wrapper'>
                      <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                      
                    
                      <div className="mx-1">
                          <div className="float-right">
                              <Button variant="outline-primary btn-sm" > <i className="nav-icon fas fa-edit" /></Button>
                          </div>
                      </div>
                
                      <br />

                      <div className="form-row">
                              
                          <label className="col-sm-2 col-form-label">
                                   
                          </label>
                            
                          <div className="col-md-10">
                            
                              <div className="form-row">
                                       
                                  <div className="col-sm-4">
                                      <label>BMI: </label> <br />
                                      <label>Waist Line: </label> <br />
                                      <label>Pulse Rate: </label>  <br />
                                  </div>

                                  <div className="col-sm-3">
                                      <label>Blood Pressure:</label> <br />
                                      <label>Blood Sugar: </label> <br />
                                      <label>Cholesterol: </label> <br />
                                  </div>
                                    
                                  {/* <div className="col-sm-4">
                                        <label>Uric Acid: </label> <span className="badge badge-danger text-wrap ">120/50</span>
                                    </div> */}

                              </div>
                            
                          </div>


                      </div>
                        
                      <hr />
                 
                      
                  </div>
                
              ) : 
                (
                
                      
            fetchHealth.length > 0 ?
                fetchHealth.map((data) => {

                    const {
                        id,
                        period,
                        BMI,
                        waistline,
                        pulserate,
                        BP,
                        sugarlevel,
                        cholesterol,
                    } = data;

                
                    // color status of BMI
                    const statusBMI = (BMI) => {
                
                        let bmi = BMI;

                        if (bmi < 18.5) {  // underweight
                            return "badge badge-secondary text-wrap";
                        } else if (bmi <= 24.9) { //normal range
                            return "badge badge-primary text-wrap";
                        } else if (bmi <= 29.9) {
                            return "badge badge-warning text-wrap";
                        } else if (bmi >= 30) {
                            return "badge badge-danger text-wrap";
                        } else {
                            return null;
                        }
                    };

                
                    const BloodPressure = (BP) => {
                        const Systolic = BP && BP.split("/")[0];
                        const Diastolic = BP && BP.split("/")[1];

                        if (Systolic <= 120 && Diastolic <= 80) {
                            return "badge badge-success text-wrap"
                        } else if (Systolic <= 129 && Diastolic <= 80) {
                            return "badge badge-info text-wrap"
                        } else if (Systolic <= 139 || Diastolic <= 89) {
                            return "badge badge-warning text-wrap"
                        } else if (Systolic <= 140 || Diastolic <= 90) {
                            return "badge badge-secondary text-wrap"
                        } else if (Systolic > 140 || Diastolic > 90) {
                            return "badge badge-danger text-wrap"
                        } else {
                            return null
                        }

                    }

                    return (
                        <div key={id}>
                    
                            <div className="mx-1">
                                <div className="float-right">
                                    <Button variant="outline-primary btn-sm" onClick={() => handleEditModal(data)} > <i className="nav-icon fas fa-edit" /></Button>
                                  
                                </div>
                            </div>
            
                            <br />

                            <div className="form-row">
                            
                                <label className="col-sm-2 col-form-label">
                                    {format(new Date(period), 'MMMM d, yyyy')}
                                </label>
                        
                                <div className="col-md-10">
                        
                                    <div className="form-row">
                                    
                                        <div className="col-sm-4">
                                            <label>BMI: <span className={statusBMI(BMI)}>{BMI === null ? '' : BMI}</span></label> <br />
                                            <label>Waist Line: <span>{waistline === null ? '' : waistline}</span></label> <br />
                                            <label>Pulse Rate: <span>{pulserate === null ? '' : pulserate}</span></label>  <br />
                                        </div>

                                        <div className="col-sm-3">
                                            <label>Blood Pressure: <span className={BloodPressure(BP)}>{BP === null ? '' : BP}</span></label> <br />
                                            <label>Blood Sugar: <span>{sugarlevel === null ? '' : sugarlevel}</span></label> <br />
                                            <label>Cholesterol: <span>{cholesterol === null ? '' : cholesterol}</span> </label> <br />
                                        </div>
                                
                                        {/* <div className="col-sm-4">
                                    <label>Uric Acid: </label> <span className="badge badge-danger text-wrap ">120/50</span>
                                </div> */}

                                    </div>
                        
                                </div>


                            </div>
                    
                            <hr />
                        </div>
                
                    )
                })
                                
                :
                  ""
               )
                 
        }

          
         
          <HealthEditModal show={showEditModal} handleEditClose={handleEditClose} data={editDataModal} fetchDataHealth={fetchDataHealth} unique={props.unique} />

    </div>
  )
}
