import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';


const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function PersonalPropModal(props) {

     const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

    const [fetchData, setFetchData] = useState({
        description : "",
        year_aquired : "",
        acquisition_cost : "", 
    });

    const [error, setError] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;  
    
      // Remove commas and non-digit characters from input value
      const cleanedValue = value.replace(/[^\d]/g, '');

      // Format the cleaned value with commas
      const formattedValue = formatNumberWithCommas(cleanedValue);

      if (name === "acquisition_cost") {
            setFetchData({ ...fetchData, [name]: formattedValue });
      } else {
          setFetchData({ ...fetchData, [name]: value });
      }  
    
      
    };

      useEffect(() => {
        axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
         setEmpInfo(res.data.EmpFullDetails[0])
        })  
      }, [props.unique]);
    
    const submitData = async (e) => {
      e.preventDefault();
      
        const acquisitionCost = fetchData.acquisition_cost.replace(/,/g, '');

        await axios.post(`/api/storedPersonalProper`, {
            IDNO: fetchEmpInfo.IDNO,
            description: fetchData.description,
            year_aquired: fetchData.year_aquired,
            acquisition_cost: acquisitionCost,
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                })
                setError(res.data.errors);
            } else {
                 Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.handleClose(false)
                    props.fetchDataSALN(props.unique)
                    setFetchData({
                        description: '',
                        year_aquired: '',
                        acquisition_cost: '',
                    });
                })

            }

        });
    };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} >
        <Modal.Header >
          <Modal.Title>Creat Personal Properties</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitData}>
            
        <Modal.Body>
             <div className="form-row">
                <div className="form-group col-md-5">
                    <label>Year Aquired</label>
                    <input type="text" className={`form-control ${error.year_aquired ? 'is-invalid' : ''}`} name="year_aquired" value={fetchData.year_aquired ?? ""} onChange={handleChange}/>
                    <div  className="invalid-feedback">{error.year_aquired}</div>
                </div>    
                
                 <div className="form-group col-md-7">
                    <label>Acquisition Cost/Amount</label>
                    <input type="text" className={`form-control ${error.acquisition_cost ? 'is-invalid' : ''}`} name="acquisition_cost" value={fetchData.acquisition_cost ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.acquisition_cost}</div>
                </div>   

                <div className="form-group col-md-12">
                    <label>Description</label>
                     <input type="text" className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" value={fetchData.description ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.description}</div>
                </div>        
                    
            </div>
            {/* form-row */}

                
        </Modal.Body>
        
        <Modal.Footer>
              <Button variant="outline-danger" onClick={props.handleClose}>
                Close
            </Button>
            <Button type="submit" variant="success" >
              Save 
            </Button>
        </Modal.Footer> 
        </Form>
      </Modal>
    </>
  )
}
