import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
// import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';



export default function SpouseModal(props) {

    const [show, setShow] = useState(false);    
    // const { unique } = useParams();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
        SSURNAME: "",
        SFIRSTNAME	: "",
        SMIDNAME: "",
        SEXTENSION: "",
        OCCUPATION: "",
        EMPLOYNAME: "",
        BUSADRS: "",
        BUSTEL: "",
    });

    const handleChange = (e) => {
        setEmpInfo(fetchEmpInfo =>({...fetchEmpInfo, [e.target.name]: e.target.value}))
    }


    useEffect(() => {
        if (props.spouseData) {
            setEmpInfo({
            IDNO: props.spouseData.employee_no || "",
            SSURNAME: props.spouseData.SSURNAME || "",
            SFIRSTNAME: props.spouseData.SFIRSTNAME || "",
            SMIDNAME: props.spouseData.SMIDNAME || "",
            SEXTENSION: props.spouseData.SEXTENSION || "",
            OCCUPATION: props.spouseData.OCCUPATION || "",
            EMPLOYNAME: props.spouseData.EMPLOYNAME || "",
            BUSADRS: props.spouseData.BUSADRS || "",
            BUSTEL: props.spouseData.BUSTEL || "",
        
        });
        }
    }, [props.spouseData]);


    // useEffect(() => {
    //     axios.get(`http://localhost:8000/api/EmpSpouseData/${unique}`).then((res) => {
    //         setEmpInfo(res.data.EmpSpouse[0] ?? "")
    //      })  
    //  }, [unique]);

// STORED AND UPDATE
    const [error, setError] = useState([]); 
    
    const submitInfo = async (e) => {
        e.preventDefault();


        await axios.post(`http://localhost:8000/api/EmpSpouse`, {
            IDNO: fetchEmpInfo.IDNO,
            SSURNAME: fetchEmpInfo.SSURNAME,
            SFIRSTNAME: fetchEmpInfo.SFIRSTNAME,
            SMIDNAME: fetchEmpInfo.SMIDNAME,
            OCCUPATION: fetchEmpInfo.OCCUPATION,
            EMPLOYNAME: fetchEmpInfo.EMPLOYNAME,
            BUSADRS: fetchEmpInfo.BUSADRS,
            BUSTEL: fetchEmpInfo.BUSTEL,
          
        }).then((res) => {

           

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
                }
            else {
                // console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    setError("");
                    setShow(false);
                    props.fetchDataFamily(props.unique);
                   
                })
            }
        
        })

    }

  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
            <i className="nav-icon fas fa-edit" />
        </Button>
        
        <Modal size="lg" show={show} onHide={handleClose}  backdrop="static" aria-labelledby="example-modal-sizes-title-lg">
            
            <Modal.Header>
                <Modal.Title><i className="fas fa-user"/> Spouse Details </Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitInfo}>

                 
                <Modal.Body>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Spouse's Name </label>
                            
                                <div className="row">
                                {/* <input type="hidden" name="app_id" id="appId_id" ref={appIdRef} defaultValue="1" /> */}
                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.SSURNAME ? 'is-invalid' : ''}`} placeholder="Surname" name="SSURNAME" id="surname_id" value={fetchEmpInfo.SSURNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.SSURNAME } </div>
                                </div>
                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.SFIRSTNAME ? 'is-invalid' : ''}`} placeholder="Firstname" name="SFIRSTNAME" id="firstname_id" value={fetchEmpInfo.SFIRSTNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> {error.SFIRSTNAME } </div>
                                </div>
                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.SMIDNAME ? 'is-invalid' : ''}`} placeholder="Middlename" name="SMIDNAME" id="middlename_id" value={fetchEmpInfo.SMIDNAME ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> {error.SMIDNAME } </div>
                                </div>
                                {/* <div className="col-md-3">
                                    <input type="text" className={`form-control ${error.SEXTENSION ? 'is-invalid' : ''}`} placeholder="Extension Name" name="SEXTENSION" id="extension_id" value={fetchEmpInfo.SEXTENSION ?? ""} onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.SEXTENSION } </div>
                                </div> */}
                            </div>
                            
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <label>Occupation</label>
                            <input type="text" className={`form-control ${error.OCCUPATION ? 'is-invalid' : ''}`} name="OCCUPATION" id="occupation_id" value={fetchEmpInfo.OCCUPATION ?? ""} onChange={handleChange} />
                            <div className="invalid-feedback"> { error.OCCUPATION } </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label>Employer/Business Name</label>
                            <input type="text" className={`form-control ${error.EMPLOYNAME ? 'is-invalid' : ''}`} name="EMPLOYNAME" id="businessName_id" value={fetchEmpInfo.EMPLOYNAME ?? ""} onChange={handleChange}/>
                            <div className="invalid-feedback"> {error.EMPLOYNAME } </div>
                        </div>
                    </div>
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-8">
                            <label>Business Address</label>
                            <input type="text" className={`form-control ${error.BUSADRS ? 'is-invalid' : ''}`} name="BUSADRS" id="businessAddress_id" value={fetchEmpInfo.BUSADRS ?? ""} onChange={handleChange}/>
                            <div className="invalid-feedback"> {error.BUSADRS } </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label>Telephone</label>
                            <input type="text" className={`form-control ${error.BUSTEL ? 'is-invalid' : ''}`} name="BUSTEL" id="businessTel_id" value={fetchEmpInfo.BUSTEL ?? ""} onChange={handleChange}/>
                            <div className="invalid-feedback"> {error.BUSTEL } </div>
                        </div>
                    </div>
                      
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 

                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
          


    </>
  )
}
