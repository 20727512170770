import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
// import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';


export default function EligibilityModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [error, setError] = useState([]);  
   
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });


  
    useEffect(() => {
       axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);


// STORED IN THE DATABASE 
    const [modeldata, setModaldata] = useState({

        IDNO: '',
        TITLE: '', 
        ELIRATE: '', 
        EXDATE: '', 
        EXPL: '', 
        LICENSENO: '', 
        DATEREL: '',
    }) 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }


    // stored Data
    const submitEligi = async (e) => {
        e.preventDefault();

      

        await axios.post(`http://localhost:8000/api/StoredEligible`, {

            IDNO: fetchEmpInfo.IDNO,
            TITLE: modeldata.TITLE,
            ELIRATE: modeldata.ELIRATE,
            EXDATE: modeldata.EXDATE,
            EXPL: modeldata.EXPL,
            LICENSENO: modeldata.LICENSENO,
            DATEREL: modeldata.DATEREL,
         
      
        }).then((res) => {

            if (res.data.status === 422) {
                    Swal.fire({
                        icon: 'error',
                        title: 'All fields are required',
                    });
                     setError(res.data.errors);
                }
            else {   
           
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                   
                    props.fetchDataEligibility(props.unique);
                    setModaldata({
                        TITLE: '',
                        ELIRATE: '',
                        EXDATE: '',
                        EXPL: '',
                        LICENSENO: '',
                        DATEREL: '',
                    });
                     setShow(false);
                })
            }
        
        })
    
    
    }



  return (
    <>
        <Button variant="primary" onClick={handleShow}>
        <i className="nav-icon fas fa-plus" />
        </Button>

        <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-address-card"/> Create Civil Service Eligibility </Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitEligi}>

                <Modal.Body>

                  
                    <div className="form-row">
                    
                        <div className="form-group col-md-9">
                            <label className="text-sm">CAREER SERVICE/ RA 1080 (BOARD/ BAR) UNDER SPECIAL LAWS/ CES/ CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE</label>
                            <input type="text" className={`form-control ${error.TITLE ? 'is-invalid' : ''}`} name="TITLE" id="eligibility_license_id" value={modeldata.TITLE} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.TITLE} </div>
                        </div>
                        
                        <div className="form-group col-md-2">
                            <label className="text-sm">Rating <br/> (if Applicable)</label>
                            <input type="text" className={`form-control ${error.ELIRATE ? 'is-invalid' : ''}`} name="ELIRATE" id="rating_id" value={modeldata.ELIRATE} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.ELIRATE} </div>
                        </div>        

                    </div>
                      {/*  form-row */}

                    <div className="form-row">
                    
                        <div className="form-group col-md-4">
                            <label className="text-sm">Date of Examination/Conferment</label>
                            <input type="date" className={`form-control ${error.EXDATE ? 'is-invalid' : ''}`} name="EXDATE" id="date_exam_id" value={modeldata.EXDATE} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.EXDATE } </div>
                        </div>
                        
                        <div className="form-group col-md-7">
                            <label className="text-sm">Place of Examination/Conferment</label>
                            <input type="text" className={`form-control ${error.EXPL ? 'is-invalid' : ''}`} name="EXPL" id="place_exam_id" value={modeldata.EXPL} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.EXPL} </div>
                        </div>   

                    </div>
                      {/*  form-row */}

                      <div className="form-row">
                    
                        <div className="form-group col-md-4">
                            <label className="text-sm">License Number</label>
                            <input type="text" className={`form-control ${error.LICENSENO ? 'is-invalid' : ''}`} name="LICENSENO" id="license_number_id" value={modeldata.LICENSENO} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.LICENSENO} </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label className="text-sm">Date of Validity</label>
                            <input type="date" className={`form-control ${error.DATEREL ? 'is-invalid' : ''}`} name="DATEREL" id="date_validity_id" value={modeldata.DATEREL} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.DATEREL} </div>
                        </div>  

                    </div>
                      {/*  form-row */}

                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
