import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import { useParams } from 'react-router-dom';

import WorkExpModal from '../components/WorkExpModal'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/Modal'
import Form  from 'react-bootstrap/Form'
import Swal from 'sweetalert2';
import moment from 'moment'
import { debounce } from 'lodash';

export default function EmployeeWorkExp() {


    const { unique } = useParams();
    const [isLoading, setisLoading] = useState(false);
    // Display Educ
    const [fetchEmployeeWork, setEmployeeWork] = useState([])
    //Update Function
    const [modeldata, setModaldata] = useState({
        unique: '',
        from: '',
        to: '',
        position: '',
        department: '',
        monthly: '',
        sgrade: '',
        status: '',
        government: ''
    })

    const fetchDataWork = async (unique) => {
        setisLoading(true);
    
        try {
            const res = await axios.get(`/api/EmpWork/${unique}`);
            setEmployeeWork(res.data.EmpWork);

           
        } catch (error) {
            console.log(error);
        } finally { 
            setisLoading(false);
        }
    };

    useEffect(() => {
         const debouncedFetch = debounce(fetchDataWork, 1000); // Adjust the delay time as needed
            // Invoke the debounced function
        debouncedFetch(unique);

        // Return a cleanup function
        return () => debouncedFetch.cancel();

    }, [unique]);



    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }

    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = (data) => {
        setModaldata(data);        
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [error, setError] = useState([]);

    const updateData = async (e) => {
        e.preventDefault();
        
        
        const formData = new FormData()
        formData.append('id', modeldata.unique ?? "")
        formData.append('from', modeldata.from ?? "")
        formData.append('to', modeldata.to ?? "")
        formData.append('position', modeldata.position ?? "")
        formData.append('department', modeldata.department ?? "")
        formData.append('monthly', modeldata.monthly ?? "")
        formData.append('sgrade', modeldata.sgrade ?? "")
        formData.append('status', modeldata.status ?? "")
        formData.append('government', modeldata.government ?? "")


        await axios.post(`/api/EmpUpdatedWork`, formData).then((res)=>{
        
            if (res.data.status === 422) {
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                })
            setError(res.data.errors);

            }
            else {
            
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    setShowModal(false);
                    fetchDataWork(unique);
                    setError('');
                })
            }
            
        });



    }
    
  return (
    <div>
       
      
            <div className="row">
                <div className="col-sm-6">
                    <h4 className="m-2"><i className="fas fa-briefcase"/> Work Experience </h4> 
              </div>{/* /.col */}   
              

                <div className="col-sm-6">
                    <div className="float-right">
                      <WorkExpModal fetchDataWork={fetchDataWork} unique={unique} />
                    </div>
                </div>{/* /.col */} 
            </div>{/* /.row */}
            <hr />
        {
        isLoading  ? (
            <div className='overlay-wrapper'> 
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                <div className="mx-1">
                    <div className="float-right">
                        <Button variant="outline-primary btn-sm"> <i className="nav-icon fas fa-edit" /></Button>
                    </div>
                </div>        

                <span>
                
                    <label className="text-md"></label> | <span></span> <br />
                    <span></span> <br />
                    <span></span>
                </span>
                <hr/> 
            </div> 
        ) :
           
           fetchEmployeeWork.length > 0 ?
                 fetchEmployeeWork.map((data) => {
                     const {
                        
      
                        from,
                        to, 
                        position, 
                        department, 
                        status,
                        unique,

                     } = data;
                     return (
         
                        <div key={unique}> 
                            <div className="mx-1">
                                <div className="float-right">
                                    <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                                </div>
                            </div>        

                            <span>
                            
                                <label className="text-md">{position === 'null'? "N/A" : position}</label> | <span>{status === 'null'? "N/A" : status}</span> <br />
                                <span>{department === 'null'? "N/A" : department }</span> <br />
                                <span>{from === 'null'? "N/A" : moment(from).format("MMM DD, YYYY") } - {to === null ? "Present" : moment(to).format("MMM DD, YYYY") }</span>
                            </span>
                            <hr/> 
                       </div>
                   )
                      
            })
                :
                ""
     
          
          }    

    <Modal show={showModal} backdrop="static" keyboard={false}  size="lg">
       
       <Modal.Header>
            <Modal.Title><i className="fas fa-book"/> Edit Work Experience</Modal.Title>
       </Modal.Header>
       <Form onSubmit={updateData}>
           <Modal.Body>
           
                     
                <div className="form-row">
                
                    <div className="form-group col-md-3">
                        <label >From</label>
                        <input type="date" className={`form-control ${error.from ? 'is-invalid' : ''}`} name="from" id="from_id" value={modeldata.from === null  ? '' : modeldata.from } onChange={handleChange} />
                        <div  className="invalid-feedback"> { error.from } </div>
                    </div>
                    
                    <div className="form-group col-md-3">
                        <label>To</label>
                        <input type="date" className={`form-control ${error.to ? 'is-invalid' : ''}`} name="to" id="to_id" value={modeldata.to === null  ? '' : modeldata.to } onChange={handleChange} />
                            {/* 
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id="to" name="to"  />
                            <label className="form-check-label" htmlFor="to">Present</label>
                        </div> */}
                        
                            
                        <div  className="invalid-feedback"> { error.to } </div>
                    </div>

                    <div className="form-group col-md-5">
                        <label>Position Title</label>
                        <input type="text" className={`form-control ${error.position ? 'is-invalid' : ''}`} name="position" id="position_id" value={modeldata.position === null  ? '' : modeldata.position} onChange={handleChange}/>
                        <div  className="invalid-feedback"> { error.position } </div>
                    </div>  


                </div>
                {/*  form-row */}
                <div className="form-row">
                
                    <div className="form-group col-md-8">
                        <label>Department/Agency/Office/Company</label>
                        <input type="text" className={`form-control ${error.department ? 'is-invalid' : ''}`} name="department" id="departmenr_id" value={modeldata.department === null  ? '' : modeldata.department} onChange={handleChange}/>
                        <div  className="invalid-feedback"> { error.department } </div>
                    </div>  

                    <div className="form-group col-md-3">
                        <label>Monthly Salary</label>
                        <input type="text" className={`form-control ${error.salary ? 'is-invalid' : ''}`} name="monthly" id="salary_id" value={modeldata.monthly === null  ? '' : modeldata.monthly} onChange={handleChange}/>
                        <div   className="invalid-feedback"> { error.salary } </div>
                    </div>
                        
                </div>
               {/*  form-row */}
               <div className="form-row">
           
                   <div className="form-group col-md-3">
                       <label>Salary/Job/Pay Grade</label>
                       <input type="text" className="form-control" name="sgrade" id="sgrade_id" value={modeldata.sgrade === null  ? '' : modeldata.sgrade} onChange={handleChange}/>
                       {/* <div  className="invalid-feedback"> { error.sgrade } </div> */}
                   </div>

                   <div className="form-group col-md-5">
                       <label>Status of Appointment</label>
                       <input type="text" className={`form-control ${error.status ? 'is-invalid' : ''}`} name="status" id="status_id" value={modeldata.status === null  ? '' : modeldata.status} onChange={handleChange}/>
                       {/* <select className={`custom-select ${error.status_appointment ? 'is-invalid' : ''}`} name="status" id="status_appointment_id" value={modeldata.status === null  ? '' : modeldata.status} onChange={handleChange}>
                           <option value=''>Select</option>
                           <option value="Contractual">CONTRACT OF SERVICE</option>
                           <option value="Casual">CASUAL</option>
                           <option value="Permanent">PERMANENT</option>
                           <option value="Regular">REGULAR</option>
                           <option value="Temporary">TEMPORARY</option>
                           <option value="Probitionary">PROBATIONARY</option>
                       </select> */}
                       <div  className="invalid-feedback"> { error.status } </div>
                   </div>  
                     

                   <div className="form-group col-md-3">
                       <label>Gov't Service</label>
                       <select className={`custom-select ${error.government ? 'is-invalid' : ''}`} name="government" id="govt_service_id" value={modeldata.government === null  ? "" : modeldata.government} onChange={handleChange}>
                           <option value="">Select</option>
                           <option value="yes">YES</option>
                           <option value="no">NO</option>
                       </select>
                       <div  className="invalid-feedback"> { error.government } </div>
                     </div>  
                   
               </div>
               {/*  form-row */}
           </Modal.Body>
       
           <Modal.Footer>
           <Button variant="outline-danger" onClick={() => handleCloseModal()}>
               Close
           </Button>
           <Button type='submit' variant="success">Save Changes</Button> 
           </Modal.Footer>
       </Form>        
    </Modal>





    </div>
      
      
      
      
  )
}
