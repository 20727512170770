import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

 
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default function LiabilitiesEditModal(props) {

    const [modalData, setModalData] = useState({    
        id: "",
        nature: "",
        name_creditors: "",
        outstanding_balance: "",
    });
    
    const [error, setError] = useState([]);

    useEffect(() => {
       if (props.data) {

           setModalData({
               id: props.data.id || "",
               nature: props.data.nature || "",
               name_creditors: props.data.name_creditors || "",
               outstanding_balance: formatNumberWithCommas(props.data.outstanding_balance || ""),
           });
           
       }
    }, [props.data])

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Remove commas and non-digit characters from input value
        const cleanedValue = value.replace(/[^\d]/g, '');

        // Format the cleaned value with commas
        const formattedValue = formatNumberWithCommas(cleanedValue);

        if (name === "outstanding_balance") {
            setModalData({ ...modalData, [name]: formattedValue });
        } else {
            setModalData({ ...modalData, [name]: value });
        }
        
        
    };


    const UpdateData = async (e) => {
      e.preventDefault();
      
       const outstandingBalance = modalData.outstanding_balance.replace(/,/g, '');

        await axios.post(`/api/updatedLiabilities`, {
            id: modalData.id,
            nature: modalData.nature,
            name_creditors: modalData.name_creditors,
            outstanding_balance: outstandingBalance,
        }).then((res) => {
            
            if (res.data.status === 422) {
                Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                });

                setError(res.data.errors);
            } else {
                Swal.fire({
                icon: "success",
                title: res.data.message,
                }).then((res) => {
                props.EditCloseModal(false);
                props.fetchDataSALN(props.unique)   
                });

            }


        });

    }

  return (
    <>
      <Modal show={props.show} onHide={props.EditCloseModal}>
        <Modal.Header>
          <Modal.Title>Edit Liabilities</Modal.Title>
        </Modal.Header>
        <Form onSubmit={UpdateData}>
        <Modal.Body>
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>Nature</label>
                   <input type="text" className={`form-control ${error.nature ? 'is-invalid' : ''}`} name="nature" value={modalData.nature ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.nature}</div>
                </div>    
                
                 <div className="form-group col-md-12">
                    <label>Name of Creditors</label>
                    <input type="text" className={`form-control ${error.name_creditors ? 'is-invalid' : ''}`} name="name_creditors" value={modalData.name_creditors ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.name_creditors}</div>
                </div>   

                <div className="form-group col-md-6">
                    <label>Outstanding Balance</label>
                     <input type="text" className={`form-control ${error.outstanding_balance ? 'is-invalid' : ''}`} name="outstanding_balance" value={modalData.outstanding_balance ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.outstanding_balance}</div>
                </div>        
                    
            </div>
            {/* form-row */}
        </Modal.Body>
        
          <Modal.Footer>
            <Button variant="outline-danger" onClick={props.EditCloseModal}>
                Close
            </Button>
            <Button type="submit" variant="success">
              Save Changes
            </Button>
        </Modal.Footer>
        </Form>  
      </Modal>
    </>
  )
}
