import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import Swal from 'sweetalert2';
import DatePicker, { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Check from '@mui/icons-material/Check';

export default function Page(props) {
    const curDate = new DateObject();
    const [selectedDate, setSelectedDate] = useState(new DateObject());
    const [employeeSchedule, setEmployeeSchedule] = useState([]);
    const [employeeRecords, setEmployeeRecords] = useState([]);
    const [allowFirstApprove, setAllowFirstApprove] = useState(true);
    const [allowSecondApprove, setAllowSecondApprove] = useState(true);
    const [dtrApproved, setDTRApproved] = useState(null);
    useEffect(() => {
        fetchEmployeeSchedule();
    }, [selectedDate]);

    const fetchEmployeeSchedule = async () => {
        Swal.fire({
            icon: 'info',
            text: 'Loading Schedule...',
            showConfirmButton: false
        });

        let dateNow = new DateObject();
        let dtCh1 = new DateObject(`${selectedDate.year}/${selectedDate.month.number}/16`);
        let dtCh2 = new DateObject(`${selectedDate.year}/${selectedDate.month.number}/${selectedDate.month.length + 1}`);
        let nDateNow = dateNow.toDate();
        let nDateCh1 = dtCh1.toDate();
        let nDateCh2 = dtCh2.toDate();

        await axios.get(`/api/employee/scheduleanddtr`, { params: { month: selectedDate.month.number, year: selectedDate.year } }).then(({ data }) => {
            setDTRApproved(data.approved);
            let dtrCh = data.approved;
            if (data.result) {
                if(!Array.isArray(dtrCh)){
                    if(dtrCh && dtrCh.emp_approved){
                        setAllowFirstApprove(false);
                        setAllowSecondApprove(false);
                    }else{
                        if(nDateNow > nDateCh2){
                            setAllowFirstApprove(true);
                            setAllowSecondApprove(true);
                        }else{
                            setAllowFirstApprove(false);
                            setAllowSecondApprove(false);
                        }
                    }
                } else {
                    if(dtrCh[0] && dtrCh[0].emp_approved){
                        setAllowFirstApprove(false);
                    }else{
                        if(nDateNow > nDateCh1){
                            setAllowFirstApprove(true);
                        }else{
                            setAllowFirstApprove(false);
                        }
                    }
                    if(dtrCh[1] && dtrCh[1].emp_approved){
                        setAllowSecondApprove(false);
                    }else{
                        if(nDateNow > nDateCh2){
                            setAllowSecondApprove(true);
                        }else{
                            setAllowSecondApprove(false);
                        }
                    }
                }
                setEmployeeSchedule(data.sched);
                let recordsTemp = {};
                data.records.forEach((rec) => {
                    let recTemp = new DateObject(rec.attendance);
                    let hours = recTemp.hour;
                    let minutes = recTemp.minute;
                    let ampm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    hours = (hours < 10 ? '0' : '') + hours;
                    minutes = minutes < 10 ? '0' + minutes : minutes;
                    let recStr = recTemp.month.number + "/" + recTemp.day + "/" + recTemp.year + " " + hours + ":" + minutes + " " + ampm;
                    recordsTemp[' ' + rec.id] = recStr;
                })
                Swal.close();
                setEmployeeRecords(recordsTemp);
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            Swal.update({
                icon: "error",
                text: error.r,
                showConfirmButton: true
            })
        })
    }

    const empApproveDTR = async (mode) => {
        let refreshResult = await generateDTR();
        if(refreshResult){
            const formData = new FormData()
            formData.append('month', selectedDate.month.number)
            formData.append('year', selectedDate.year)
            formData.append('mode', parseInt(mode));
            Swal.fire({
                icon: 'info',
                text: 'Saving...',
                showConfirmButton: false
            });
            await axios.post(`/api/employee/scheduleanddtr/approve`, formData).then(({ data }) => {
                if (data.result) {
                    let dtrU = data.undertime;
                    Swal.fire({
                        icon : 'warning',
                        title : `${selectedDate.month.name} ${selectedDate.year} DTR ${mode == 1 ? "" : mode == 2 ? "1st Half" : "2nd Half"} Results`,
                        html : `<div>
                                    <p>Kindly review the results of your DTR.</p>
                                    <b>Total Undertime</b> : ${dtrU.total_minutes} minutes<br/>
                                    <b>Tardiness</b> : ${dtrU.tardiness}<br/>
                                    <b>Undertime with pay</b> : ${dtrU.minutes_deducted} minutes (${dtrU.wPay} VL used)<br/>
                                    <b>Undertime remaining</b> : ${dtrU.minutes_remaining} minutes<br/>
                                    <b>Vacation Leave Balance</b> : ${dtrU.vlBalance}<br/>
                                    </br>
                                    <p>If you have concerns, please do not approve and coordinate with your Administrative Officer or Timekeeper. <span class="text-danger"><b>Approval is irreversible</b></span>.</p>
                                </div>`,
                        showConfirmButton:true,
                        showCancelButton: true,
                        confirmButtonText: "Approve",
                        confirmButtonColor: "green"
                    }).then((result) => {
                        if(result.isConfirmed){
                            empApproveDTRConfirm(dtrU.id, mode);
                        }
                    });
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                }
            }).catch((error) => {
                //console.log(response)
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
            })
        }
    }

    const empApproveDTRConfirm = async (id, mode) => {
        Swal.fire({
            icon: 'info',
            text: 'Saving...',
            showConfirmButton: false
        });
        await axios.post(`/api/employee/scheduleanddtr/confirm`, { id }).then(({ data }) => {
            setDTRApproved(data.approved);
            if (data.result) {
                if(mode == 1){
                    setAllowFirstApprove(false);
                    setAllowSecondApprove(false);
                }else if(mode == 2){
                    setAllowFirstApprove(false);
                }else if(mode == 3){
                    setAllowSecondApprove(false);
                }
                Swal.update({
                    icon: "success",
                    text: data.message,
                    showConfirmButton: true
                });
            } else {
                Swal.update({
                    icon: "error",
                    text: data.message,
                    showConfirmButton: true
                });
            }
        }).catch((error) => {
            //console.log(response)
            Swal.update({
                icon: "error",
                text: error.response.data.message,
                showConfirmButton: true
            })
        })
    }

    const generateDTR = async () => {
        //var start = new Date().getTime();
        if (employeeSchedule.length !== 0) {
            const formData = new FormData()
            formData.append('month', selectedDate.month.number)
            formData.append('year', selectedDate.year)
            Swal.fire({
                icon: 'info',
                text: 'Refreshing...',
                showConfirmButton: false
            });
            return await axios.post(`/api/employee/scheduleanddtr/generate`, formData).then(({ data }) => {
                // console.log('generate emp dtr', data);
                if (data.result) {
                    Swal.close();
                    setEmployeeSchedule(data.sched);
                    return true;
                } else {
                    Swal.update({
                        icon: "error",
                        text: data.message,
                        showConfirmButton: true
                    });
                    return false;
                }
                // var end = new Date().getTime();
                // var time = end - start;
                // alert('Execution time: ' + time);
            }).catch((error) => {
                Swal.update({
                    icon: "error",
                    text: error.response.data.message,
                    showConfirmButton: true
                })
                return false;
            })
        }
    }

    // const approveTest = async () => {
    //     Swal.fire({
    //         icon:'info',
    //         text:'Generating...',
    //         showConfirmButton:false
    //     });
    //     await axios.post(`/api/testsign`).then(({data})=>{
    //         if(data.result){
    //             Swal.update({
    //                 icon:"success",
    //                 text:data.message,
    //                 showConfirmButton:true
    //             });
    //             console.log(data.test);
    //         }else{
    //             Swal.update({
    //                 icon:"error",
    //                 text:data.message,
    //                 showConfirmButton:true
    //             });
    //         }
    //     }).catch((error)=>{
    //         Swal.update({
    //             icon:"error",
    //             text:response.data.message,
    //             showConfirmButton:true
    //         })
    //     })
    //     // if(employeeSchedule.length !== 0){
    //     //     const formData = new FormData()
    //     //     formData.append('month', selectedDate.month.number)
    //     //     formData.append('year', selectedDate.year)
    //     //     formData.append('mode', 1);
    //     //     Swal.fire({
    //     //         icon:'info',
    //     //         text:'Generating...',
    //     //         showConfirmButton:false
    //     //     });
    //     //     await axios.post(`/api/employee/scheduleanddtr/approveTest`, formData).then(({data})=>{
    //     //         if(data.result){
    //     //             Swal.update({
    //     //                 icon:"success",
    //     //                 text:data.message,
    //     //                 showConfirmButton:true
    //     //             });
    //     //             console.log(data.test);
    //     //         }else{
    //     //             Swal.update({
    //     //                 icon:"error",
    //     //                 text:data.message,
    //     //                 showConfirmButton:true
    //     //             });
    //     //         }
    //     //     }).catch((error)=>{
    //     //         Swal.update({
    //     //             icon:"error",
    //     //             text:response.data.message,
    //     //             showConfirmButton:true
    //     //         })
    //     //     })
    //     // }
    // }

    const scheduleRow = (sched, i) => {
        const date = new DateObject(sched.date);
        let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "HL", "WS", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];
        const checkDT = (dt, ms = false, scDt = sched.date) => {
            if (dt === "" || dt === null) {
                return "";
            }
            if (legends.includes(dt)) {
                return dt;
            }
            let res = ms ? new DateObject(parseInt(dt)).format("hh:mm") : new DateObject(`${scDt} ${dt}`).format("hh:mm");
            //console.log(dt, ms, scDt, res);
            return res;
        }

        let schedAmIn = checkDT(sched.sched_am_in);
        let schedAmOut = checkDT(sched.sched_am_out);
        let schedPmIn = checkDT(sched.sched_pm_in);
        let schedPmOut = checkDT(sched.sched_pm_out);
        let attAmIn = checkDT(sched.attend_am_in, true);
        let attAmOut = checkDT(sched.attend_am_out, true);
        let attPmIn = checkDT(sched.attend_pm_in, true);
        let attPmOut = checkDT(sched.attend_pm_out, true);
        let otCutOff = checkDT(sched.ot_cutoff);
        let otIn = checkDT(sched.ot_in, true);
        let otOut = checkDT(sched.ot_out, true);

        let otIndicatorMap = {
            0 : "",
            1 : "",
            2 : "N",
            3 : "D/N",
        }

        let utTot = 0;
        if (sched.undertime) {
            let ut = sched.undertime.split(",").map(Number);
            utTot = ut[0] + ut[1] + ut[2] + ut[3];
        }

        let empApp = null;
        let offApp = null;

        if (!Array.isArray(dtrApproved)) {
            if (dtrApproved) {
                empApp = dtrApproved.emp_approved;
                offApp = dtrApproved.off_approved;
            }
        } else {
            if (i < 15) {
                if (dtrApproved[0]) {
                    empApp = dtrApproved[0].emp_approved;
                    offApp = dtrApproved[0].off_approved;
                }
            } else {
                if (dtrApproved[1]) {
                    empApp = dtrApproved[1].emp_approved;
                    offApp = dtrApproved[1].off_approved;
                }
            }
        }

        return (
            <Row key={i} className="border-bottom border-1 border-dark text-center">
                <Col className={`border-end border-2 border-dark text-end`} xs="2">
                    {empApp ? offApp ? <DoneAllIcon className="text-success mt-0 me-2" /> : <CheckIcon style={{ fontSize: 20 }} className="text-primary me-1" /> : null}
                    <Form.Label className={`p-0 m-0 ${empApp ? offApp ? "text-success" : "text-primary" : ""}`}>{`${date.weekDay.shortName}, ${date.month.name} ${date.day}`}</Form.Label>
                </Col>
                <Col className={`border-end border-2 border-dark ${sched.sched_approved === 1 ? "" : "text-danger"}`} xs="4">
                    <Row>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedAmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedAmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{schedPmIn}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0 bg-light">
                            &nbsp;{schedPmOut}
                        </Col>
                        <Col className="border-end border-1 border-dark p-0">
                            &nbsp;{otIndicatorMap[sched.ot_indicator]}
                        </Col>
                        <Col className="p-0" xs="2">
                            &nbsp;{otCutOff}
                        </Col>
                    </Row>
                </Col>
                <Col className="border-end border-2 border-dark" xs="4">
                    <Row>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.am_in_entry === 0 ? "" : sched.am_in_entry === 1 ? " text-primary" : sched.am_in_entry === 2 ? " text-danger" : sched.am_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark bg-light" + (sched.am_out_entry === 0 ? "" : sched.am_out_entry === 1 ? " text-primary" : sched.am_out_entry === 2 ? " text-danger" : sched.am_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attAmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.pm_in_entry === 0 ? "" : sched.pm_in_entry === 1 ? " text-primary" : sched.pm_in_entry === 2 ? " text-danger" : sched.pm_in_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmIn}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark p-0 bg-light" + (sched.pm_out_entry === 0 ? "" : sched.pm_out_entry === 1 ? " text-primary" : sched.pm_out_entry === 2 ? " text-danger" : sched.pm_out_entry === 4 ? "" : " text-success-lighter")}>
                            &nbsp;{attPmOut}
                        </Col>
                        <Col className={"border-end border-1 p-0 border-dark" + (sched.ot_in_entry === 0 ? "" : sched.ot_in_entry === 1 ? " text-primary" : sched.ot_in_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otIn}
                        </Col>
                        <Col className={"p-0 bg-light" + (sched.ot_out_entry === 0 ? "" : sched.ot_out_entry === 1 ? " text-primary" : sched.ot_out_entry === 2 ? " text-danger" : " text-success-lighter")}>
                            &nbsp;{otOut}
                        </Col>
                    </Row>
                </Col>
                <Col className="p-0 border-end border-2 border-dark" xs="1">
                    &nbsp;{utTot}
                </Col>
                <Col className="border-end border-2 border-dark" xs="1">
                    &nbsp;{sched.overtime}
                </Col>
            </Row>
        )
    }

    const renderScheds = () => {
        let result = employeeSchedule.map((sched, i) => (
            scheduleRow(sched, i)
        ));
        return result;
    }

    return (
        <>
            <Row className="m-0 p-2" style={{ height: "100%" }}>
                <Col className="ps-0 pe-1 pt-0 pb-0" xs="12" md="2">
                    <Row>
                        <Col className="pb-1" style={{ height: "100%" }}>
                            <div className="card h-100">
                                <div className="card-body p-0 text-center">
                                    <Row className="m-1">
                                        <Col className="sched-rmdp-container">
                                            <DatePicker className="m-auto"
                                                //render={<InputIcon/>}
                                                format="MMMM YYYY"
                                                value={selectedDate}
                                                onChange={setSelectedDate}
                                                onlyMonthPicker
                                                inputClass="form-control text-center fw-bold"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="m-1">
                                        <Col className="d-grid">
                                            <Button variant="primary" onClick={generateDTR}>Refresh DTR</Button>
                                        </Col>
                                    </Row>
                                    {(props.user.plantilla.ESTA === "Permanent" || props.user.plantilla.ESTA === "Co-Terminous") && (allowFirstApprove && allowSecondApprove) ?
                                        <Row className="m-1">
                                            <Col className="d-grid">
                                                <Button variant="primary" onClick={() => empApproveDTR(1)}>Approve</Button>
                                            </Col>
                                        </Row> :
                                        <>
                                            {allowFirstApprove ?
                                                <Row className="m-1">
                                                    <Col className="d-grid">
                                                        <Button variant="primary" onClick={() => empApproveDTR(2)}>Approve 1st Half</Button>
                                                    </Col>
                                                </Row>
                                                : ""}
                                            {allowSecondApprove ?
                                                <Row className="m-1">
                                                    <Col className="d-grid">
                                                        <Button variant="primary" onClick={() => empApproveDTR(3)}>Approve 2nd Half</Button>
                                                    </Col>
                                                </Row>
                                                : ""}
                                        </>
                                    }
                                    {/* <Row className="m-1">
                                        <Col className="d-grid">
                                            <Button variant="primary" onClick={() => approveTest()}>Approve Test</Button>
                                        </Col>
                                    </Row> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col className="m-0 ps-0 pe-1 pt-0 pb-1" xs="12" md="10" style={{ height: "100%" }}>
                    <div className="card overflow-auto" style={{ height: "calc(100%)" }}>
                        <div className="card-body" style={{ minWidth: "1100px" }}>
                            <Row className="">
                                <Col className="">
                                    <Row className="border border-2 border-bottom-2 border-dark text-center">
                                        <Col className="border-end border-2 border-dark d-flex align-items-center" xs="2">
                                            <Form.Label className="m-auto">Employee Schedule</Form.Label>
                                        </Col>

                                        <Col className="border-end border-2 border-dark" xs="4">
                                            <Row className="border-bottom border-1 border-dark" >
                                                <Col>
                                                    <Form.Label className="m-auto">Schedule</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row className="border-bottom border-1 border-dark" >
                                                <Col className="border-end border-1 border-dark" >
                                                    <Form.Label className="m-auto">AM</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark" >
                                                    <Form.Label className="m-auto">PM</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1">
                                                    <Form.Label className="m-auto">OT</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IND</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>CUT</Form.Label>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="border-end border-2 border-dark" xs="4">
                                            <Row className="border-bottom border-1 border-dark" >
                                                <Col>
                                                    <Form.Label className="m-auto">Attendance</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row className="border-bottom border-1 border-dark" >
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto">AM</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto">PM</Form.Label>
                                                </Col>
                                                <Col>
                                                    <Form.Label className="m-auto">OT</Form.Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                </Col>
                                                <Col className="border-end border-1 border-dark">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>IN</Form.Label>
                                                </Col>
                                                <Col className="bg-light">
                                                    <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>OUT</Form.Label>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                            <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Undertime</Form.Label>
                                        </Col>

                                        <Col className="border-end border-2 border-dark d-flex align-items-center p-0" xs="1">
                                            <Form.Label className="m-auto" style={{ fontSize: '10pt' }}>Overtime</Form.Label>
                                        </Col>

                                        {/* <Col className="d-flex align-items-center" xs="2">
                                            <Form.Label className="m-auto">Remarks</Form.Label>
                                        </Col> */}

                                    </Row>

                                    {/* employeeSchedules */}
                                    <Row className="border-end border-start border-2 border-dark">
                                        <Col>
                                            {renderScheds()}
                                        </Col>
                                    </Row>

                                    {employeeSchedule.length !== 0 ? ""
                                        // <Row className="border border-2 border-dark">
                                        //     <Col className="border-end border-2 border-dark" xs="2">
                                        //         {`Total:  work days`}
                                        //     </Col>
                                        //     <Col className="border-end border-2 border-dark" xs="4">
                                        //     </Col>
                                        //     <Col className="border-end border-2 border-dark" xs="4">
                                        //         {'Actual:  work days'}
                                        //     </Col>
                                        //     <Col className="border-end border-2 border-dark" xs="1">
                                        //         {` min`}
                                        //     </Col>
                                        //     <Col className="border-end border-2 border-dark" xs="1">
                                        //         {` min`}
                                        //     </Col>
                                        //     {/* <Col className="" xs="2">

                                        //     </Col> */}
                                        // </Row>
                                        :
                                        <Row className="border border-2 border-dark">
                                            <Col className="text-center">
                                                <Form.Label className="text-danger">
                                                    No Schedule Found
                                                </Form.Label>
                                            </Col>
                                        </Row>}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}