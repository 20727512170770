import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { format } from 'date-fns';
import TimesRomanNormal from '../report/fonts/bookmanoldstyle.ttf';
import TimesRomanBold from '../report/fonts/bookmanoldstyle_bold.ttf';
import TimesRomanItalic from '../report/fonts/bookmanoldstyle_italic.ttf';
import TimesRomanBoldItalic from '../report/fonts/bookmanoldstyle_bolditalic.ttf';
import Unchecked from '../../../../image/checked-box.png';
import checked from '../../../../image/checked.png';

Font.register({
  family: 'TimesRoman',
  src: TimesRomanNormal
});

Font.register({
  family: 'TimesRomanBold',
  src: TimesRomanBold
});

Font.register({
  family: 'TimesRomanItalic',
  src: TimesRomanItalic
});

Font.register({
  family: 'TimesRomanBoldItalic',
  src: TimesRomanBoldItalic
});

const styles = StyleSheet.create({

  sections: {
    paddingHorizontal: 20,
    fontFamily: 'TimesRoman',
    paddingTop: '35px',
    paddingLeft: '15px',
    paddingRight: '20px'
  
  },

  TitleLeft: {
    fontSize: 7,
    fontFamily: 'TimesRoman',
  },

  TitleFormPosition: {
    textAlign: "center"
  },

  TitleForm: {
    marginTop: "9px",
    fontWeight: 900,
    fontSize: 11.8,
    fontFamily: 'TimesRomanBold',
  },

  checkChoice: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: '4px'

  },
  checkText: {
    marginTop: '2px',
    fontSize: 9,

  },
  ImageCheckbox: {
    width: 8,
    height: 8,
    marginTop: '1.2px',
    
  },

  // 
  page: {
    marginTop: "12px",
    marginLeft: "36px",
    flexDirection: 'row',
  },
  section: {
    margin: 5,
    padding: 2,
    flexGrow: 2
  },


  tableRow: {
    flexDirection: "row"
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: "auto",
  },

  


  outerBorder: {
    marginTop: 10,
    marginLeft: '36px',
    width: 525,
    borderColor: 'black',
    borderBottom: 1,
    padding: 1,
    
  },
  innerBorder: {
    marginLeft: '36px',
    width: 525,
    borderColor: 'black',
    borderTop: 1,
  },

  // title children
  TitleChildren: {
    marginTop: 15,
    textAlign: 'center'
  },
  secondTitle: {
    fontSize: '9.2px',
    textDecoration: "underline",
    fontFamily: 'TimesRomanBold',
  },

  //Table
  properties: {
    paddingTop: 4,
    paddingBottom: 5
  },
  tableProperties: {
    display: 'table',
    width: '85%',
    marginLeft: 50
  },
  tablePropertiesRow: {
    flexDirection: 'row',
  },
  tablePropertiesCell: {
    padding: 8,
    fontSize: 9,
    fontFamily: 'TimesRomanBold'
  },
  tablePropertiesHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '7px',
    marginBottom: '-8px',
  },
    
  tableBodyRow: {
    flexDirection: 'row',
  },
  tableBodyCell: {
    padding: 5,
    fontSize: 10,
    borderStyle: 'solid',
    borderColor: '#000',
    borderBottom: 1,
    marginRight: '20px',
    marginTop: '-5px'
      
  },
  textcell: {
    textAlign: 'center',
    lineHeight: '0.5em',
    marginBottom: '-5px',
      
  },
  TitleAssetsLiabilitiesNet: {
    marginTop: '3px',
    textAlign: 'center',
  },




    
  // Real Properties Table
  RealProperty: {
    paddingTop: 4,
    marginLeft: '30px'
  },
  tableRealProp: {
    display: 'table',
    width: '95%',
    borderWidth: 0.5,
    borderStyle: 'solid',
    borderColor: '#000',
  },
  tableRowReal: {
    flexDirection: 'row',
  },
  tableHeaderReal: {
    height: '50px',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '7px',
    backgroundColor: '#a8a8a8',
    borderBottom: '2px'
  },
  tablesCellReal: {
    padding: 2,
    fontSize: '7px',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 0.5,
  },
  ValueContainer: {
    width: '25%',
  },
  valueCell: {
    width: '100%',
    borderBottomWidth: 0.5,
    textAlign: 'center',
  },
  valueRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  acquisitionContainer: {
    width: '15%',
  },
  acquisitionCell: {
    width: '100%',
    borderBottomWidth: 0.5,
    textAlign: 'center',
  },
  yearModeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  yearModeCell: {
    width: '50%',
    textAlign: 'center',
    borderTopWidth: 0.5,
  },


  // Subtotal and Total text format 
  tableRowSubTotal: {
    flexDirection: "row",
    marginTop: '5px',
  },
  tableColSubTotal: {
    width: '50px', 
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0

  },
  tableCellSubtotal: {
    margin: "auto",
  },

  tableRowTotal: {
    flexDirection: "row",
    marginTop: '5px',
  },
  tableColTotal: {
    width: '100px', 
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0

  },
  tableCelltotal: {
    margin: "auto",
  },

  // Liabilities subtotal and total
  tableRowSubTotalLiabilities: {
    flexDirection: "row",
    marginTop: '5px',
  },
  tableColSubTotalLiabilities: {
    borderStyle: "solid",
    borderLeftWidth: 0,
    borderTopWidth: 0

  },
  tableCellSubtotalLiabilities: {
    margin: "auto",
  },


  //css table use in Personal, Liabilities, Business Interest and Relatives in the Government
      
  PersonalProperty: {
    paddingTop: 4,
    marginLeft: '30px'
  },
      
  staticTable: {
    display: 'table',
    width: '95%',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
  },
    
  staticTableRow: {
    flexDirection: 'row',
  },
     
  // staticTableHeader: {
  //   fontWeight: 'bold',
  //   fontFamily: 'TimesRomanBold',
  //   textAlign: 'center',
  //   fontSize: '8px',
  //   backgroundColor: '#a8a8a8',
  //   borderBottom: '2px'
  // },
    
  staticLiabilitiesTableHeader: {
    fontWeight: 'bold',
    fontFamily: 'TimesRomanBold',
    textAlign: 'center',
    backgroundColor: '#a8a8a8',
    fontSize: 7,
  },
    
  staticTableCol: {
    // width: '25%',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
    padding: 5,
  },
    
  // staticTableCell: {
  //   fontSize: 8,
  // },
    
  staticTableValueCell: {
    fontSize: 8,
  },

  // Business Interest
  TitleBusinessInterest: {
    marginTop: '20px',
    textAlign: 'center',

  },

  businessBoxImage: {
    width: 7,
    height: 7,
    marginTop: '1px',
  },

  // business table and relatives table same css code 

  BusinessInterest: {
    paddingTop: 4,
    marginLeft: '30px'
  },
      
  BusinessTable: {
    display: 'table',
    width: '95%',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
  },
    
  BusinessTableRow: {
    flexDirection: 'row',
  },
     
  staticTableHeader: {
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
    padding: 5,
      
    fontWeight: 'bold',
    fontFamily: 'TimesRomanBold',
    textAlign: 'center',
    fontSize: '7px',
    backgroundColor: '#a8a8a8',
    borderBottom: '2px'
  },
    
   
   
  BusinessTableCol: {
    // width: '25%',
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: '#000',
    padding: 5,
  },
    
  businessTableHeader: {
    fontWeight: 'bold',
    fontFamily: 'TimesRomanBold',
    textAlign: 'center',
    backgroundColor: '#a8a8a8',
    fontSize: 7,
  },
    
  relativesTableHeader: {
    fontWeight: 'extrabold',
    fontFamily: 'TimesRomanBold',
    textAlign: 'center',
    backgroundColor: '#a8a8a8',
    fontSize: 7,
    lineHeight: 1,
    margin: 0,
    padding: 0,
     
        
  },

  // staticTableCell: {
  //   fontSize: 8,
  // },
    
  businessTableValueCell: {
    textAlign: 'center',
    fontSize: 9,
  },
  relativesTableValueCell: {
    textAlign: 'center',
    fontSize: 7,
    
  },

  certifyText: {
    fontSize: '10px',
    fontFamily: 'TimesRoman',
    lineHeight: '1.5px',
    textAlign: 'justify',
  },
  dateCertify: {
    fontSize: '10px',
    fontFamily: 'TimesRoman',
    marginTop: '15px',
    marginLeft: '50px',
    flexDirection: 'row',
     
  }

});

const splitAddress = (address) => {
  const maxLength = 40; 
  const words = address.split(' ');
  let lines = [''];
  let currentLine = 0;

  words.forEach(word => {
    if (lines[currentLine].length + word.length + 1 <= maxLength) {
      lines[currentLine] += (lines[currentLine] ? ' ' : '') + word;
    } else {
      lines.push(word);
      currentLine += 1;
    }
  });

  if (lines.length === 1) {
    lines.push('');
  }

  return lines;
};

const splitAddressOffice = (address) => {
  const maxLength = 20; // Adjust this value based on your needs
  if (address.length > maxLength) {
    return [address.slice(0, maxLength), address.slice(maxLength)];
  }
  return [address, ""];
};

const formatNumber = (num) => {
if (num === 0) return "0"; // Return 0 as a string if the number is exactly 0
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


const getCurrentDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0'); // Ensure two digits
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();
  return `${month}/${day}/${year}`;
};


export default function LayoutSALN({ dataInfo, dataSpouse, dataChild, dataRealProper, dataPersonalProper, dataLiabilities, dataBusinessInter, dataGovRelatives, totalRealProper, totalPersonalProper, totalLiabilities, totalProper, totalNetWorth}) {

  
  const [addressLine1, addressLine2] = splitAddress(dataInfo.completeaddress || "");
  const [officeAddress1, officeAddress2] = splitAddressOffice(dataInfo.offaddress || "");
  
  const calculateAge = (birthDate) => {
  const currentDate = new Date();
  const birthDateObj = new Date(birthDate);
  
  return currentDate.getFullYear() - birthDateObj.getFullYear() - 
    (currentDate.getMonth() < birthDateObj.getMonth() || 
     (currentDate.getMonth() === birthDateObj.getMonth() && currentDate.getDate() < birthDateObj.getDate()) ? 1 : 0);
  };
  

  return (
    <Document>
      <Page size="LEGAL">

        <View style={styles.sections}>

            <View style={{ marginLeft: "76%" }}>
              <Text style={styles.TitleLeft}>Revised as of January 2015</Text>
              <Text style={styles.TitleLeft}>Per CSC Resolution No. 1500088</Text>  
              <Text style={styles.TitleLeft}>Promulgated on January 23, 2015</Text>
          </View>
          
           <View style={styles.TitleFormPosition}>
              <Text style={styles.TitleForm}>SWORN STATEMENT OF ASSETS, LIABILITIES AND NET WORTH</Text>
              <Text style={{ fontSize: 9 }}>As of <Text style={{ textDecoration: "underline" }}>December 31, 2021</Text></Text>
              <Text style={{fontSize: 8}}>(Required by R.A.6713)</Text>
            </View>
      
            <View style={styles.TitleFormPosition}>
              <Text style={{ marginTop: "9px", fontSize: 8.8, fontFamily: 'TimesRomanItalic' }}>
                <Text >Note: </Text>
                <Text>Husband and wife who are both public officials and employees may file the required statement jointly or separately.</Text>
              </Text>
           </View> 

            <View style={styles.checkChoice}>
              <View style={[styles.checkText, { flexDirection: 'row', marginLeft: 138 }]}>
                <Image style={styles.ImageCheckbox} src={Unchecked} />
                <Text style={{ fontFamily: 'TimesRoman' }}> Joint Filing</Text>
              </View>
            
              <View style={[styles.checkText, {flexDirection: 'row', marginLeft: 47}]}>
                <Image style={styles.ImageCheckbox} src={Unchecked} /> 
                <Text style={{ fontFamily: 'TimesRoman' }}> Separate Filing</Text>
              </View>
            
              <View style={[styles.checkText, {flexDirection: 'row', marginLeft: 38}]}>
               <Image style={styles.ImageCheckbox} src={Unchecked} />
               <Text style={{ fontFamily: 'TimesRoman' }}> Not Applicable</Text>
              </View>
            </View>

            {/* personal  form  */}

          <View style={styles.page}>
            {/* right */}
            <View style={[styles.table, { width: '51.6%' }]}>
                
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold'}]}>DECLARANT:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1, marginLeft: '12px' }]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textTransform: 'capitalize' }]}>
                    {dataInfo.SURNAME ?? "N/A"}
                 </Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1 }]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textTransform: 'capitalize' }]}>
                    {dataInfo.FINAME ?? "N/A"}
                  </Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1 }]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textTransform: 'capitalize' }]}>
                    {dataInfo.MIDNAME[0] ?? "N/A"}.
                  </Text>
                </View>
              </View>


              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold' }]}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '18px' }]}> (Family Name)</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '20px' }]}> (First Name)</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '28px' }]}>(M.I.)</Text>
                </View>
              </View>

           
              <View style={[styles.tableRow, { marginTop: 4, marginLeft:'4px' }]}>
                <View style={styles.tableCol}>
                  <Text style={{fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left"}}>ADDRESS:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1, textAlign:"left", width: '132%'}]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRoman', textAlign: "left" }}>{addressLine1}</Text>
                </View>
              </View>
              
              <View style={[styles.tableRow, addressLine2 ? { marginTop: 3, marginLeft:'4px' } : { marginTop: 5, marginLeft:'4px' } ]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', marginLeft: '2px' }]}></Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1, textAlign:"left", width: '132%'}]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRoman' }}>{addressLine2}</Text>
                </View>
              </View>

              {
                (dataSpouse.SSURNAME === null && dataSpouse.SFIRSTNAME === null && dataSpouse.SMIDNAME === null) ? 

                  <View style={[styles.tableRow, {marginTop: 8, marginLeft:'4px'}]}>
                    <View style={styles.tableCol}>
                      <Text style={{fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left"}}>SPOUSE:</Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1, marginLeft: '8px' }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}> N/A</Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1 }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}> </Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1 }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}></Text>
                    </View>
                  </View> 
                  :
                  <View style={[styles.tableRow, {marginTop: 8, marginLeft:'4px'}]}>
                    <View style={styles.tableCol}>
                      <Text style={{fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left"}}>SPOUSE:</Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1, marginLeft: '8px' }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}> {dataSpouse.SSURNAME ?? 'N/A'}</Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1 }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}> {dataSpouse.SFIRSTNAME ?? 'N/A'}</Text>
                    </View>
                    <View style={[styles.tableCol, { borderBottom: 1 }]}>
                      <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}>{dataSpouse.SMIDNAME ?? 'N/A'}</Text>
                    </View>
                  </View>
              }

          

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold' }]}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '18px' }]}> (Family Name)</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '20px' }]}> (First Name)</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '6px', fontFamily: 'TimesRoman', marginLeft: '28px' }]}>(M.I.)</Text>
                </View>
              </View>

            
            </View>
            {/* end right */}

              {/* left */}
          <View style={[styles.table, { width: '50%', marginRight:'32px'}]}>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", marginLeft: "22px" }]}>POSITION:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}>
                     {dataInfo.designation ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", }]}>AGENCY / OFFICE:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign: "center" }]}>
                    {dataInfo.offdesc ?? "N/A"}
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", }]}>OFFICE ADDRESS:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign:"left" }]}>{officeAddress1}</Text>
                </View>
              </View>

              <View style={[styles.tableRow, officeAddress2 ? { marginTop: 2 } : { marginTop: 5 }]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8x', fontFamily: 'TimesRomanBold' }]}></Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign:"left" }]}>{officeAddress2}</Text>
                </View>
              </View>

              <View style={[styles.tableRow, {marginTop: 7,}]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", marginLeft: "22px" }]}>POSITION:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman' }]}>N/A</Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", }]}>AGENCY / OFFICE:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign:"center" }]}>MANAGEMENT INFORMATION SERVICES OFFIC</Text>
                </View>
              </View>

              <View style={[styles.tableRow]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8px', fontFamily: 'TimesRomanBold', textAlign: "left", }]}>OFFICE ADDRESS:</Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign:"left" }]}>{officeAddress1}</Text>
                </View>
              </View>
              <View style={[styles.tableRow, officeAddress2 ? { marginTop: 2 } : { marginTop: 5 }]}>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { fontSize: '8x', fontFamily: 'TimesRomanBold' }]}></Text>
                </View>
                <View style={[styles.tableCol, { borderBottom: 1}]}>
                  <Text style={[styles.tableCell, { fontSize: '7px', fontFamily: 'TimesRoman', textAlign:"left" }]}>{officeAddress2}</Text>
                </View>
              </View>

            </View>
            {/*end left */}

          </View>

          <Text style={{ marginTop: 10, marginLeft: "35px", width: 520, borderColor: 'black', borderTop: 1 }}></Text>
          <Text style={{ marginTop: 2,  marginLeft: "35px", width: 520, borderColor: 'black', borderTop: 1  }}></Text>

          <View style={styles.TitleChildren}>
            <Text style={styles.secondTitle}>UNMARRIED CHILDREN BELOW EIGHTEEN (18) YEARS OF AGE LIVING IN DECLARANT'S HOUSEHOLD</Text>
          </View>


          <View style={styles.properties}>
            <View style={styles.tableProperties}>

              <View style={[styles.tablePropertiesRow, styles.tablePropertiesHeader]}>
                <View style={[styles.tablePropertiesCell, { width: '45%' }]}>
                  <Text>NAME</Text>
                </View>
                <View style={[styles.tablePropertiesCell, { width: '35%' }]}>
                  <Text>DATE OF BIRTH</Text>
                </View>
                <View style={[styles.tablePropertiesCell, { width: '15%' }]}>
                  <Text>AGE</Text>
                </View>
              </View>

               {/* table body */}
              {
                dataChild.map((data) => {
                  const {
                    id,
                    children_name,
                    children_bday,
                  } = data; 
                  const age = calculateAge(children_bday);
                  
                  const dateForamt = format(new Date(children_bday), 'MM/dd/yyyy');
                  return (
                    
                    <View style={[styles.tableBodyRow]} key={id}>
                      <View style={[styles.tableBodyCell, { width: '47%' }]}>
                        <Text style={[styles.textcell]}>{children_name}</Text>
                      </View>
                      <View style={[styles.tableBodyCell, { width: '25%' }]}>
                        <Text style={[styles.textcell]}>{dateForamt}</Text>
                      </View>
                      <View style={[styles.tableBodyCell, { width: '15%' }]}>
                        <Text style={[styles.textcell]}>{age}</Text>
                      </View>
                    </View>


                  )

                })
              } 
              
              

            </View>
          </View>
          {/* end properties */}


          <Text style={{ marginTop: 10, marginLeft: "35px", width: 520, borderColor: 'black', borderTop: 1 }}></Text>
          <Text style={{ marginTop: 2,  marginLeft: "35px", width: 520, borderColor: 'black', borderTop: 1  }}></Text>
          
           <View style={styles.TitleAssetsLiabilitiesNet}>
            <Text style={{ fontFamily: 'TimesRomanBold', fontSize: 9.6, textDecoration: 'underline' }}>ASSETS, LIABILITIES AND NETWORTH</Text>
            <Text style={{ fontSize: 10}}>(Including those of the spouse and unmarried children below eighteen (18)</Text>
            <Text style={{ fontSize: 10}}>years of age living in declarant's household)</Text>
          </View>  

          {/* Real Properties */}
          <Text style={{ fontSize: 9, fontFamily: 'TimesRomanBold', fontWeight: '900', marginLeft: '35px' }}>1. &nbsp;ASSETS</Text>
          <Text style={{ fontSize: 9, fontFamily: 'TimesRomanBold', marginLeft: '49px', marginTop: '5px' }}>a. &nbsp;&nbsp;&nbsp;Real Properties*</Text>

           <View style={[styles.RealProperty]}>
            <View style={[styles.tableRealProp]}>

              {/* Header */}
              <View style={[styles.tableRowReal, styles.tableHeaderReal]}>

                <View style={[styles.tablesCellReal, { width: '13%' }]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRomanBold', marginTop: '5px' }}>DISCRIPTION</Text>
                  <Text style={{fontSize: 6, fontFamily: 'TimesRomanBold', marginTop:'2px', textAlign:'center' }}>(e.g lot house and lot, condominium and improvements)</Text>
                </View>
                <View style={[styles.tablesCellReal, { width: '14%' }]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRomanBold', marginTop: '5px' }}>KIND</Text>
                  <Text style={{fontSize: 6, fontFamily: 'TimesRomanBold', marginTop:'2px', textAlign:'center' }}>(e.g residential, commercial, industrial, agriculture and mixed use)</Text>
                </View> 
                <View style={[styles.tablesCellReal, { width: '18%' }]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRomanBold', marginTop: '5px'  }}>EXACT</Text>
                  <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop:'3px'}}>LOCATION</Text>
                </View>
                

                 {/* Assessed Value, Market Value, and Description */}
                <View style={[styles.ValueContainer]}>
                  <View style={[styles.valueRow]}>
                      <View style={[styles.tablesCellReal, {  width: '80%', borderBottomWidth: 0.5, textAlign: 'center'}]}>
                        <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop: '5px' }}>ASSESSED</Text>
                        <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop:'3px'}}>VALUE</Text>
                      </View>
                      <View style={[styles.tablesCellReal, {  width: '100%', borderBottomWidth: 0.5, textAlign: 'center'}]}>
                        <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop: '5px'}}>CURRENT FAIR</Text>
                        <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop:'3px'}}>MARKET VALUE</Text>
                      </View>
                  </View>
                
                  <View style={[styles.tablesCellReal, styles.valueCell]}>
                    <Text style={{fontSize: 6, fontFamily:'TimesRoman', marginTop: '3px', textAlign:'center'}}>(As found in the Tax Declaration of Real Property)</Text>
                  </View>
                </View>

                {/* Acquisition, Year, and Mode */}
                <View style={[styles.acquisitionContainer]}>

                  <View style={[styles.tablesCellReal,  {  width: '100%', borderBottomWidth: 0.5, textAlign: 'center'}]}>
                    <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop: '5px', marginBottom: '11.8px' }}>ACQUISITION</Text>
                  </View>

                  <View style={[styles.yearModeRow]}>
                    <View style={[styles.tablesCellReal, styles.yearModeCell]}>
                      <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop: '5px', marginBottom:'7px'}}>YEAR</Text>
                    </View>
                    <View style={[styles.tablesCellReal, styles.yearModeCell]}>
                      <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop: '5px', marginBottom:'7px'}}>MODE</Text>
                    </View>
                  </View>

                </View>

                <View style={[styles.tablesCellReal, { width: '15%' }]}>
                  <Text style={{ fontSize: 7, fontFamily: 'TimesRomanBold', marginTop: '5px'}}>ACQUISITION </Text>
                  <Text style={{fontSize: 7, fontFamily:'TimesRomanBold', marginTop:'3px'}}>CODE</Text>
                </View>


              </View>
              {/* end of header */}

              {/* tbody */}
              {
                dataRealProper.map((data) => {

                  const { 
                    id, 
                    description, 
                    kind, 
                    exact_location,
                    assessed_value, 
                    market_value, 
                    acquisition_year,
                    acquisition_mode, 
                    acquisition_cost
                  } = data;
                  
                  return (

                  <View style={styles.tableRowReal} key={id}>
                      <View style={[styles.tablesCellReal, { width: '13%' }]}>
                          <Text style={{ fontSize: 8, marginTop: '2px', textAlign: 'center' }}>
                            {description}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '14%' }]}>
                          <Text style={{fontSize: 8, marginTop:'2px', textAlign:'center' }}>
                            {kind}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '18%' }]}>
                          <Text style={{fontSize: 8, marginTop:'3px', textAlign:'center'}}>
                            {exact_location}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '11%' }]}>
                          <Text style={{ marginTop:'3px', textAlign:'center'}}>
                            {formatNumber(assessed_value)}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '14%' }]}>
                          <Text style={{ marginTop:'3px', textAlign:'center'}}>
                            {formatNumber(market_value)}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '7.5%' }]}>
                          <Text style={{ marginTop:'3px', textAlign:'center'}}>
                            {acquisition_year}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '7.5%' }]}>
                          <Text style={{ marginTop:'3px', textAlign:'center'}}>
                            {acquisition_mode}
                          </Text>
                        </View>

                        <View style={[styles.tablesCellReal, { width: '15%' }]}>
                          <Text style={{ marginTop:'3px', textAlign:'center'}}>
                            {formatNumber(acquisition_cost)}
                          </Text>
                        </View>
                    </View>


                  );

                })
              }

             {/* end of tbody */}


            </View>
          </View>


            <View style={[styles.tableRowSubTotal, {marginLeft: '430px'}]}>
              <View style={styles.tableColSubTotal}> 
                  <Text style={[styles.tableCellSubtotal, { fontSize: '9px', fontFamily: 'TimesRomanBold'}]}>Subtotal: </Text>
              </View>
              <View style={[styles.tableColSubTotal, { borderBottom: 1,  width:'70px' }]}> 
                <Text style={[styles.tableCellSubtotal, { fontSize: '9px', fontFamily: 'TimesRomanBold' }]}>
                  {formatNumber(totalRealProper) ?? ""}
                </Text>
              </View>
            </View>

     
      

          
             {/* Personal Properties */}
           <Text style={{fontSize: 9, fontFamily:'TimesRomanBold', marginLeft: '49px', marginTop:'4px'}}>b. &nbsp;&nbsp;&nbsp;&nbsp;Perosanl Properties*</Text>
        
            <View style={[styles.PersonalProperty]}>
              <View style={styles.staticTable}>
                  {/* Header  */}
                  <View style={styles.staticTableRow}>
                      <View style={[styles.staticTableCol, styles.staticTableHeader, {width:"56%"}]}>
                          <Text>DESRIPTION</Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, styles.staticTableHeader, {width:"29%"}]}>
                          <Text style={styles.staticTableCell}>YEAR ACQUIRED</Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, styles.staticTableHeader, {width:"15%"}]}>
                        <Text style={styles.staticTableCell}>ACQUISITION</Text>
                        <Text style={styles.staticTableCell}>COST/AMOUNT</Text>
                      </View>
                  </View>
              {/* end */}
              

              {/* body */}
                  {
                    dataPersonalProper.map((data) => {
                      const { 
                        id, 
                        acquisition_cost,
                        description,
                        year_aquired
                      } = data;
                      return (
                          <View style={styles.staticTableRow} key={id}>
                            <View style={[styles.staticTableCol, {width:"56%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{description}</Text>
                            </View>
                            
                            <View style={[styles.staticTableCol, {width:"29%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{year_aquired}</Text>
                            </View>
                            
                            <View style={[styles.staticTableCol, {width:"15%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{ formatNumber(acquisition_cost)}</Text>
                            </View>
                          </View>
                      )
                    })
                  }
              {/* end */}
              </View>
            </View>
              
            {/* Subtotal and total Properties  */}
            <View style={[styles.tableRowSubTotal, {marginLeft: '430px'}]}>
              <View style={styles.tableColSubTotal}> 
                  <Text style={[styles.tableCellSubtotal, { fontSize: '9px', fontFamily: 'TimesRomanBold'}]}>Subtotal: </Text>
              </View>
              <View style={[styles.tableColSubTotal, { borderBottom: 1, width:'70px' }]}> 
                <Text style={[styles.tableCellSubtotal, { fontSize: '9px', fontFamily: 'TimesRomanBold' }]}>
                  {formatNumber(totalPersonalProper) ?? ""}
                </Text>
              </View>
            </View>
          
           <View style={[styles.tableRowTotal, {marginLeft: '380px'}]}>
              <View style={styles.tableColTotal}> 
                  <Text style={[styles.tableCelltotal, { fontSize: '9px', fontFamily: 'TimesRomanBold'}]}>TOTAL ASSETS(a+b): </Text>
              </View>
              <View style={[styles.tableColTotal, { borderBottom: 1, width:'70px' }]}> 
                <Text style={[styles.tableCelltotal, { fontSize: '9px', fontFamily: 'TimesRomanBold' }]}>
                  {formatNumber(totalProper) ?? ""}
                </Text>
              </View>
            </View>
          

        

            <Text style={{ fontSize: 9, fontFamily:'TimesRomanItalic',marginTop:'20px', textAlign:'center'}} render={({ pageNumber, totalPages }) => (`Page ${pageNumber} of ${totalPages}`)} ></Text>


        </View>


      </Page>

      {/* Second Page */}
      <Page size="LEGAL">
        <View style={styles.sections}>

           <Text style={{ fontSize: 9, fontFamily: 'TimesRomanBold', fontWeight: '900', marginLeft: '35px' }}>2. &nbsp;&nbsp;LIABILITIES</Text>
       
            <View style={[styles.PersonalProperty]}>
              <View style={styles.staticTable}>
                  {/* Header  */}
                  <View style={styles.staticTableRow}>
                      <View style={[styles.staticTableCol, styles.staticLiabilitiesTableHeader, {width:"40%"}]}>
                          <Text>NATURE</Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, styles.staticLiabilitiesTableHeader, {width:"40%"}]}>
                          <Text>NAME OF CREDITORS</Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, styles.staticLiabilitiesTableHeader, {width:"20%"}]}>
                        <Text>OUTSTANDING BALANCE</Text>
                      </View>
                  </View>
                  {/* end */}

              {/* body */}
                  {
                dataLiabilities.length > 0 ?
              
                    dataLiabilities.map((data) => {
                      const { 
                        id, 
                        name_creditors,
                        nature,
                        outstanding_balance
                      } = data;  

                      return (
                        <View style={styles.staticTableRow} key={id}>
                          <View style={[styles.staticTableCol, {width:"40%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{nature}</Text>
                          </View>
                          
                          <View style={[styles.staticTableCol, {width:"40%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{name_creditors}</Text>
                          </View>
                          
                          <View style={[styles.staticTableCol, {width:"20%"}]}>
                            <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{formatNumber(outstanding_balance)}</Text>
                          </View>
                        </View>
                      )

                    })
                  :
                  
                    <View style={styles.staticTableRow}>
                      <View style={[styles.staticTableCol, {width:"40%"}]}>
                        <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, {width:"40%"}]}>
                        <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                      </View>
                      
                      <View style={[styles.staticTableCol, {width:"20%"}]}>
                        <Text style={[styles.staticTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                      </View>
                    </View>
                  
                  
               }


            
                 {/* end */}
              </View>
            </View>
              
            {/* <Text style={{fontSize: 10, fontFamily:'TimesRomanBold',marginTop:'2px', marginLeft: '338px'}}>TOTAL LIABILITIES:  ___________________</Text> */}
              
          
          <View style={[styles.tableRowSubTotalLiabilities, {marginLeft: '340px'}]}>
            <View style={[styles.tableColSubTotalLiabilities, {width: '110px'}]}> 
                  <Text style={[styles.tableCellSubtotalLiabilities, { fontSize: '10px', fontFamily: 'TimesRomanBold'}]}>TOTAL LIABILITIES: </Text>
              </View>
              <View style={[styles.tableColSubTotalLiabilities, { borderBottom: 1, width:'100px' }]}> 
                <Text style={[styles.tableCellSubtotalLiabilities, { fontSize: '9px', fontFamily: 'TimesRomanBold' }]}>
                  {formatNumber(totalLiabilities) ?? ""}
                </Text>
              </View>
          </View>

          <View style={[styles.tableRowSubTotalLiabilities, {marginLeft: '190px'}]}>
            <View style={[styles.tableColSubTotalLiabilities, {width: '260px'}]}> 
                  <Text style={[styles.tableCellSubtotalLiabilities, { fontSize: '10px', fontFamily: 'TimesRomanBold'}]}>NET WORTH : Total Assets less Total Liabilities = </Text>
              </View>
              <View style={[styles.tableColSubTotalLiabilities, { borderBottom: 1, width:'100px' }]}> 
                <Text style={[styles.tableCellSubtotalLiabilities, { fontSize: '9px', fontFamily: 'TimesRomanBold' }]}>
                  {formatNumber(totalNetWorth) ?? ""}
                </Text>
              </View>
          </View>
          
            {/* <Text style={{fontSize: 9, fontFamily:'TimesRomanBold',marginTop:'10px', marginLeft: '220px'}}>NET WORTH : Total Assets less Total Liabilities =  ____________________</Text> */}
          {/* End */}
          

           {/* Business interest */}
          <View style={styles.TitleBusinessInterest}>
            <Text style={{ fontFamily: 'TimesRomanBold', fontSize: 9.6, textDecoration: 'underline' }}>BUSINESS INTERESTS AND FINANCIAL CONNECTIONS</Text>
            <Text style={{ fontSize: 8, marginTop: "3px"}}>(of Declarant / Declarant's spouse / Unmarried Children Below Eighteen (18) years of Age Living in Declarant's Household)</Text>
           
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '3px' }}>
             
              <Image src={dataBusinessInter.length > 0 ? Unchecked : checked} style={styles.businessBoxImage} /> 
          

              <Text style={{ fontSize: 9, fontWeight: 'black' }}>
                &nbsp;&nbsp;I/We do not have any business interest or financial connection.
              </Text>
            </View>
           
          </View>

          <View style={[styles.BusinessInterest]}>
            <View style={styles.BusinessTable}>

              {/* Header  */}
                  <View style={styles.BusinessTableRow}>
                      <View style={[styles.BusinessTableCol, styles.businessTableHeader, {width:"25%"}]}>
                          <Text>NAME OF ENTITY / BUSINESS ENTERPRISE</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.businessTableHeader, {width:"25%"}]}>
                          <Text>BUSINESS ADDRESS</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.businessTableHeader, {width:"25%"}]}>
                        <Text>{'NATURE OF BUSINESS \n INTEREST &/OR FINANCIAL CONNECTION'}</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.businessTableHeader, {width:"25%"}]}>
                        <Text>DATE OF ACQUISITION OF INTEREST OR CONNECTION</Text>
                      </View>
                  </View>
              {/* end */}
              

              {/* body */}

              {
              dataBusinessInter.length > 0 ? 
                dataBusinessInter.map((data) => {
                  const { 
                    id, 
                    business_enterprise,
                    business_address,
                    nature_business,
                    date_acquired    
                  } = data;  
                  
                  return (

                    <View style={styles.BusinessTableRow} key={id}>
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{business_enterprise}</Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{business_address}</Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{nature_business}</Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{date_acquired}</Text>
                        </View>
                    </View>

                  )

                }) : 
                     <View style={styles.BusinessTableRow} >
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                        </View>
                        
                        <View style={[styles.BusinessTableCol, {width:"25%"}]}>
                        <Text style={[styles.businessTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}></Text>
                        </View>
                    </View>

              }
              
                  

                     {/* end  */}
            </View>
          </View>
          {/* end  */}


          {/* Relative in the government service */}
          <View style={styles.TitleBusinessInterest}>
            <Text style={{ fontFamily: 'TimesRomanBold', fontSize: 9.6, textDecoration: 'underline' }}>RELATIVE IN THE GOVERNMENT SERVICE</Text>
            <Text style={{ fontSize: 8, marginTop: "3px"}}>(Within the Fourth Degree of Consanguinity or Affinity. Include also Bilas, Balae and Inso)</Text>
           <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '3px' }}>
               <Image src={dataGovRelatives.length > 0 ? Unchecked : checked} style={styles.businessBoxImage} />
              <Text style={{ fontSize: 9, fontWeight:'black' }}>
                &nbsp;&nbsp;I/We do not know of any relative/ s in the government service
              </Text>
            </View>
            
          </View>


          <View style={[styles.BusinessInterest]}>
              <View style={styles.BusinessTable}>
                  {/* Header  */}
                  <View style={styles.BusinessTableRow}>
                      <View style={[styles.BusinessTableCol, styles.relativesTableHeader, {width:"30%"}]}>
                          <Text>NAME OF RELATIVES</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.relativesTableHeader, {width:"20%"}]}>
                          <Text>RELATIONSHIP</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.relativesTableHeader, {width:"15%"}]}>
                        <Text>POSITION</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, styles.relativesTableHeader, {width:"35%"}]}>
                        <Text>NAME OF AGENCY / OFFICE AND ADDRESS</Text>
                      </View>
                  </View>
              {/* end */}
              

              {/* body */}
                {
                dataGovRelatives.length > 0 ? 
                  dataGovRelatives.map((data) => {
                    const { 
                      id, 
                      name_relatives,
                      relationship,
                      position,
                      name_agency    
                    } = data; 

                    return (

                    <View style={styles.BusinessTableRow} key={id}>
                      <View style={[styles.BusinessTableCol, {width:"30%"}]}>
                          <Text style={[styles.relativesTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{name_relatives}</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"20%"}]}>
                          <Text style={[styles.relativesTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{relationship}</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"15%"}]}>
                          <Text style={[styles.relativesTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{position}</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"35%"}]}>
                          <Text style={[styles.relativesTableValueCell, { marginBottom: '-5px', marginTop: '-3px' }]}>{name_agency}</Text>
                      </View>
                  </View>

                    )

                  })
                :
                    <View style={styles.BusinessTableRow}>
                      <View style={[styles.BusinessTableCol, {width:"30%"}]}>
                        <Text style={[styles.relativesTableValueCell, {marginBottom: '-5px', marginTop: '-3px'}]}>Nadine Lustre</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"20%"}]}>
                          <Text style={[styles.relativesTableValueCell, {marginBottom: '-5px', marginTop: '-3px'}]}>sister</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"15%"}]}>
                        <Text style={[styles.relativesTableValueCell, {marginBottom: '-5px', marginTop: '-3px'}]}>Attorney</Text>
                      </View>
                      
                      <View style={[styles.BusinessTableCol, {width:"35%"}]}>
                        <Text style={[styles.relativesTableValueCell, {marginBottom: '-5px', marginTop: '-3px'}]}>Department of Education, Quezon City</Text>
                      </View>
                  </View>
                }
              
                  

                  
              
              </View>
            </View>
          {/* end  */}


          {/* certify */}
            <Text style={[styles.certifyText, {marginLeft: '80px', marginTop: '15px'}]}>
              I hereby certify that these are true and correct statements of my assets, liabilities, net worth,
            </Text>
            <Text style={[styles.certifyText, {width: '485px', marginLeft: '50px'}]}>
              business interest and financial connections, including those of my spouse and unmarried children below eighteen (18) years of age living in my household, and that to the best of my knowledge,
              the above-enumerated are names of my relatives in the government within the fourth civil degree of consanguity or affinity.
            </Text>
          

            <Text style={[styles.certifyText, {marginLeft: '80px', marginTop: '15px'}]}>
              I hereby authorize the Ombudsman or his/her duly authorized representative to obtain and 
            </Text>
            <Text style={[styles.certifyText, {width: '485px', marginLeft: '50px'}]}>
            secure from all appropriate government agencies, including the Bureau of Internal Revenue such documents that may show my assets, liabilities, net worth, business interests and financial connections, to include those of my spouse and unmarried children below 18
            years of age living with me in my household covering previous years to include the year I first assumed office in government.
          </Text>
          

          <View style={styles.dateCertify}>
            <Text>Date:</Text>
            <Text style={{ paddingLeft: "10px", textDecoration: 'underline' }}>{getCurrentDate()}</Text>
          </View>
    
          <View style={{ flexDirection: 'row', gap: '5px' }}>
            <View style={{ marginTop: "20px", marginLeft: '50px' }}>
              <Text>________________________</Text>        
              <Text style={{ fontSize: '9px', marginLeft: '65px' }}>(Signature of  Declarant)</Text> 
            </View>
            
            <View style={{ marginTop: "20px", marginLeft: '50px'}}>
              <Text>________________________</Text>        
              <Text style={{ fontSize: '9px', marginLeft: '30px' }}>(Signature of  Co-Declarant/Spouse)</Text> 
            </View> 
        </View>
        
      <View style={{ flexDirection:'row', marginLeft: '5px', gap:'-25px'}}>

        <View style={{ flexDirection:'column', gap:'-2px', marginTop: '15px', marginLeft: '50px' }}>
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>Goverment Issued ID: &nbsp;&nbsp;</Text>
            <View style={{ borderBottom: 1, width: "50%", }}>
              <Text> &nbsp;&nbsp;&nbsp;</Text>
            </View>
            
          </View>  
          
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>ID No.:</Text>
            <View style={{ borderBottom: 1, width: "50%",marginLeft: '66px' }}>
              <Text>&nbsp;&nbsp;&nbsp;</Text>
            </View>
          </View>  
            
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>Date Issued: </Text>
             <View style={{ borderBottom: 1, width: "50%",marginLeft: '42px' }}>
              <Text>&nbsp;&nbsp;&nbsp;</Text>
            </View>
          </View>  
        </View>
        
        <View style={{ flexDirection:'column', gap:'-2px', marginTop: '15px', marginLeft: '50px' }}>
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>Goverment Issued ID: &nbsp;&nbsp;</Text>
            <View style={{ borderBottom: 1, width: "50%", }}>
              <Text> &nbsp;&nbsp;&nbsp;</Text>
            </View>
            
          </View>  
          
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>ID No.:</Text>
            <View style={{ borderBottom: 1, width: "50%",marginLeft: '66px' }}>
              <Text>&nbsp;&nbsp;&nbsp;</Text>
            </View>
          </View>  
            
          <View style={{ flexDirection:'row', fontSize: '8px', marginTop: '5px' }}>
            <Text>Date Issued: </Text>
             <View style={{ borderBottom: 1, width: "50%",marginLeft: '42px' }}>
              <Text>&nbsp;&nbsp;&nbsp;</Text>
            </View>
          </View>  
        </View>
            
      </View>  
                
        <View style={{ marginTop: 15, marginLeft:'60px', fontSize: '10px', width: "489px" }} >
        
          <Text>
            SUBSCRIBED AND SWORN to before me this{' '}
          <Text style={{ textDecoration: 'underline', }}> ________</Text>{' '}day of{' '}
          <Text style={{ textDecoration: 'underline', }}>
            ________
          </Text>
          , affiant exhibiting to me the above-stated government issued identification card.
        
        </Text>
        
      </View>
     
          
      <View style={{ marginLeft: '260px' }}>
         <View style={{ marginTop: "20px", marginLeft: '50px' }}>
            <Text>_____________________</Text>        
            <Text style={{ fontSize: '9px', marginLeft: '38px' }}>(Person Administermg Oath)</Text> 
          </View>
      </View>
   
           <Text style={{ fontSize: 9, fontFamily:'TimesRomanItalic',marginTop:'20px', textAlign:'center'}} render={({ pageNumber, totalPages }) => (`Page ${pageNumber} of ${totalPages}`)} ></Text>

        </View>
      </Page>

    </Document>
  )
}
