import axios from '../../../../services/axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function LiabilitiesModal(props) {

  const [fetchEmpInfo, setEmpInfo] = useState({
      IDNO: "",
  });
  const [fetchData, setFetchData] = useState({
    nature: "",
    name_creditors: "",
    outstanding_balance: "",
  });
  const [error, setError] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "outstanding_balance") {

      const cleanedValue = value.replace(/[^\d]/g, '');
      const formattedValue = formatNumberWithCommas(cleanedValue);
      
      setFetchData({ ...fetchData, [name]: formattedValue });
    } else {
      setFetchData({ ...fetchData, [name]: value });
    }

  };

    useEffect(() => {
        axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
         setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);

  const submitData = async (e) => {
    e.preventDefault();
    
     const outstandingBalance = fetchData.outstanding_balance.replace(/,/g, '');

    await axios.post(`/api/storedLiabilities`, {
      IDNO: fetchEmpInfo.IDNO,
      nature: fetchData.nature,
      name_creditors: fetchData.name_creditors,
      outstanding_balance: outstandingBalance,
    }).then((res) => {
      
      // console.log(res.data.message);

      if (res.data.status === 422) {
        
        Swal.fire({
            icon: 'error',
            title: 'All fields are required',
        })
         setError(res.data.errors);
      } else {


         Swal.fire({
              icon: "success",
              title: res.data.message,
            }).then((res) => {
              props.handleClose(false)
              props.fetchDataSALN(props.unique)
              setFetchData({
                nature: '',
                name_creditors: '',
                outstanding_balance: '',
              });
            })
      }

    });

  }


  return (
    <>
    <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header >
          <Modal.Title>Create Liabilities</Modal.Title>
        </Modal.Header>
        
        <Form onSubmit={submitData}>
            <Modal.Body>
                
                      
            <div className="form-row">
                <div className="form-group col-md-12">
                    <label>Nature</label>
                   <input type="text" className={`form-control ${error.nature ? 'is-invalid' : ''}`} name="nature" value={fetchData.nature ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.nature}</div>
                </div>    
                
                 <div className="form-group col-md-12">
                    <label>Name of Creditors</label>
                    <input type="text" className={`form-control ${error.name_creditors ? 'is-invalid' : ''}`} name="name_creditors" value={fetchData.name_creditors ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.name_creditors}</div>
                </div>   

                <div className="form-group col-md-6">
                    <label>Outstanding Balance</label>
                     <input type="text" className={`form-control ${error.outstanding_balance ? 'is-invalid' : ''}`} name="outstanding_balance" value={fetchData.outstanding_balance ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.outstanding_balance}</div>
                </div>        
                    
            </div>
            {/* form-row */}


            </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-danger" onClick={props.handleClose}>
                Close
            </Button>

            <Button type="submit" variant="success">
            Save 
          </Button>
        </Modal.Footer>
           </Form>
      </Modal> 
    </>
  )
}
