import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from '../../../../services/axios';
import { debounce } from 'lodash';
// import { useParams } from 'react-router-dom';



export default function ChildModal(props) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [EmpInfo, setEmpInfo] = useState([]);
    const [modeldata, setModaldata] = useState({
        CHILDNAME: '',
        CBIRTHDATE: '',
    });  
    const [error, setError] = useState([]);  


    // display the Emp_IDNUMBER
    
 useEffect(() => {
 
        const fetchEmpData = async () => {
            try {
                const response = await axios.get(`/api/EmpFullInfo/${props.unique}`);
                setEmpInfo(response.data.EmpFullDetails[0]);
              
            } catch (error) {
                console.error("Error fetching employee data:", error); 

            } 
        };

        const debouncedFetch = debounce(fetchEmpData, 1000); // Adjust the delay time as needed

        debouncedFetch();

        // Return a cleanup function
        return () => debouncedFetch.cancel();
    }, [props.unique]);




    // Stored Data
    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }


    


    
    const submitChildren = async (e) => {
        e.preventDefault();

        await axios.post(`/api/ChildreAdd`, {
            IDNO: EmpInfo.IDNO,
            CHILDNAME: modeldata.CHILDNAME,
            CBIRTHDATE: modeldata.CBIRTHDATE,
            
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {
                // console.log(res.data.message);                
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                   
                    props.fetchDataChild(props.unique);
                    setError("");
                    setShow(false);
                    setModaldata({
                        CHILDNAME: '',
                        CBIRTHDATE: '',
                    });
                })
            }
        
        })

    }

  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
            <i className="fas fa-user-plus" />
        </Button>
          
        <Modal size="md" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-sm">
      
            <Modal.Header>
                    <Modal.Title><i className="fas fa-baby"/> Add Children Details </Modal.Title>
            </Modal.Header>

            <Form onSubmit={submitChildren}>

            <Modal.Body>
              
                    <div className="modal-body">
                       
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Name of Child</label>
                            <div className="col-sm-8">
                                <input type="text" className={`form-control ${error.CHILDNAME ? 'is-invalid' : ''}`} name="CHILDNAME" id="children_name_id" value={modeldata.CHILDNAME ?? ""} onChange={handleChange} />
                                <div className="invalid-feedback"> { error.CHILDNAME } </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Date of Birth</label>
                            <div className="col-sm-8">
                                <input type="date" className={`form-control ${error.CBIRTHDATE ? 'is-invalid' : ''}`} name="CBIRTHDATE" id="children_bday_id" value={modeldata.CBIRTHDATE ?? ""} onChange={handleChange} />
                                  <div className="invalid-feedback"> {error.CBIRTHDATE} </div>
                                  
                                  
                            </div>
                        </div>

                    </div>


                </Modal.Body>
                    
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 
                      
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>  
    </>
  )
}
