import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import emptyPic from '../../../../image/emptyImage.jpg';
import '../../../../Styles.css';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from '../../../../services/axios';
import Spinner from 'react-bootstrap/Spinner';


export default function EmployeePic(props) {


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);
  
  const [btnLoading, setBtnLoading] = useState(false);
  const [imgProfile, setImgProfile] = useState(false);
  const [ImageError, setImageError] = useState(null);

  const [btnSubmit, setBtnSubmit] = useState(false);
  const fileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const maxSize = 2 * 1024 * 1024; // 2MB

  let profile;
  if (props.fetchEmpImage) {
    profile = props.profilePic;
  } else {
    profile = emptyPic;
  }

  const changeHandler = (e) => {
    const file = e.target.files[0];
    

    if (file) {
      if (!fileTypes.includes(file.type)) {
         setImageError('Please select a valid image file (JPEG, JPG, PNG).');
        setBtnSubmit(true);
        return;
      }

       // Validate file size
      if (file.size > maxSize) {
        setImageError('File size exceeds 5MB.');
        setBtnSubmit(true);
        return;
      }

      setImgProfile(file);
      setBtnSubmit(false);
      setImageError("");
    }
  };


  const submitPic = async (e) => {
    e.preventDefault();
     setBtnLoading(true);

    const formData = new FormData()
    formData.append('unique', props.unique);
    formData.append('profile_pic', imgProfile);

    await axios.post(`/api/UploadPic`, formData).then((res) => {
      // console.log(res.data.errors.profilePic);
      
      if (res.data.status === 422) {

         Swal.fire({
          icon: 'error',
          title: 'Upload Image fields are required',
         })
        setImageError(res.data.errors.profile_pic);

        setTimeout(() => {
              setBtnLoading(false);
          }, 2000);

      } else if (res.data.status === 200) {


          props.fetchEmpData(props.unique);
          setTimeout(() => {
              setBtnLoading(false);
          }, 2000);
          
      
        
      } 
        
    });

  }
  
  return (
    <div>
       <div className="container-profilepic card overflow-hidden"  onClick={handleOpen}>
        
          <Image src={profile} thumbnail style={{ backgroundRepeat: 'no-repeat', backgroundSize: '220px 220px', backgroundPosition: 'center' }} />
          <div className="middle-profilepic text-center card-img-overlay flex-column justify-content-center">
              <div className="text-profilepic">
                  <i className="fas fa-camera" />
                  <div className="text-profilepic">Change it</div>
              </div>
          </div>
      </div>
      
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Picture</Modal.Title>
        </Modal.Header>
         <Form onSubmit={submitPic} encType="multipart/form-data">
        <Modal.Body>
          
         
            <div className="row mb-3 ">
              {/* <label className="col-sm-5 col-form-label text-center">Upload Image</label> */}
              <div className="col-md-12">
                <input type="file" className={`form-control ${ImageError ? 'is-invalid' : ''}`} onChange={changeHandler} style={{ cursor: "pointer" }}/>
                <div className="invalid-feedback"> { ImageError } </div>  
              </div>
            </div>
      
 
        </Modal.Body>
          <Modal.Footer>
         
             {
                      btnLoading ? (
                     
                           <Button type="submit" id="btn_submit" variant="success" disabled>
                             <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading... 
                      
                          </Button>
                      ) : (
                         <Button variant="success" id="btn_submit" type="submit" disabled={btnSubmit}>
                            Save Changes
                          </Button>
                      )
                    }
                   
          
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  )
}
