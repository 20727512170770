import React from 'react'

import QuestionsForm from '../components/QuestionsForm'

export default function EmployeeQuestion() {
  return (
    <div>
        <div className="row">
                <div className="col-sm-6">
                <h4 className="m-2"><i className="fas fa-info-circle"/> Other Information</h4> 
            </div>{/* /.col */}   
            <div className="col-sm-6">
                <div className="float-right">
                    {/* <QuesModal /> */}
                </div>
            </div>{/* /.col */}
        </div>{/* /.row */}

        <hr /> 
          
        <QuestionsForm/>

    </div>
  )
}
