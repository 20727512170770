import React, { useEffect, useState } from 'react';
import axios from '../../../../services/axios'; 

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';



export default function ReferModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

  
    useEffect(() => {
       axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);
    
    const [inputValue, setInputValue] = useState({
        name1: '',
        name2: '',
        name3: '',
        address1: '',
        address2: '',
        address3: '',
        contact1: '',
        contact2: '',
        contact3: '',
    });

    const handleChange = (e) => {
        setInputValue(inputValue => ({ ...inputValue, [e.target.name]: e.target.value }))
    };

    const submitRefer = async (e) => {
        e.preventDefault();

        await axios.post(`/api/storedRef`, { IDNO: fetchEmpInfo.IDNO, inputValue }).then((res) => {
        
           if (res.status === 200) {
                 Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.fetchDataRefer(props.unique);
                    setShow(false);
                    setInputValue({
                        name1: '',
                        name2: '',
                        name3: '',
                        address1: '',
                        address2: '',
                        address3: '',
                        contact1: '',
                        contact2: '',
                        contact3: '',  
                    });
               
            });
           } else {
               console.log('failed to insert');
           }
           
        });

    };


  return (
    <>
        <Button variant="outline-primary" onClick={handleShow}>
            <i className="nav-icon fas fa-edit" />
        </Button>

         <Modal size="xl" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-users"/> Create References </Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitRefer}>

                <Modal.Body>

                   
                    <div className="form-row">
                    
                        <div className="form-group col-md-5">
                            <label >Reference Name</label>
                            <input type="text" className="form-control" name="name1" id="name_ref1_id" value={inputValue.name1}  onChange={handleChange}/>
                           
                        </div>
                        
                        <div className="form-group col-md-5">
                            <label>Reference Address</label>
                            <input type="text" className="form-control" name="address1" id="address_ref1_id" value={inputValue.address1}  onChange={handleChange}/>
                           
                        </div>

                        <div className="form-group col-md-2">
                            <label>Reference Tell No.</label>
                            <input type="text" className="form-control" name="contact1" id="tellno_ref1_id" value={inputValue.contact1}  onChange={handleChange} />
                            
                        </div>  

                    </div>
                      {/*  form-row */}


                      <div className="form-row">
                    
                        <div className="form-group col-md-5">
                            <input type="text" className="form-control"  name="name2" id="name_ref2_id" value={inputValue.name2}  onChange={handleChange} />
                             <div  className="invalid-feedback"> { } </div>
                        </div>
                        
                        <div className="form-group col-md-5">
                            <input type="text" className="form-control" name="address2" id="address_ref2_id" value={inputValue.address2}  onChange={handleChange} />
                             <div  className="invalid-feedback"> { } </div>
                        </div>

                        <div className="form-group col-md-2">
                            <input type="text" className="form-control" name="contact2" id="tellno_ref2_id" value={inputValue.contact2}  onChange={handleChange} />
                             <div  className="invalid-feedback"> { } </div>

                        </div>  

                    </div>
                      {/*  form-row */}

                    <div className="form-row">
                    
                        <div className="form-group col-md-5">
                            <input type="text" className="form-control" name="name3" id="name_ref3_id" value={inputValue.name3}  onChange={handleChange}  />
                             <div  className="invalid-feedback"> { } </div>
                        </div>
                        
                        <div className="form-group col-md-5">
                            <input type="text" className="form-control" name="address3" id="address_ref3_id" value={inputValue.address3}  onChange={handleChange}  />
                             <div  className="invalid-feedback"> { } </div>
                        </div>

                        <div className="form-group col-md-2">
                            <input type="text" className="form-control"  name="contact3" id="tellno_ref3_id" value={inputValue.contact3}  onChange={handleChange}  />
                             <div  className="invalid-feedback"> { } </div>
                        </div>  

                    </div>
                    {/*  form-row */}
                </Modal.Body>
                
                <Modal.Footer>
                <Button variant="success" type="submit" >
                    Save Changes
                </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
