import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import LiabilitiesModal from './LiabilitiesModal';
import LiabilitiesEditModal from './LiabilitiesEditModal';



const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function LiabilitiesTable(props) {

    const [showModal, setShowModal] = useState(false);
    const [editShowModal, setEditShowModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);

    const handleCreateModal = () => {
        setShowModal(true)
    };
    
    const handleClose = () => {
        setShowModal(false)
    };

    const handelEditModal = (data) => {
        setEditShowModal(true);
        setDataModal(data);
    };

    const EditCloseModal = () => {
        setEditShowModal(false);
    };


  return (
      <div>
        
        <div className="card">
            <div className="card-body">

                <div className="row mb-2">
                    <div className="col-sm-8">
                        <label className="fw-bold text-decoration-underline">2. LIABILITIES</label>
                    </div>{/* /.col */}   
                    <div className="col-sm-4">
                        <div className="float-right">
                            <Button type="button" variant="outline-primary" onClick={() => handleCreateModal()}><i className="nav-icon fas fa-plus" /> Add</Button>        
                        </div>
                    </div>{/* /.col */} 
                </div>{/* /.row */}

                <div className="table-responsive">
                    <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="text-center">Nature</th>
                                <th className="text-center">Name of Creditors</th>
                                <th className="text-center">Outstanding Balance</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.dataLiabilities.length > 0 ? 
                                    props.dataLiabilities.map((data, index) => {
                                    
                                        const { 
                                            nature,
                                            name_creditors,
                                            outstanding_balance,
                                        } = data;

                                        return (
                                            <tr key={index}>
                                                <td>{ nature }</td>
                                                <td>{ name_creditors }</td>
                                                <td>{ formatNumberWithCommas(outstanding_balance) }</td>
                                                <td align='center'>
                                                   <Button variant="outline-primary btn-sm" title="Edit" onClick={() => handelEditModal(data)}> <i className="nav-icon fas fa-edit" /></Button>       
                                                </td>
                                            </tr> 
                                        );
                                    
                                    })
                                      
                                :
                                      
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>   
                            }
                              
                        </tbody>
                    </table>
                </div>
                        
                  
                <div className="row justify-content-end">
                    <div className="col-md-4">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text fw-bolder">TOTAL LIABILITIES :</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={formatNumberWithCommas(props.totalLiabilities)} readOnly/>
                        </div>
                      </div>

                    <div className="col-md-6">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text fw-bolder">NET WORTH : Total Assets less Total Liabilities :</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={formatNumberWithCommas(props.totalNetWorth)}  readOnly/>
                        </div>        
                    </div>  
                </div>  
                  {/* <div className="row justify-content-end"> */}
                       
                    {/* </div>   */}
                    
             


                
            </div>
        </div> 
            
          <LiabilitiesModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
          <LiabilitiesEditModal show={editShowModal} EditCloseModal={EditCloseModal} data={dataModal} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />                 
    </div>
  )
}
