import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import RealPropertiesModal from './RealPropertiesModal';
import RealPropertiesEditModal from './RealPropertiesEditModal';


const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const RealPropertiesTable = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [DataEditModal, setDataEditModal] = useState([]);

    const handleCreateModal = () => {
        setShowModal(true);
    };
    const handleClose = () => {
        setShowModal(false);
    };

    const handelEditModal = (data) => {
        setDataEditModal(data);
        setShowEditModal(true);
    };

    const handleCloseEdit = () => {
         setShowEditModal(false);
    };




  return (
    <div>

        <div className="card">
            <div className="card-body">
            
                <div className="row mb-2">
                    <div className="col-sm-8">
                    <label className="fw-bold text-decoration-underline">1. ASSETS</label>
                    <br />
                        <label className="fw-bold text-decoration-underline">a. Real Properties</label>
                    </div>{/* /.col */}   
                    <div className="col-sm-4">
                        <div className="float-right">
                            <Button type="button" variant="outline-primary" onClick={() => handleCreateModal()} ><i className="nav-icon fas fa-plus" /> Add</Button>        
                        </div>
                    </div>{/* /.col */} 
                </div>{/* /.row */}
                    

                <div className="row mb-2">
                    <div className="col">
                    <div className="table-responsive">
                        
                        <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                                <tr key={1}>
                                    <th rowSpan="2" className="text-center">Description</th>
                                    <th rowSpan="2" className="text-center">Kind</th>
                                    <th rowSpan="2" className="text-center">Exact Location</th>
                                    <th rowSpan="2" className="text-center">Assessed Value</th>
                                    <th rowSpan="3" className="text-center">Current Fair <br/> Market Value</th>
                                    <th colSpan="2" className="text-center">Acquisition</th>
                                    <th rowSpan="2" className="text-center">Acquisition Cost</th>
                                    <th rowSpan="2" className="text-center">Action</th>
                                </tr>
                                
                                <tr key={2}>
                                    <th className="text-center">Year</th>
                                    <th className="text-center">Mode</th>
                                </tr>
                        </thead>
                        <tbody>
                            {
                                props.dataRealProper.length > 0 ? 
                                 props.dataRealProper.map((data, index) => {
                                  
                                     const {
                                        description,
                                        kind,
                                        exact_location,
                                        assessed_value,
                                        market_value,
                                        acquisition_year,
                                        acquisition_mode,
                                        acquisition_cost,	
                                     } = data;
                                
                                     return (
                                        <tr key={index}>
                                            <td>{ description }</td>
                                            <td>{ kind }</td>
                                            <td>{ exact_location }</td>
                                            <td>{ formatNumberWithCommas(assessed_value) }</td>
                                            <td>{ formatNumberWithCommas(market_value) }</td>
                                            <td>{ acquisition_year }</td>
                                            <td>{ acquisition_mode }</td>
                                            <td>{ formatNumberWithCommas(acquisition_cost) }</td>
                                            <td align='center'>
                                               <Button variant="outline-primary btn-sm" title="Edit" onClick={() => handelEditModal(data)}> <i className="nav-icon fas fa-edit" /></Button>     
                                            </td>
                                        </tr>   
                                     )
                                 })
                                
                                :
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>             
                            }
                           
                        </tbody>
                        </table>
                    </div>
                        
                </div>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-3">
                        
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text fw-bolder">Subtotal</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={formatNumberWithCommas(props.totalRealProper)} readOnly/>
                        </div>

                    </div>
                </div>     
                

                
            </div>
        </div>
          
        <RealPropertiesModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
        <RealPropertiesEditModal show={showEditModal} handleCloseEdit={handleCloseEdit} data={DataEditModal} fetchDataSALN={props.fetchDataSALN} unique={props.unique}/>
    </div>
  )
}

export default RealPropertiesTable