import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';


const BusinessEditModal = (props) => {


  const [modalData, setModalData] = useState({
    id: '',
    business_enterprise: '',
    business_address: '',
    nature_business: '',
    date_acquired: ''

  });
  const [error, setError] = useState([]);
  

  const handleInputChange = (e) => {
    setModalData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };
  
  useEffect(() => {
    if (props.data) {
      setModalData({
        id: props.data.id,
        business_enterprise: props.data.business_enterprise,
        business_address: props.data.business_address,
        nature_business: props.data.nature_business,
        date_acquired: props.data.date_acquired,

      });
    }
  }, [props.data]);


  const updateData = async (e) => {
    e.preventDefault();


    await axios.post(`/api/updatedBusiness`, {
      id: modalData.id,
      business_enterprise: modalData.business_enterprise, 
      business_address: modalData.business_address,
      nature_business: modalData.nature_business, 
      date_acquired: modalData.date_acquired, 

    }).then((res) => {

        if (res.data.status === 422) {
            
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
            })
            setError(res.data.errors);

        } else {
              
            Swal.fire({
                icon: "success",
                title: res.data.message,
            }).then((res) => {
                    
                props.handleEditClose();
                props.fetchDataSALN(props.unique);
              
            })

        }

    });


  }

  return (
    <>
    
      <Modal show={props.show} onHide={props.handleEditClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Edit Business Interests and Financial Connection</Modal.Title>
        </Modal.Header>
        <Form onSubmit={updateData}>
          <Modal.Body>
              
                <div className="form-row">
                      
                    <div className="form-group col-md-12">
                          <label>Name of Entity/Business Enterprise</label>
                          <input type="text" className={`form-control ${error.business_enterprise ? 'is-invalid' : ''}`} name="business_enterprise" value={modalData.business_enterprise ?? " "} onChange={handleInputChange} />
                          <div  className="invalid-feedback">{error.business_enterprise}</div>
                    </div>  
                      
                    <div className="form-group col-md-12">
                          <label>Business Address</label>
                          <input type="text" className={`form-control ${error.business_address ? 'is-invalid' : ''}`} name="business_address" value={modalData.business_address ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.business_address }</div>
                    </div>  
                      
                    <div className="form-group col-md-6">
                          <label style={styles.labelText}>Nature of Business Interest &/or Financial Connection</label>
                          <input type="text" className={`form-control ${error.nature_business ? 'is-invalid' : ''}`} name="nature_business" value={modalData.nature_business ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.nature_business }</div>
                    </div>  
                    
                    <div className="form-group col-md-6">
                          <label style={styles.labelText}>Date of Aquisition of Interest or Connection</label>
                          <input type="text" className={`form-control ${error.date_acquired ? 'is-invalid' : ''}`} name="date_acquired" value={modalData.date_acquired ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.date_acquired }</div>
                    </div>     
                          
                </div>
                
          </Modal.Body>
          <Modal.Footer>

            <Button type="submit" variant="success" >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
       
      </Modal>

    </>
  )
}

const styles = {
    labelText: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
  
}
export default BusinessEditModal