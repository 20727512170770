import axios from '../../../../services/axios';
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';

const BusinessModal = (props) => {

    const [fetchData, SetFetchData] = useState({

        business_enterprise: "",
        business_address: "",
        nature_business: "",
        date_acquired: "",

    });
    const [error, setError] = useState([]);

    const handleInputChange = (e) => {
        SetFetchData(fetchData => ({...fetchData, [e.target.name]: e.target.value}))
    };


    const submitData = async (e) => {
        e.preventDefault();


      await axios.post(`/api/storedBusiness`, {
            // IDNO: ,
            plantilla_id: props.unique,
            business_enterprise: fetchData.business_enterprise,
            business_address: fetchData.business_address,
            nature_business: fetchData.nature_business,
            date_acquired: fetchData.date_acquired
        }).then((res) => {
           if (res.data.status === 422) {
               Swal.fire({
                icon: 'error',
                title: 'All fields are required',
              })
              setError(res.data.errors);
           } else {
              
                 Swal.fire({
                    icon: "success",
                    title: res.data.message,
                  }).then((res) => {
                    props.handleClose(false);
                    props.fetchDataSALN(props.unique);
                    SetFetchData({
                      business_enterprise: '',
                      business_address: '',
                      nature_business: '',
                      date_acquired: '',
                    });
                  })
            }
        })

    }
  return (
    <div>

      <Modal show={props.show} onHide={props.handleClose} size='lg'>
        <div aria-hidden="true">
        <Modal.Header closeButton>
          <Modal.Title>Create Business Interests and Financial Connection</Modal.Title>
        </Modal.Header>
        <Form onSubmit={submitData}>
            <Modal.Body>

                  <div className="form-row">
                      
                    <div className="form-group col-md-12">
                          <label>Name of Entity/Business Enterprise</label>
                          <input type="text" className={`form-control ${error.business_enterprise ? 'is-invalid' : ''}`} name="business_enterprise" value={fetchData.business_enterprise ?? " "} onChange={handleInputChange} />
                          <div  className="invalid-feedback">{error.business_enterprise}</div>
                    </div>  
                      
                    <div className="form-group col-md-12">
                          <label>Business Address</label>
                          <input type="text" className={`form-control ${error.business_address ? 'is-invalid' : ''}`} name="business_address" value={fetchData.business_address ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.business_address }</div>
                    </div>  
                      
                    <div className="form-group col-md-6">
                          <label style={styles.labelText}>Nature of Business Interest &/or Financial Connection</label>
                          <input type="text" className={`form-control ${error.nature_business ? 'is-invalid' : ''}`} name="nature_business" value={fetchData.nature_business ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.nature_business }</div>
                    </div>  
                    
                    <div className="form-group col-md-6">
                          <label style={styles.labelText}>Date of Aquisition of Interest or Connection</label>
                          <input type="text" className={`form-control ${error.date_acquired ? 'is-invalid' : ''}`} name="date_acquired" value={fetchData.date_acquired ?? " "} onChange={handleInputChange}/>
                          <div className="invalid-feedback">{ error.date_acquired }</div>
                    </div>     
                          
                </div>

          </Modal.Body>
            <Modal.Footer>
            <Button type="submit" variant="success" >
              Save 
            </Button>
            </Modal.Footer>
        </Form>
        </div>
 
      </Modal>
          
    </div>
  )
}

const styles = {
    labelText: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
    // inputField: {
    //     padding: '10px',
    //     fontSize: '14px',
    // },
    // invalidFeedback: {
    //     color: 'red',
    //     fontSize: '12px',
    // },
}

export default BusinessModal