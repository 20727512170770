import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';
import VoluntaryModal from '../components/VoluntaryModal';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/esm/Button'
import Form from 'react-bootstrap/Form'
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

export default function EmployeeVoluntary({fetchEmpInfo}) {

    const { unique } = useParams();
    // fetch list of voluntary
    const [fetchVoluntary, setfetchVoluntary] = useState([]);
    const [isLoading, setisLoading] = useState(false);

    const fetchDataVoluntary = async (unique) => {
        setisLoading(true);

        try {

            const response = await axios.get(`/api/EmpVoluntary/${unique}`);
            setfetchVoluntary(response.data.EmpVoluntary);
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    };
    
    useEffect(() => {
        const debouncedFetch = debounce(fetchDataVoluntary, 1000); // Adjust the delay time as needed
            // Invoke the debounced function
        debouncedFetch(unique);

        // Return a cleanup function
        return () => debouncedFetch.cancel();
            // fetchDataSpouse()
    }, [unique]);


//Edit Function
const [modeldata, setModaldata] = useState({
    id: '',
    ORGANIZATION: '',
    FROMDATE: '',
    TODATE: '',
    HOURS: '',
    POSITION: '',
    
})

    
const handleChange = (e) => {
    setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
}

const [showModal, setShowModal] = useState(false);

const handleButtonClick = (data) => {
     setModaldata(data);
    setShowModal(true);
};

const handleCloseModal = () => {
    setShowModal(false);
};
    
 const [error, setError] = useState([]);


 const updateData = async (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('id', modeldata.unique)
    formData.append('ORGANIZATION', modeldata.ORGANIZATION ?? "")
    formData.append('FROMDATE', modeldata.FROMDATE ?? "")
    formData.append('TODATE', modeldata.TODATE ?? "")
    formData.append('HOURS', modeldata.HOURS ?? "")
    formData.append('POSITION', modeldata.POSITION ?? "")


    await axios.post(`/api/EmpUpdatedVol`, formData)
    .then((res)=>{
    
        if (res.data.status === 422) {
        Swal.fire({
            icon: 'error',
            title: 'All fields are required',
            })
        setError(res.data.errors);

        }
        else {
       
            Swal.fire({
                icon: "success",
                title: res.data.message,
            }).then((res) => {
                setShowModal(false);
                fetchDataVoluntary(unique);
                setError('');
            })
        }
        
    });



}




  return (
    <div>
        <div className="row">
            <div className="col-sm-6">
                <h4 className="m-2"><i className="fas fa-handshake"/> Voluntary Work </h4>
            </div>{/* /.col */}   
            <div className="col-sm-6">
                <div className="float-right">
                      <VoluntaryModal fetchDataVoluntary={fetchDataVoluntary} unique={unique} />
                </div>
            </div>{/* /.col */}
        </div>{/* /.row */}
        <hr />
        
        
          {
            isLoading ? (
                <div className="overlay-wrapper">
                    <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                    <div className="mx-1">
                        <div className="float-right">
                            <Button variant="outline-primary btn-sm" > <i className="nav-icon fas fa-edit" /></Button>
                        </div>
                    </div> 
                    
                    <span>
                        <label className="text-md"></label> | <span></span> <br />
                        <span></span>
                    </span>
                    <hr/>
                </div>
            ) : 
               fetchVoluntary.length > 0 ? 

                fetchVoluntary.map((data) => {
                    const {
                        ORGANIZATION, 
                        FROMDATE,
                        TODATE, 
                        POSITION,
                        unique
                    } = data;

                    return ( 
                        <div key={unique}>
                            <div className="mx-1">
                                <div className="float-right">
                                    <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                                </div>
                            </div> 
                            
                            <span>
                                <label className="text-md">{ORGANIZATION}</label> | <span>{POSITION}</span> <br />
                                <span>{FROMDATE} - {TODATE}</span>
                            </span>
                            <hr/>
                        </div>
                      

                    )

                })
                
                :
                ""   
            }

      
          
        <Modal show={showModal} backdrop="static" keyboard={false}  size="lg">
        
                <Modal.Header>
                    <Modal.Title><i className="fas fa-handshake"/> Edit Voluntary Work </Modal.Title>
                </Modal.Header>
            <Form onSubmit={updateData}>

                <Modal.Body>
                        
                
                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label>Name and Address of Organization</label>
                            <input type="text" className={`form-control ${error.ORGANIZATION ? 'is-invalid' : ''}`} name="ORGANIZATION" id="organization_id" value={modeldata.ORGANIZATION} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { error.ORGANIZATION } </div>
                        </div> 
                    </div>       
                    {/*  form-row */}
                    
                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>From</label>
                            <input type="text" className={`form-control ${error.FROMDATE ? 'is-invalid' : ''}`} name="FROMDATE" id="FROMDATE_id" value={modeldata.FROMDATE} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { error.FROMDATE } </div>
                        </div>  
                        
                        <div className="form-group col-md-3">
                            <label>To</label>
                            <input type="text" className={`form-control ${error.TODATE ? 'is-invalid' : ''}`} name="TODATE" id="TODATE_id" value={modeldata.TODATE} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { error.TODATE } </div>
                        </div>  

                        <div className="form-group col-md-5">
                            <label>Position/Nature Work</label>
                            <input type="text" className={`form-control ${error.POSITION ? 'is-invalid' : ''}`} name="POSITION" id="POSITION_id" value={modeldata.POSITION} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { error.POSITION } </div>

                        </div> 
                    </div>
                    {/*  form-row */}
                        
                    <div className="form-row">

                        <div className="form-group col-md-3">
                            <label>Number of Hours</label>
                            <input type="text" className={`form-control ${error.HOURS ? 'is-invalid' : ''}`} name="HOURS" id="HOURS_id" value={modeldata.HOURS} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { error.HOURS } </div>
                        </div>  
                        
                    </div>
                    {/*  form-row */}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => handleCloseModal()}>
                        Close
                    </Button>
                    <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
        </Modal>

    </div>
  )
}
