import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom';
import { DateObject } from "react-multi-date-picker";

export const CompensatoryOvertimeCredits = React.forwardRef((props, ref) => {
    // console.log(props.cto);
    const navigate = useNavigate();

    const fSize = 14;
    const marginTop = "10mm"
    const marginRight = "10mm"
    const marginBottom = "10mm"
    const marginLeft = "10mm"

    const getPageMargins = () => {
        return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    };

    function limitDecimals(num, maxDecimals) {
        const decimalPart = num.toString().split('.')[1];
        if (decimalPart && decimalPart.length > maxDecimals) {
            const factor = Math.pow(10, maxDecimals);
            return Math.round(num * factor) / factor;
        }
        return num;
    }

    return (
        <div className="text-dark" ref={ref} style={{ fontSize: `${fSize}pt`, fontFamily: `Times New Roman` }}>
            <style nonce="hris-style">{getPageMargins()}</style>
            <Row className="pt-0 mt-0 me-1 ms-1">
                <Col className="">
                    <Row>
                        <Col className="text-center">
                            <Image
                                style={{
                                    maxWidth: "12%",
                                }}
                                src="https://upload.wikimedia.org/wikipedia/commons/4/4d/Official_Seal_of_Pangasinan.svg"
                                roundedCircle
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="text-bold">Republic of the Philippines</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="text-bold">PROVINCE OF PANGASINAN</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="text-bold">HUMAN RESOURCE MGT. & DEVELOPMENT OFFICE</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="">1st Floor, Palaris Building, Capitol Compound, Lingayen, Pangasinan</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="">Contact No.: HR Main Office — 0928-440-2568 / 0965-036-7848</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="">Center for Excellence — 0928-362-7146 / 0965-036-7854</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <span className="">Email Address: <u>humanresource@hrmdolgupangasinan.com</u></span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <hr className="mt-0 me-3 ms-3" style={{ border: "1px solid black", opacity: "80%" }} />

            <Row className="pt-0 mt-0 mb-3 me-1 ms-1">
                <Col className="">
                    <Row>
                        <Col className="text-center" style={{ fontSize: `${fSize + 1}pt` }} >
                            <span className="text-bold">APPLICATION FOR COMPENSATORY OVERTIME CREDITS (COC)</span>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row className="pt-0 mt-0 me-1 ms-1">
                <Col className="">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="ps-1 text-bold" style={{ width: "15%" }}>NAME:</td>
                                <td className="ps-1" style={{ width: "42%" }}>{props.cto?.employee.name}</td>
                                <td className="ps-1 text-bold" style={{ width: "23%" }}>EARNED COC(HRS):</td>
                                <td className="text-bold text-center" style={{ width: "20%" }}>
                                    {/* {props.cto?.coc_bal ? props.cto.coc_bal != "0.0000" ? `${limitDecimals(props.cto.coc_bal, 2)} hours` : "" : ""} */}
                                    {`${limitDecimals(props.cto.coc_bal, 2)} hours`}
                                </td>
                            </tr>
                            <tr>
                                <td className="ps-1 text-bold" style={{ width: "15%" }}>POSITION:</td>
                                <td className="ps-1" style={{ width: "42%" }}>{props.cto?.position}</td>
                                <td className="ps-1 text-bold" style={{ width: "23%" }}>HRS. OF COC:</td>
                                <td className="text-bold text-center" style={{ width: "20%" }}>
                                    {props.cto?.hours ? `${limitDecimals(props.cto?.hours, 2)} hours` : ""}
                                </td>
                            </tr>
                            <tr>
                                <td className="ps-1 text-bold" style={{ width: "15%" }}>DATE OF COC:</td>
                                <td className="ps-1" style={{ width: "42%" }}>
                                    {props.cto?.date ? new DateObject(props.cto.date).format("MMMM DD, YYYY") : ""}
                                </td>
                                <td className="ps-1 text-bold" rowSpan="2" style={{ width: "23%" }}>COC BALANCE (HRS):</td>
                                <td className="text-bold text-center" rowSpan="2" style={{ width: "20%" }}>
                                    {`${limitDecimals((parseFloat(props.cto.coc_bal) + parseFloat(props.cto.hours)), 2)} hours`}
                                </td>
                            </tr>
                            <tr>
                                <td className="ps-1 text-bold" style={{ width: "15%" }}>DATE FILED:</td>
                                <td className="ps-1" style={{ width: "42%" }}>{new DateObject(props.cto?.created_at).format("MMMM DD, YYYY")}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>

            <br />

            <Row className="pt-0 mt-0 me-1 ms-1">
                <Col className="">
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td className="ps-1 text-bold" style={{ width: "70%" }}>FUNCTIONS/WORK TO BE DONE</td>
                                <td className="ps-1 text-bold" style={{ width: "30%" }}>NO. OF HOURS NEEDED</td>
                            </tr>
                            <tr>
                                <td className="ps-1" style={{ width: "70%" }}>{props.cto?.cto_reason}</td>
                                <td className="ps-1 text-center" style={{ width: "30%" }}>{`${limitDecimals(props.cto?.hours, 2)} hours`}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>

            <br />

            <Row className="pt-0 mt-0 me-1 ms-1">
                <Col sm="2"></Col>
                <Col className="">
                    <b>REQUESTED BY:</b>
                    <br />
                    <br />
                    <br />
                    <b>
                        {!(props.cto.office == 101101) ? props.cto.off_assign ?
                            `${props.cto.off_assign.plantilla.salutation ? props.cto.off_assign.plantilla.salutation + " " : ""}
                                ${props.cto.off_assign.plantilla.FINAME} ${props.cto.off_assign.plantilla.MIDNAME.split(" ").map(word => word[0].toUpperCase() + ".").join("")} ${props.cto.off_assign.plantilla.SURNAME}${props.cto.off_assign.post_honorific ? `, ${props.cto.off_assign.post_honorific}` : ''}`.toUpperCase()
                            : <br />
                            : "RICHARD A. DIZON, DPA"}
                    </b><br />
                    {!(props.cto?.office == 101101) ? props.cto?.off_assign ? props.cto?.off_assign.position_title : <br /> : "Community Affairs Assistant II"}
                    <br />
                    {props.cto?.off_sign_date ? new DateObject(props.cto.off_sign_date).format("MMMM DD, YYYY") : ""}
                </Col>
                <Col>
                    <b>APPROVED BY:
                        <br />
                        <br />
                        <br />
                        {!props.cto.forHead ? "ATTY. RONN DALE B. CASTILLO" : "MR. MELICIO FLORES PATAGUE II"}
                    </b><br />
                    {!props.cto.forHead ? "Executive Assistant III" : "Provincial Administrator"}
                    <br />
                    {props.cto?.pgo_sign_date ? new DateObject(props.cto.pgo_sign_date).format("MMMM DD, YYYY") : ""}
                </Col>
            </Row>

            <br />

            <Row className="border border-dark pt-0 mt-0 me-1 ms-1">
                <Col className="border-end border-dark">
                    PGP-HRM-161
                </Col>
                <Col className="border-end border-dark">
                    Revision : 00
                </Col>
                <Col className="">
                    Effectivity Date : February 8, 2024
                </Col>
            </Row>
        </div>
    );
});