import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import EmployeeSpouse from '../components/EmployeeSpouse'
// import EmployeeParent from '../components/EmployeeParent'
import EmployeeChild from '../components/EmployeeChild';
import SpouseModal from '../components/SpouseModal';
import ParentModal from '../components/ParentModal';
import axios from '../../../../services/axios';
import { debounce } from 'lodash';

export default function EmployeeFamily() {

  const { unique } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [spouseData, setSpouseData] = useState([]);
  const [parentsData, setParentsData] = useState([]);

  const fetchDataFamily = async (unique) => {
    
    setisLoading(true);

    try {
      const res1 = await axios.get(`/api/EmpSpouseData/${unique}`);
      const res2 = await axios.get(`/api/EmpParentData/${unique}`);

      setSpouseData(res1.data.EmpSpouse[0]);
      setParentsData(res2.data.EmpParent[0]);
      
    } catch (error) {
        console.log(error);
    } finally {
      setisLoading(false);
    }
  };


  useEffect(() => {
  const debouncedFetch = debounce(fetchDataFamily, 1000); // Adjust the delay time as needed
      // Invoke the debounced function
  debouncedFetch(unique);

  // Return a cleanup function
  return () => debouncedFetch.cancel();
    // fetchDataSpouse()
  }, [unique]);


  return (
    <div>
      {
        isLoading ? ( 
          <div className='overlay-wrapper'>
               
            <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
           
            <div className="row">
                    <div className="col-sm-6">
                        <h4 className="m-2"><i className="fas fa-users"/> Family Background </h4>
                    </div>{/* /.col */}   
              </div>{/* /.row */}
              
              <hr/>
              
                  <div className="mb-2">
                      <div className="float-right">
                      <SpouseModal unique={unique} />
                      </div>
                  </div> 
                
                <br />  
                <div className="form-row">
                        <div className="col-md-5">
                            <div className="form-row">
                                <span className="col-md-9"><label>Spouse Name:</label> </span>
                                <span className="col-md-9"><label>Occupation:</label> </span>
                            </div>
                        </div>
                    
                        <div className="col-md-6">
                          <div className="form-row">
                                <span className="col-md-12"><label>Employer/Business Name:</label> </span>
                                <span className="col-md-12"><label>Business Address:</label>  </span>
                                <span className="col-md-12"><label>Telephone No.:</label>  </span>
                            </div>
                        </div>
                </div>
                
                {/* <EmployeeSpouse /> */}
              
              <hr />
                  <div className="float-right">
                    <ParentModal />
                  </div> 

                  <div className="form-row">
                    <span className="col-md-5"><label>Father's Name:</label> </span>  
                    <span className="col-md-6"><label>Mother's Maiden Name:</label> </span>  
                  </div>
                  {/* <EmployeeParent /> */}
              <hr />


          </div>
        ): (
          <>
            <div className="row">
              <div className="col-sm-6">
                  <h4 className="m-2"><i className="fas fa-users"/> Family Background </h4>
              </div>{/* /.col */}   
            </div>{/* /.row */}
            
            <hr/>
            
                <div className="mb-2">
                    <div className="float-right">
                      <SpouseModal spouseData={spouseData} fetchDataFamily={fetchDataFamily} unique={unique} />
                    </div>
                </div> 
              
              <br />  
              <div className="form-row">
                      <div className="col-md-5">
                          <div className="form-row">
                              <span className="col-md-9"><label>Spouse Name:</label> { `${spouseData.SSURNAME ?? "" }, ${spouseData.SFIRSTNAME ?? ""} ${spouseData.SMIDNAME ?? ""} `}</span>
                              <span className="col-md-9"><label>Occupation:</label> {spouseData.OCCUPATION ?? "" }</span>
                          </div>
                      </div>
                  
                      <div className="col-md-6">
                        <div className="form-row">
                              <span className="col-md-12"><label>Employer/Business Name:</label> {spouseData.EMPLOYNAME ?? "" }</span>
                              <span className="col-md-12"><label>Business Address:</label>  {spouseData.BUSADRS ?? "" }</span>
                              <span className="col-md-12"><label>Telephone No.:</label>   {spouseData.BUSTEL ?? "" }</span>
                          </div>
                      </div>
              </div>
              
              {/* <EmployeeSpouse /> */}
            
            <hr />
                <div className="float-right">
                  <ParentModal parentsData={parentsData} fetchDataFamily={fetchDataFamily} unique={unique} />
                </div> 

                <div className="form-row">
                  <span className="col-md-5"><label>Father's Name:</label> { `${parentsData.FSURNAME ?? "" }, ${parentsData.FFIRSTNAME ?? ""} ${parentsData.FMIDNAME ?? ""} `}</span>  
                  <span className="col-md-6"><label>Mother's Maiden Name:</label> { `${parentsData.MSURNAME ?? "" }, ${parentsData.MFIRSTNAME ?? ""} ${parentsData.MMIDNAME ?? ""} `}</span>  
                </div>
                {/* <EmployeeParent /> */}
            <hr />
              <EmployeeChild unique={unique} />  
          </>    
        )
        
      }
    </div>
  )
}
