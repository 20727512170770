import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import { format } from 'date-fns';
import EmployeePic from '../components/EmployeePic'
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import EmployeeModal from '../components/EmployeeModal';


export default function EmployeeInfo(props) {



    const [showModal, setShowModal] = useState(false);
    const [fetchEmpInfo, setEmpInfo] = useState([]);
    const [fetchEmpImage, setEmpImage] = useState('');
    const [fetchUrlImage, setFetchUrlImage] = useState('');
    const [isLoading, setisLoading] = useState(false);
    
    

    const handleShowModal = () => {
        setShowModal(true);
    };
    
    const handleClose = () => {
        setShowModal(false);
    };


 
    const fetchEmpData = async (uniqueID) => {
   
        setisLoading(true);

        try {
            const response = await axios.get(`/api/EmpFullInfo/${uniqueID}`);
            const respImage = await axios.get(`/api/EmpImage/${uniqueID}`);

            setEmpInfo(response.data.EmpFullDetails[0]);
            setEmpImage(response.data.EmpFullDetails[0].profilePicture)
            setFetchUrlImage(respImage.data.profilePath);
       
        } catch (error) {
            console.error("Error fetching employee data:", error); 

        } finally {
            setisLoading(false);
        }
    };

     useEffect(() => {
        const debouncedFetch = debounce(fetchEmpData, 1000); // Adjust the delay time as needed
        // Invoke the debounced function
        if (props.unique) {
          debouncedFetch(props.unique);
        }
        // Return a cleanup function
        return () => debouncedFetch.cancel();
    }, [props.unique]);


    // format of date
   const formatDate = (dateStr) => {
    if (!dateStr) {
      return ''; // Handle undefined or empty input
    }

    // Format the date to 'MMM d, yyyy' (e.g., Jan. 4, 1968)
    return format(dateStr, 'MMM d, yyyy');
  };
    
    return (
    <div>
        <div className="row">
            <div className="col-sm-6">
                <h4 className="m-2"><i className="fas fa-user"/> General Information</h4>
            </div>{/* /.col */}   
            <div className="col-sm-6">
            <div className="float-right">
                    <Button variant="primary" onClick={handleShowModal}> <i className="nav-icon fas fa-edit" /></Button>
                    <EmployeeModal show={showModal} handleClose={handleClose} unique={props.unique} fetchEmpData={fetchEmpData} data={fetchEmpInfo} />
                </div>
            </div>{/* /.col */} 
        </div>{/* /.row */}
            
        <hr/>

 
            {
                isLoading ? (
                    <div className='overlay-wrapper'>

                      <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>

                        
                        <div className="row">
                        
                            <div className="col-md-3 ">

                                
                                <div className="mb-3">    
                                    <EmployeePic unique={props.unique}  />
                                </div>
                            </div>

                            <div className="col-md-9 py-2">
                                
                                <div className="mb-2">
                                    <h4>Details</h4>   
                                </div>
                                
                                        <div>
                                            <div className="form-row">
                                                <span className="col-md-6"><label>Name:</label> </span>
                                                <span className="col-md-6"><label>Gender:</label> </span>
                                            </div>

                                            <div className="form-row">
                                                <span className="col-md-6"><label>Birthday:</label> </span>
                                                <span className="col-md-6"><label>Place of Birth:</label> </span>
                                            </div>

                                            <div className="form-row">
                                                <span className="col-md-6"><label>Email:</label></span>
                                                <span className="col-md-6"><label>Mobile No:</label> </span>
                                            </div>
                                        </div>
                                
                                    
                                <hr /> 


                                <div className="mb-2">
                                    <h4>Goverment Issued ID </h4>   
                                </div> 
                                    
                        
                                <div>
                                    <div className="form-row">
                                        <span className="col-md-6"><label>GSIS ID No.:</label> </span>  
                                        <span className="col-md-6"><label>Pag-Ibig ID No.:</label></span>  
                                    </div>
                                        
                                    <div className="form-row">
                                        <span className="col-md-6"><label>Philhealth No.:</label></span>  
                                        <span className="col-md-6"><label>SSS No.:</label> </span>
                                    </div>

                                    <div className="form-row">
                                        <span className="col-md-6"><label>TIN No.:</label></span>  
                                    </div>
                                </div>      
                            
                                    

                            </div>            

                        </div>
                    </div>
               
              ) : (
                    <div className="row">
                        
                        <div className="col-md-3 ">
                                <div className="mb-3">    
                                    <EmployeePic profilePic={fetchUrlImage} fetchEmpImage={fetchEmpImage} unique={props.unique} fetchEmpData={fetchEmpData} />
                                </div>
                        </div>

                        <div className="col-md-9 py-2">
                            
                            <div className="mb-2">
                                <h4>Details</h4>   
                            </div>
                            
                                    <div>
                                        <div className="form-row">
                                            <span className="col-md-6"><label>Name:</label> { `${fetchEmpInfo.SURNAME ?? "" }, ${fetchEmpInfo.FINAME ?? ""} ${fetchEmpInfo.MIDNAME ?? ""} `} </span>
                                            <span className="col-md-6"><label>Gender:</label> {fetchEmpInfo.sex}</span>
                                        </div>

                                        <div className="form-row">
                                        {/* <span className="col-md-6"><label>Birthday:</label> {fetchEmpInfo.dbirth !== null ? format(dbirthFormat, "MMM dd, YYYY") : ""  }</span> */}
                                            <span className="col-md-6"><label>Birthday:</label> {fetchEmpInfo.dbirth !== null ? formatDate(fetchEmpInfo.dbirth) : ""  }</span>
                                            <span className="col-md-6"><label>Place of Birth:</label> {fetchEmpInfo.pbirth ?? ""}</span>
                                        </div>

                                        <div className="form-row">
                                            <span className="col-md-6"><label>Email:</label> {fetchEmpInfo.email ?? ""}</span>
                                            <span className="col-md-6"><label>Mobile No:</label> {fetchEmpInfo.cellno ?? ""}</span>
                                        </div>
                                    </div>
                            
                                
                            <hr /> 


                            <div className="mb-2">
                                <h4>Goverment Issued ID </h4>   
                            </div> 
                                
                    
                            <div>
                                <div className="form-row">
                                    <span className="col-md-6"><label>GSIS ID No.:</label> {fetchEmpInfo.gsis ?? ""}</span>  
                                    <span className="col-md-6"><label>Pag-Ibig ID No.:</label> {fetchEmpInfo.pagibig ?? ""} </span>  
                                </div>
                                    
                                <div className="form-row">
                                    <span className="col-md-6"><label>Philhealth No.:</label> {fetchEmpInfo.philhealthNo ?? ""}</span>  
                                    <span className="col-md-6"><label>SSS No.:</label> {fetchEmpInfo.SSS ?? ""}</span>
                                </div>

                                <div className="form-row">
                                    <span className="col-md-6"><label>TIN No.:</label> {fetchEmpInfo.TIN ?? ""}</span>  
                                    <span className="col-md-6"><label>Agency Employee No.:</label> {fetchEmpInfo.IDNO ?? ""}</span>  
                                </div>
                            </div>      
                        
                                

                        </div>            

                    </div>      
                            
                            
                            
                            
                            
              )  
            }
                    
       
                
       
    </div>
  )
}
