import React, { useCallback, useEffect, useState } from 'react'
import axios from '../../../../services/axios';

import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Swal from 'sweetalert2';
import { debounce } from 'lodash';


export default function HealthModal(props) {

    const [EmpInfo, setEmpInfo] = useState({
        IDNO: "",
        OFFCODE: "",
    });
    const [modaldata, setModaldata] = useState({
        period: '',
        service: '',
        waistline: '',
        height: '',
        weight: '',
        BP: '',
        pulserate: '',
        temperature2: '',
        cholesterol: '',
        sugarlevel: '',
        ride: '',
        chemistry: '',
        urinalysis: '',
        echo: '',
        ultrasound: '',
        labresult: '',
        diagnosis: '',
        findings: '',
        temperature: '',
        plano: '',
    });
        // for checkbox value
    const [chkdata, setChkdata] = useState({
        gymequip: '',
        steambath: '',
        massage: '',
        haircolor: '',
        haircut: '',
        hairtrim: '',
        facial: '',
        footspa: '',
        manicure: '',
        withproblem: '',
        withimprovement: '',
        satisfied: '',
    });

    const [error, setError] = useState([]);
    // Computation of BMI
    const [ResultBMI, setResultBMI] = useState(null);


    const handleChange = (e) => {
        setModaldata(modaldata => ({ ...modaldata, [e.target.name]: e.target.value }))
    }

    //  fetchhing the data from the checkbox
    const handleCheckChange = (event) => {
        const { name, checked } = event.target;        
        const updatedValue = checked ? 1 : 0;
         
        setChkdata((prevData) => ({ ...prevData, [name]: updatedValue}));
    };

    const computeBMI = useCallback(() => {

    // const height = parseFloat(modaldata.height);
    // const weight = parseFloat(modaldata.weight);
    
    const heightRegex = /^(\d+)'(\d+)$/;
    const match = modaldata.height.match(heightRegex);
    const weight = parseFloat(modaldata.weight);

    if (match && !isNaN(weight)) {
        
      const feet = parseInt(match[1], 10);
      const inches = parseInt(match[2], 10);
      const totalInches = feet * 12 + inches;
      const heightInMeters = totalInches * 0.0254; // Convert inches to meters
      const resultBMI = weight / (heightInMeters ** 2);
        setResultBMI(resultBMI.toFixed(1));
        
    } else {
        setResultBMI(0);
    }
    }, [modaldata.height, modaldata.weight]);

    useEffect(() => {
        computeBMI();
    }, [computeBMI]);


    // 

 
    const fetchEmpData = async () => {
        try {
            const response = await axios.get(`/api/EmpFullInfo/${props.unique}`);
            setEmpInfo(response.data.EmpFullDetails[0]);
        } catch (error) {
            console.error("Error fetching employee data:", error); 
        } 
    };
    
    useEffect(() => {
        const debouncedFetch = debounce(fetchEmpData, 1000); // Adjust the delay time as needed

        if (props.unique) {
          debouncedFetch(props.unique);
        }

        // Return a cleanup function
        return () => debouncedFetch.cancel();
    }, [props.unique]);



    // stored in the database
    const submitHealth = async (e) => {
        e.preventDefault();

        await axios.post(`http://localhost:8000/api/StoredHealth`, {
            offcode: EmpInfo.OFFCODE,
            IDNO: EmpInfo.IDNO,
            period: modaldata.period,
            service: modaldata.service, 
            waistline: modaldata.waistline,
            height: modaldata.height,
            weight: modaldata.weight,
            BMI: ResultBMI, 
            BP: modaldata.BP,
            pulserate: modaldata.pulserate, 
            temperature2: modaldata.temperature2, 
            cholesterol: modaldata.cholesterol,
            sugarlevel: modaldata.sugarlevel, 
            ride: modaldata.ride, 
            chemistry: modaldata.chemistry, 
            urinalysis: modaldata.urinalysis, 
            echo: modaldata.echo, 
            ultrasound: modaldata.ultrasound, 
            labresult: modaldata.labresult, 
            findings: modaldata.findings, 
            diagnosis: modaldata.diagnosis,
            temperature: modaldata.temperature, 
            plano: modaldata.plano, 
            gymequip: chkdata.gymequip, 
            steambath: chkdata.steambath, 
            massage: chkdata.massage,
            haircolor: chkdata.haircolor, 
            haircut: chkdata.haircut, 
            hairtrim: chkdata.hairtrim, 
            facial: chkdata.facial, 
            footspa: chkdata.footspa,
            manicure: chkdata.manicure,
            withproblem: chkdata.withproblem,
            withimprovement: chkdata.withimprovement, 
            satisfied: chkdata.satisfied,
            
        }).then((res) => {
            // console.log(res.data.message);
          
            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {   
            
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.handleClose();
                props.fetchDataHealth(props.unique);
                   setModaldata({
                        period: '', 
                        service: '', 
                        waistline: '', 
                        height: '', 
                        weight: '', 
                        BP: '', 
                        pulserate: '', 
                        temperature2: '',
                        cholesterol: '', 
                        sugarlevel: '',
                        ride: '', 
                        chemistry: '',
                        urinalysis: '', 
                        echo: '', 
                        ultrasound: '', 
                        labresult: '', 
                        diagnosis: '', 
                        findings: '',
                        temperature: '',
                        plano: '', 
                    });
                    setChkdata({
                        gymequip: '', 
                        steambath: '',
                        massage: '', 
                        haircolor: '', 
                        haircut: '',
                        hairtrim: '',
                        facial: '',
                        footspa: '', 
                        manicure: '', 
                        withproblem: '', 
                        withimprovement: '', 
                        satisfied: '', 
                    });
                    
                })
             }

        })
            
    }



  return (
    <>
      
        <Modal show={props.show} onHide={props.handleClose} backdrop="static" keyboard={false}  size="lg">
        
            <Modal.Header>
                <Modal.Title><i className="fas fa-file-medical"/> Create Health Record </Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitHealth}>
                <Modal.Body>
                   
                    <div className="form-row">
                        <div className="form-group col-md-4">
                            <label className="text-sm">Date <span className="text-danger">*</span> </label>
                            <input type="date" className={`form-control ${error.period ? 'is-invalid' : ''}`} name="period" id="period_id" value={modaldata.period} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.period} </div>
                        </div>

                        <div className="form-group col-md-8">
                            <label className="text-sm">Service <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.service ? 'is-invalid' : ''}`} name="service" id="service_id" value={modaldata.service} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.service} </div>
                        </div>
                    </div>
                     {/*  form-row */}
                    <hr />
                      <div className="mb-2">
                         <span className="font-weight-bold">Vitals and Measurements</span>
                      </div>  


                    <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label className="text-sm">Waistline <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.waistline ? 'is-invalid' : ''}`} name="waistline" id="waistline_id" value={modaldata.waistline} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.waistline} </div>
                        </div>


                        <div className="form-group col-md-3">
                              <label className="text-sm">Height (Feet)<span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.height ? 'is-invalid' : ''}`} placeholder="5'11.." name="height" id="height_id" value={modaldata.height} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.height} </div>
                        </div>

                                
           
                          
                        <div className="form-group col-md-3">
                            <label className="text-sm">Weight (Kilograms) <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.weight ? 'is-invalid' : ''}`} name="weight" id="weight_id"  value={modaldata.weight} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.weight} </div>
                        </div>  

                        <div className="form-group col-md-3">
                            <label className="text-sm">BMI </label> 
                            <input type="text" className="form-control" name="BMI" id="BMI_id" value={ResultBMI} readOnly/>
                            {/* <div  className="invalid-feedback"> </div> */}
                        </div>    
                    </div>  
                     {/*  form-row */}


                   
                    <div className="form-row">
                    
                        <div className="form-group col-md-2">
                            <label className="text-sm">Blood Pressure <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.BP ? 'is-invalid' : ''}`} name="BP" id="BP_id" value={modaldata.BP} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.BP} </div>
                        </div>

                        <div className="form-group col-md-2">
                            <label className="text-sm">Pulse Rate <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.pulserate ? 'is-invalid' : ''}`} name="pulserate" id="pulserate_id" value={modaldata.pulserate} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.pulserate} </div>
                        </div>  
                          
                        <div className="form-group col-md-2">
                            <label className="text-sm">Temperature <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.temperature2 ? 'is-invalid' : ''}`} name="temperature2" id="temperature2_id" value={modaldata.temperature2} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.temperature2} </div>
                        </div>  

                         <div className="form-group col-md-2">
                            <label className="text-sm">Cholesterol <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.cholesterol ? 'is-invalid' : ''}`} name="cholesterol" id="cholesterol_id" value={modaldata.cholesterol} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.cholesterol} </div>
                        </div> 
                          
                        <div className="form-group col-md-2">
                            <label className="text-sm">Sugar Level <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.sugarlevel ? 'is-invalid' : ''}`} name="sugarlevel" id="sugarlevel_id" value={modaldata.sugarlevel} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.sugarlevel} </div>
                        </div>    

                          <div className="form-group col-md-2">
                            <label className="text-sm">Trigicy Ride</label>
                            <input type="text" className="form-control" name="ride" id="ride_id" value={modaldata.ride} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  

                      </div>


                    {/*  form-row */}
                    <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label className="text-sm">Clinical Chemistry <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.chemistry ? 'is-invalid' : ''}`} name="chemistry" id="chemistry_id" value={modaldata.chemistry} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.chemistry} </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label className="text-sm">Urinalysis <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.urinalysis ? 'is-invalid' : ''}`} name="urinalysis" id="urinalysis_id" value={modaldata.urinalysis} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.urinalysis} </div>
                        </div>  
                          
                        <div className="form-group col-md-3">
                            <label className="text-sm">2D Echo</label>
                            <input type="text" className="form-control" name="echo" id="echo_id" value={modaldata.echo} onChange={handleChange} />
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                          
                        <div className="form-group col-md-3">
                            <label className="text-sm">Ultrasound</label>
                            <input type="text" className="form-control" name="ultrasound" id="ultrasound_id" value={modaldata.ultrasound} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  

                    </div>  
                    
                    <hr />
                      <div className="mb-2">
                         <span className="font-weight-bold">Result and Findings</span>
                      </div>
                  

                  
                 

                    {/*  form-row */}
                    <div className="form-row">  

                         <div className="form-group col-md-6">
                            <label className="text-sm">Laratory Result</label>
                            <input type="text" className="form-control" name="labresult" id="labresult_id" value={modaldata.labresult} onChange={handleChange} />
                            <div  className="invalid-feedback"> { } </div>
                        </div>      

                        <div className="form-group col-md-6">
                            <label className="text-sm">Findings</label>
                            <input type="text" className="form-control" name="findings" id="findings_id" value={modaldata.findings} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>
                    </div>  
                    {/*  form-row */}
                      
                    <div className="form-row">
                         <div className="form-group col-md-6">
                            <label className="text-sm">Diagnosis</label>
                            <input type="text" className="form-control" name="diagnosis" id="diagnosis_id" value={modaldata.diagnosis} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                          

                         <div className="form-group col-md-6">
                            <label className="text-sm">Other Findings</label>
                            <input type="text" className="form-control" name="temperature" id="otherFindings_id" value={modaldata.temperature} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                    </div>  
                      {/*  form-row */}
                      
                  
                    <div className="form-row">  
                         <div className="form-group col-md-12">
                            <label className="text-sm">Plan/Remarks <span className="text-danger">*</span></label>
                            <textarea className={`form-control ${error.plano ? 'is-invalid' : ''}`} cols={1} rows={2} name="plano" id="plano_id" value={modaldata.plano} onChange={handleChange}></textarea>  
                            <div  className="invalid-feedback"> {error.plano} </div>
                        </div>      
                    </div>  
                    {/*  form-row */}
                
                  <hr />
                  
                    {/* <div className="mb-2">
                          <span className="font-weight-bold">Services</span>   
                    </div> */}
                   
                    <div className="col">
                        
                        <div className="form-row">
                                <div className="form-group col-3">
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="gymequip" id="gymequip_id" value="1" checked={chkdata.gymequip} onChange={handleCheckChange}/>
                                        <label className="form-check-label"  htmlFor="gymequip_id">
                                           Equipment Gym
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="steambath" id="steambath_id" value="1" checked={chkdata.steambath} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="steambath_id">
                                            Steam Bath
                                        </label>
                                    </div>
                                    
                                   <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="massage" id="massage_id" value="1" checked={chkdata.massage} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="massage_id">
                                            Massage
                                        </label>
                                    </div>
                                </div>
                              
                               <div className="form-group col-2">
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="haircolor" id="haircolor_id" value="1" checked={chkdata.haircolor} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="haircolor_id">
                                            Haircolor
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="haircut" id="haircut_id" value="1" checked={chkdata.haircut} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="haircut_id">
                                            Haircut
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="hairtrim" id="hairtrim_id" value="1" checked={chkdata.hairtrim} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="hairtrim_id">
                                            Hairtrim
                                        </label>
                                    </div>

                                
                                </div>
                              
                              <div className="form-group col-3">
                                  
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="facial" id="facial_id" value="1" checked={chkdata.facial} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="facial_id">
                                            Facial
                                        </label>
                                    </div>
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="footspa" id="footspa_id" value="1" checked={chkdata.footspa} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="footspa_id">
                                            Footspa
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="manicure" id="manicure_id" value="1" checked={chkdata.manicure} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="manicure_id">
                                           Manicure/Pedicure
                                        </label>
                                    </div>
                                 </div>
                              

                              <div className="form-group col-4">

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="withproblem" id="withproblem_id" value="1" checked={chkdata.withproblem} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="withproblem_id">
                                            With Health Problem
                                        </label>
                                    </div>


                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="withimprovement" id="withimprovement_id" value="1" checked={chkdata.withimprovement} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="withimprovement_id">
                                            With Health Improvement
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="satisfied" id="satisfied_id" value="1" checked={chkdata.satisfied} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="satisfied_id">
                                           Satisfied
                                        </label>
                                    </div>
                                </div>
                                                            
                        </div>
                        {/* form-row */}

                    </div>
                      {/* col */}
        
                </Modal.Body>
            
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="success">Save</Button> 
                </Modal.Footer>
            </Form>        
        </Modal>
    </>
  )
}

