import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';

import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';

import HobbiesModal from '../components/HobbiesModal';


export default function EmployeeHobby() {
    
    const { unique } = useParams('')
    const [isLoading, setisLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [fetchSkills, setFetchSkills] = useState([]);
    // Edit Function
    //  const [error, setError] = useState([]);  
    const [modeldata, setModaldata] = useState({
        unique: '',
        SKILLDETAILS: '',
        Recognition: '',
        Organization: '',
    });



    const fetchDataSkills = async (unique) => {

        setisLoading(true);
        try {
            const response = await axios.get(`/api/EmpSkills/${unique}`);
            setFetchSkills(response.data.EmpSkill);
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    };        

    useEffect(() => {
       const debouncedFetch = debounce(fetchDataSkills, 1000); // Adjust the delay time as needed
            // Invoke the debounced function
        debouncedFetch(unique);

        // Return a cleanup function
        return () => debouncedFetch.cancel();
            // fetchDataSpouse()
    }, [unique]);
    


    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }


    const handleButtonClick = (data) => {
         setModaldata(data);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
    setShowModal(false);
    };

    const updateData = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('id', modeldata.unique)
        formData.append('SKILLDETAILS', modeldata.SKILLDETAILS)
        formData.append('Recognition', modeldata.Recognition)
        formData.append('Organization', modeldata.Organization)
        

        try {
            const { data } = await axios.post(`/api/UpdatedSkill`, formData);
            Swal.fire({
                icon: "success",
                title: data.message,
            }).then((res) => {
                fetchDataSkills(unique);
                setShowModal(false);
                
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "An error occurred",
                text: error.response?.data?.message || "Please try again later.",
            });   
        }

       


    }
  
    return (
    <div>
       <div className="row">
                <div className="col-sm-6">
                    <h4 className="m-2"><i className="fas fa-info-circle"/> Other Information</h4> 
            </div>{/* /.col */}   
            <div className="col-sm-6">
                <div className="float-right">
                        <HobbiesModal fetchDataSkills={fetchDataSkills} unique={unique} />
                </div>
            </div>{/* /.col */} 
        </div>{/* /.row */}

        <hr />
        {
            isLoading ? (
                <div className="overlay-wrapper">
                    <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                            
                            {/* /.card-header */}
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Special Skills and Hobbies</th>
                                        <th>Non-Academic Distinctions/Recognation</th>
                                        <th>Membership in Association/Organization</th>
                                        <th>Action</th>
                                    </tr>  
                                </thead>
                                <tbody>
                    
                                </tbody>
                                </table>
                            </div>
                            {/* /.card-body */}
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>
            ) : (
                
                 <div className="row">
                    <div className="col-12">
                        <div className="card">
                        
                    
                        {/* /.card-header */}
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>Special Skills and Hobbies</th>
                                    <th>Non-Academic Distinctions/Recognation</th>
                                    <th>Membership in Association/Organization</th>
                                    <th>Action</th>
                                </tr>  
                            </thead>
                            <tbody>
                                {
                                    fetchSkills.length > 0 ? 
                                        fetchSkills.map((data) => {
                                            const {
                                                SKILLDETAILS,
                                                Recognition,
                                                Organization,
                                                unique
                                            } = data;
                                            return (
                                                
                                                <tr key={unique}>
                                                    <td>{SKILLDETAILS}</td>
                                                    <td>{Recognition}</td>
                                                    <td>{Organization}</td>
                                                    <td align="center">
                                                    <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                                                    </td>   
                                                </tr>


                                            )
                                        
                                        })                
                                                    
                                    :
                                    (
                                    <tr>
                                        <td colSpan={12} align='center'>No Records found</td>
                                    </tr>
                                    )
                                }
                            </tbody>
                            </table>
                        </div>
                        {/* /.card-body */}
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            )
        }
       
            {/* /.row */}



        {/* Edit Modal  */}
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
       
            <Modal.Header>
            <Modal.Title><i className="fas fa-book"/> Edit Education Background</Modal.Title>
            </Modal.Header>
                <Form onSubmit={updateData}>
                    <Modal.Body>
                            
                         
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="text-sm">Special Skills and Hobbies</label>
                                <input type="text" className="form-control" name="SKILLDETAILS" id="skills_hobbies_id" value={modeldata.SKILLDETAILS === null ? '' : modeldata.SKILLDETAILS} onChange={handleChange} /> 
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="text-sm">Non-Academic Distinctions/Recognation</label>
                                <input type="text" className="form-control" name="Recognition" id="recognation_id" value={modeldata.Recognition === null ? '' : modeldata.Recognition} onChange={handleChange} />
                            </div>
                        </div>
                            
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label className="text-sm">Membership in Association/Organization </label>
                                <input type="text" className="form-control" name="Organization" id="membership_id" value={modeldata.Organization === null ? '' : modeldata.Organization} onChange={handleChange} />
                            </div>       
                        </div>

                    </Modal.Body>
            
                <Modal.Footer>
                <Button variant="outline-danger" onClick={() => handleCloseModal()}>
                    Close
                </Button>
                <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
        </Modal>

    </div>
  )
}
