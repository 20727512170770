import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
// import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';



export default function VoluntaryModal(props) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
    
    const [error, setError] = useState([]);  
    const [fetchEmpInfo, setEmpInfo] = useState({
         IDNO: "",
    });

  
    useEffect(() => {
       axios.get(`/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);
  
  
     // STORED IN THE DATABASE 
    const [modeldata, setModaldata] = useState({
        IDNO: '',
        ORGANIZATION: '',
        FROMDATE: '',
        TODATE: '',
        HOURS: '',
        POSITION: '',
    
    }); 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    };

    
    const submitVoluntary = async (e) => {
        e.preventDefault();

      

        await axios.post(`/api/EmpStoredVol`, {

            IDNO: fetchEmpInfo.IDNO,
            ORGANIZATION: modeldata.ORGANIZATION,
            FROMDATE: modeldata.FROMDATE,
            TODATE: modeldata.TODATE,
            HOURS: modeldata.HOURS,
            POSITION: modeldata.POSITION,
          
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {   
        
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                  setShow(false);
                  props.fetchDataVoluntary(props.unique);
                  setError('')
                    setModaldata({
                        ORGANIZATION: '',
                        FROMDATE: '',
                        TODATE: '',
                        HOURS: '',
                        POSITION: '',
                    });
                 
                })
            }
        
        })
    
    
    }



  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="nav-icon fas fa-plus" />
      </Button>
      
        <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
          <Modal.Header>
            <Modal.Title><i className="fas fa-handshake"/> Create Voluntary Work </Modal.Title>
          </Modal.Header>
            <Form onSubmit={submitVoluntary}>

                <Modal.Body>

                    {/* <input type="hidden" name="app_id" id="appId_id" ref={appIdRef} defaultValue="1" /> */}
                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label>Name and Address of Organization</label>
                            <input type="text" className={`form-control ${error.ORGANIZATION ? 'is-invalid' : ''}`} name="ORGANIZATION" id="ORGANIZATION_id" value={modeldata.ORGANIZATION} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.ORGANIZATION} </div>
                        </div> 
                    </div>
                      {/*  form-row */}

                      <div className="form-row">
                          
                            <div className="form-group col-md-3">
                                <label>From</label>
                                <input type="text" className={`form-control ${error.FROMDATE ? 'is-invalid' : ''}`}  name="FROMDATE" id="from_id" value={modeldata.FROMDATE} onChange={handleChange}/>
                                <div  className="invalid-feedback"> {error.FROMDATE} </div>
                            </div>  
                          
                            <div className="form-group col-md-3">
                                <label>To</label>
                                <input type="text" className={`form-control ${error.TODATE ? 'is-invalid' : ''}`} name="TODATE" id="to_id" value={modeldata.TODATE} onChange={handleChange} />
                                <div  className="invalid-feedback"> {error.TODATE} </div>
                            </div>  

                            <div className="form-group col-md-5">
                                <label>Position/Nature Work</label>
                                <input type="text" className={`form-control ${error.POSITION ? 'is-invalid' : ''}`} name="POSITION" id="POSITION_id" value={modeldata.POSITION} onChange={handleChange}/>
                                <div  className="invalid-feedback"> {error.POSITION} </div>
                            </div> 
                        </div>
                        {/*  form-row */}

                        <div className="form-row">

                            <div className="form-group col-md-3">
                                <label>Number of Hours</label>
                                <input type="text" className={`form-control ${error.HOURS ? 'is-invalid' : ''}`} name="HOURS" id="HOURS_id" value={modeldata.HOURS} onChange={handleChange}/>
                                <div  className="invalid-feedback"> {error.HOURS} </div>
                            </div>  
                            
                        </div>
                        {/*  form-row */}
                
                 
                </Modal.Body>
                
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleClose}>
                        Close
                    </Button> 
                <Button variant="success" type="submit">
                    Save
                </Button>
                </Modal.Footer>
            </Form>

        </Modal>


    </>
  )
}
