import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import PersonalPropModal from './PersonalPropModal';
import PersonalPropEditModal from './PersonalPropEditModal';


const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const PersonalPropertiesTable = (props) => {

    const [showModal, setShowModal] = useState(false);

    const [showEditModal, setShowEditModal] = useState(false);
    const [dataEditModal, setDataEditModal] = useState([]);

    const handleCreateModal = () => {
        setShowModal(true)
    };

    const handleClose = () => {
        setShowModal(false)
    };

    const handleEditModal = (data) => {
        setDataEditModal(data);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
        setShowEditModal(false);
    }


  return (
    <div>
        <div className="card">
            <div className="card-body">

                <div className="row mb-2">
                    <div className="col-sm-8">
            
                        <label className="fw-bold text-decoration-underline">b. Personal Properties</label>
                    </div>{/* /.col */}   
                    <div className="col-sm-4">
                        <div className="float-right">
                            <Button type="button" variant="outline-primary" onClick={() => handleCreateModal()} ><i className="nav-icon fas fa-plus" /> Add</Button>        
                        </div>
                    </div>{/* /.col */} 
                </div>{/* /.row */}

                <div className="table-responsive">
                    <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                        <tr>
                            <th className="text-center">Description</th>
                            <th className="text-center">Year Acquired</th>
                            <th className="text-center">Acquisition Cost/Amount</th>
                            <th className="text-center">Action</th>
                        </tr>
                        </thead>
                          <tbody>
                            {
                                props.dataPersonalProper.length > 0 ? 
                                    props.dataPersonalProper.map((data, index) => {
                                        
                                        const { 
                                            description, 
                                            year_aquired, 
                                            acquisition_cost, 
                                        } = data;

                                        return (

                                        <tr key={index}>
                                            <td>{ description }</td>
                                            <td>{ year_aquired }</td>
                                            <td>{ formatNumberWithCommas(acquisition_cost)}</td>
                                            <td align='center'>
                                                <Button  variant="outline-primary btn-sm" title="Edit" onClick={() => handleEditModal(data)} >
                                                    <i className="nav-icon fas fa-edit" />
                                                </Button>
                                            </td>
                                        </tr>

                                        )

                                    })
                                :
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                             
                        </tbody>
                    </table>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text fw-bolder">Subtotal</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={formatNumberWithCommas(props.totalPersonalProper)} readOnly/>
                        </div>
                    </div>
                    
                    <div className="col-md-4">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text fw-bolder">TOTAL ASSETS (a+b)</span>
                            </div>
                            <input type="text" className="form-control" defaultValue={formatNumberWithCommas(props.totalProper)} readOnly/>
                        </div>       
                    </div>  
                    
                </div>  

            </div>
          </div>    
          
        <PersonalPropModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique}  />
        <PersonalPropEditModal data={dataEditModal} show={showEditModal} handleEditClose={handleEditClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique}  /> 
    </div>
  )
}

export default PersonalPropertiesTable