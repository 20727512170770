import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function PersonalPropEditModal(props) {

    const [modalData, setModalData] = useState({
        id: '',
        year_aquired: '',
        acquisition_cost: '',
        description: '', 
    });

    const [error, setError] = useState([]);
    useEffect(() => {
        
        if (props.data) {
            setModalData({
                id: props.data.id || "",
                year_aquired: props.data.year_aquired || "",
                acquisition_cost: formatNumberWithCommas(props.data.acquisition_cost || ""),
                description: props.data.description || "",
            });
        }

    }, [props.data]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Remove commas and non-digit characters from input value
        const cleanedValue = value.replace(/[^\d]/g, '');

        // Format the cleaned value with commas
        const formattedValue = formatNumberWithCommas(cleanedValue);


        if (name === "acquisition_cost") {
            setModalData({ ...modalData, [name]: formattedValue });
        } else {
            setModalData({ ...modalData, [name]: value });
        }

        
    };

    const updateData = async (e) => {
        e.preventDefault();
        
        const acquisitionCost = modalData.acquisition_cost.replace(/,/g, '');

        await axios.post(`/api/updatePersonalProper`, {
            id: modalData.id, 
            year_aquired: modalData.year_aquired, 
            acquisition_cost: acquisitionCost, 
            description: modalData.description,      

        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                })
                setError(res.data.errors);
            } else {
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then(() => { 
                    props.handleEditClose(false);
                    props.fetchDataSALN(props.unique)
                });
            }

        });
    };

  return (
    <>
      <Modal show={props.show} onHide={props.handleEditClose} >
        <Modal.Header >
          <Modal.Title>Edit Personal Properties</Modal.Title>
        </Modal.Header>
        <Form onSubmit={updateData}>
            
        <Modal.Body>
             <div className="form-row">
                <div className="form-group col-md-5">
                    <label>Year Aquired</label>
                    <input type="text" className={`form-control ${error.year_aquired ? 'is-invalid' : ''}`} name="year_aquired" value={modalData.year_aquired ?? ""} onChange={handleChange}/>
                    <div  className="invalid-feedback">{error.year_aquired}</div>
                </div>    
                
                 <div className="form-group col-md-7">
                    <label>Acquisition Cost/Amount</label>
                    <input type="text" className={`form-control ${error.acquisition_cost ? 'is-invalid' : ''}`} name="acquisition_cost" value={modalData.acquisition_cost ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.acquisition_cost}</div>
                </div>   

                <div className="form-group col-md-12">
                    <label>Description</label>
                     <input type="text" className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" value={modalData.description ?? ""} onChange={handleChange} />
                    <div  className="invalid-feedback">{error.description}</div>
                </div>        
                    
            </div>
            {/* form-row */}

                
        </Modal.Body>
        
        <Modal.Footer>
            <Button variant="outline-danger" onClick={props.handleEditClose}>
                Close
            </Button>
            <Button type="submit" variant="success" >
            Save Changes
            </Button>
        </Modal.Footer> 
        </Form>
      </Modal>
    </>
  )
}
