import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';


import EmployeeInfo from '../../employeeInfo/page/EmployeeInfo';
import ServiceRecord from '../../serviceRecord/page/ServiceRecord';
import EmployeeFamily from '../../employeeFamily/page/EmployeeFamily'
import EmployeeEduc from '../../employeeEduc/page/EmployeeEduc'

import HealthRecord from '../../employeeHealth/page/EmployeeHealth'
import EmployeeEligibility from '../../employeeEligibility/page/EmployeeEligibility'
import EmployeeWorkExp from '../../employeeWorkExp/page/EmployeeWorkExp'
import EmployeeVoluntary from '../../employeeVoluntary/page/EmployeeVoluntary'
import EmployeeTrainings from '../../employeeTrainings/page/EmployeeTrainings'
import EmployeeHobby from '../../employeeHobby/page/EmployeeHobby'
import EmployeeQuestion from '../../employeeQuestion/page/EmployeeQuestion'
import EmployeeReference from '../../employeeReference/page/EmployeeReference'
import EmployeeSALN from '../../employeeSALN/EmployeeSALN';
import UpdateEmployeeID from '../components/UpdateEmployeeID';
import PrintPDS from '../../../HRIS/printPDS/page/PrintPDS'

export default function EmployeeDetails() {

     const { unique } = useParams();
    
    // const [EmpInfo, setEmpInfo] = useState([]);
    const [activeTab, setActiveTab] = useState("info");
    const [showModal, setShowModal] = useState(false);
    const [showPDSModal, setShowPDSModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleClosedModal = () => {
        setShowModal(false);
    };


    const handleOpenPDSModal = () => {
        setShowPDSModal(true);
    }

     const handleClosePDSModal = () => {
        setShowPDSModal(false);
    }


    const toggleTabClick = (tab) => {
        setActiveTab(tab);
    };

    


  return (
    <div>

              
        {/* Main content */}
        <div className="col-md-12 py-2">
            <div className="card">

                {/* card-header  */}
                    <div className="card-body shadow">

                        <div className="row">
                                <div className="col-sm-6">
                                    <h4 className="m-2"><i className="fas fa-user"/> Employee Personal Information </h4>
                                </div>{/* /.col */}   
                                
                    
                                <div className="col-sm-6">
                                    <div className="float-right">
                                       <div className="row">
                                            <div className="col-md">
                                              
                                                <div className="d-flex gap-2">
                                                 {/* <Link to={`/employee-details/${unique}`} title="View" className="btn btn-outline-primary" > <i className="nav-icon fas fa-file" /> Print PDS</Link> */}
                                                <Button 
                                                    type="button" 
                                                    variant="outline-primary" 
                                                    title="Print PDS" 
                                                    name="btn_pds"
                                                    onClick={() => handleOpenPDSModal()}
                                                >
                                                    <i className="nav-icon fas fa-file" /> Print PDS
                                                </Button>


                                                <Button 
                                                    type="button" 
                                                    variant="outline-primary" 
                                                    name="btn_IDNO" 
                                                    title="Change ID Number" 
                                                    onClick={() => handleOpenModal()}
                                                >
                                                    <i className="nav-icon fas fa-id-card" /> Employee ID
                                                </Button>
                                                </div>

                                          
                                          <PrintPDS show={showPDSModal} handleClosePDSModal={handleClosePDSModal}  unique={unique}/>  
                                          <UpdateEmployeeID show={showModal} handleClosedModal={handleClosedModal} unique={unique} />
                                          
                                            </div>
                                        </div>

                                  
                                    </div>
                                </div>{/* /.col */} 
                            </div>{/* /.row */}
                        
                    <hr />
                        
                
                        <div className="card card-primary card-outline card-outline-tabs shadow">
                            <div className="card-header p-0 border-bottom-0">
                                <ul className="nav nav-tabs" id="custom-tabs-four-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active text-sm" id="custom-tabs-four-pi-tab" data-toggle="pill" href="#custom-tabs-four-pi" onClick={() => toggleTabClick('info')} role="tab" aria-controls="custom-tabs-four-pi" aria-selected="true">Perosnal Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-sr-tab" data-toggle="pill" href="#custom-tabs-four-sr" role="tab" onClick={() => toggleTabClick('service')} aria-controls="custom-tabs-four-sr" aria-selected="false">Service Record </a>
                                    </li>       
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-hr-tab" data-toggle="pill" href="#custom-tabs-four-hr" role="tab" onClick={() => toggleTabClick('health')} aria-controls="custom-tabs-four-hr" aria-selected="false">Health Record </a>
                                    </li>                     
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-fb-tab" data-toggle="pill" href="#custom-tabs-four-fb" role="tab" onClick={() => toggleTabClick('family')} aria-controls="custom-tabs-four-fb" aria-selected="false">Family Background</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-eb-tab" data-toggle="pill" href="#custom-tabs-four-eb" role="tab" onClick={() => toggleTabClick('educ')} aria-controls="custom-tabs-four-eb" aria-selected="false">Educational Background</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-csc-tab" data-toggle="pill" href="#custom-tabs-four-csc" role="tab" onClick={() => toggleTabClick('csc')} aria-controls="custom-tabs-four-csc" aria-selected="false">Civil Service Eligibility</a>
                                    </li>
                                        <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-we-tab" data-toggle="pill" href="#custom-tabs-four-we" role="tab" onClick={() => toggleTabClick('work_exp')} aria-controls="custom-tabs-four-we" aria-selected="false">Work Experience</a>
                                    </li>
                                        <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-vw-tab" data-toggle="pill" href="#custom-tabs-four-vw" role="tab" onClick={() => toggleTabClick('vol_exp')} aria-controls="custom-tabs-four-vw" aria-selected="false">Voluntary Work</a>
                                    </li>
                                        <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-ld-tab" data-toggle="pill" href="#custom-tabs-four-ld" role="tab" onClick={() => toggleTabClick('learning_dev')} aria-controls="custom-tabs-four-ld" aria-selected="false">Learning and Development</a>
                                    </li>
                                        <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-oi-tab" data-toggle="pill" href="#custom-tabs-four-oi" role="tab" onClick={() => toggleTabClick('other_infos')} aria-controls="custom-tabs-four-oi" aria-selected="false">Other Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-ques-tab" data-toggle="pill" href="#custom-tabs-four-ques" role="tab" onClick={() => toggleTabClick('other_info')} aria-controls="custom-tabs-four-ques" aria-selected="false">Other Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-ref-tab" data-toggle="pill" href="#custom-tabs-four-ref" role="tab" onClick={() => toggleTabClick('referrence')}  aria-controls="custom-tabs-four-ref" aria-selected="false">Reference</a>
                                    </li>
                                     <li className="nav-item">
                                        <a className="nav-link text-sm" id="custom-tabs-four-saln-tab" data-toggle="pill" href="#custom-tabs-four-saln" role="tab" onClick={() => toggleTabClick('saln')}  aria-controls="custom-tabs-four-saln" aria-selected="false">Assets and Liabilities</a>
                                    </li>  
                                
                                </ul>
                            </div>

                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-four-tabContent">
                                    <div className="tab-pane fade show active" id="custom-tabs-four-pi" role="tabpanel" aria-labelledby="custom-tabs-four-pi-tab">
                                      
                                          {
                                              activeTab === "info" ? <EmployeeInfo unique={unique} /> : ""
                                          }
                                   
                                    </div>
                                                
                                      <div className="tab-pane fade" id="custom-tabs-four-sr" role="tabpanel" aria-labelledby="custom-tabs-four-sr-tab">
                                            {
                                                activeTab === "service" ? <ServiceRecord /> : ""
                                            }
                                    
                           
                                    </div>
                                
                                    <div className="tab-pane fade" id="custom-tabs-four-hr" role="tabpanel" aria-labelledby="custom-tabs-four-hr-tab">
                                            {
                                              activeTab === "health" ? <HealthRecord unique={unique} /> : ""
                                            }
                                    
                                    </div>
                                                
                                    <div className="tab-pane fade" id="custom-tabs-four-fb" role="tabpanel" aria-labelledby="custom-tabs-four-fb-tab">
                                            {
                                                activeTab === "family" ?  <EmployeeFamily  /> : ""
                                            }
                                       
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-eb" role="tabpanel" aria-labelledby="custom-tabs-four-eb-tab">
                                       
                                            {
                                                activeTab === "educ" ?  <EmployeeEduc /> : ""
                                            }
                                         
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-csc" role="tabpanel" aria-labelledby="custom-tabs-four-csc-tab">
                                          {
                                                    activeTab === "csc" ?  <EmployeeEligibility /> : ""
                                          }
                                          
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-we" role="tabpanel" aria-labelledby="custom-tabs-four-we-tab">
                                             {
                                                activeTab === "work_exp" ?  <EmployeeWorkExp /> : ""
                                            }
                                         
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-vw" role="tabpanel" aria-labelledby="custom-tabs-four-vw-tab">
                                            {
                                                activeTab === "vol_exp" ?  <EmployeeVoluntary /> : ""
                                            }
                                         
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-ld" role="tabpanel" aria-labelledby="custom-tabs-four-ld-tab">
                                            {
                                                activeTab === "learning_dev" ?  <EmployeeTrainings /> : ""
                                            }
                                         
                                    </div>
                                
                                    <div className="tab-pane fade" id="custom-tabs-four-oi" role="tabpanel" aria-labelledby="custom-tabs-four-oi-tab">
                                            {
                                                activeTab === "other_infos" ?  <EmployeeHobby /> : ""
                                            }
                                         
                                    </div>
                                
                                    <div className="tab-pane fade" id="custom-tabs-four-ques" role="tabpanel" aria-labelledby="custom-tabs-four-ques-tab">
                                            {
                                                activeTab === "other_info" ?  <EmployeeQuestion /> : ""
                                            }
                                         
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-ref" role="tabpanel" aria-labelledby="custom-tabs-four-ref-tab">
                                            {
                                                activeTab === "referrence" ? <EmployeeReference /> : ""
                                            }
                                          
                                    </div>
                                    
                                    <div className="tab-pane fade" id="custom-tabs-four-saln" role="tabpanel" aria-labelledby="custom-tabs-four-saln-tab">
                                            {
                                                activeTab === "saln" ? <EmployeeSALN /> : ""
                                            }
                                          
                                    </div>
                                </div>
                            </div>
                            {/* card-body */}
                        </div>
                        {/* card */}

                    
                    
                </div>
                {/* card-body */}
            </div>
            {/* card card-primary */}
        </div>
        {/* col-md-12 */}



  
        {/* /.content */}
    </div>
  )
}
