import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';

import Button from 'react-bootstrap/esm/Button';
import TableServices from '../components/TableServices';
// import EditService from '../components/EditService';
import AddServiceRecord from '../components/AddServiceRecord';
import { format, parse, isValid } from 'date-fns';


export default function ServiceRecord() {
    
    const { unique } = useParams();
    const [fetchIDNO, setFetchIDNO] = useState('');
    const [fetchDetailsEmp, setDetailsEmp] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [ServiceModalOpen, setServiceModalOpen] = useState(false);


    //total length of sevice 
    const  [fetchLengthService, SetLengthService] = useState('')
    //Display Service Record
    const [fetchServiceRecord, SetServiceRecord] = useState([])
    const [isloadingService, setisLoadingService] = useState(false);


    const getFormattedDate = (dateStr) => {
        if (!dateStr) return "";
        const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
        return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : "";
    };

    const handleEditModalOpen = () => {
        setServiceModalOpen(true);
    };


    const handleClose = () => {
        setServiceModalOpen(false);
    };

    const fetchEmpData = async (unique) => {
    
          setisLoading(true);
            try {
                const response = await axios.get(`/api/employeeDetails/${unique}`);
        
                setDetailsEmp(response.data.ServiceEmpDetails[0]);
                setFetchIDNO(response.data.ServiceEmpDetails[0].IDNO)
              
            } catch (error) {
                console.error("Error fetching employee data:", error); 
            } finally {
                setisLoading(false);
            }
        };
      
    //Details of employee
    useEffect(() => {
        const debouncedFetch = debounce(fetchEmpData, 1000); // Adjust the delay time as needed

        // Invoke the debounced function
        debouncedFetch(unique);

        // Return a cleanup function
        return () => debouncedFetch.cancel();

    }, [unique]);





    // sercvice Record Data
    const fetchRecord = async (unique) => {
            setisLoadingService(true);
            
            try {
                const response = await axios.get(`/api/empServiceRecord/${unique}`);
                SetServiceRecord(response.data.EmpService); 
                
                         
            // Computation of total length of service
            const totalSum = response.data.EmpService.reduce((acc, data) => {
                const date1 = new Date(data.fromdate);
                const date2 = new Date(data.todate);

                      // Check if the dates are valid
            if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
                console.error("Invalid date encountered:", data);
                return acc; // Skip this iteration if dates are invalid
            }

            const timeDiff = Math.abs(date2 - date1);
            const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24) + 1);

            return acc + daysDifference;
             
            }, 0); // Initialize the accumulator to 0
            

            // Calculate years, months, and days from totalSum
            const years = Math.floor(totalSum / 365);
            const months = Math.floor((totalSum % 365) / 30);
            const days = totalSum - (years * 365) - (months * 30);
       
            const TotalLengthService = years + ' Years ' + months + ' Months ' + days + ' days ';
            SetLengthService(TotalLengthService);
 
            } catch (error) {
                console.log(error);
            } finally {
                setisLoadingService(false);
            }
    } 
    
    //fetch data
    useEffect(() => {
         const debouncedFetch = debounce(fetchRecord, 1000); // Adjust the delay time as needed
                // Invoke the debounced function
            debouncedFetch(unique);

            // Return a cleanup function
        return () => debouncedFetch.cancel();
        

    }, [unique]);

    
  return (
    <div>
        {/* <div className="content-wrapper"> */}

           

            {/* <br /> */}
            {/* Main content */}
            {/* <div className="col-md-11 m-auto">
                <div className="card">
                 */}
                    {/* card-header  */}
                    {/* <div className="card-body shadow"> */}
                        
                        <div className="row">
                            <div className="col-sm-6">
                                <h4 className="m-2"><i className="nav-icon fa fa-file-signature"/> Service Record</h4>
                            </div>{/* /.col */}   
                            <div className="col-sm-6">
                                <div className="float-right">
                                        <Button variant="outline-primary" title='Edit' onClick={handleEditModalOpen}>
                                              <i className="nav-icon fas fa-edit" /> Office / Position
                                        </Button>
                                        <AddServiceRecord show={ServiceModalOpen} handleClose={handleClose} unique={unique} IDNO={fetchIDNO} data={fetchDetailsEmp} fetchEmpData={fetchEmpData} fetchRecord={fetchRecord} />
                                        {/* <EditService ServiceModal={ServiceModal} handleClose={handleClose} data={fetchDetailsEmp} unique={unique} /> */}
                                    </div>
                            </div>{/* /.col */} 
                        </div>{/* /.row */}
                            
                        <hr />
                        {
                        isLoading ? (
                            <div className='overlay-wrapper'> 
                                
                                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                               
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">From: </label>
                                    <div className="col-sm-2">
                                        <input type="date" className="form-control"  readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">To: </label>
                                    <div className="col-sm-2">
                                        <input type="date" className="form-control" readOnly />
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">Office: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-2 col-form-label" >Clothing Allowance: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control"  readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">RA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" readOnly />
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">Position: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control"   readOnly/>
                                    </div>

                                    <label className="col-sm-2 col-form-label" >Subsitence: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control" readOnly />
                                    </div> 

                                    <label  className="col-sm-1 col-form-label">TA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control"  readOnly/>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <label className="col-sm-1 col-form-label">Division: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-2 col-form-label" >Service Status: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control"  readOnly/>
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">PERA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" readOnly />
                                    </div>
                                </div>

                                <div  className="form-group row">
                                    
                                    <label  className="col-sm-1 col-form-label" >CSC Status: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control"  readOnly />
                                    </div>  
                                    
                                    <label  className="col-sm-2 col-form-label" >Employment Status: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control"  readOnly />
                                    </div>

                                </div>  

                        
                                
                                <div className="form-group row">
                                    
                                    <label  className="col-sm-1 col-form-label text-sm" >Salary Grade: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control"  readOnly />
                                    </div>

                                    <label  className="col-sm-1 col-form-label" >Step: </label>
                                    <div className="col-sm-1">
                                        <input type="text" className="form-control"  readOnly />
                                    </div>          
                
                                </div>

                                <hr/>
                                
                                <div className="form-row">
                                
                                    <div className="form-group col-md-4">      
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Date of Orginal Appointment</span>
                                            </div>  
                                            <input type="text" className="form-control " aria-describedby="basic-addon1" />
                                        </div>
                                    </div>     
                                    
                                    <div className="form-group col-md-4">      
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" >Date of Last Promotion</span>
                                            </div>  
                                            <input type="text" className="form-control " aria-describedby="basic-addon1" />
                                        </div>
                                    </div>      
                                    
                                </div>
                            </div>
                         ) : (
                            <>
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">From: </label>
                                    <div className="col-sm-2">
                                        <input type="date" className="form-control" value={getFormattedDate(fetchDetailsEmp.fromdate) ?? "" } readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">To: </label>
                                    <div className="col-sm-2">
                                        <input type="date" className="form-control" value={getFormattedDate(fetchDetailsEmp.todate) ?? "" } readOnly />
                                    </div>
                                </div>
                        
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">Office: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.offdesc ?? "" } readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-2 col-form-label" >Clothing Allowance: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control" value={fetchDetailsEmp.clothing ?? ""} readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">RA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.rata ?? "" } readOnly />
                                    </div>
                                </div>
                          
                                <div className="form-group row">
                                    <label  className="col-sm-1 col-form-label">Position: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.Designation ?? ""}  readOnly/>
                                    </div>

                                    <label className="col-sm-2 col-form-label" >Subsitence: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control" value={fetchDetailsEmp.subsitence ?? ""} readOnly />
                                    </div> 

                                    <label  className="col-sm-1 col-form-label">TA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.rata ?? ""}  readOnly/>
                                    </div>
                                </div>
                                
                                <div className="form-group row">
                                    <label className="col-sm-1 col-form-label">Division: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.division ?? ""} readOnly />
                                    </div>
                                    
                                    <label  className="col-sm-2 col-form-label" >Service Status: </label>
                                    <div className="col-sm-2">
                                        <input type="text"  className="form-control" value={fetchDetailsEmp.SERVICESTAT ?? ""} readOnly/>
                                    </div>
                                    
                                    <label  className="col-sm-1 col-form-label">PERA: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.pera ?? ""} readOnly />
                                    </div>
                                </div>

                                <div  className="form-group row">
                                    
                                    <label  className="col-sm-1 col-form-label" >CSC Status: </label>
                                    <div className="col-sm-4">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.CSCStatus ?? ""} readOnly />
                                    </div>  
                                    
                                    <label  className="col-sm-2 col-form-label" >Employment Status: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.ESTA === 'Permanent' ? "PERMANENT" :  fetchDetailsEmp.ESTA === 'Co-Terminous' ? "Co-Terminus" : fetchDetailsEmp.ESTA === 'CON' ? "CONSULTANT" : fetchDetailsEmp.ESTA === 'JO' ? "JOB ORDER" : fetchDetailsEmp.ESTA === 'CA' ? "CASUAL" : '' } readOnly />
                                    </div>

                                </div>  

                        
                                
                                <div className="form-group row">
                                    
                                    <label  className="col-sm-1 col-form-label text-sm" >Salary Grade: </label>
                                    <div className="col-sm-2">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.SGRADE ?? ""} readOnly />
                                    </div>

                                    <label  className="col-sm-1 col-form-label" >Step: </label>
                                    <div className="col-sm-1">
                                        <input type="text" className="form-control" value={fetchDetailsEmp.STEP ?? ""} readOnly />
                                    </div>          
                
                                </div>

                                <hr/>
                                
                                <div className="form-row">
                                
                                    <div className="form-group col-md-4">      
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">Date of Orginal Appointment</span>
                                            </div>  
                                            <input type="text" className="form-control " aria-describedby="basic-addon1" />
                                        </div>
                                    </div>     
                                    
                                    <div className="form-group col-md-4">      
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" >Date of Last Promotion</span>
                                            </div>  
                                            <input type="text" className="form-control " aria-describedby="basic-addon1" />
                                        </div>
                                    </div>      
                                    
                                </div>
                                {/* form-row  */}
                                
                                
                                
                                
                                {/* Table service Record */}
                                {/* <TableServices unique={unique} DataDesignation={fetchDetailsEmp.Designation} DataOffice={fetchDetailsEmp.offdesc} DataStation={fetchDetailsEmp.servicerecordname} /> */}

                                <TableServices fetchServiceRecord={fetchServiceRecord} fetchLengthService={fetchLengthService} isloadingService={isloadingService} />
                            </>
                         )                     
                            
                        }
                        
                     
                        
                        
                    {/* </div> */}
                    {/* card-body */}
            {/* </div> */}
            {/* card card-primary */}
         {/* </div> */}
            {/* col-md-12 */}     

           

        {/* </div> */}
    </div>
  )
}
//  const styles = {
//     clothing: {
//         fontText: '5px'
//     }
// }
