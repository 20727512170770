import { useEffect, useState } from 'react';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form'



export default function EmployeeModal(props) {

    const [error, setError] = useState([]);
    const [checkedAdd, setCheckedAdd] = useState(false);
    const [modalData, setModalData] = useState({
        IDNO: "",
        SURNAME: "",
        FINAME: "", 
        MIDNAME: "",
        dbirth: "",
        pbirth: "",
        sex: "", 
        civistat: "", 
        height: "", 
        weight: "", 
        bloodtype: "", 
        telephone2: "", 
        cellno: "", 
        email: "", 
        agencynum: "", 
        citizen: "", 
        citizenby: "", 
        citizencountry: "", 
        gsis: "", 
        philhealthNo: "", 
        pagibig: "", 
        SSS: "", 
        TIN: "", 
        province: "", 
        city: "", 
        zip: "", 
        barangay: "", 
        subdivision: "", 
        street: "",
        houseno: "",
        province2: "", 
        city2: "", 
        zip2: "", 
        barangay2: "", 
        subdivision2: "", 
        street2: "",
        houseno2: ""
    });


    useEffect(() => { 
        if (props.data) {
          
            setModalData({
                IDNO: props.data.IDNO || "",
                FINAME: props.data.FINAME || "",
                SURNAME: props.data.SURNAME || "",
                MIDNAME: props.data.MIDNAME || "",
                dbirth: props.data.dbirth || "",
                pbirth: props.data.pbirth || "",
                height: props.data.height || "",
                weight: props.data.weight || "",
                bloodtype: props.data.bloodtype || "",
                email: props.data.email || "",
                // agencynum: props.data.IDNO || "",
                gsis: props.data.gsis || "",
                pagibig: props.data.pagibig || "",
                philhealthNo: props.data.philhealthNo || "",
                sex: props.data.sex || "",
                civistat: props.data.civistat || "",
                telephone2: props.data.telephone2 || "",
                cellno: props.data.cellno || "",
                citizen: props.data.citizen || "",
                citizencountry: props.data.citizencountry || "",
                province: props.data.province || "",
                city: props.data.city || "",
                zip: props.data.zip || "",
                barangay: props.data.barangay || "",
                subdivision: props.data.subdivision || "",
                street: props.data.street || "",
                houseno: props.data.houseno || "",
                province2: props.data.province2 || "",
                city2: props.data.city2 || "",
                zip2: props.data.zip2 || "",
                barangay2: props.data.barangay2 || "",
                subdivision2: props.data.subdivision2 || "",
                street2: props.data.street2 || "",
                houseno2: props.data.houseno2 || "",
             
            }); 
        }


        // auto check of same resident
       if ((props.data.province === "" || props.data.province === null) &&
        (props.data.city === "" || props.data.city === null) &&
        (props.data.zip === "" || props.data.zip === null)) {
           
            setCheckedAdd(false);
        } else {
            setCheckedAdd(true);
}
    }, [props.data]);
        
    // const convertBdate = (dateString) => {
    //      const [month, day, year] = dateString.split(/[-/]/);
    //      return `${year}-${month}-${day}`;
    // };


     const handleChange = (e) => {
        setModalData(modalData =>({...modalData, [e.target.name]: e.target.value}))
    }

    const handleCopy = (e) => {
        if(!e.target.checked){

            setModalData({...modalData, 
                province2: "", 
                city2: "", 
                zip2: "", 
                barangay2: "", 
                subdivision2: "", 
                street2: "",
                houseno2: ""
            });

            setCheckedAdd(false);
        }   
        else {
          
            setModalData({...modalData, 
                province2: modalData.province, 
                city2: modalData.city, 
                zip2: modalData.zip, 
                barangay2: modalData.barangay, 
                subdivision2: modalData.subdivision, 
                street2: modalData.street,
                houseno2: modalData.houseno
            });
            setCheckedAdd(true);
        }

        
    }

    // useEffect(() => {
    //    axios.get(`http://localhost:8000/api/EmpFullInfo/${unique}`).then((res) => {
    //        setEmpInfo(res.data.EmpFullDetails[0])
    //         // console.log(res.data.EmpFullDetails[0]);
    //     })  
    // }, [unique]);




    // // stored datan and Update

    const submitInfo = async (e) => {
        e.preventDefault();

        await axios.post(`/api/EmpStored`, {
            IDNO: modalData.IDNO,
            SURNAME: modalData.SURNAME, 
            FINAME: modalData.FINAME,
            MIDNAME: modalData.MIDNAME,
            dbirth: modalData.dbirth, 
            pbirth: modalData.pbirth, 
            sex: modalData.sex, 
            civistat: modalData.civistat,
            height: modalData.height, 
            weight: modalData.weight,
            bloodtype: modalData.bloodtype,
            telephone2: modalData.telephone2,
            cellno: modalData.cellno,
            email: modalData.email,
            // agencynum: modalData.agencynum, 
            citizen: modalData.citizen,
            citizenby: modalData.citizenby,
            citizencountry: modalData.citizencountry,
            gsis: modalData.gsis,
            philhealthNo: modalData.philhealthNo,
            pagibig: modalData.pagibig,
            SSS: modalData.SSS, 
            TIN: modalData.TIN,
            province: modalData.province,
            city: modalData.city, 
            zip: modalData.zip,
            barangay: modalData.barangay,
            subdivision: modalData.subdivision,
            street: modalData.street, 
            houseno: modalData.houseno,
            province2: modalData.province2,
            city2: modalData.city2, 
            zip2: modalData.zip2,
            barangay2: modalData.barangay2,
            subdivision2: modalData.subdivision2,
            street2: modalData.street2, 
            houseno2: modalData.houseno2,
        }).then((res) => {
        //     console.log(res.data.message);
            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
                  
                }
            else {
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.handleClose(false);
                    props.fetchEmpData(props.unique);
                    setError('')
                })
            }
        
        })



    }
    
// console.log(modalData.dbirth);

    
  return (
    <>
        {/* <Button variant="outline-primary" onClick={handleShow}>
            <i className="nav-icon fas fa-plus" />
        </Button> */}
        
          
        <Modal show={props.show} size='xl' onHide={props.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header >
                 <Modal.Title><h4 className="modal-title m-2"><i className="fas fa-user"/> Personal Information </h4></Modal.Title>
            </Modal.Header>
              
            <Form onSubmit={submitInfo}>  
                 <Modal.Body>
               
                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label>Full Name<span className="text-danger">*</span></label>
                            <div className="row">
                                <div className="col-md-3">
                                    <input type="text" className={`form-control ${error.SURNAME ? 'is-invalid' : ''}`}  name="SURNAME" id="surname_id" placeholder="Surname" value={modalData.SURNAME ?? ""} onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.SURNAME } </div>
                                </div>
                                <div className="col-md-3">
                                    <input type="text" className={`form-control ${error.FINAME ? 'is-invalid' : ''}`} name="FINAME" id="firstname_id" placeholder="Firstname" value={modalData.FINAME ?? ""} onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.FINAME } </div>
                                </div>
                                <div className="col-md-3">
                                    <input type="text" className={`form-control ${error.MIDNAME ? 'is-invalid' : ''}`} name="MIDNAME" id="middlename_id" placeholder="Middlename" value={modalData.MIDNAME ?? ""} onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.MIDNAME } </div>
                                </div>
                       
                            </div>
                            {/* row */}
                        </div>
                    </div>
                    {/* form-row */}      

                    <div className="row">
                          <div className="col-md-6">   

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>Date of Birth <span className="text-danger">*</span></label>                           
                                    <input type="date" className={`form-control ${error.dbirth ? 'is-invalid' : ''}`} name="dbirth" id="dbirth_id" value={modalData.dbirth ? modalData.dbirth : ""} onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.dbirth } </div>
                                </div>

                                <div className="form-group col-md-7">
                                    <label>Place of Birth <span className="text-danger">*</span></label>                           
                                    <input type="text" className={`form-control ${error.pbirth ? 'is-invalid' : ''}`} name="pbirth" id="pbirth_id" value={modalData.pbirth ?? ""} onChange={handleChange}  />
                                    <div className="invalid-feedback"> { error.pbirth } </div>
                                </div>

                            </div>
                              {/* form-row */}
                              

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>Height (m) <span className="text-danger">*</span></label>                           
                                    <input type="text" className={`form-control ${error.height ? 'is-invalid' : ''}`} name="height" id="height_id" value={modalData.height ?? ""} onChange={handleChange}   />
                                    <div className="invalid-feedback"> { error.height } </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Weight (kg) <span className="text-danger">*</span></label>                           
                                    <input type="text" className={`form-control ${error.weight ? 'is-invalid' : ''}`} name="weight" id="weight_id" value={modalData.weight ?? ""} onChange={handleChange}  />
                                    <div className="invalid-feedback"> { error.weight } </div>
                                </div>


                                <div className="form-group col-md-3">
                                    <label>Blood Type <span className="text-danger">*</span></label>                          
                                    <select className={`form-control ${error.bloodtype ? 'is-invalid' : ''}`} name="bloodtype" id="bloodType_id" value={modalData.bloodtype ?? ""} onChange={handleChange} >
                                    <option value="">Select</option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                    </select>
                                    <div className="invalid-feedback"> { error.bloodtype } </div>   
                                </div>
                               

                            </div>
                            {/* form-row */}
                            
                             <div className="form-row">
                                <div className="form-group col-md-7">
                                    <label>E-Mail <span className="text-danger">*</span></label>                           
                                    <input type="text" className={`form-control ${error.email ? 'is-invalid' : ''}`} name="email" id="email_id" value={modalData.email ?? ""} onChange={handleChange} />
                                     <div className="invalid-feedback"> { error.email } </div>   
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Agency Employee No.</label>                           
                                    <input type="text" className="form-control" name="agencynum" id="agencyNo_id" value={modalData.IDNO ?? "" } onChange={handleChange} readOnly/>
                                     {/* <div className="invalid-feedback">  </div>    */}
                                </div>
                                

                            </div>
                              {/* form-row */}
                              
                            <div className="form-row">
                                <div className="form-group col-md-5">
                                    <label>GSIS ID NO.</label>                           
                                    <input type="text"  className="form-control" name="gsis" id="gsisId_id" value={modalData.gsis ?? "" } onChange={handleChange} />
                                    {/* <div className="invalid-feedback"> </div>    */}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Pag-Ibig ID NO.</label>                           
                                    <input type="text" className="form-control" name="pagibig" id="pagibigId_id" value={modalData.pagibig?? "" } onChange={handleChange} />
                                     {/* <div className="invalid-feedback"> </div>    */}
                                </div>
                            </div>
                            {/* form-row */}

                            <div className="form-row">
                                <div className="form-group col-md-5">
                                    <label>PHILHEALTH NO.</label>                           
                                    <input type="text"  className="form-control" name="philhealthNo" id="philhealthId_id" value={modalData.philhealthNo ?? "" } onChange={handleChange} />
                                    {/* <div className="invalid-feedback"> </div>    */}
                                </div>

                                <div className="form-group col-md-6">
                                    <label>SSS NO.</label>                           
                                    <input type="text" className="form-control" name="SSS" id="sssId_id" value={modalData.SSS ?? "" } onChange={handleChange} />
                                    {/* <div className="invalid-feedback">  </div>    */}
                                </div>
                            </div>
                            {/* form-row */}

                        </div>
                          {/* col-md-6 */}

                          <div className="col-md-6">

                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>Gender <span className="text-danger">*</span></label>                           
                                    <select className={`custom-select ${error.sex ? 'is-invalid' : ''}`} name="sex" id="gender_id" value={modalData.sex ?? "" } onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <div className="invalid-feedback"> { error.sex } </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Civil Status <span className="text-danger">*</span></label>                           
                                    <select className={`custom-select ${error.civistat ? 'is-invalid' : ''}`} name="civistat" id="civilStatus_id" value={modalData.civistat ?? "" } onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="Single">Single</option>
                                        <option value="Married">Married</option>
                                        <option value="Widowed">Widowed</option>
                                        <option value="Separated">Separated</option>
                                    </select>
                                    <div className="invalid-feedback"> { error.civistat } </div>
                                </div>
                            </div>
                           {/* form-row  */}
                           
                            <div className="form-row">
                               <div className="form-group col-md-5">
                                    <label>Telephone</label>                           
                                    <input type="text" className="form-control" name="telephone2" id="telNum_id" value={modalData.telephone2 ?? "" } onChange={handleChange} />
                                    {/* <div className="invalid-feedback"> </div> */}
                                </div>

                                <div className="form-group col-md-5">
                                    <label>Mobile No. <span className="text-danger">*</span></label>                           
                                    <input type="text" className={`form-control ${error.cellno ? 'is-invalid' : ''}`} placeholder="09xxxxxxxxx" maxLength={12} name="cellno" id="cellNum_id" value={modalData.cellno ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.cellno } </div>
                                </div>
                            </div>
                           {/* form-row  */}

                           <div className="form-row">
                               <div className="form-group col-md-5">
                                    <label>Citizenship <span className="text-danger">*</span></label>                           
                                    <select className={`form-control ${error.citizen ? 'is-invalid' : ''}`} name="citizen" id="citizenship_id" value={modalData.citizen ?? "" } onChange={handleChange}>
                                        <option value="">Select</option>
                                        <option value="Filipino">Filipino</option>
                                        <option value="Dual citizenship">Dual Citizenship</option>
                                    </select>
                                    <div className="invalid-feedback"> { error.citizen } </div>
                                </div>

                                <div className="form-group col-md-5">
                                    <label>if Dual Citizenship </label>                           
                                    <select className={`custom-select ${error.citizenby ? 'is-invalid' : ''}`} name="citizenby" id="dualCitizen_id" value={ modalData.citizen === "Dual citizenship" ? modalData.citizenby : "" }  onChange={handleChange} disabled={modalData.citizen === "Dual citizenship" ? false  : true } >
                                        <option value="">Select</option>
                                        <option value="by Birth">By Birth</option>
                                        <option value="by Naturalization">By Naturalization</option>
                                    </select>
                                     <div className="invalid-feedback"> { error.citizenby }</div>
                                </div>
                            </div>
                           {/* form-row  */}

                            <div className="form-row">
                               <div className="form-group col-md-10">
                                    <label>Please indicate country <span className="text-danger">*</span></label>                           
                                    <select className={`custom-select ${error.citizencountry ? 'is-invalid' : ''}`} name="citizencountry" id="country_id" value={modalData.citizencountry ?? "" } onChange={handleChange}  >
                                                  <option value="">Select</option>
                                                  <option value='Afghanistan'>Afghanistan</option>   
                                                  <option value="Albania">Albania</option>
                                                  <option value="Algeria">Algeria</option>
                                                  <option value="Andorra">Andorra</option>
                                                  <option value="Angola">Angola</option>
                                                  <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                  <option value="Argentina">Argentina</option>
                                                  <option value="Armenia">Armenia</option>
                                                  <option value="Australia">Australia</option>
                                                  <option value="Austria">Austria</option>
                                                  <option value="Azerbaijan">Azerbaijan</option>
                                                  <option value="Bahamas">Bahamas</option>
                                                  <option value="Bahrain">Bahrain</option>
                                                  <option value="Bangladesh">Bangladesh</option>
                                                  <option value="Barbados">Barbados</option>
                                                  <option value="Belarus">Belarus</option>
                                                  <option value="Belgium">Belgium</option>
                                                  <option value="Belize">Belize</option>
                                                  <option value="Benin">Benin</option>
                                                  <option value="Bhutan">Bhutan</option>
                                                  <option value="Bolivia">Bolivia</option>
                                                  <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                  <option value="Botswana">Botswana</option>
                                                  <option value="Brazil">Brazil</option>
                                                  <option value="Brunei">Brunei</option>
                                                  <option value="Bulgaria">Bulgaria</option>
                                                  <option value="Burkina Faso">Burkina Faso</option>
                                                  <option value="Burundi">Burundi</option>
                                                  <option value="Cote D'Ivoire'Ivoire">Cote D"Ivoire</option>
                                                  <option value="Cabo Verde">Cabo Verde</option>
                                                  <option value="Cambodia">Cambodia</option>
                                                  <option value="Cameroon">Cameroon</option>
                                                  <option value="Canada">Canada</option>
                                                  <option value="Central African Republic">Central African Republic</option>
                                                  <option value="Chad">Chad</option>
                                                  <option value="Chile">Chile</option>
                                                  <option value="China">China</option>
                                                  <option value="Colombia">Colombia</option>
                                                  <option value="Comoros">Comoros</option>
                                                  <option value="Congo (Congo-Brazzaville)">Congo (Congo-Brazzaville)</option>
                                                  <option value="Costa Rica">Costa Rica</option>
                                                  <option value="Croatia">Croatia</option>
                                                  <option value="Cuba">Cuba</option>
                                                  <option value="Cyprus">Cyprus</option>
                                                  <option value="Czechia (Czech Republic)">Czechia (Czech Republic)</option>
                                                  <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                                                  <option value="Denmark">Denmark</option>
                                                  <option value="Djibouti">Djibouti</option>
                                                  <option value="Dominica">Dominica</option>
                                                  <option value="Dominican Republic">Dominican Republic</option>
                                                  <option value="Ecuador">Ecuador</option>
                                                  <option value="Egypt">Egypt</option>
                                                  <option value="El Salvador">El Salvador</option>
                                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                  <option value="Eritrea">Eritrea</option>
                                                  <option value="Estonia">Estonia</option>
                                                  <option value="Eswatini (fmr. Swaziland)">Eswatini (fmr. Swaziland)</option>
                                                  <option value="Ethiopia">Ethiopia</option>
                                                  <option value="Fiji">Fiji</option>
                                                  <option value="Finland">Finland</option>
                                                  <option value="France">France</option>
                                                  <option value="Gabon">Gabon</option>
                                                  <option value="Gambia">Gambia</option>
                                                  <option value="Georgia">Georgia</option>
                                                  <option value="Germany">Germany</option>
                                                  <option value="Ghana">Ghana</option>
                                                  <option value="Greece">Greece</option>
                                                  <option value="Grenada">Grenada</option>
                                                  <option value="Guatemala">Guatemala</option>
                                                  <option value="Guinea">Guinea</option>
                                                  <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                  <option value="Guyana">Guyana</option>
                                                  <option value="Haiti">Haiti</option>
                                                  <option value="Holy See">Holy See</option>
                                                  <option value="Honduras">Honduras</option>
                                                  <option value="Hungary">Hungary</option>
                                                  <option value="Iceland">Iceland</option>
                                                  <option value="India">India</option>
                                                  <option value="Indonesia">Indonesia</option>
                                                  <option value="Iran">Iran</option>
                                                  <option value="Iraq">Iraq</option>
                                                  <option value="Ireland">Ireland</option>
                                                  <option value="Israel">Israel</option>
                                                  <option value="Italy">Italy</option>
                                                  <option value="Jamaica">Jamaica</option>
                                                  <option value="Japan">Japan</option>
                                                  <option value="Jordan">Jordan</option>
                                                  <option value="Kazakhstan">Kazakhstan</option>
                                                  <option value="Kenya">Kenya</option>
                                                  <option value="Kiribati">Kiribati</option>
                                                  <option value="Kuwait">Kuwait</option>
                                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                  <option value="Laos">Laos</option>
                                                  <option value="Latvia">Latvia</option>
                                                  <option value="Lebanon">Lebanon</option>
                                                  <option value="Lesotho">Lesotho</option>
                                                  <option value="Liberia">Liberia</option>
                                                  <option value="Libya">Libya</option>
                                                  <option value="Liechtenstein">Liechtenstein</option>
                                                  <option value="Lithuania">Lithuania</option>
                                                  <option value="Luxembourg">Luxembourg</option>
                                                  <option value="Madagascar">Madagascar</option>
                                                  <option value="Malawi">Malawi</option>
                                                  <option value="Malaysia">Malaysia</option>
                                                  <option value="Maldives">Maldives</option>
                                                  <option value="Mali">Mali</option>
                                                  <option value="Malta">Malta</option>
                                                  <option value="Marshall Islands">Marshall Islands</option>
                                                  <option value="Mauritania">Mauritania</option>
                                                  <option value="Mauritius">Mauritius</option>
                                                  <option value="Mexico">Mexico</option>
                                                  <option value="Micronesia">Micronesia</option>
                                                  <option value="Moldova">Moldova</option>
                                                  <option value="Monaco">Monaco</option>
                                                  <option value="Mongolia">Mongolia</option>
                                                  <option value="Montenegro">Montenegro</option>
                                                  <option value="Morocco">Morocco</option>
                                                  <option value="Mozambique">Mozambique</option>
                                                  <option value="Myanmar (formerly Burma)">Myanmar (formerly Burma)</option>
                                                  <option value="Namibia">Namibia</option>
                                                  <option value="Nauru">Nauru</option>
                                                  <option value="Nepal">Nepal</option>
                                                  <option value="Netherlands">Netherlands</option>
                                                  <option value="New Zealand">New Zealand</option>
                                                  <option value="Nicaragua">Nicaragua</option>
                                                  <option value="Niger">Niger</option>
                                                  <option value="Nigeria">Nigeria</option>
                                                  <option value="North Korea">North Korea</option>
                                                  <option value="North Macedonia">North Macedonia</option>
                                                  <option value="Norway">Norway</option>
                                                  <option value="Oman">Oman</option>
                                                  <option value="Pakistan">Pakistan</option>
                                                  <option value="Palau">Palau</option>
                                                  <option value="Palestine State">Palestine State</option>
                                                  <option value="Panama">Panama</option>
                                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                                  <option value="Paraguay">Paraguay</option>
                                                  <option value="Peru">Peru</option>
                                                  <option value="Philippines">Philippines</option>
                                                  <option value="Poland">Poland</option>
                                                  <option value="Portugal">Portugal</option>
                                                  <option value="Qatar">Qatar</option>
                                                  <option value="Romania">Romania</option>
                                                  <option value="Russia">Russia</option>
                                                  <option value="Rwanda">Rwanda</option>
                                                  <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                  <option value="Saint Lucia">Saint Lucia</option>
                                                  <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                                  <option value="Samoa">Samoa</option>
                                                  <option value="San Marino">San Marino</option>
                                                  <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                                  <option value="Senegal">Senegal</option>
                                                  <option value="Serbia">Serbia</option>
                                                  <option value="Seychelles">Seychelles</option>
                                                  <option value="Sierra Leone">Sierra Leone</option>
                                                  <option value="Singapore">Singapore</option>
                                                  <option value="Slovakia">Slovakia</option>
                                                  <option value="Slovenia">Slovenia</option>
                                                  <option value="Solomon Islands">Solomon Islands</option>
                                                  <option value="Somalia">Somalia</option>
                                                  <option value="South Africa">South Africa</option>
                                                  <option value="South Korea">South Korea</option>
                                                  <option value="South Sudan">South Sudan</option>
                                                  <option value="Spain">Spain</option>
                                                  <option value="Sri Lanka">Sri Lanka</option>
                                                  <option value="Sudan">Sudan</option>
                                                  <option value="Suriname">Suriname</option>
                                                  <option value="Sweden">Sweden</option>
                                                  <option value="Switzerland">Switzerland</option>
                                                  <option value="Syria">Syria</option>
                                                  <option value="Tajikistan">Tajikistan</option>
                                                  <option value="Tanzania">Tanzania</option>
                                                  <option value="Thailand">Thailand</option>
                                                  <option value="Timor-Leste">Timor-Leste</option>
                                                  <option value="Togo">Togo</option>
                                                  <option value="Tonga">Tonga</option>
                                                  <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                  <option value="Tunisia">Tunisia</option>
                                                  <option value="Turkey">Turkey</option>
                                                  <option value="Turkmenistan">Turkmenistan</option>
                                                  <option value="Tuvalu">Tuvalu</option>
                                                  <option value="Uganda">Uganda</option>
                                                  <option value="Ukraine">Ukraine</option>
                                                  <option value="United Arab Emirates">United Arab Emirates</option>
                                                  <option value="United Kingdom">United Kingdom</option>
                                                  <option value="United States of America">United States of America</option>
                                                  <option value="Uruguay">Uruguay</option>
                                                  <option value="Uzbekistan">Uzbekistan</option>
                                                  <option value="Vanuatu">Vanuatu</option>
                                                  <option value="Venezuela">Venezuela</option>
                                                  <option value="Vietnam">Vietnam</option>
                                                  <option value="Yemen">Yemen</option>
                                                  <option value="Zambia">Zambia</option>
                                                  <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                    <div className="invalid-feedback"> { error.citizencountry } </div>
                                </div>
                            </div>
                           {/* form-row  */}

                           <div className="form-row">
                               <div className="form-group col-md-10">
                                    <label>TIN NO.</label>                           
                                    <input type="text" className="form-control" name="TIN" id="tinId_id" value={modalData.TIN ?? "" } onChange={handleChange}/>
                                    {/* <div className="invalid-feedback"> </div> */}
                                </div>
                            </div>
                           {/* form-row  */}

                        </div>
                         {/* col-md-6 */}


                         <div className="form-group col-md-12">
                            <label>Residential Address <span className="text-danger">*</span></label>

                            <div className="row">
                                <div className="col-md-5">
                                    <input type="text" className={`form-control ${error.province ? 'is-invalid' : ''}`} name="province" id="r_province_id" placeholder="Province" value={modalData.province ?? "" } onChange={handleChange} />
                                   
                                      {/* <select className="custom-select" name="r_province" id="r_province_id" >
                                         {
                                            province.map((getProvince, index) => (
                                                <option key={index} value={getProvince.code}>{getProvince.name}</option>
                                             ))
                                        }
                                    </select> */}
                                 <div className="invalid-feedback"> { error.province } </div>
                                </div>

                                 <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.city ? 'is-invalid' : ''}`} name="city" id="r_municipality_id" placeholder="Municipality" value={modalData.city ?? "" } onChange={handleChange} />
                                   
                                      {/* <select className="custom-select" name="r_municipality" id="r_municipality_id" >
                                        <option defaultValue="">Select City/Municipality</option>
                                          {
                                                municipality.map((getMunicipality, index) => (
                                                    <option key={index} value={getMunicipality.code}>{getMunicipality.name}</option>
                                                ))
                                            }
                                 </select> */}
                                 <div className="invalid-feedback"> { error.city } </div>
                                </div>

                                 <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.zip ? 'is-invalid' : ''}`} placeholder="ZIP CODE" name="zip" id="r_zip_id"  value={modalData.zip ?? "" } onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.zip } </div>
                                </div>
                                
                            </div>

                            {/* row */}
                        </div>
                          {/* form-group */}
                          
                        <div className="form-group col-md-12">
                            <label></label>
                            <div className="row">
                               
                                <div className="col-md-3"> 
                                     <input type="text" className={`form-control ${error.barangay ? 'is-invalid' : ''}`} name="barangay" id="r_barangay_id" placeholder="Barangay" value={modalData.barangay ?? "" } onChange={handleChange} />
                                   
                                      {/* <select className="custom-select" name="r_barangay" id="r_barangay_id" >
                                        <option defaultValue="">Select Barangay</option>
                                            {
                                                  barangay.map((getBarangay, index) => (
                                                    <option key={index} value={getBarangay.code}>{getBarangay.name}</option>
                                                ))
                                            }
                                    </select> */}
                                    <div className="invalid-feedback"> { error.barangay } </div>
                                </div>

                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.subdivision ? 'is-invalid' : ''}`} placeholder="Subdivision/Village" name="subdivision" id="r_subdivision_id" value={modalData.subdivision ?? "" } onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.subdivision } </div>
                                </div>

                                <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.street ? 'is-invalid' : ''}`} placeholder="street" name="street" id="r_street_id" value={modalData.street ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.street } </div>
                                </div>

                                <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.houseno ? 'is-invalid' : ''}`} placeholder="House/Block/Lot No." name="houseno" id="r_houseNo_id" value={modalData.houseno ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.houseno } </div>
                                </div>
                               
                            </div>
                            {/* row */}
                        </div>
                          {/* form-group */}
                          
                        <div className="form-group col-md-12">
                            <label>
                                Permanent Address <span className="text-danger">*</span>
                                     <div className="form-group">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="gridCheck" checked={checkedAdd} onChange={handleCopy} />
                                            <label className="form-check-label" htmlFor="gridCheck">
                                                Same as Residential Address
                                            </label>
                                        </div>
                                    </div>


                            </label>

                            <div className="row">
                               <div className="col-md-5">
                                     <input type="text" className={`form-control ${error.province2 ? 'is-invalid' : ''}`} name="province2" id="p_province_id" placeholder="Province" value={modalData.province2 ?? "" } onChange={handleChange} />
                                      
                                    {/* <select className="custom-select" name="p_province" id="p_province_id" >
                                       
                                      <option defaultValue="">Select Province</option>
                                     {
                                                province.map((getProvince, index) => (
                                                <option key={index} value={getProvince.code}>{getProvince.name}</option>
                                            ))
                                        }
                                           
                                 </select>  */}
                                 <div className="invalid-feedback"> { error.province2 } </div>
                                </div>

                                 <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.city2 ? 'is-invalid' : ''}`} name="city2" id="p_municipality_id" placeholder="Municipality" value={modalData.city2 ?? "" } onChange={handleChange} />
                                {/* <select className="custom-select" name="p_municipality" id="p_municipality_id" >
                                        <option defaultValue="">Select City/Municipality</option>
                                         {
                                                   municipalitys.map((getMunicipalitys, index) => (
                                                    <option key={index} value={getMunicipalitys.code}>{getMunicipalitys.name}</option>
                                                ))
                                        } 
                                 </select> */}
                                 <div className="invalid-feedback"> { error.city2 } </div>
                                </div>

                                <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.zip2 ? 'is-invalid' : ''}`} placeholder="ZIP CODE" name="zip2" id="p_zip_id" value={modalData.zip2 ?? "" } onChange={handleChange}/>
                                    <div className="invalid-feedback"> { error.zip2 } </div>
                                </div>

                            </div>
                            {/* row */}
                        </div>
                        {/* form-group */}
                        

                        <div className="form-group col-md-12">
                            <label></label>

                            <div className="row">
                                
                                  <div className="col-md-3">
                                      <input type="text" className={`form-control ${error.barangay2 ? 'is-invalid' : ''}`} name="barangay2" id="p_barangay_id" placeholder="Barangay" value={modalData.barangay2 ?? "" } onChange={handleChange} />
                                 {/* <select className="custom-select" name="p_barangay" id="p_barangay_id" >
                                        <option defaultValue="">Select Barangay</option>
                                      {
                                                barangays.map((getBarangay, index) => (
                                                <option key={index} value={getBarangay.code}>{getBarangay.name}</option>
                                            ))
                                        }
                                 </select> */}
                                 <div className="invalid-feedback"> { error.barangay2 } </div>
                                </div>

                                <div className="col-md-4">
                                    <input type="text" className={`form-control ${error.subdivision2 ? 'is-invalid' : ''}`} placeholder="Subdivision/Village" name="subdivision2" id="p_subdivision_id"  value={modalData.subdivision2 ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.subdivision2 } </div>
                                </div>

                                <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.street2 ? 'is-invalid' : ''}`} placeholder="Street" name="street2" id="p_street_id" value={modalData.street2 ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.street2 } </div>
                                </div>

                                 <div className="col-md-2">
                                    <input type="text" className={`form-control ${error.houseno2 ? 'is-invalid' : ''}`} placeholder="House/Block/Lot No."  name="houseno2" id="p_houseNo_id" value={modalData.houseno2 ?? "" } onChange={handleChange} />
                                    <div className="invalid-feedback"> { error.houseno2 } </div>
                                </div>
                            </div>
                            {/* row */}
                        </div>
                         {/* form-group */}
  
                    
                    </div>
                      

               
                </Modal.Body>
              
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={props.handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="success">Saved Changes</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
  )
}
