import React, { useState, useEffect } from 'react';
import axios from '../../../../services/axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import ChildModal from './ChildModal';
import { format } from 'date-fns';

export default function EmployeeChild({unique}) {

    const [isLoading, setisLoading] = useState(false);
    const [fetchChildren, setChildren] = useState([]);
    const [showModal, setShowModal] = useState(false);   
    const [modeldata, setModaldata] = useState({
        unique: '',
        CHILDNAME: '',
        CBIRTHDATE: '',
    });

    const fetchDataChild = async (unique) => {
        setisLoading(true);
        
        try {
            const resp = await axios.get(`/api/Empchildren/${unique}`);
            setChildren(resp.data.EmpChild);
            // console.log(resp.data.EmpChild);
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    }

    useEffect(() => {
        
        const debouncedFetch = debounce(fetchDataChild, 1000); // Adjust the delay time as needed
        debouncedFetch(unique);
        return () => debouncedFetch.cancel();


    }, [unique]);

    
    // const getFormattedDate = (dateStr) => {
    // if (!dateStr) return "";
    // const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
    // return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : "";
    // };

    // const childBdate = getFormattedDate(modeldata.CBIRTHDATE);


    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    };
    
    const handleButtonClick = (data) => {
        setModaldata(data);
        setShowModal(true);
    };

const handleCloseModal = () => {
    setShowModal(false);
}; 
  
//Update Children
    const [error, setError] = useState([]);  
    
    const editChildren = async (e) => {
        e.preventDefault();

        await axios.post(`/api/ChildreUpdate`, {
            unique: modeldata.unique,
            CHILDNAME: modeldata.CHILDNAME,
            CBIRTHDATE: modeldata.CBIRTHDATE,
      
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {  
                // console.log(res.data.message);
                
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    
                    fetchDataChild(unique);
                    setShowModal(false);
                })
            }
        
        })

    }

  return (
    <div>
      <div className="row">
            <div className="col-12">

                <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>
                                <center>Name of Child</center>
                            </th>
                            <th>
                                <center>Date of Birth</center>
                            </th>
                            <th>
                                <center>Action</center>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                          {
                              isLoading ? (
                                    <tr>
                                        <td colSpan={12} align="center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>         
                                        </td>
                                    </tr>
                              ):
                              
                              
                              fetchChildren.length > 0 ?
                                  fetchChildren.map((data) => {
                               
                                      const {
                                          CHILDNAME,
                                          CBIRTHDATE,
                                          unique,
                                      } = data;
                                   
                                      return (
                                        <tr key={unique}>

                                            <td className="text-center">{ CHILDNAME }</td>
                                            <td className="text-center">{ format(new Date(CBIRTHDATE), "MM/dd/yyyy" ) }</td>
                                            <td className="text-center">
                                                <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                                            </td>  
                                        </tr>
                                       
                                       
                                      )
                                   })
                                  
                            :
                            <tr>
                                <td colSpan={12} align="center" >No Data Found</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
                        
        <div className="float-right">
              <ChildModal unique={unique} fetchDataChild={fetchDataChild} />            
        </div>  

          
          {/* Edit */}
        <Modal show={showModal} onHide={() => handleCloseModal()} >
            <Modal.Header>
                <Modal.Title>
                       <h5 className="modal-title" id="staticBackdropLabel">Edit Children Details </h5>
                </Modal.Title>
            </Modal.Header>
                <Form onSubmit={editChildren}>
                    <Modal.Body>
                        {/* <input type="hidden" name="id" value={modeldata.unique ?? ""} /> */}
                        
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Name of Child</label>
                            <div className="col-sm-8">
                                <input type="text" className={`form-control ${error.CHILDNAME ? 'is-invalid' : ''}`} name="CHILDNAME" id="children_name_id" value={modeldata.CHILDNAME ?? ""} onChange={handleChange} />
                                <div className="invalid-feedback"> { error.CHILDNAME } </div>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">Date of Births</label>
                          <div className="col-sm-8">
                              
                                <input type="date" className={`form-control ${error.CBIRTHDATE ? 'is-invalid' : ''}`} name="CBIRTHDATE" id="children_bday_id" value={modeldata.CBIRTHDATE ?? ""} onChange={handleChange} />
                                <div className="invalid-feedback"> { error.CBIRTHDATE } </div>
                            </div>
                        </div> 
                    </Modal.Body>
                
                    <Modal.Footer> 
                        <Button variant="outline-danger" onClick={() => handleCloseModal()}  >
                            Close
                        </Button>
                        <Button type='submit' variant="success">Save Changes</Button> 
                    </Modal.Footer>
                </Form>
        </Modal>

    </div>
  )
}
