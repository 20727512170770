import React, { useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from '../../../services/axios';
import { useNavigate } from 'react-router-dom'
import { DateObject } from "react-multi-date-picker";
import Modal from 'react-bootstrap/Modal';
import ReactToPrint from 'react-to-print';

import LeaveApplicationModal from '../../Global/LeaveApplicationModal.component';
import { LeaveApplicationLayout } from '../../Global/PrintComponents/LeaveApplicationLayout.component';
import QueryFilter from '../../Global/QueryFilter2.component';
import PrintIcon from '@mui/icons-material/Print';
import Swal from "sweetalert2";
import LeaveLedger from '../../HRIS/Reports/LeaveLedger';

export default function PageIndex(props) {


    const navigate = useNavigate();

    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showPrint, setShowPrint] = useState(false);
    const [showLeaveID, setShowLeaveID] = useState(null);
    const [showID, setShowID] = useState(false);
    const idref = useRef('');
    
    const [ledger, setLedger] = useState(null);
    const [showLedger, setShowLedger] = useState(false);
    const closeLedger = () => setShowLedger(false);
    
    const closeID = () => setShowID(false);
    let userIsMobile = window.innerWidth <= 768;

    const openLeaveModal = () => setShowLeaveModal(true);
    const closeLeaveModal = () => setShowLeaveModal(false);
    const closeFetchLeaveModal = () => {
        setShowLeaveModal(false);
        fetchLeaves(filter);
    }

    const openPrint = (lvID) => {
        setShowLeaveID(lvID)
        setShowPrint(true);
    };
    const closePrint = () => setShowPrint(false);

    const [leaves, setLeaves] = useState([]);
    const [offices, setOffices] = useState([]);
    const [filter, setFilter] = useState({});

    const [leavesArranged, setLeavesArranged] = useState({});

    const get_idnumber = () => {
        idref.current = ''
        setShowID(true)
    }

    const process_ID = () => {
        closeID()
        getledger(idref.current.value)
    }

    const openledger = async () => {
        await getledger();
        setShowLedger(true);
    }

    const getledger = async (id) => {
        const response = await axios.get(`/api/LeaveLedger/${id}`)
        if (response.data.result === 0) {
            Swal.fire({
                text: "Data not found",
                icon: "error",
            });
        }
        else {
            if (response.data.result.ledger.length === 0) {
                Swal.fire({
                    text: "There is no leave ledger record found.",
                    icon: "error",
                });
            }
            else {
                setLedger(response.data.result);
                setShowLedger(true);
            }
        }
    }

    useEffect(() => {
        let lvgr = {};
        leaves.forEach(lv => {
            if (!(lv.office.offdesc in lvgr)) {
                lvgr[lv.office.offdesc] = {};
            }
            let empName = lv.plantilla.FINAME + " " + lv.plantilla.MIDNAME + " " + lv.plantilla.SURNAME;
            if (!(empName in lvgr[lv.office.offdesc])) {
                lvgr[lv.office.offdesc][empName] = [];
            }
            lvgr[lv.office.offdesc][empName].push(lv.id);
        });
        setLeavesArranged(lvgr);
    }, [leaves])

    useEffect(() => {
        if (showID && idref.current) {
            idref.current.focus(); // Focus the input element when showID is true
        }
    }, [showID]);

    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        fetchOffices();
    }, [])

    const fetchLeaves = async (filter) => {
        setFilter(filter);
        setLeavesArranged({});
        await axios.post(`/api/hr/leaveapplication/index`, { ...filter, page, perPage }).then(({ data }) => {
            setLeaves(data.index);
            if (data.lastPage !== pages) {
                setPages(data.lastPage);
            }
        })
    }

    const fetchOffices = async () => {
        await axios.get(`/api/office/index`).then(({ data }) => {
            setOffices(data.offices);
        })
    }

    const componentRef = useRef();

    const collapseDiv = (div) => {
        var divElement = document.getElementById(div);
        if (divElement.style.display === "none") {
            divElement.style.display = "block"; // Show the div again if it's already collapsed
        } else {
            divElement.style.display = "none"; // Collapse the div by hiding it
        }
    }

    const summarizeDates = (dates) => {
        if(dates.length === 0){
            return "";
        }
        let dStr = "";
        let curM = null;
        let curD = null;
        let curY = null;
        let fD = null;
        let m, d, y = null;
        dates.map(date => {
            let dtC = new DateObject(date);
            m = dtC.month.shortName;
            d = dtC.day;
            y = dtC.year;

            if (curM !== m) {
                if (curM !== null) {
                    if (curD === null) {
                        dStr += d;
                        fD = d;
                    }
                    if ((curD !== (d - 1)) && (curD !== null)) {
                        if (curD !== fD && curD !== (d - 1)) {
                            dStr += `-${curD}`;
                        }
                        fD = d;
                    }
                }

                if (curM !== null) {
                    dStr += `/${curY} | `;
                }

                dStr += `${m}/`;
                dStr += `${d}`;

                curD = d;
                curM = m;
                curY = y;
                if (fD === null) {
                    fD = d;
                }
            } else {
                if (curD === null) {
                    dStr += d;
                    fD = d;
                }

                if ((curD !== (d - 1)) && curD !== null) {
                    if (curD !== fD) {
                        dStr += `-${curD}`;
                    }
                    dStr += `,${d}`;
                    fD = d;
                }
                curD = d;
            }
        });

        if (curD !== fD && fD !== null) {
            dStr += `-${d}`;
        }
        dStr += `/${y}`;

        return dStr;
    }

    return (
        <div>
            <Row className="page-index-controls cus-color-2">
                <Col xs={12} md={"auto"}>
                    <QueryFilter
                        fetch={fetchLeaves}
                        openledger={get_idnumber}
                        perPage={perPage}
                        setPages={setPages}
                        page={page}
                        setPage={setPage}
                        inputs={{
                            office: {
                                input: 'select',
                                blankOption: { label: 'Office', value: '' },
                                selectOptions: offices,
                                optionMap: {
                                    label: 'offdesc',
                                    value: 'offcode'
                                }
                            },
                            leaveType: {
                                input: 'select',
                                blankOption: { label: 'Leave Type', value: '' },
                                selectOptions: [
                                    { label: "Vacation Leave", value: 1 },
                                    { label: "Mandatory/Forced Leave", value: 2 },
                                    { label: "Sick Leave", value: 3 },
                                    { label: "Maternity Leave", value: 4 },
                                    { label: "Paternity Leave", value: 5 },
                                    { label: "Special Privilege Leave", value: 6 },
                                    { label: "Solo Parent Leave", value: 7 },
                                    { label: "Study Leave", value: 8 },
                                    { label: "10-Day VAWC Leave", value: 9 },
                                    { label: "Special Leave Benefits for Women", value: 10 },
                                    { label: "Rehabilitation Privilege", value: 11 },
                                    { label: "Special Emergency (Calamity) Leave", value: 12 },
                                    { label: "Adoption Leave", value: 13 },
                                    { label: "Others - CTO", value: 14 },
                                    { label: "Others - Covid-19 Quarantine/Treatment Leave", value: 15 },
                                    { label: "Others - Covid-19 Quarantine Leave", value: 16 },
                                    { label: "Others - Monetization of Leave Credits", value: 17 },
                                    { label: "Others - Terminal Leave", value: 18 }
                                ],
                            },
                            leaveStatus: {
                                input: 'select',
                                selectOptions: [
                                    { label: 'Status', value: '' },
                                    { label: 'Pending', value: 0 },
                                    // { label: 'Disapproved', value: 1 },
                                    { label: 'Approved', value: 2 },
                                    // { label: 'Cancelled', value: 3 }
                                ]
                            },
                            dateFrom: {
                                input: 'date',
                                defaultValue: new DateObject().format("YYYY-MM") + '-01'
                            },
                            dateTo: {
                                input: 'date'
                            },
                        }}
                    />
                </Col>
            </Row>

            <Modal dialogClassName="leave-application-view-modal" show={showLeaveModal} onHide={closeLeaveModal}>
                <LeaveApplicationModal
                    showEmpDetails={true}
                    showLeaveBalance={true}
                    showAppFor={false}
                    editWhenOffApp={true}
                    editWhenPgoApp={true}
                    showLeaveSummary={true}
                    editable={{
                        type: false,
                        attachment: false,
                        details: false,
                        noDays: false,
                        incDates: false,
                        commutation: false,
                        lvBalance: false,
                        appFor: false,
                        offApp: false,
                        hrApp: true,
                        pgoApp: false,
                        cancel: false,
                        hrDisReason: true
                    }}

                    showEdit={showLeaveModal}
                    closeEdit={closeLeaveModal}
                    closeFetchEdit={closeFetchLeaveModal}
                    leave={leaves.find(leave => leave.id === showLeaveID)}
                    user={props.user}
                />
            </Modal>

            <Modal dialogClassName="leave-application-print-modal " show={showPrint} onHide={closePrint}>
                <Modal.Header closeButton>
                    <Modal.Title>Leave Application</Modal.Title>
                    <ReactToPrint
                        trigger={() => <Button className="ms-3" variant="primary" >Print</Button>}
                        content={() => componentRef.current}
                    />
                </Modal.Header>
                <Modal.Body className="">
                    <LeaveApplicationLayout
                        closePrint={closePrint}
                        leave={leaves.find(leave => leave.id === showLeaveID)}
                        ref={componentRef}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showLedger} onHide={closeLedger} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <div className="fw-bold">Leave Ledger</div>
                </Modal.Header>
                <Modal.Body>
                    <LeaveLedger
                        leaveData={ledger}
                        sig1={{ name: ' ', position: ' ' }}
                        // sig1={{ name: 'BRIAN MIKE G. DEL MUNDO', position: 'Administrative Aide I' }}
                        sig2={{ name: ' ', position: ' ' }}
                        // sig2={{ name: 'MERLYN Y. ADAN', position: 'Supervising Administrative Officer' }}
                        sig3={{ name: 'MARIA VIRGINIA JAILE G. DE LEON', position: "Prov'l. Gov't. Assistant Department Head" }}
                    />
                </Modal.Body>
            </Modal>

            <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={showID} onHide={closeID}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Enter ID Number
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input ref={idref} type="text" />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={process_ID}>Submit</Button>
                </Modal.Footer>
            </Modal>


            <div>
                {pages > 1 ?
                    <Row className="mt-2">
                        <Col className="text-center">
                            {page > 1 ? <Button size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : null}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>
                    : ''}

                <Row className="mt-2 mb-2">
                    {Object.entries(leavesArranged).map(([key, val]) => {
                        return (
                            <Col className="" key={key} sm={12}>
                                <div className="card">
                                    <div className="card-header fw-bold">
                                        {key}
                                    </div>
                                    <div className="card-body pt-0 pb-0">
                                        {!userIsMobile ? <Row className="p-1 border-bottom border-1 fw-bold text-center">
                                            <Col>Employee</Col>
                                            <Col>Type</Col>
                                            <Col>Date Submitted</Col>
                                            <Col>Date of Leave</Col>
                                            <Col>Status</Col>
                                            <Col></Col>
                                        </Row> : null}
                                        {
                                            Object.entries(val).map(([empKey, empVal]) => {
                                                return (
                                                    <Row className="" key={empKey}>
                                                        <Col className="">
                                                            {/* <Row className="border-bottom border-1 p-1">
                                                        <Col className="fw-bold">
                                                            {empKey}
                                                        </Col>
                                                    </Row> */}
                                                            {
                                                                empVal.map((leaveId) => {
                                                                    let leave = leaves.find(lv => lv.id === leaveId);
                                                                    const aoApp = leave.ao_approved;
                                                                    const offApp = leave.office_approved;
                                                                    return (
                                                                        <Row className="border-bottom border-1 p-1 text-center" key={leave.id}>
                                                                            <Col className="" xs={12} md={true}>
                                                                                {userIsMobile ? <span className="fw-bold">Name: </span> : null}{empKey}
                                                                            </Col>
                                                                            <Col className="" xs={12} md={true}>
                                                                                {userIsMobile ? <span className="fw-bold">Type: </span> : null}{leave.leave_type.type}
                                                                            </Col>
                                                                            <Col className="" >
                                                                                {userIsMobile ? <span className="fw-bold">Date Submitted: </span> : null}{new DateObject(leave.created_at).format("MM/DD/YYYY")}
                                                                            </Col>
                                                                            <Col className="" xs={12} md={true}>
                                                                                {userIsMobile ? <span className="fw-bold">Date of Leave: </span> : null}{summarizeDates(leave.leave_date)}
                                                                            </Col>
                                                                            <Col className="" xs={12} md={true}>
                                                                                <div className="progress mt-1" style={{ height: "20px" }}>
                                                                                    {leave.status ? <>
                                                                                        <div className={`progress-bar ${aoApp !== 1 ? aoApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>AO</div>
                                                                                        <div className={`progress-bar ${offApp !== 1 ? offApp === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>Chief</div>
                                                                                        <div className={`progress-bar ${leave.hr_approved !== 1 ? leave.hr_approved === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>HR</div>
                                                                                        <div className={`progress-bar ${leave.signed !== 1 ? leave.signed === null ? "bg-warning" : "bg-danger" : "bg-success"}`} style={{ width: `25%` }}>PGO</div>
                                                                                    </> : <div className="progress-bar bg-danger" style={{ width: "100%" }}>Cancelled</div>}
                                                                                </div>
                                                                            </Col>
                                                                            <Col className="" xs={12} md={true}>
                                                                                <Row className="mt-1">
                                                                                    <Col>
                                                                                        <button className="btn btn-sm btn-primary" data-key={leave.id} onClick={(e) => {
                                                                                            setShowLeaveID(leave.id);
                                                                                            openLeaveModal();
                                                                                        }}>
                                                                                            View
                                                                                        </button>
                                                                                        {(props.allowPrint) ?
                                                                                            <PrintIcon className="ms-3" role="button" onClick={(e) => {
                                                                                                openPrint(leave.id);
                                                                                            }} /> : null}
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    );
                                                                })
                                                            }
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
                </Row>

                {pages > 1 ?
                    <Row className="mb-2">
                        <Col className="text-center">
                            {page > 1 ? <Button className="" size='sm' onClick={(e) => {
                                setPage(page => page - 1);
                            }}>{'<'}</Button> : null}
                            <Form.Label className="ms-2 me-2">{`Pages ${page} of ${pages}`}</Form.Label>
                            {page === pages ? "" : <Button className="" size='sm' onClick={(e) => {
                                setPage(page => page + 1);
                            }}>{'>'}</Button>}
                        </Col>
                    </Row>
                    : ''}
            </div>
        </div>
    )
}