import React from 'react'


export default function TableServices(props) {   

    

  return (
    <div>
       
          <div className="mb-3">
                      
            {/* <Button variant="outline-primary" title="Add" onClick={handleAddModalOpen}>
                    <i className="nav-icon fas fa-clipboard"/> Add Serivce Record
            </Button>
              <AddServiceRecord showAddModal={showAddModal} setShowAddModal={setShowAddModal} unique={unique} fetchRecordList={fetchRecordList} DataDesignation={DataDesignation} DataOffice={DataOffice} DataStation={DataStation}  />       */}
        </div>

        <div className="row mb-2">
            <div className="col-sm-12">
                <div className="card-body table-responsive p-0" style={{ height: "600px", }}>
                    <table className="table table-striped table-head-fixed text-nowrap" width="100%" cellSpacing="0">
                        
                        <thead>
                            <tr>
                                {/* <th>
                                    <center>Length of Service</center>
                                </th>  */}
                                <th>
                                    <center>Date From</center>
                                </th>
                                <th>
                                    <center>Date To</center>
                                </th> 
                                <th>
                                    <center>Designation</center>
                                </th>
                                <th>
                                    <center>Status</center>
                                </th>  
                                <th>
                                    <center>Salary</center>
                                </th>  
                                <th>
                                    <center>Contract</center>
                                </th>  
                                <th>
                                    <center>Station</center>
                                </th>  
                                <th>
                                    <center>Branch</center>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                         props.isloadingService ? (
                             <tr>
                                <td colSpan={12} align="center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>         
                                </td>
                            </tr>
                         ) :  
                        props.fetchServiceRecord.length > 0 ?
                              
                            props.fetchServiceRecord.map((data) => {
                               
                                const {
                                    fromdate,
                                    todate,
                                    designation,
                                    status,
                                    contract,
                                    station,
                                    salary,
                                    branch, 
                                    unique,
                                } = data;
                                
                                // const date1 = new Date(data.fromdate);
                                // const date2 = new Date(data.todate);
                                
                                // const timeDiff = Math.abs(date1 - date2);   
                                // const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
                                // const monthsDifference = Math.floor(daysDifference / 30);
                                // const remainingDays = (daysDifference % 365) % 30;

                                // const serviceLen = monthsDifference + ' Months ' + remainingDays + ' days'; 

                                return (
                                    
                                <tr key={unique}>
                                
                                    {/* <td>{ serviceLen   }</td>  */}
                                    <td align="center">{ fromdate }</td>
                                    <td align="center">{ todate }</td>    
                                    <td>{ designation }</td>
                                    <td>{status}</td>
                                    <td>{ salary }</td>    
                                    <td align="center">{ contract }</td>
                                    <td>{ station }</td>
                                    <td>{ branch }</td>
                                </tr>
                                    
                                )
                            
                            })
                                
                            :
                            <tr>
                                <td colSpan={12} align="center" >No Data Found</td>
                            </tr>
                            
                        } 


                        
                            
                        </tbody>
                        
                    </table>
                </div>      
            </div>
              {/* col-12 */}
        </div>      
          {/* row */}
                        
            <div className="input-group mb-3">
              <input type="text" className="form-control col-md-3" value={props.fetchLengthService} readOnly />
               <div className="input-group-prepend">
                    <span className="input-group-text" >Total Length of Services</span>
                </div>  
            </div>
    </div>
  )
}
