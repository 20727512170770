import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default function RealPropertiesEditModal(props) {

  const [modalData, setModalData] = useState({
    id: '',
    IDNO: '',
    description: '',
    kind: '',
    exact_location: '',
    assessed_value: '',
    market_value: '',
    acquisition_year: '',
    acquisition_mode: '',
    acquisition_cost	: '',
  });
    
  const [error, setError] = useState([]);
  
  // fetchdata from the main table
  useEffect(() => {
    if (props.data) {
 
      
      setModalData({
        id: props.data.id || "",
        description: props.data.description || "",
        kind: props.data.kind || "",
        exact_location: props.data.exact_location || "",
        assessed_value: formatNumberWithCommas(props.data.assessed_value || ""),
        market_value:formatNumberWithCommas(props.data.market_value || ""),
        acquisition_year: props.data.acquisition_year || "",
        acquisition_mode: props.data.acquisition_mode || "",
        acquisition_cost: formatNumberWithCommas(props.data.acquisition_cost || ""),
              
      })
    }
  }, [props.data])
  

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Remove commas and non-digit characters from input value
    const cleanedValue = value.replace(/[^\d]/g, '');

    // Format the cleaned value with commas
    const formattedValue = formatNumberWithCommas(cleanedValue);

    if (name === "assessed_value" || name === "market_value" || name === "acquisition_cost") {
      setModalData({ ...modalData, [name] : formattedValue})
    } else {
       setModalData({ ...modalData, [name]: value });
    }


   
  };
    

  const updateData = async (e) => {
    e.preventDefault();

    const assessedValue = modalData.assessed_value.replace(/,/g, '');
    const marketValue = modalData.market_value.replace(/,/g, '');
    const acquisitionCost = modalData.acquisition_cost.replace(/,/g, '');

    await axios.post(`/api/updateRealProper`, {
      id: modalData.id,
      description: modalData.description,
      kind: modalData.kind,
      exact_location: modalData.exact_location,
      assessed_value: assessedValue,
      market_value: marketValue,
      acquisition_year: modalData.acquisition_year,
      acquisition_mode	: modalData.acquisition_mode	,
      acquisition_cost: acquisitionCost,
    }).then((res) => {
      
      if (res.data.status === 422) {
        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
        });

        setError(res.data.errors);
      } else {
        Swal.fire({
          icon: "success",
          title: res.data.message,
        }).then((res) => {
          props.handleCloseEdit(false);
          props.fetchDataSALN(props.unique)   
        });

      }

    });

  };

  return (
    <>
        <Modal show={props.show} onHide={props.handleCloseEdit} size="lg">
              
            <Modal.Header >
                <Modal.Title>Edit Real Properties</Modal.Title>
            </Modal.Header>
            
            <Form onSubmit={updateData}>
           
            <Modal.Body>
             {/* <div className="form-row">
              
                <div className="col-md-6"> */}
                   
              <div className="form-row">
              
                  <div className="form-group col-md-8">
                    <label>Description</label>  <span className="text-sm"> (e.g lot house and lot, condominium and improvements)</span>
                    <input type="text" className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" onChange={handleChange} value={modalData.description ?? ""}  />
                    <div  className="invalid-feedback">{error.description}</div>
                  </div>    
                    
              
                  <div className="form-group col-md-4">
                      <label>Kind</label> 
                        <select className={`form-control ${error.kind ? 'is-invalid' : ''}`} name="kind" onChange={handleChange} value={modalData.kind ?? ""}>
                          <option value="">Select</option>
                          <option value="Resident">Residential</option>
                          <option value="Commercial">Commercial</option>
                          <option value="Industrial">Industrial</option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Mixed use">Mixed use</option>
                        </select>
                      <div  className="invalid-feedback">{error.kind}</div> 

                  

                      {/* <input type="text" className={`form-control ${error.kind ? 'is-invalid' : ''}`} name="kind" onChange={handleChange} value={modalData.kind ?? ""} />
                      <div  className="invalid-feedback">{error.kind}</div> */}
                    </div>    
                    
              
                    <div className="form-group col-md-12">
                      <label>Exact Location</label>
                      <input type="text" className={`form-control ${error.exact_location ? 'is-invalid' : ''}`} name="exact_location" onChange={handleChange} value={modalData.exact_location ?? ""}/>
                        <div  className="invalid-feedback">{error.children_bday}</div>
                  </div> 
              
                  <div className="form-group col-md-6">
                      <label>Assessed Value</label>
                      <input type="text" className={`form-control ${error.assessed_value ? 'is-invalid' : ''}`} name="assessed_value" onChange={handleChange} value={modalData.assessed_value ?? ""}/>
                      <div  className="invalid-feedback">{error.assessed_value}</div>
                  </div> 
                  
                  <div className="form-group col-md-6">
                      <label>Current Fair Market Value</label>
                      <input type="text" className={`form-control ${error.market_value ? 'is-invalid' : ''}`} name="market_value" onChange={handleChange} value={modalData.market_value ?? ""}/>
                      <div  className="invalid-feedback">{error.market_value}</div>
                  </div>  
              
                  <div className="form-group col-md-6">
                      <label>Acquisition Year</label>
                      <input type="text" className={`form-control ${error.acquisition_year ? 'is-invalid' : ''}`} name="acquisition_year" onChange={handleChange} value={modalData.acquisition_year ?? ""}/>
                      <div  className="invalid-feedback">{error.acquisition_year}</div>
                  </div> 
                  
                    <div className="form-group col-md-6">
                      <label>Acquisition Mode</label>
                      <input type="text" className={`form-control ${error.acquisition_mode ? 'is-invalid' : ''}`} name="acquisition_mode" onChange={handleChange} value={modalData.acquisition_mode ?? ""}/>
                      <div  className="invalid-feedback">{error.acquisition_mode}</div>
                  </div> 
                  
                  <div className="form-group col-md-6">
                    <label>Acquisition Cost</label>
                    <input type="text" className={`form-control ${error.acquisition_cost ? 'is-invalid' : ''}`} name="acquisition_cost" onChange={handleChange} value={modalData.acquisition_cost ?? ""}/>
                    <div  className="invalid-feedback">{error.acquisition_cost}</div>
                  </div> 
                            
                   {/* </div>
                    
                </div> */}
                
                {/* <div className="col-md-6">
                      <div className="form-group col-md-12">
                        <label>Description</label> <br/>
                        <span className="text-sm"> (e.g lot house and lot, condominium and improvements)</span>
                        <textarea className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" rows={11} onChange={handleChange} value={fetchData.description ?? ""}  />
                        <div  className="invalid-feedback">{error.description}</div>
                    </div>    

                </div>             */}

                   
                          
                </div>
                {/* form-row */}
                      

            </Modal.Body>
            
          <Modal.Footer>
                <Button variant="outline-danger" onClick={props.handleCloseEdit}>
                    Close
                </Button>
                <Button type="submit" variant="success">
                    Save Changes
                </Button>
            </Modal.Footer>
            
            </Form>    
        </Modal>
    </>
  )
}
