import React, { useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';

export default function HealthEditModal(props) {

    const [modalData, setModalData] = useState({
        id: '',
        period: '',
        service: '',
        waistline: '',
        height: '',
        weight: '',
        BMI: '',
        BP: '',
        pulserate: '',
        temperature2: '',
        cholesterol: '',
        sugarlevel: '',
        ride: '',
        chemistry: '',
        urinalysis: '',
        echo: '',
        ultrasound: '',
        labresult: '',
        findings: '',
        diagnosis: '',
        temperature: '',
        plano: '',
    });

     const [chkdata, setChkdata] = useState({
        gymequip: '',
        steambath: '',
        massage: '',
        haircolor: '',
        haircut: '',
        hairtrim: '',
        facial: '',
        footspa: '',
        manicure: '',
        withproblem: '',
        withimprovement: '',
        satisfied: '',
    });
    const [error, setError] = useState([]);
    const [ResultBMI, setResultBMI] = useState(null);

    const handleChange = (e) => {
        setModalData(modalData => ({ ...modalData, [e.target.name]: e.target.value }))
    }

        //  fetchhing the data from the checkbox
    const handleCheckChange = (event) => {
        const { name, checked } = event.target;        
        const updatedValue = checked ? 1 : 0;
         
        setChkdata((prevData) => ({ ...prevData, [name]: updatedValue}));
    };

    useEffect(() => {
        setModalData({
            id: props.data.id || "",
            period: props.data.period || "",
            service: props.data.service || "",
            waistline: props.data.waistline || "",
            height: props.data.heightt || "",
            weight: props.data.weight || "",
            BMI: props.data.BMI || "",
            BP: props.data.BP || "",
            pulserate: props.data.pulserate || "",
            temperature2: props.data.temperature2 || "",
            cholesterol: props.data.cholesterol || "",
            sugarlevel: props.data.sugarlevel || "",
            ride: props.data.ride || "",
            chemistry: props.data.chemistry || "",
            urinalysis: props.data.urinalysis || "",
            echo: props.data.echo || "",
            ultrasound: props.data.ultrasound || "",
            labresult: props.data.labresult || "",
            findings: props.data.findings || "",
            diagnosis: props.data.diagnosis || "",
            temperature: props.data.temperature || "",
            plano: props.data.plano || "",
           
        
        });


        setChkdata({
            gymequip: props.data.gymequip || "",
            steambath: props.data.steambath || "",
            massage: props.data.massage || "",
            haircolor: props.data.haircolor || "",
            haircut: props.data.haircut || "",
            hairtrim: props.data.hairtrim || "",
            facial: props.data.facial || "",
            footspa: props.data.footspa || "",
            manicure: props.data.manicure || "",
            withproblem: props.data.withproblem || "",
            withimprovement: props.data.withimprovement || "",
            satisfied: props.data.satisfied || "",
        });
    }, [props.data]);

    const computeBMI = useCallback(() => {

    // const height = parseFloat(modaldata.height);
    // const weight = parseFloat(modaldata.weight);
    
    const heightRegex = /^(\d+)'(\d+)$/;
    const match = modalData.height.match(heightRegex);
    const weight = parseFloat(modalData.weight);

    if (match && !isNaN(weight)) {
        
      const feet = parseInt(match[1], 10);
      const inches = parseInt(match[2], 10);
      const totalInches = feet * 12 + inches;
      const heightInMeters = totalInches * 0.0254; // Convert inches to meters
      const resultBMI = weight / (heightInMeters ** 2);
        setResultBMI(resultBMI.toFixed(1));
        
    } else {
        setResultBMI(0);
    }
    }, [modalData.height, modalData.weight]);

    useEffect(() => {
        computeBMI();
    }, [computeBMI]);



const updateData = async (e) => {
e.preventDefault();

const formData = new FormData()
formData.append('id', modalData.id)
formData.append('period', modalData.period ?? "")
formData.append('service', modalData.service ?? "")
formData.append('waistline', modalData.waistline ?? "")
formData.append('height', modalData.height ?? "")
formData.append('weight', modalData.weight ?? "")
formData.append('BMI', modalData.BMI ?? "")
formData.append('BP', modalData.BP ?? "")
formData.append('pulserate', modalData.pulserate ?? "")
formData.append('temperature2', modalData.temperature2 ?? "")
formData.append('cholesterol', modalData.cholesterol ?? "")
formData.append('sugarlevel', modalData.sugarlevel ?? "")
formData.append('ride', modalData.ride ?? "")
formData.append('chemistry', modalData.chemistry ?? "")
formData.append('urinalysis', modalData.urinalysis ?? "")
formData.append('echo', modalData.echo ?? "")
formData.append('ultrasound', modalData.ultrasound ?? "")
formData.append('labresult', modalData.labresult ?? "")
formData.append('findings', modalData.findings ?? "")
formData.append('diagnosis', modalData.diagnosis ?? "")
formData.append('temperature', modalData.temperature ?? "")
formData.append('plano', modalData.plano ?? "")
formData.append('gymequip', chkdata.gymequip ?? "")
formData.append('steambath', chkdata.steambath ?? "")
formData.append('massage', chkdata.massage ?? "")   
formData.append('haircolor', chkdata.haircolor ?? "")
formData.append('haircut', chkdata.haircut ?? "")
formData.append('hairtrim', chkdata.hairtrim ?? "")    
formData.append('facial', chkdata.facial ?? "")
formData.append('footspa', chkdata.footspa ?? "")
formData.append('manicure', chkdata.manicure ?? "")    
formData.append('withproblem', chkdata.withproblem ?? "")   
formData.append('withimprovement', chkdata.withimprovement ?? "")   
formData.append('satisfied', chkdata.satisfied ?? "")   
    
    
    
await axios.post(`http://localhost:8000/api/UpdatedHealth`, formData)
    .then((res) => {
        
    if (res.data.status === 422) {
    Swal.fire({
        icon: 'error',
        title: 'All fields are required',
        })
    setError(res.data.errors);

    }
    else {
    
        Swal.fire({
            icon: "success",
            title: res.data.message,
        }).then((res) => {
            props.handleEditClose(false);
            props.fetchDataHealth(props.unique);
            setError('');
        })
    }
    
});

}
// end of updateData
    return (
      <>
        
        <Modal show={props.show} onHide={props.handleEditClose} size="lg">
        
            <Modal.Header>
                <Modal.Title><i className="fas fa-file-medical"/> Edit Health Record </Modal.Title>
            </Modal.Header>
            <Form onSubmit={updateData}>
                <Modal.Body>

                      <div className="form-row">
                        <div className="form-group col-md-4">
                            <label className="text-sm">Date <span className="text-danger">*</span></label>
                              <input type="date" className={`form-control ${error.period ? 'is-invalid' : ''}`} name="period" id="period_id"  value={modalData.period ? format(new Date(modalData.period), 'yyyy-MM-dd') : ''} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.period} </div>
                        </div>

                        <div className="form-group col-md-8">
                            <label className="text-sm">Service <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.service ? 'is-invalid' : ''}`} name="service" id="service_id" value={modalData.service === null ? '' : modalData.service} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.service} </div>
                        </div>
                    </div>
                     {/*  form-row */}
                    <hr />
                      <div className="mb-2">
                         <span className="font-weight-bold">Vitals and Measurements</span>
                      </div>  


                    <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label className="text-sm">Waistline <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.waistline ? 'is-invalid' : ''}`} name="waistline" id="waistline_id" value={modalData.waistline === null ? '' : modalData.waistline} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.waistline} </div>
                        </div>
                        
                        {/* <div className="form-group col-md-4">
                            <div className="form-row">
                                <div className="col-md-6">
                                    <label className="text-sm">Height<span className="text-danger">*</span></label>
                                    <input type="text" className={`form-control ${error.heightt ? 'is-invalid' : ''}`} name="modalFeet" id="heightt_id" maxLength="2" value={modalFeet} onChange={e => setModalFeet(e.target.value)}/>
                                    <span className="text-sm">(Feet)</span>
                                </div>

                                <div className="col-md-6">
                                    <label className="text-sm">&nbsp;</label>
                                    <input type="text" className={`form-control ${error.heightt ? 'is-invalid' : ''}`} name="modalInches" id="heightt_id" maxLength="2" value={modalInches} onChange={e => setModalInches(e.target.value)}/>
                                    <span className="text-sm">(Inches)</span>
                                    
                                </div>
                                   
                            </div>     
                          <input type="hidden" className={`form-control ${error.heightt ? 'is-invalid' : ''}`} />
                           <div className="invalid-feedback"> {error.heightt} </div>
                        </div> */}
                        {/* <div className="form-group col-md-3">
                            <label className="text-sm">Height (Feet) <span className="text-danger">*</span></label>  
                            <input type="text" className={`form-control ${error.heightt ? 'is-invalid' : ''}`} name="heightt" id="heightt_id" value={modalData.height === null ? '' : modalData.height} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.heightt} </div>
                        </div> */}
                        
                          <div className="form-group col-md-2">
                             <label className="text-sm">Height (Feet)<span className="text-danger">*</span></label>
                              <input type="text" className={`form-control ${error.height ? 'is-invalid' : ''}`} placeholder="5'11.." name="height" id="height_id" value={modalData.height === null ? '' :  modalData.height } onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.height} </div>
                        </div>   
                          
                     
                      <div className="form-group col-md-3">
                            <label className="text-sm">Weight (Kilograms) <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.weight ? 'is-invalid' : ''}`} name="weight" id="weight_id" maxLength="3" value={modalData.weight === null ? '' : modalData.weight} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.weight} </div>
                        </div>  

                        <div className="form-group col-md-3">
                            <label className="text-sm">BMI <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.BMI ? 'is-invalid' : ''}`} name="BMI" id="BMI_id"  value={ResultBMI} readOnly/>
                          
                        </div>    
                    </div>  
                     {/*  form-row */}


                   
                    <div className="form-row">
                    
                        <div className="form-group col-md-2">
                            <label className="text-sm">Blood Pressure <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.BP ? 'is-invalid' : ''}`} name="BP" id="BP_id" value={modalData.BP === null ? '' : modalData.BP} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.BP} </div>
                        </div>

                        <div className="form-group col-md-2">
                            <label className="text-sm">Pulse Rate <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.pulserate ? 'is-invalid' : ''}`} name="pulserate" id="pulserate_id" value={modalData.pulserate === null ? '' : modalData.pulserate} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.pulserate} </div>
                        </div>  
                          
                        <div className="form-group col-md-2">
                            <label className="text-sm">Temperature <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.temperature2 ? 'is-invalid' : ''}`} name="temperature2" id="temperature2_id" value={modalData.temperature2 === null ? '' : modalData.temperature2} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.temperature2} </div>
                        </div>  

                         <div className="form-group col-md-2">
                            <label className="text-sm">Cholesterol <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.cholesterol ? 'is-invalid' : ''}`} name="cholesterol" id="cholesterol_id" value={modalData.cholesterol === null ? '' : modalData.cholesterol} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.cholesterol} </div>
                        </div> 
                          
                        <div className="form-group col-md-2">
                            <label className="text-sm">Sugar Level <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.sugarlevel ? 'is-invalid' : ''}`} name="sugarlevel" id="sugarlevel_id" value={modalData.sugarlevel === null ? '' : modalData.sugarlevel} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.sugarlevel} </div>
                        </div>    

                          <div className="form-group col-md-2">
                            <label className="text-sm">Trigicy Ride</label>
                            <input type="text" className="form-control" name="ride" id="ride_id" value={modalData.ride === null ? '' : modalData.ride} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  

                      </div>


                    {/*  form-row */}
                    <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label className="text-sm">Clinical Chemistry <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.chemistry ? 'is-invalid' : ''}`} name="chemistry" id="chemistry_id" value={modalData.chemistry === null ? '' : modalData.chemistry} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.chemistry} </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label className="text-sm">Urinalysis <span className="text-danger">*</span></label>
                            <input type="text" className={`form-control ${error.urinalysis ? 'is-invalid' : ''}`} name="urinalysis" id="urinalysis_id" value={modalData.urinalysis === null ? '' : modalData.urinalysis} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.urinalysis} </div>
                        </div>  
                          
                        <div className="form-group col-md-3">
                            <label className="text-sm">2D Echo</label>
                            <input type="text" className="form-control" name="echo" id="echo_id" value={modalData.echo === null ? '' : modalData.echo} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                          
                        <div className="form-group col-md-3">
                            <label className="text-sm">Ultrasound</label>
                            <input type="text" className="form-control" name="ultrasound" id="ultrasound_id" value={modalData.ultrasound === null ? '' : modalData.ultrasound} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  

                    </div>  
                    
                    <hr />
                      <div className="mb-2">
                         <span className="font-weight-bold">Result and Findings</span>
                      </div>
                  

                  
                
                    {/*  form-row */}
                    <div className="form-row">  

                         <div className="form-group col-md-6">
                            <label className="text-sm">Laratory Result</label>
                            <input type="text" className="form-control" name="labresult" id="labresult_id" value={modalData.labresult === null ? '' : modalData.labresult} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>      

                        <div className="form-group col-md-6">
                            <label className="text-sm">Findings</label>
                            <input type="text" className="form-control" name="findings" id="findings_id" value={modalData.findings === null ? '' : modalData.findings} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>
                    </div>  
                    {/*  form-row */}
                      
                    <div className="form-row">
                         <div className="form-group col-md-6">
                            <label className="text-sm">Diagnosis</label>
                            <input type="text" className="form-control" name="diagnosis" id="diagnosis_id" value={modalData.diagnosis === null ? '' : modalData.diagnosis} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                          

                         <div className="form-group col-md-6">
                            <label className="text-sm">Other Findings</label>
                            <input type="text" className="form-control" name="temperature" id="otherFindings_id" value={modalData.temperature === null ? '' : modalData.temperature} onChange={handleChange}/>
                            <div  className="invalid-feedback"> { } </div>
                        </div>  
                    </div>  
                      {/*  form-row */}
                      
                  
                    <div className="form-row">  
                         <div className="form-group col-md-12">
                            <label className="text-sm">Plan/Remarks <span className="text-danger">*</span></label>
                            <textarea className={`form-control ${error.plano ? 'is-invalid' : ''}`} cols={1} rows={2} name="plano" id="plano_id" value={modalData.plano === null ? '' : modalData.plano} onChange={handleChange}></textarea>  
                         
                            <div  className="invalid-feedback"> {error.plano} </div>
                        </div>      
                    </div>  
                    {/*  form-row */}
                
                  <hr />
                  
                    <div className="mb-2">
                          <span className="font-weight-bold">Services</span>   
                    </div>
                   
                    <div className="col">
                        
                     <div className="form-row">
                                <div className="form-group col-3">
                                    
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" name="gymequip" id="gymequip_id" value="1" checked={chkdata.gymequip} onChange={handleCheckChange} />
                                        <label className="form-check-label" htmlFor="gymequip_id">
                                           Equipment Gym
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="steambath" id="steambath_id" value="1" checked={chkdata.steambath} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="steambath_id">
                                            Steam Bath
                                        </label>
                                    </div>
                                    
                                   <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="massage" id="massage_id" value="1" checked={chkdata.massage} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="massage_id">
                                            Massage
                                        </label>
                                    </div>
                                </div>
                              
                               <div className="form-group col-2">
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="haircolor" id="haircolor_id" value="1" checked={chkdata.haircolor} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="haircolor_id">
                                            Haircolor
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="haircut" id="haircut_id" value="1" checked={chkdata.haircut} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="haircut_id">
                                            Haircut
                                        </label>
                                  </div>
                                  
                                  <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="hairtrim" id="hairtrim_id" value="1" checked={chkdata.hairtrim} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="hairtrim_id">
                                            Hairtrim
                                        </label>
                                    </div>
                                </div>
                              
                              <div className="form-group col-3">
                                  
                                   <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="facial" id="facial_id" value="1" checked={chkdata.facial} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="facial_id">
                                            Facial
                                        </label>
                                    </div>
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="footspa" id="footspa_id" value="1" checked={chkdata.footspa} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="footspa_id">
                                            Footspa
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="manicure" id="manicure_id" value="1" checked={chkdata.manicure} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="manicure_id">
                                           Manicure/Pedicure
                                        </label>
                                    </div>
                                 </div>
                              

                              <div className="form-group col-4">
                                  
                                   <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="withproblem" id="withproblem_id" value="1" checked={chkdata.withproblem} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="withproblem_id">
                                            With Health Problem
                                        </label>
                                    </div>
                                    
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="withimprovement" id="withimprovement_id" value="1" checked={chkdata.withimprovement} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="withimprovement_id">
                                            With Health Improvement
                                        </label>
                                    </div>

                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="satisfied" id="satisfied_id" value="1" checked={chkdata.satisfied} onChange={handleCheckChange}/>
                                        <label className="form-check-label" htmlFor="satisfied_id">
                                           Satisfied
                                        </label>
                                    </div>
                                </div>
                                                            
                        </div> 
                        {/* form-row */}

                    </div>
                      {/* col */}
                  
                 
                </Modal.Body>
            
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={props.handleEditClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
        </Modal>
          

    </>
  )
}
