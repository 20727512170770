import React, {useEffect, useState } from 'react'

import axios from '../../../../services/axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';



export default function WorkExpModal(props) {
       
    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

  
    useEffect(() => {
       axios.get(`/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);

    // STORED IN THE DATABASE 
    const [modeldata, setModaldata] = useState({
        IDNO: '',
        from: '', 
        to: '', 
        position: '', 
        department: '', 
        monthly: '', 
        sgrade: '',
        status: '',
        government: '',
    }) 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }

    const [error, setError] = useState([]);  
    
    const submitWork = async (e) => {
        e.preventDefault();

      

        await axios.post(`/api/StoredWorkExp`, {

            IDNO: fetchEmpInfo.IDNO,
            from: modeldata.from,
            to: modeldata.to,
            position: modeldata.position,
            department: modeldata.department,
            monthly: modeldata.monthly,
            sgrade: modeldata.sgrade,
            status: modeldata.status,
            government: modeldata.government,
         
      
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {   
            //  console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    props.fetchDataWork(props.unique);
                    setModaldata({
                        from: '',
                        to: '',
                        position: '',
                        department: '',
                        monthly: '',
                        sgrade: '',
                        status: '',
                        government: '',
                    });
                    setShow(false);

                 
                })
            }
        
        })
    
    
    }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
            <i className="nav-icon fas fa-plus" />
        </Button>

        <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-briefcase"/> Create Work Experience </Modal.Title>
        </Modal.Header>
             <Form onSubmit={submitWork}>

                <Modal.Body>

                    <input type="hidden" name="app_id" id="appId_id" defaultValue="1" />
                      
                    <div className="form-row">
                    
                        <div className="form-group col-md-3">
                            <label >From</label>
                            <input type="date" className={`form-control ${error.from ? 'is-invalid' : ''}`} name="from" id="from_id" value={modeldata.from} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.from} </div>
                        </div>
                        
                        <div className="form-group col-md-3">
                            <label>To</label>
                            <input type="date" className={`form-control ${error.to ? 'is-invalid' : ''}`} name="to" id="to_id" value={modeldata.to} onChange={handleChange}/>

                            {/* <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" name="to"  />
                                <label className="form-check-label" htmlFor="exampleCheck1">Present</label>
                            </div> */}

                            <div  className="invalid-feedback"> {error.to} </div>
                        </div>

                        <div className="form-group col-md-5">
                            <label>Position Title</label>
                            <input type="text" className={`form-control ${error.position ? 'is-invalid' : ''}`} name="position" id="position_id" value={modeldata.position} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.position} </div>
                             
                        </div>  

                    </div>
                      {/*  form-row */}


                    <div className="form-row">
                    
                        <div className="form-group col-md-8">
                            <label>Department/Agency/Office/Company</label>
                            <input type="text" className={`form-control ${error.department ? 'is-invalid' : ''}`} name="department" id="departmenr_id" value={modeldata.department} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.department} </div>
                        </div>  

                        <div className="form-group col-md-3">
                            <label>Monthly Salary</label>
                            <input type="text" className={`form-control ${error.monthly ? 'is-invalid' : ''}`} name="monthly" id="monthly_id" value={modeldata.monthly} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.monthly} </div>
                        </div>
                          
                    </div>
                      {/*  form-row */}

                
                    <div className="form-row">
                
                        <div className="form-group col-md-3">
                            <label>Salary/Job/Pay Grade</label>
                            <input type="text" className="form-control" name="sgrade" id="sgrade_id" value={modeldata.sgrade} onChange={handleChange}/>
                        </div>

                        <div className="form-group col-md-4">
                              <label>Status of Appointment</label>
                              <input type="text" className={`form-control ${error.status ? 'is-invalid' : ''}`} name="status" id="status_id" value={modeldata.status} onChange={handleChange}/>
                            {/* <select className="custom-select" name="status_appointment" id="status_appointment_id" >
                                <option value="">Select</option>
                                <option value="CONTRACT OF SERVICE">CONTRACT OF SERVICE</option> 
                                <option value="CASUAL">CASUAL</option>
                                <option value="PERMANENT">PERMANENT</option>
                                <option value="REGULAR">REGULAR</option>
                                <option value="TEMPORARY">TEMPORARY</option>
                                <option value="PROBATIONARY">PROBATIONARY</option>
                            </select> */}
                            <div  className="invalid-feedback"> {error.status} </div>
                        </div>  
                          

                        <div className="form-group col-md-3">
                            <label>Gov't Service</label>
                            <select className={`custom-select ${error.government ? 'is-invalid' : ''}`}  name="government" id="government_id" value={modeldata.government} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="yes">YES</option>
                                <option value="no">NO</option>
                            </select>
                            <div  className="invalid-feedback"> {error.government} </div>
                          </div>  
                        
                    </div>
                    {/*  form-row */}
                 
                </Modal.Body>
                
                <Modal.Footer>
                     <Button variant="outline-danger" onClick={handleClose}>
                        Close
                      </Button> 
                      
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
