import React, { useEffect, useState } from 'react'
import UnmarriedChildrenTable from './components/UnmarriedChildrenTable';
import RealPropertiesTable from './components/RealPropertiesTable';
import PersonalPropertiesTable  from './components/PersonalPropertiesTable';
import LiabilitiesTable from './components/LiabilitiesTable';
import BusinessTable from './components/BusinessTable';
import RelativesTable from './components/RelativesTable';
import { useParams } from 'react-router-dom';
import axios from '../../../services/axios';
import { debounce } from 'lodash';
import { Button } from 'react-bootstrap';
import SALNReport from './report/SALNReport';



export default function EmployeeSALN() {
  
  const { unique } = useParams();
  const [isLoading, setisLoading] = useState(false);
  
  const [showModalReport, setshowModalReport] = useState(false);
  const [dataInfo, setDataInfo] = useState([]);
  const [dataSpouse, setDataSpouse] = useState([]);

  const [dataChild, setDataChild] = useState([]);
  const [dataRealProper, setDataRealProper] = useState([]);
  const [dataPersonalProper, setDataPersonalProper] = useState([]);
  const [dataLiabilities, setDataLiabilities] = useState([]);
  const [dataBusinessInter, setDataBusinessInter] = useState([]);
  const [dataGovRelatives, setDataGovRelatives] = useState([]);
  
  // displays the subtotal and total
  const [totalRealProper, setTotalRealProper] = useState(0);
  const [totalPersonalProper, setTotalPersonalProper] = useState(0);
  const [totalLiabilities, setTotalLiabilities] = useState(0);
  const totalProper = totalRealProper + totalPersonalProper;
  const totalNetWorth = totalProper - totalLiabilities;




  const handleReportModal = () => {
    setshowModalReport(true);
  };

  const handleCloseModal = () => {
    setshowModalReport(false);
  };

  const fetchDataSALN = async (unique) => {
    setisLoading(true);
    
    try {
    
      const respInfo = await axios.get(`/api/EmpFullInfo/${unique}`);
      const respSpouse = await axios.get(`/api/EmpSpouseData/${unique}`);


      const resp1 = await axios.get(`/api/unmarriedChildren/${unique}`);
      const resp2 = await axios.get(`/api/realProperties/${unique}`);
      const resp3 = await axios.get(`/api/perosanalProper/${unique}`);
      const resp4 = await axios.get(`/api/liabilities/${unique}`);
      const resp5 = await axios.get(`/api/BusinessInterests/${unique}`);
      const resp6 = await axios.get(`/api/GovermentRelatives/${unique}`)

      setDataInfo(respInfo.data.EmpFullDetails[0]);
      setDataSpouse(respSpouse.data.EmpSpouse[0]);

      setDataChild(resp1.data.UnmarriedChildren);
      setDataRealProper(resp2.data.RealProper);
      setDataPersonalProper(resp3.data.PersonalProper);
      setDataLiabilities(resp4.data.Listliabilities);
      setDataBusinessInter(resp5.data.BusinessResult);
      setDataGovRelatives(resp6.data.relativesResult);

      
    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }
  }




  
  useEffect(() => {
    const debouncedFetch = debounce(fetchDataSALN, 1000); 

        if (unique) {
          debouncedFetch(unique);
        }

        // Return a cleanup function
        return () => debouncedFetch.cancel();
  }, [unique]);



  const fetchTotalReal = async (unique) => {
    try {
      const resp1 = await axios.get(`/api/totalRealProperties/${unique}`);
      const resp2 = await axios.get(`/api/totalPersonalProperties/${unique}`);
      const resp3 = await axios.get(`/api/totalLiabilities/${unique}`);
      
      setTotalRealProper(resp1.data.sumAcquisitionCost);
      setTotalPersonalProper(resp2.data.sumAcquisitionCost1);
      setTotalLiabilities(resp3.data.sumOutstandingBalance);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
     if (unique) { // Ensure unique is defined before calling the API
      fetchTotalReal(unique);
    }
  }, [unique]);



  
  return (
     <div>
 
          <div className="row">
            <div className="col-sm-10">
              <h4 className="m-2">
                <i className="fas fa-file-alt" /> Sworn Statement of Assets, Liabilities and Net Worth
              </h4>
            </div> {/* /.col */}
            <div className="col-sm-2 d-flex justify-content-end">
          <Button
            type="button"
            variant="primary"
            title="Pring SALN"
            onClick={() => handleReportModal()}
          >
            <i className="nav-icon fas fa-print" /> PRINT SALN
          </Button>
             
            </div> {/* /.col */}
          </div> {/* /.row */}
            
            <hr/>
            {
            isLoading ? (
             
                <div className="overlay-wrapper">
                   <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
              
                    {/* children table */}
                    {/* <UnmarriedChildrenTable /> */}

                      <div className="text-center mb-3">
                            <label className="fw-bolder">
                            ASSETS, LIABILITIES AND NETWORTH
                            </label><br/>
                            <span className="fw-normal">(Including those of the spouse and unmarried children below eighteen (18) years of age living in declarant's household)</span>
                    
                      </div>

                    {/* Assets */}

                    {/* Real Properties */}
                    {/* <RealPropertiesTable/> */}
                    
                    <div className="row">
                      <div className="col-6">
                        
                        {/* Personal Properties */}
                        {/* <PersonalPropertiesTable/> */}

                      </div>
                      
                        <div className="col-6">
                        
                        {/* Liabilities */}
                        {/* <LiabilitiesTable/> */}
                

                      </div>
                    </div>
                    
                </div>
                
                 

        
              ) : 
                  <>
                    {/* children table */}
                    <UnmarriedChildrenTable dataChild={dataChild} fetchDataSALN={fetchDataSALN} unique={unique} />

                      <div className="text-center mb-3">
                        <label className="fw-bolder">ASSETS, LIABILITIES AND NETWORTH</label><br/>
                        <span className="fw-normal">(Including those of the spouse and unmarried children below eighteen (18) years of age living in declarant's household)</span>
                      </div>

                    {/* Assets */}

                    {/* Real Properties */}
                    <RealPropertiesTable dataRealProper={dataRealProper} fetchDataSALN={fetchDataSALN} unique={unique} totalRealProper={totalRealProper} />
                    
                    {/* Personal Properties */}
                    <PersonalPropertiesTable dataPersonalProper={dataPersonalProper} fetchDataSALN={fetchDataSALN} unique={unique} totalPersonalProper={totalPersonalProper} totalProper={totalProper} />

                    {/* Liabilities */}
                    <LiabilitiesTable dataLiabilities={dataLiabilities} fetchDataSALN={fetchDataSALN} unique={unique} totalLiabilities={totalLiabilities} totalNetWorth={totalNetWorth} />
                
                    <div className="text-center mb-3">
                      <label className="fw-bolder">BUSINESS INTERESTS AND FINANCIAL CONNECTIONS</label><br/>
                      <span className="fw-normal">(of Declarant / Declarant's/ Unmarried Children Below Eighteen(18) years of Age Living in Declarant's Household)</span>
                    </div>

                  {/* Business */}
                  <BusinessTable dataBusinessInter={dataBusinessInter} fetchDataSALN={fetchDataSALN} unique={unique} />
                  
                  {/* Relatives */}
                  <RelativesTable dataGovRelatives={dataGovRelatives} fetchDataSALN={fetchDataSALN} unique={unique}/>
                </>
            }

          {/* report modal */}
      <SALNReport show={showModalReport} handleCloseModal={handleCloseModal}
        dataInfo={dataInfo}
        dataSpouse={dataSpouse}
        dataChild={dataChild}
        dataRealProper={dataRealProper}
        dataPersonalProper={dataPersonalProper}
        dataLiabilities={dataLiabilities}
        dataBusinessInter={dataBusinessInter}
        dataGovRelatives={dataGovRelatives}

        totalRealProper={totalRealProper}
        totalPersonalProper={totalPersonalProper}
        totalLiabilities={totalLiabilities}
        totalProper={totalProper}
        totalNetWorth={totalNetWorth}
      />
    </div>
  )
}
