import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../services/axios';


import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { debounce } from 'lodash';
import ReferModal from '../components/ReferModal';
import Swal from 'sweetalert2';
import GovModal from '../components/GovModal';
// import CardRefer from '../components/CardRefer'
// import CardGov from '../components/CardGov'


export default function EmployeeReference() {
  const { unique } = useParams();
  const [isLoading, setisLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fetchRefer, setFetchRefer] = useState([]);
  const [fetchGovtID, setFetchGovID] = useState([]);
  // Edit Function
  const [modeldata, setModaldata] = useState({
      id:'',
      name: '',
      address: '',
      contact: '',
  });

  
  
  const fetchDataRefer = async (unique) => {
    setisLoading(true);
    try {
      const resp1 = await axios.get(`/api/EmpRefer/${unique}`);
      const resp2 = await axios.get(`/api/EmpGovID/${unique}`);
      
       setFetchRefer(resp1.data.EmpRefer)
       setFetchGovID(resp2.data.EmpGovtID) 

    } catch (error) {
      console.log(error);
    } finally {
      setisLoading(false);
    }       
  };
  
  useEffect(() => {
      const debouncedFetch = debounce(fetchDataRefer, 1000); // Adjust the delay time as needed
  
      
      // Invoke the debounced function
      debouncedFetch(unique);

      // Return a cleanup function
      return () => debouncedFetch.cancel();
  }, [unique]);
  
  
    const handleButtonClick = (data) => {
        setModaldata(data);
        setShowModal(true);
    };
    
    const handleClose = () => {
        setShowModal(false);
  }; 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }
  
  // udpate Referrence
   const updateData = async (e) => {
         e.preventDefault();
         
    
        const formData = new FormData()
        formData.append('id', modeldata.id);
        formData.append('name', modeldata.name ?? "");
        formData.append('address', modeldata.address ?? "");
        formData.append('contact', modeldata.contact ?? "");

        try {
            const { data } = await axios.post(`/api/UpdatedRef`, formData); // Destructure the response
            Swal.fire({
                icon: "success",
                title: data.message, // Using destructured data
            }).then(() => {
                handleClose(); // Close modal after success
                fetchDataRefer(unique); // Refresh data
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "An error occurred",
                text: error.response?.data?.message || "Please try again later.",
            });
        }
    }

  return (
    <div>
        {/* Display of Reference */}
        <div className="card">
            <div className="card-body">
                <div className="row">
                        <div className="col-sm-6">
                            <h4 className="m-2"><i className="fas fa-users"/> References </h4>
                        </div>{/* /.col */}   
                        <div className="col-sm-6">
                             <div className="float-right">
                              {
                                fetchRefer.length > 0 ? (
                                ""
                              ): (
                                          <ReferModal unique={unique} fetchDataRefer={fetchDataRefer} />
                                )
                              }                
                             
                            </div>
                        </div>{/* /.col */} 
                </div>{/* /.row */}

                  <hr />
                  {  
                    isLoading ? (
                    <div className="overlay-wrapper">
                        <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                    </div>
                    ) : (   
                    <>

                    <div className="form-row">
                    {
                  
              
                      fetchRefer.length > 0 ? 
                          fetchRefer.map((data) => {
                              const {
                                  name, 
                                  address,
                                  contact, 
                                  id,
                              } = data;
                              return (
                                <div className="col-md-4" key={id}>
                                
                                    <div className="card shadow">
                                        <div className="card-body">  
                                            <div className="mb-2 float-right">
                                                <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } ><i className="nav-icon fas fa-edit" /></Button>                                       
                                            
                                            </div>


                                            <label className="text-md">{name === null ? "N/A" : name} </label> <br/>
                                            <span>{address === null ? "N/A" : address}</span>  <br />
                                            <span>{contact === null ? "N/A" : contact}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                                
                              )
                          })              
                        : 
                           ""
                    } 
                    </div>
                
                    {/* Card Gov  */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <h4 className="m-2"><i className="fas fa-id-card" /> <span className="text-md">Government Issued ID (i.e.Passport, GSIS, SSS, PRC, Driver's License, etc.)
                                    PLEASE INDICATE ID Number and Date of Issuance  </span>
                                    </h4>
                                </div>{/* /.col */}   
                                <div className="col-sm-6">
                                    <div className="float-right">
                                                      <GovModal fetchGovtID={fetchGovtID} fetchDataRefer={fetchDataRefer} unique={unique} />
                                    </div>
                                </div>{/* /.col */} 
                            </div>{/* /.row */}
                    
                        <hr /> 
                        {
                            fetchGovtID.length > 0 ?    
                             <div>
                                <label>Government Issued ID: </label> <span>{fetchGovtID[0]?.issuedid}</span> <br/>
                                <label>ID/License/Passport No.: </label> <span>{fetchGovtID[0]?.issueno}</span> <br/>
                                <label>Date/Place of Issuance: </label> <span>{fetchGovtID[0]?.issuedat}</span> <br/>
                            </div>   
                            : ""                 
                        }
                           
                        </div>
                    </div>
                </>
                    )
                }
            </div>
        </div>
      
        {/* Display of Government Id */}
        
      


      {/* Modal Referrence */}
    <Modal show={showModal} onHide={handleClose}  size="md">
        
            <Modal.Header>
                <Modal.Title><i className="fas fa-users"/> Update Reference Details </Modal.Title>
            </Modal.Header>
            <Form onSubmit={updateData}>
                <Modal.Body>
                   
                    
                  <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Reference Name:</label>
                            <input type="text" className="form-control" name="name" id="name_id" value={modeldata.name === null ? '' : modeldata.name} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Reference Address:</label>
                            <input type="text" className="form-control" name="address" id="address_id" value={modeldata.address === null ? '' : modeldata.address} onChange={handleChange} />
                        </div>  
                    </div>

                     <div className="form-row">
                        <div className="form-group col-md-12">
                            <label className="text-sm">Reference Tell No.e:</label>
                            <input type="text" className="form-control"  name="contact" id="contact_id" value={modeldata.contact === null ? '' : modeldata.contact} onChange={handleChange} />
                        </div>       
                    </div>
                
                </Modal.Body>
            
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
    </Modal>

    </div>

    
  )
}
