import React, { useState } from 'react';
import axios from '../../../../services/axios';
import { Button } from 'react-bootstrap'
import BusinessModal from './BusinessModal';
import BusinessEditModal from './BusinessEditModal';
import Swal from 'sweetalert2';



const BusinessTable = (props) => {

  // const [isChecked, SetIsChecked] = useState(false);
  const [showModal, SetShowModal] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editDataModal, setEditDataModal] = useState([]);



  
  // const handleCheckboxChange = (e) => {

  //   if (props.dataBusinessInter.length > 0) {
      
  //     SetIsChecked(false);
  //     Swal.fire({
  //       title: "Ooops!",
  //       text: "You have existing data inside the table. Before checking, the table must be empty.",
  //       icon: "error"
  //     });
  
  //   } else {
      
  //     handleChecked();
  //     // SetIsChecked(e.target.checked);



  //   }
    
  // }


  // create modal 
  const handleModalBusiness = () => {
    SetShowModal(true);
  }

  const handleClose = () => {
    SetShowModal(false);
  }

  // Edit Modal
  const handleEditModal = (data) => {
       setEditDataModal(data);
      setShowEditModal(true);
  };

  const handleEditClose = () => {
        setShowEditModal(false);
  };

  
  // Checked Function
  // const handleChecked = async () => {

  //   await axios.post(`/api/selectedCheckbox`, {
  //     plantilla_id: props.unique

  //   }).then((res) => {

  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Checkbox selected successfully',
  //       })

  //   });

  // }


  // Delete Function 
  const handleDelete = (id) => {

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async  (result) => {
  
        if (result.isConfirmed) {
          try {
            await axios.delete(`/api/delBusiness/${id}`).then((res) => {

              Swal.fire({
                title: "Deleted!",
                text: res.data.message,
                icon: "success"
              }).then((res) => {
                
                props.fetchDataSALN(props.unique);
              });

            });

           
          } catch (error) {
            console.error("There was an error deleting the record", error);
          }
  
    }
    
      });
    
    
   
  } 
 





  return (
      <>
   
     <div className="card">
       <div className="card-body">
        
          <div className="row mb-2">
                <div className="col-sm-8">

                  {/* <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}  />
                    <label className="form-check-labe fw-boldl" htmlFor="flexCheckChecked">
                        I/We do not have any business interest or financial connection.
                    </label>
                </div>   */}
    
                </div>{/* /.col */}   
                <div className="col-sm-4">
                    <div className="float-right">
                      <Button type="button"  onClick={handleModalBusiness} variant="outline-primary"><i className="nav-icon fas fa-plus" /> Add</Button>        
                    </div>
                </div>{/* /.col */} 
            </div>{/* /.row */}
                
            <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                
                  <thead>
                      <tr>
                        <th className="text-center">NAME OF ENTITY/BUSINESS ENTERPRISE</th>
                        <th className="text-center">BUSINESS ADDRESS</th>
                        <th className="text-center">NATURE OF BUSINESS INTEREST &/OR FINANCIAL CONNECTION</th>
                        <th className="text-center">DATE OF ACQUISITION OF INTEREST OF CONNECTION</th>
                        <th className="text-center"> 
                            Action
                        </th>
                      </tr>
                </thead>      
                <tbody>
                  {
                    props.dataBusinessInter.length > 0 ? 
                     props.dataBusinessInter.map((data) => {
                       const { 
                         id, 
                         business_enterprise,
                         business_address,
                         nature_business,
                         date_acquired,
                       } = data;

                        return (
                            <tr key={id}>
                              <td>{ business_enterprise }</td>
                              <td>{ business_address }</td>
                              <td align='center'>{ nature_business }</td>
                              <td align='center'>{ date_acquired }</td>
                            <td align='center'>
                              <div className='row'>
                                <div className='col-md-5'>
                                    <Button variant="outline-danger btn-sm" title="delete" onClick={() => handleDelete(data.id)}  ><i className="nav-icon fas fa-trash" /></Button>
                                </div>
                                <div className='col-md-5'>
                                    <Button variant="outline-primary btn-sm" title="Edit" onClick={() => handleEditModal(data)} ><i className="nav-icon fas fa-edit" /></Button>
                                </div>
                              </div>
                                 
                              </td>
                            </tr>    
                          
                        )

                      })
                    
                    :
                   <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>    
                  }
                   
                </tbody>
                
            </table>          

         


       </div> 
    </div>

      <BusinessEditModal show={showEditModal} handleEditClose={handleEditClose} data={editDataModal} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
      <BusinessModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
     </>
  )
}

export default BusinessTable 