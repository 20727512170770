import axios from '../../../../services/axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';


const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export default function RealPropertiesModal(props) {

  const [fetchEmpInfo, setEmpInfo] = useState({
      IDNO: "",
  });
  
  const [fetchData, setFetchData] = useState({
    description: "",
    kind: "",
    exact_location: "",
    assessed_value: "",
    market_value: "",
    acquisition_year: "",
    acquisition_mode: "",
    acquisition_cost: "",
  });

  const [error, setError] = useState([]);
  
 const handleChange = (e) => {
    const { name, value } = e.target;

    // Remove commas and non-digit characters from input value
    const cleanedValue = value.replace(/[^\d]/g, '');

    // Format the cleaned value with commas
    const formattedValue = formatNumberWithCommas(cleanedValue);



    if (name === "assessed_value" || name === "market_value" || name === "acquisition_cost") {
      setFetchData({
        ...fetchData,
        [name]: formattedValue
      });
    } else {
       setFetchData({
        ...fetchData,
        [name]: value
      });
    }
   
  };

  useEffect(() => {
        axios.get(`http://localhost:8000/api/EmpFullInfo/${props.unique}`).then((res) => {
          setEmpInfo(res.data.EmpFullDetails[0])
          
          
        })  
  }, [props.unique]);

  
  // function to save data 
  const submitData = async (e) => {
      e.preventDefault();


    const assessedValue = fetchData.assessed_value.replace(/,/g, '');
    const marketValue = fetchData.market_value.replace(/,/g, '');
    const acquisitionCost = fetchData.acquisition_cost.replace(/,/g, '');
    
    await axios.post(`/api/storedRealProper`, {
      IDNO: fetchEmpInfo.IDNO,
      plantilla_id: props.unique,
      description: fetchData.description,
      kind: fetchData.kind,
      exact_location: fetchData.exact_location,
      assessed_value: assessedValue,
      market_value: marketValue,
      acquisition_year: fetchData.acquisition_year,
      acquisition_mode: fetchData.acquisition_mode,
      acquisition_cost: acquisitionCost,
    }).then((res) => {

        if (res.data.status === 422) {
          
          Swal.fire({
            icon: 'error',
            title: 'All fields are required',
          })
          setError(res.data.errors);
       
        } else {
            Swal.fire({
              icon: "success",
              title: res.data.message,
            }).then((res) => {
              props.fetchDataSALN(props.unique)
              setFetchData({
                description: '',
                kind: '',
                exact_location: '',
                assessed_value: '',
                market_value: '',
                acquisition_year: '',
                acquisition_mode: '',
                acquisition_cost: '',
              });
               props.handleClose(false)
            })
        }   
     
    });

  };


  return (
    <>
      
          <Modal show={props.show} onHide={props.handleClose} size="lg">
              
            <Modal.Header >
                <Modal.Title>Create Real Properties</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitData}>
            <Modal.Body>
             {/* <div className="form-row">
              
                <div className="col-md-6"> */}
                   
              <div className="form-row">
              
                  <div className="form-group col-md-8">
                    <label>Description</label>  <span className="text-sm"> (e.g lot house and lot, condominium and improvements)</span>
                    <input type="text" className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" onChange={handleChange} value={fetchData.description ?? ""}  />
                    <div  className="invalid-feedback">{error.description}</div>
                  </div>    
                    
              
                  <div className="form-group col-md-4">
                      <label>Kind</label> 
                        <select className={`form-control ${error.kind ? 'is-invalid' : ''}`} name="kind" onChange={handleChange} value={fetchData.kind ?? ""}>
                          <option value="">Select</option>
                          <option value="Resident">Residential</option>
                          <option value="Commercial">Commercial</option>
                          <option value="Industrial">Industrial</option>
                          <option value="Agriculture">Agriculture</option>
                          <option value="Mixed use">Mixed use</option>
                        </select>
                      <div  className="invalid-feedback">{error.kind}</div> 

                  

                      {/* <input type="text" className={`form-control ${error.kind ? 'is-invalid' : ''}`} name="kind" onChange={handleChange} value={fetchData.kind ?? ""} />
                      <div  className="invalid-feedback">{error.kind}</div> */}
                    </div>    
                    
              
                    <div className="form-group col-md-12">
                      <label>Exact Location</label>
                      <input type="text" className={`form-control ${error.exact_location ? 'is-invalid' : ''}`} name="exact_location" onChange={handleChange} value={fetchData.exact_location ?? ""}/>
                        <div  className="invalid-feedback">{error.children_bday}</div>
                  </div> 
              
                  <div className="form-group col-md-6">
                      <label>Assessed Value</label>
                      <input type="text" className={`form-control ${error.assessed_value ? 'is-invalid' : ''}`} name="assessed_value" onChange={handleChange} value={fetchData.assessed_value ?? ""}/>
                      <div  className="invalid-feedback">{error.assessed_value}</div>
                  </div> 
                  
                  <div className="form-group col-md-6">
                      <label>Current Fair Market Value</label>
                      <input type="text" className={`form-control ${error.market_value ? 'is-invalid' : ''}`} name="market_value" onChange={handleChange} value={fetchData.market_value ?? ""}/>
                      <div  className="invalid-feedback">{error.market_value}</div>
                  </div>  
              
                  <div className="form-group col-md-6">
                      <label>Acquisition Year</label>
                      <input type="text" className={`form-control ${error.acquisition_year ? 'is-invalid' : ''}`} name="acquisition_year" onChange={handleChange} value={fetchData.acquisition_year ?? ""}/>
                      <div  className="invalid-feedback">{error.acquisition_year}</div>
                  </div> 
                  
                    <div className="form-group col-md-6">
                      <label>Acquisition Mode</label>
                      <input type="text" className={`form-control ${error.acquisition_mode ? 'is-invalid' : ''}`} name="acquisition_mode" onChange={handleChange} value={fetchData.acquisition_mode ?? ""}/>
                      <div  className="invalid-feedback">{error.acquisition_mode}</div>
                  </div> 
                  
                  <div className="form-group col-md-6">
                    <label>Acquisition Cost</label>
                    <input type="text" className={`form-control ${error.acquisition_cost ? 'is-invalid' : ''}`} name="acquisition_cost" onChange={handleChange} value={fetchData.acquisition_cost ?? ""}/>
                    <div  className="invalid-feedback">{error.acquisition_cost}</div>
                  </div> 
                            
                   {/* </div>
                    
                </div> */}
                
                {/* <div className="col-md-6">
                      <div className="form-group col-md-12">
                        <label>Description</label> <br/>
                        <span className="text-sm"> (e.g lot house and lot, condominium and improvements)</span>
                        <textarea className={`form-control ${error.description ? 'is-invalid' : ''}`} name="description" rows={11} onChange={handleChange} value={fetchData.description ?? ""}  />
                        <div  className="invalid-feedback">{error.description}</div>
                    </div>    

                </div>             */}

                   
                          
                </div>
                {/* form-row */}
                      

            </Modal.Body>
            
            <Modal.Footer>
                <Button variant="outline-danger" onClick={props.handleClose}>
                    Close
                </Button>
                <Button type="submit" variant="success">
                    Save Changes
                </Button>
            </Modal.Footer>
          </Form>    
        </Modal>
    </>
  )
}

