import axios from '../../../../services/axios';
import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal'
import Form  from 'react-bootstrap/Form'
import Swal from 'sweetalert2'
import { debounce } from 'lodash';
import { format } from 'date-fns';

import TrainingModal from '../components/TrainingModal'



export default function EmployeeTrainings() {

    const { unique } = useParams(); 
    const [isLoading, setisLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [error, setError] = useState([]);
    //  Training and seminar 
    const [fetchTrainings, setTrainings] = useState([]);
    //Edit Function
    const [modeldata, setModaldata] = useState({
        id: '',
        TRCORS: '',
        TRNFROM: '',
        TRNTO: '',
        TRNHRS: '',
        LD: '',
        CONDUCT: ''
      
    });

    // const getFormattedDate = (dateStr) => {
    //     if (!dateStr) return "";
    //     const parsedDate = parse(dateStr, 'MM/dd/yyyy', new Date());
    //     return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : "";
    // };
    
    const fetchDataTraining = async (unique) => {
        
    setisLoading(true);
      try {
            const response = await axios.get(`/api/EmpTraining/${unique}`);
                setTrainings(response.data.EmpTraining);

        } catch (error) {
            console.log(error);
        } finally {
          setisLoading(false);
        }
    };

    useEffect(() => { 
            const debouncedFetch = debounce(fetchDataTraining, 1000); // Adjust the delay time as needed
                // Invoke the debounced function
            debouncedFetch(unique);

            // Return a cleanup function
            return () => debouncedFetch.cancel();
   
    }, [unique]);




   const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }


    const handleButtonClick = (data) => {
        setModaldata(data);
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
    setShowModal(false);
  };
  

    const updateData = async (e) => {
        e.preventDefault();

        const formData = new FormData()
        formData.append('id', modeldata.train_id);
        formData.append('TRCORS', modeldata.TRCORS ?? "");
        formData.append('TRNFROM', modeldata.TRNFROM ?? "");
        formData.append('TRNTO', modeldata.TRNTO ?? "");
        formData.append('TRNHRS', modeldata.TRNHRS ?? "");
        formData.append('LD', modeldata.LD ?? "");
        formData.append('CONDUCT', modeldata.CONDUCT ?? "");

        await axios.post(`/api/UpdatedTraining`, formData)
            .then((res) => {
                // console.log(res.data.message);
                if (res.data.status === 422) {
                    Swal.fire({
                        icon: 'error',
                        title: 'All fields are required',
                    })
                    setError(res.data.errors);

                }
                else {
                    Swal.fire({
                        icon: "success",
                        title: res.data.message,
                    }).then((res) => {
                        setShowModal(false);
                        fetchDataTraining(unique);
                        setError('');
                    })
                }
            
            });

        
    };

  return (
    <div>
        <div className="row">
            <div className="col-sm-6">
                <h4 className="m-2"><i className="fas fa-chalkboard"/> Learning and Development</h4> 
            </div>{/* /.col */}   
            <div className="col-sm-6">
                <div className="float-right">
                      <TrainingModal fetchDataTraining={fetchDataTraining} unique={unique} />

                </div>
            </div>{/* /.col */} 
        </div>{/* /.row */}

        <hr /> 
    {
        isLoading ? (
        <div className="overlay-wrapper">
            <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
              
                <div className="mx-1">
                    <div className="float-right">
                        <Button variant="outline-primary btn-sm" > <i className="nav-icon fas fa-edit" /></Button>
                    </div>
                </div>        
                
                <br />
                <div className="form-row">
            
                    <label className="col-sm-3 col-form-label">
                        
                    </label>
                    <div className="col-md-7">
                        <label></label> <br />
                        <span></span> |<span className="text-uppercase"> </span>
                    
                    </div>
        
                </div>
                <hr/> 
                
            </div>
        ) : 
        fetchTrainings.length > 0 ? 
          fetchTrainings.map((data) => {
            const {
              TRCORS, 
              TRNFROM, 
              TRNTO, 
              TRNHRS, 
              CONDUCT,
              train_id
            } = data;

            return (
                <div key={train_id}>
                        
                      <div className="mx-1">
                          <div className="float-right">
                             <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                          </div>
                      </div>        
                      
                      <br />
                      <div className="form-row">
                  
                          <label className="col-sm-3 col-form-label">
                                {format(new Date(TRNFROM), 'MMMM d, yyyy')} - {format(new Date(TRNTO), 'MMMM d, yyyy')}
                          </label>
                          <div className="col-md-7">
                              <label>{TRCORS}</label> <br />
                              <span>{CONDUCT}</span> |<span className="text-uppercase"> {TRNHRS != null ? TRNHRS + "Hours" : ""}</span>
                          
                          </div>
              
                      </div>
                      <hr/> 
                  
                </div>
              )
            })
        
          :
          ""
      }
      
       {/* Edit Modal  */}
        <Modal show={showModal} backdrop="static" keyboard={false}  size="lg">
       
            <Modal.Header>
                <Modal.Title><i className="fas fa-chalkboard"/> Edit Learning Development</Modal.Title>
            </Modal.Header>
              
            <Form onSubmit={updateData}>
                <Modal.Body>
                  
                    
                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label>Title of Learning and Development Intervention/Training Program</label>
                            <input type="text" className={`form-control ${error.TRCORS ? 'is-invalid' : ''}`}  name="TRCORS" id="TRCORS_id" value={modeldata.TRCORS ?? "" } onChange={handleChange}/>
                             <div  className="invalid-feedback"> { error.TRCORS } </div>
                        </div>              
                    </div>
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>From</label>
                            <input type="date" className={`form-control ${error.TRNFROM ? 'is-invalid' : ''}`}  name="TRNFROM" id="TRNFROM_id" value={modeldata.TRNFROM ?? ""} onChange={handleChange} />
                             <div  className="invalid-feedback"> { error.TRNFROM } </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label>To</label>
                            <input type="date" className={`form-control ${error.TRNTO ? 'is-invalid' : ''}`}  name="TRNTO" id="TRNTO_id" value={modeldata.TRNTO ?? ""} onChange={handleChange} />
                             <div  className="invalid-feedback"> { error.TRNTO } </div>   
                        </div>  

                        <div className="form-group col-md-5">
                            <label>Type of Learning Development</label>
                            <select className={`custom-select ${error.LD ? 'is-invalid' : ''}`} name="LD" id="LD_id" value={modeldata.LD ?? ""} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="foundation">FOUNDATION</option>
                                <option value="technical">TECHNICAL</option>
                                <option value="supervisional">SUPERVISIONAL</option>
                                <option value="managerial">MANAGERIAL</option>
                            </select>
                            <div  className="invalid-feedback"> { error.LD } </div>
                        </div>            
                    </div>
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Number of Hours</label>
                            <input type="text" className="form-control"  name="TRNHRS" id="TRNHRS_id" value={modeldata.TRNHRS ?? ""} onChange={handleChange} />
                           
                        </div>

                        <div className="form-group col-md-8">
                            <label>Conducted/Sponsored By</label>
                            <input type="text" className={`form-control ${error.CONDUCT ? 'is-invalid' : ''}`}  name="CONDUCT" id="CONDUCT_id" value={modeldata.CONDUCT ?? ""} onChange={handleChange} />
                            <div  className="invalid-feedback"> { error.CONDUCT } </div> 
                        </div>              
                    </div>
                     {/* form-row */}
                      
                </Modal.Body>
            
                <Modal.Footer>
                <Button variant="outline-danger" onClick={() => handleCloseModal()}>
                    Close
                </Button>
                <Button type='submit' variant="success">Save Changes</Button> 
                </Modal.Footer>
            </Form>        
        </Modal> 


    </div>
  )
}
