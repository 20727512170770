import React, { useEffect, useState} from 'react'
import axios from '../../../../services/axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import emptyPic from '../../../../image/emptyImage.jpg';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner';

export default function AddNewEmployee(props) {


  const [ImageError, setImageError] = useState(null);
  const [error, setError] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [fetchImage, setFetchImage] = useState(null);
  const [fetchData, setFetchData] = useState({
    employeeID: '',
    surname: '', 
    fname: '',
    mname: '',
    lname: '', 
    adcom: '',
    pera: '',
    rata: '',
    clothing: '', 
    subsitence: '', 
  });
  const [fetchServiceData, setServiceData] = useState({
    fromDate: '',
    toDate: '',
    contract: '',
    status: '',
    branch: '',
  });




  const [fetchEsta, setFetchEsta] = useState('');
  const [fetchOffcode, setFetchOffcode] = useState('');
  const [fetchCharges, setFetchCharges] = useState('');
  const [fetchPostNO, setFetchPostNO] = useState('');
  const [fetchDesignation, setFetchDesignation] = useState('');
  const [fetchSequence, setFetchSequence] = useState('');
  const [fetchSalary, setFetchSalary] = useState('');
  const [fetchStation, setFetchStation] = useState('');

  // display in dropdowns
  const [fetchOffices, setOffices] = useState([]);
  const [OptionPosition, setOptionPosition] = useState([]);

  const [btnSubmit, setBtnSubmit] = useState(false);
  const fileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  const maxSize = 5 * 1024 * 1024; // 5MB
  
  
  const handleProfilePic = (e) => {
  const file = e.target.files[0];
    
    setImageError(null);

    if (file) { 

      if (!fileTypes.includes(file.type)) {
         setImageError('Please select a valid image file (JPEG, JPG, PNG).');
        setBtnSubmit(true);
        return;
      }

      // Validate file size
      if (file.size > maxSize) {
        setImageError('File size exceeds 5MB.');
        setBtnSubmit(true);
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setProfilePic(imageUrl);
      setFetchImage(e.target.files[0]);
      setBtnSubmit(false);
    }
  
  }


  const handleEstatusChange = (e) => {
    setFetchEsta(e.target.value);
    setFetchOffcode('');
    setFetchPostNO('');
    setFetchSalary('');
    setFetchStation('');
  };

  const handleOfficeChange = (e) => {
    setFetchOffcode(e.target.value);
  };

  const handlePostNOchange = (e) => {
    setFetchPostNO(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setFetchSalary(e.target.value)
  }


  
  const handleChange = (e) => {
     const { name, value } = e.target;
      setFetchData(fetchData => ({ ...fetchData, [name]: value }));
  };

  const handleServiceChange = (e) => {
         const { name, value } = e.target;
      setServiceData(fetchServiceData => ({ ...fetchServiceData, [name]: value }));
  }


    // fetching of list of Office
   useEffect(() => {
      const getOffice = async () => {
        try {
          const response = await axios.get('/api/Listoffices');
          setOffices(response.data.offices);
          
        } catch (error) {
          // Handle error
        }
      };
      getOffice();
   }, []);
  
    // fetching details of office
    useEffect(() => {
      if (fetchOffcode !== '') 
      {
        const SelectedDept = fetchOffcode;

          const Department = fetchOffices.find((option) => option.offcode === SelectedDept);
    
        if (Department) {
          setFetchStation(Department.servicerecordname || "");
          setFetchCharges(Department.charges || "");
          }
          else {
            setFetchStation('');
        }
      }
      else {

      }
        
    }, [fetchOffcode, fetchOffices]);
  
  // fetch if list of postion based on the employeement office
  useEffect(() => {
    if (fetchEsta === 'Permanent' || fetchEsta === 'Co-Terminous') {

      if (fetchOffcode) {
        
        const getPosition = async () => {
        
          try {
            const response = await axios.get(`/api/RegPosition/${fetchOffcode}`);
              setOptionPosition(response.data.regPosition); // set the diffault position with the selected position
          } catch (error) {
            console.log(error);
            
          }
        
        }
        getPosition();
        }
        else {
            setOptionPosition([]);

        }

    } else if (fetchEsta === 'JO' || fetchEsta === 'CA'|| fetchEsta === 'CON') {
      
      const getPosition = async () => {
          await axios.get(`/api/NonRegPosition`).then((res) => {
  
          setOptionPosition(res.data.nonRegPosition)
         
          })
        }
        getPosition()
    } else {
      setOptionPosition([]);

  }
    
    
    }, [fetchOffcode, fetchEsta]);

  
  
  // fetching details of position
   useEffect(() => {
     // This effect will run when the component mounts (initial load)
    
    if (fetchPostNO !== '') {

      if (fetchEsta === 'Permanent' || fetchEsta === 'Co-Terminous') {
    
        const selectedPostNO = parseInt(fetchPostNO);
        const selectedPosition = OptionPosition.find((option) => option.postno === selectedPostNO);
  
        if (selectedPosition) {

          setFetchDesignation(selectedPosition.Designation || "");
          setFetchSequence(selectedPosition.SEQUENCE || "")
          setFetchSalary(selectedPosition.AMOUNT || "");
         
        } 


      }
      else if (fetchEsta === 'JO' || fetchEsta === 'CA' || fetchEsta === 'CON') {
        
        const selectedPostNO = parseInt(fetchPostNO);
        const selectedPosition = OptionPosition.find((option) => option.pp_unique === selectedPostNO);
       
        // 
        if (selectedPosition) {
          setFetchDesignation(selectedPosition.Position || "");
          // setFetchSalary('');
        }
     

      }
    }
   }, [fetchPostNO, OptionPosition, fetchEsta]);
  
  

  
  // stored new employee
  const navigate = useNavigate();

  const submitData = async (e) => {
    e.preventDefault();

    // setBtnLoading(true);

    const formData = new FormData()
    formData.append('IDNO', fetchData.employeeID);
    formData.append('profile_pic', fetchImage);
    formData.append('OFFCODE', fetchOffcode);
    formData.append('charges', fetchCharges);
    formData.append('POSTNO', fetchPostNO);
    formData.append('SURNAME', fetchData.lname);
    formData.append('FINAME', fetchData.fname);
    formData.append('MIDNAME', fetchData.mname);
    formData.append('SEQUENCE', fetchSequence);
    formData.append('ESTA', fetchEsta);
    formData.append('salary', fetchSalary);
    formData.append('adcom', fetchData.adcom);
    formData.append('rata', fetchData.rata);
    formData.append('pera', fetchData.pera);
    formData.append('clothing', fetchData.clothing);
    formData.append('subsitence', fetchData.subsitence);
    formData.append('fromdate', fetchServiceData.fromDate);
    formData.append('todate', fetchServiceData.toDate);
    formData.append('designation', fetchDesignation);
    formData.append('status', fetchServiceData.status);
    formData.append('contract', fetchServiceData.contract);
    formData.append('station', fetchStation);
    formData.append('branch', fetchServiceData.branch);

    await axios.post(`/api/NewEmployee`, formData ).then((res) => {
      // console.log(res.data.id);
      // console.log(res.data.status);
      if (res.data.status === 422) {

        Swal.fire({
          icon: 'error',
          title: 'All fields are required',
        })
        setError(res.data.errors);
        setImageError(res.data.errors.profile_pic);
      
        setTimeout(() => {
          setBtnLoading(false);
        }, 2000);

      } else if (res.data.status === 500) {
        
        Swal.fire({
          icon: 'error',
          title: 'Ooops!',
          text: 'Something went Wrong',
        });
       
        window.location.reload();

         setTimeout(() => {
          setBtnLoading(false);
         }, 2000);
        
     
      } else if (res.data.status === 200) {

        const uniqueId = res.data.id;
        navigate(`/employee-details/${uniqueId}`);

        if (res.data.NewEmp === 1) {
          Swal.fire({
            icon: "info",
            title: "Successfully created new employee account",
            text: "Please complete all the required information for the new employee.",

          });
          setTimeout(() => {
            setBtnLoading(false);
          }, 2000);
        }

      }
    
    });


  };
  
  
  return (
    <>

    <Modal show={props.showCreateModal} onHide={props.handleClosed}  backdrop="static"  keyboard={false}  size="xl">
        <Modal.Header >
          <Modal.Title>Create New Employee</Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitData} encType="multipart/form-data">
              <Modal.Body>
                <div className="row"> 
                  <div className="col-md-5">
                    <div className="card">
                  <div className="card-body d-flex flex-column align-items-center">
                 
                        <div className="container-profilepic card overflow-hidden mb-3 shadow" style={{ width: '220px', height: '220px' }}>
                          <div
                            className="card-img w-100 h-100"
                            style={{
                              backgroundImage: `url(${profilePic || emptyPic})`,
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                            }}
                          />
                          <div className="middle-profilepic text-center card-img-overlay d-flex flex-column justify-content-center align-items-center">
                           
                          </div>
                        </div>   

                      
                        <div className="row mb-3 w-100">
                          <label className="col-sm-5 col-form-label text-center">Upload Image</label>
                          <div className="col-sm-7">
                            <input type="file" className={`form-control ${ImageError ? 'is-invalid' : ''}`} onChange={handleProfilePic} style={{ cursor: "pointer" }}/>
                            <div className="invalid-feedback"> { ImageError } </div>  
                          </div>
                        </div>

                      
                        <div className="row mb-3 w-100">
                          <label className="col-sm-5 col-form-label">Employee IDNO: </label>
                          <div className="col-sm-7">
                            <input type="text" className={`form-control ${error.IDNO ? 'is-invalid' : ''}`} name="employeeID" id="EmployeeID_id" value={fetchData.employeeID} onChange={handleChange} />
                            <div className="invalid-feedback"> { error.IDNO } </div>
                          </div>
                        </div>
                        
                        <div className="row mb-3 w-100">
                          <label className="col-sm-5 col-form-label">Firstname: </label>
                          <div className="col-sm-7">
                            <input type="text" className={`form-control ${error.FINAME ? 'is-invalid' : ''}`} name="fname" id="fname_id" value={fetchData.fname} onChange={handleChange} />
                            <div className="invalid-feedback"> { error.FINAME } </div>
                          </div>
                        </div>

                        <div className="row mb-3 w-100">
                          <label className="col-sm-5 col-form-label">Middlename: </label>
                          <div className="col-sm-7">
                            <input type="text" className={`form-control ${error.MIDNAME ? 'is-invalid' : ''}`} name="mname" id="mname_id" value={fetchData.mname} onChange={handleChange} />
                             <div className="invalid-feedback"> { error.MIDNAME } </div>
                           </div>
                        </div>

                        <div className="row mb-3 w-100">
                          <label className="col-sm-5 col-form-label">Surname: </label>
                          <div className="col-sm-7">
                            <input type="text" className={`form-control ${error.SURNAME ? 'is-invalid' : ''}`} name="lname" id="lname_id" value={fetchData.lname} onChange={handleChange} />
                             <div className="invalid-feedback"> { error.SURNAME } </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                    
                    <div className="col-md-7">
                      <div className="card">
                        <div className="card-body" >
                          
                          <label className="fs-4">Service Record</label>

                        <div className="form-row">

                            <div className="form-group col-md-4">
                              <label>From: </label>
                                <input type="date" className={`form-control ${error.fromdate ? 'is-invalid' : ''}`} id="from_id" name="fromDate" value={fetchServiceData.fromDate} onChange={handleServiceChange}/>
                                <div  className="invalid-feedback"> {error.fromdate} </div>
                            </div>
                            
                            <div className="form-group col-md-4">
                              <label>To: </label>
                                <input type="date" className={`form-control ${error.todate ? 'is-invalid' : ''}`} id="to_id" name="toDate" value={fetchServiceData.toDate} onChange={handleServiceChange}/>
                                <div  className="invalid-feedback"> {error.todate} </div>
                            </div>
                          
                        </div>


                          <div className="form-row">
                            <div className="form-group col-md-4">
                              <label>Employment Status</label>
                                <select className={`form-control ${error.ESTA ? 'is-invalid' : ''}`} id="id_emp_status" name="Esta" value={fetchEsta} onChange={handleEstatusChange}>
                                <option value="">Choose...</option>
                                <option value="Permanent">Permanent</option>
                                <option value="CA">Casual</option>
                                <option value="JO">Job Order</option>
                                <option value="Co-Terminous">Co-Terminous</option> 
                                <option value="CON">Consultant</option>
                              </select>
                              <div  className="invalid-feedback"> { error.ESTA } </div>
                            </div>
                            
                      
                            <div className="form-group col-md-8">
                              <label>Select Office</label>
                              <select className={`form-control ${error.OFFCODE ? 'is-invalid' : ''}`} id="offcode_id" name="offcode" value={fetchOffcode} onChange={handleOfficeChange}>
                                 <option value="">Choose...</option>
                                {
                                    fetchOffices.map((option, index) => (
                                      <option key={index} value={option.offcode} >{option.offdesc }</option>
                                ))

                                }     
                              </select>
                              <div  className="invalid-feedback"> { error.OFFCODE } </div>
                            </div>
                            
                          {/* </div>  */}

                    
                          {/* <div className="form-row"> */}
                              <div className="form-group col-md-8">
                                  <label>Position</label>
                                  <select className={`form-control ${error.POSTNO ? 'is-invalid' : ''}`} id="postno_id" name="postno" value={fetchPostNO} onChange={handlePostNOchange}  >
                                       <option value="">Choose...</option>
                                        {
                                        
                                          OptionPosition.map((option, index) => (     
                                            <option key={index} value={option.postno} >{option.Position}</option>
                                          )) 
                                        }
                                  </select>
                                <div  className="invalid-feedback"> { error.POSTNO } </div>
                              </div> 

                              <div className="form-group col-md-4">
                                  <label>Salary</label>
                                   <input type="text" className={`form-control ${error.salary ? 'is-invalid' : ''}`} id="salary_id" name="salary" value={fetchSalary} onChange={handleSalaryChange} readOnly={fetchEsta === 'JO' || fetchEsta === 'CA'|| fetchEsta === 'CON' ? false : true }/>
                                <div  className="invalid-feedback"> { error.salary } </div>
                              </div> 
                              
                              <div className="form-group col-md-2">
                                  <label>Contract</label>
                                      <select className={`form-control ${error.contract ? 'is-invalid' : ''}`} id="id_contract" name="contract" value={fetchServiceData.contract} onChange={handleServiceChange}>
                                      <option value="">Choose...</option>
                                      <option value="A">Annually</option>
                                      <option value="M">Monthly</option>
                                      <option value="D">Daily</option>
                                  </select>
                                  <div className="invalid-feedback"> {error.contract} </div> 
                              </div>
                              
                              <div className="form-group col-md-3">
                                  <label>Position Status</label>
                                  <select className={`form-control ${error.status ? 'is-invalid' : ''}`} id="id_status" name="status" value={fetchServiceData.status} onChange={handleServiceChange} >
                                      <option value="">Choose...</option>
                                      <option value="Consultant">Consultant</option>
                                      <option value="Contract of Service">Contract of Service</option>
                                      <option value="Contractual">Contractual</option>
                                      <option value="Co-Terminous">Co-Terminous</option>
                                      <option value="Casual">Casual</option>
                                      <option value="Elective">Elective</option>
                                      <option value="Job Order">Job Order</option>
                                      <option value="Permanent">Permanent</option>
                                      <option value="Probationary">Probationary</option>
                                      <option value="Temporary">Temporary</option>
                                  </select>
                                  <div className="invalid-feedback"> {error.status} </div> 
                              </div>
                          
                                <div className="form-group col-md-7">
                                  <label>Station:</label>
                                  <input type="text" className="form-control" id="station_id" name="station" value={fetchStation} readOnly/>
                                   
                                </div>
                                
                                <div className="form-group col-md-3">
                                  <label>branch:</label>
                                    <input type="text" className={`form-control ${error.branch ? 'is-invalid' : ''}`} id="branch_id" name="branch" value={fetchServiceData.branch} onChange={handleServiceChange}  />
                                      <div className="invalid-feedback"> {error.branch} </div> 
                                </div>

             
                              
                              
      
                          </div>
                    <hr />

                       { (fetchEsta === 'Permanent' || fetchEsta  === 'Co-Terminous') && (
                      <>
                            <div className="form-row">
                                    
                              <div className="form-group col-md-4">
                                <label>RA:</label>
                                  <input type="text" className={`form-control ${error.adcom ? 'is-invalid' : ''}`} id="RA_id" name="adcom" value={fetchData.adcom} onChange={handleChange} placeholder='0.00' />
                                  <div className="invalid-feedback"> {error.adcom} </div> 
                              </div>

                                <div className="form-group col-md-4">
                                  <label>TA:</label>
                                    <input type="text" className={`form-control ${error.rata ? 'is-invalid' : ''}`} id="TA_id" name="rata" placeholder='0.00' value={fetchData.rata} onChange={handleChange}/>
                                     <div className="invalid-feedback"> {error.rata} </div> 
                                </div>
                                
                                <div className="form-group col-md-4">
                                  <label>PERA:</label>
                                    <input type="text" className={`form-control ${error.pera ? 'is-invalid' : ''}`} id="Pera_id" name="pera" placeholder='0.00' value={fetchData.pera} onChange={handleChange}/>
                                     <div className="invalid-feedback"> {error.pera} </div> 
                                </div>
                                  
                            </div>
                            <div className="form-row">
                                  
                              <div className="form-group col-md-4">
                                <label>Clothing Allowance:</label>
                                  <input type="text" className={`form-control ${error.clothing ? 'is-invalid' : ''}`} id="Clothing_id" name="clothing" placeholder='0.00' value={fetchData.clothing} onChange={handleChange} />
                                 <div className="invalid-feedback"> {error.clothing} </div> 
                                </div>

                                <div className="form-group col-md-4">
                                  <label>Subsitence:</label>
                                    <input type="text" className={`form-control ${error.subsitence ? 'is-invalid' : ''}`} id="Subsitence_id" name="subsitence" placeholder='0.00' value={fetchData.subsitence} onChange={handleChange}/>
                                   <div className="invalid-feedback"> {error.subsitence} </div> 
                                </div>
                            </div>
                      </>
                    )}
                          
                          
                    
                      </div>
                    </div>
                    </div>
        
                            
              </div>               
              </Modal.Body>
        
              <Modal.Footer>
                <Button variant="outline-danger" onClick={props.handleClosed}>
                  Close
                </Button>
                    {
                      btnLoading ? (
                     
                           <Button type="submit" id="btn_submit" variant="success" disabled>
                             <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Loading... 
                      
                          </Button>
                      ) : (
                         <Button type="submit" id="btn_submit" variant="success" disabled={btnSubmit}>Save</Button>
                      )
                    }
                   
              </Modal.Footer>
            </Form>
    </Modal>
          

    </>
  )
}
