import React, { useState } from 'react';
import axios from '../../../../services/axios';
import { Button, Form, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2';

const RelativesModal = (props) => {

    const [fetchData, setFetchData] = useState({
        name_relatives: "",
        relationship: "",
        position: "",
        name_agency: "",
    });
    const [error, setError] = useState([]);
    const handleInputChange = (e) => {
        setFetchData(fetchData => ({ ...fetchData, [e.target.name]: e.target.value }));
    }

    const submitData = async (e) => {
        e.preventDefault();

        await axios.post(`/api/storedRelatives`, {

            plantilla_id: props.unique,
            name_relatives: fetchData.name_relatives,
            relationship: fetchData.relationship,
            position: fetchData.position,
            name_agency: fetchData.name_agency,
        }).then((res) => {
            
             if (res.data.status === 422) {
               Swal.fire({
                icon: 'error',
                title: 'All fields are required',
              })
              setError(res.data.errors);
           } else {
              
                 Swal.fire({
                    icon: "success",
                    title: res.data.message,
                  }).then((res) => {
                    props.handleClose(false);
                    props.fetchDataSALN(props.unique);
                    setFetchData({
                        name_relatives: "",
                        relationship: "",
                        position: "",
                        name_agency: "",
                    });
                  })
            }
            
        }); 

    }

  return (
      <>
        <Modal show={props.show} onHide={props.handleClose} size='lg'>
            <Modal.Header closeButton>
            <Modal.Title>Create Relatives Interests and Financial Connections</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitData}>
                <Modal.Body>

                    <div className="row  g-3">
                        
                        <div className="col-md-6">
                            <label>Name of Relatives</label>
                              <input type="text" className={`form-control ${error.name_relatives ? 'is-invalid' : ''}`} name="name_relatives" value={fetchData.name_relatives} onChange={handleInputChange} />
                              <div className="invalid-feedback">{error.name_relatives}</div>
                        </div>
                        <div className="col-md-6">
                            <label>Relationship</label>
                            <input type="text" className={`form-control ${error.relationship ? 'is-invalid' : ''}`} name="relationship"  value={fetchData.relationship} onChange={handleInputChange} />
                            <div  className="invalid-feedback">{error.relationship}</div>
                        </div>
                        
                        <div className="col-md-4">
                             <label>Position</label>
                            <input type="text" className={`form-control ${error.position ? 'is-invalid' : ''}`} name="position" value={fetchData.position} onChange={handleInputChange} />
                            <div  className="invalid-feedback">{error.position}</div>
                        </div> 
                        
                        <div className="col-md-8">
                            <label>Name of Agency / Office and Address</label>
                            <input type="text" className={`form-control ${error.name_agency ? 'is-invalid' : ''}`} name="name_agency"  value={fetchData.name_agency} onChange={handleInputChange} />
                            <div  className="invalid-feedback">{error.name_agency}</div>
                        </div>
                        
                    </div>

                </Modal.Body>
            <Modal.Footer>
            
            <Button type="submit" variant="success" >
                Save 
            </Button>
            </Modal.Footer>
            </Form>
        </Modal>
      
      </>
  )
}

export default RelativesModal