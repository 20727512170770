import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ChildrenModal from './ChildrenModal';
import ChildrenEditModal from './ChildrenEditModal';
import { format } from 'date-fns'


const UnmarriedChildrenTable = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    
    const [editDataModal, setEditDataModal] = useState([]);

    const handleModalChild = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleEditModal = (data) => {
        setEditDataModal(data);
        setShowEditModal(true);
    };

    const handleEditClose = () => {
         setShowEditModal(false);
    };


  return (
    <div>
        
         <div className="card">
            <div className="card-body">
                
                    <div className="row mb-2">
                        <div className="col-sm-8">
                            <label className="fw-bold text-decoration-underline">UNMARRIED CHILDREN BELOW EIGHTEEN (18) YEARS OF AGE LIVING IN DECLARANT'S HOUSEHOLD</label>
                        </div>{/* /.col */}   
                        <div className="col-sm-4">
                            <div className="float-right">
                                <Button type="button" variant="outline-primary" onClick={() => handleModalChild()}><i className="nav-icon fas fa-plus" /> Add Child</Button>        
                            </div>
                        </div>{/* /.col */} 
                    </div>{/* /.row */}
                    

                <div className="row">
                    <div className="col">
                        <table className="table table-bordered table-hover" width="100%" cellSpacing="0">
                            <thead>
                                <tr>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Age</th>
                                <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                    props.dataChild.length > 0 ? 
                                        props.dataChild.map((data, index) => {
                                            const {
                                                children_name,
                                                children_bday,
                                            } = data;

                                                const birthdate = new Date(children_bday);
                                                const today = new Date();
                                                const ageDiff = today.getFullYear() - birthdate.getFullYear();
                                                const age = ageDiff.toString();
                                            
                                            return (
                                            <tr key={index}>
                                                <td>{ children_name }</td>
                                                <td>{format(new Date(children_bday), 'MMMM d, yyyy')}</td>
                                                <td>{ age }</td>
                                                <td align='center'>
                                                   <Button variant="outline-primary btn-sm" title="Edit" onClick={() => handleEditModal(data)} > <i className="nav-icon fas fa-edit" /></Button>
                                                </td>
                                            </tr>
                                            )
                                        })
                                : 
                                 <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                }     
                                 
                              </tbody>
                        </table>
                </div>
                </div>
                

            </div>
        </div>
        
        <ChildrenModal show={showModal} handleClose={handleClose} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />
        <ChildrenEditModal show={showEditModal} handleEditClose={handleEditClose} data={editDataModal} fetchDataSALN={props.fetchDataSALN} unique={props.unique} />                         
      </div>

  )
}

export default UnmarriedChildrenTable