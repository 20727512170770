import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'
import { debounce } from 'lodash';
// import EducTable from '../components/EducTable'

import EducModal from '../components/EducModal'

export default function EmployeeEduc() {
  

    
    // Display Educ
    const { unique } = useParams();
    const [isLoading, setisLoading] = useState(false);
    const [fetchEmployeeEduc, setEmployeeEduc] = useState([])
    // Fetching EDUCATION LEVEL 
    const [fetchEducLevel, setEducLevel] = useState();
    // Fetching Course List
    const [fetchCourse, setFetchCourse] = useState();


   const fetchData = async () => {
        try {
            const respEducLevel = await axios.get(`/api/EducLevel`);
            const respEducCourse = await axios.get(`/api/EducCourse`);

            setEducLevel(respEducLevel.data.levelEduc);
            setFetchCourse(respEducCourse.data.courselist);

        } catch (error) {
            console.error("Error fetching employee data:", error);
        }
           
    
    }

    useEffect(() => {
        fetchData()
    }, [])



    const fetchDataEduc = async (unique) => {
        setisLoading(true);
        try {
            const resp = await axios.get(`/api/EmpEduc/${unique}`);
            setEmployeeEduc(resp.data.EmpEduc);
        } catch (error) {
            console.log(error);
        } finally {
            setisLoading(false);
        }
    };

    useEffect(() => {
         const debouncedFetch = debounce(fetchDataEduc, 1000); // Adjust the delay time as needed

        debouncedFetch(unique);
        return () => debouncedFetch.cancel();
    }, [unique])


    //modal
    
    const [modeldata, setModaldata] = useState({
        unique: '',
        SCHOOL: '', 
        DEGREE: '', 
        YRGRAD: '', 
        UEARNED: '', 
        FROMATT	: '', 
        TOATT: '',
        HONORS: '',
        levelcode: '',
    }) 

    const handleChange = (e) => {
        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))
    }
       
    const [showModal, setShowModal] = useState(false);

    const handleButtonClick = (data) => {
         setModaldata(data);
        setShowModal(true);
    };

     const handleCloseModal = () => {
        setShowModal(false);
    };

//  update
    const [error, setError] = useState([]);

     const updateData = async (e) => {
         e.preventDefault();
         
        const Level = modeldata.levelcode === '1' || modeldata.levelcode === '2' ? "Elementary" : modeldata.levelcode === '3' || modeldata.levelcode === '4' ? "Secondary" : modeldata.levelcode === '5' ? "Vocational/Trade Course" : modeldata.levelcode === '6' || modeldata.levelcode === '7' ? "Vocational/Trade Course" :  modeldata.levelcode === '8' || modeldata.levelcode === '9' || modeldata.levelcode === '10' || modeldata.levelcode === '11' ? "Graduate Studies" : '';

        const formData = new FormData()
        formData.append('id', modeldata.unique)
        formData.append('LEVEL', Level ?? "")
        formData.append('SCHOOL', modeldata.SCHOOL ?? "")
        formData.append('DEGREE', modeldata.DEGREE ?? "")
        formData.append('FROMATT', modeldata.FROMATT ?? "")
        formData.append('TOATT', modeldata.TOATT ?? "")
        formData.append('UEARNED', modeldata.UEARNED ?? "")
        formData.append('YRGRAD', modeldata.YRGRAD ?? "")
        formData.append('HONORS', modeldata.HONORS ?? "")
        formData.append('levelcode', modeldata.levelcode ?? "")

         await axios.post(`/api/UpdateEduc`, formData).then((res) => {
            
            if (res.data.status === 422) {
            Swal.fire({
                icon: 'error',
                title: 'All fields are required',
                })
            setError(res.data.errors);

            }
            else {
           //  console.log(res.data.message);
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    fetchDataEduc(unique);
                    setError('');
                    setShowModal(false);
                })
            }
            
        });
    }


  return (
    <div>
            <div className="row">
                <div className="col-sm-6">
                    <h4 className="m-2"><i className="fas fa-book"/> Educational Background </h4>
                </div>{/* /.col */}   
                <div className="col-sm-6">
                    <div className="float-right">
                      <EducModal unique={unique} fetchDataEduc={fetchDataEduc} fetchEducLevel={fetchEducLevel} fetchCourse={fetchCourse} />
                    </div>
                </div>{/* /.col */} 
            </div>{/* /.row */}
        <hr />  
                            
        {/* <br /> */}
          
         {
           
            isLoading ? (
                <div  className='overlay-wrapper'>
                    <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin" /><div className="text-bold pt-2">Loading...</div></div>
                        <div className="mx-1">
                            <div className="float-right">     
                                <Button variant="outline-primary btn-sm" > <i className="nav-icon fas fa-edit" /></Button>
                            </div>
                        </div>

                
                            
                        <br />

                          <div className="form-row">
            
                              <label className="col-sm-2 col-form-label">
                                
                                
                              </label>
                              <div className="col-md-7">
                                  <label></label>
                                  <span>
                                      
                                      <span style={styles.yearGrad }>
                                          <br />
                                            
                                          <br />
                                       Y
                                      </span>
                                  </span>
                              </div>
            

                          </div>
                          <hr /> 
                      </div>
                
                
            ) :    
              fetchEmployeeEduc.map((data) => {
                  const {
                    //   LEVEL,
                      SCHOOL,
                      DEGREE, 
                      YRGRAD, 
                      FROMATT, 
                      TOATT,

                      unique, 
                      description
                  } = data;
                  return (
                    
                      <div key={unique}>
                        
                        <div className="mb-1">
                            <div className="float-right">     
                                <Button variant="outline-primary btn-sm" onClick={() => handleButtonClick(data) } > <i className="nav-icon fas fa-edit" /></Button>
                            </div>
                        </div>

                
                            
    
                          <div className="form-row">
            
                              <label className="col-sm-2 col-form-label">
                                
                                  { 
                                    description === "Elementary Undergraduate" ?  "Elementary Undergraduate" : 
                                    description === "Elementary Graduate" ?  "Elementary" : 
                                    description === "High School Undergraduate" ? "High School Undergraduate" :
                                    description === "High School Graduate" ? "Secondary" :
                                    description === "Vocational / Trade Course" ? "Vocational/Trade Course":
                                    description === "College Undergraduate" ? "College Undergraduate":
                                    description === "College Graduate" ? "College":
                                    description === "With Masteral Units" ? "With Masteral Units" :
                                    description === "Masters Degree" ? "Masters Degree" :
                                    description === "With Doctorate Units" ? "With Doctorate Units" :
                                    description === "Doctorate Degree" ? "Doctorate Degree" :
                                    ""
                                   }
                              </label>
                              
                              <div className="col-md-7">
                                  <label></label>
                                  <span>
                                      {SCHOOL === 'null' ? '' : SCHOOL}  | {DEGREE === 'null' ? '' : DEGREE} 
                                      <span style={styles.yearGrad }>
                                          <br />
                                              {FROMATT === 'null' ? '' : FROMATT } - {TOATT === 'null' ? '' : TOATT}
                                          <br />
                                       Year Graduated  {YRGRAD === 'null' ? '' : YRGRAD}
                                      </span>
                                  </span>
                              </div>
            

                          </div>
                          <hr /> 
                      </div>
                  );      
            })
          }
    

      <Modal show={showModal} backdrop="static" keyboard={false}  size="lg">
       
          <Modal.Header>
            <Modal.Title><i className="fas fa-book"/> Edit Education Background</Modal.Title>
          </Modal.Header>
          <Form onSubmit={updateData}>
              <Modal.Body>
                  {/* <input type="text" name="id" value={modeldata.unique  === 'null' ? "" : } onChange={handleChange}/> */}
                            
                
                  
                  <div className="form-row">
                      <div className="form-group col-md-4">
                          <label>Level</label>
                          <select className={`custom-select ${error.levelcode ? 'is-invalid' : ''}`} name="levelcode" id="level_id" value={modeldata.levelcode ?? "" } onChange={handleChange}>
                              <option value="">Select</option>
                              {
                                      fetchEducLevel?.map((educlevel, index) => (
                                          <option key={index} value={educlevel.id}>{educlevel.description}</option>
                                      ))
                                  }
                          </select>         
                          <div  className="invalid-feedback"> { error.level } </div> 
                      </div>
                      

                      <div className="form-group col-md-7">
                          <label>Name of School</label>
                          <input type="text" className={`form-control ${error.SCHOOL ? 'is-invalid' : ''}`}  name="SCHOOL" id="school_id" value={modeldata.SCHOOL ?? "" } onChange={handleChange} />
                          <div  className="invalid-feedback"> { error.SCHOOL } </div>
                      </div>              

                  </div>           
                  {/* form-row */}
                  
                  <div className="form-row">
                        <div className="form-group col-md-7">
                            <label>Basic Education/Degree/Courses</label>
                              <input list="educCourse" type="text" className={`form-control ${error.DEGREE ? 'is-invalid' : ''}`} name="DEGREE" id="basic_educ_id" value={modeldata.DEGREE ?? ""} onChange={handleChange} />
                              <datalist id='educCourse'>
                                  <option value="">Select</option>
                                   {
                                      fetchCourse?.map((educCourses, index) => (
                                            
                                        <option key={index} value={educCourses.Description}>{educCourses.Description}</option>
                                    ))
                                }
                              </datalist>
                            <div  className="invalid-feedback"> { error.DEGREE } </div>
                        </div>
                      
                      <div className="form-group col-md-2">
                          <label>From</label>
                          <input type="text" className={`form-control ${error.FROMATT ? 'is-invalid' : ''}`} name="FROMATT" id="from_id" value={modeldata.FROMATT ?? ""} onChange={handleChange} />
                          <div  className="invalid-feedback"> { error.FROMATT } </div>
                      </div>        

                      <div className="form-group col-md-2">
                          <label>To</label>
                          <input type="text" className={`form-control ${error.TOATT ? 'is-invalid' : ''}`} name="TOATT" id="to_id"  value={modeldata.TOATT ?? ""} onChange={handleChange} />
                          <div  className="invalid-feedback"> { error.TOATT } </div>
                      </div>
                              
                  </div>
                  {/* form-row */}
                        
                  <div className="form-row">
                      <div className="form-group col-md-4">
                          <label>Highest Level/Units Earned</label>
                          <input type="text" className="form-control" name="UEARNED" id="units_id"  value={modeldata.UEARNED ?? ""} onChange={handleChange} />
                          {/* <div  className="invalid-feedback"> </div> */}
                      </div>
                      
                      <div className="form-group col-md-2">
                          <label>Year Graduated</label>
                          <input type="text" className={`form-control ${error.YRGRAD ? 'is-invalid' : ''}`} name="YRGRAD" id="year_grad_id"  value={modeldata.YRGRAD ?? ""} onChange={handleChange} />
                          <div  className="invalid-feedback"> { error.YRGRAD } </div>
                      </div>        

                      <div className="form-group col-md-5">
                          <label>Scholarship/Academic Honors Recieved</label>
                          <input type="text" className="form-control" name="HONORS" id="honors_id"  value={modeldata.HONORS ?? ""}  onChange={handleChange}/>
                          {/* <div  className="invalid-feedback"> </div> */}
                      </div>
                              
                  </div>
                  {/* form-row */}
              </Modal.Body>
          
              <Modal.Footer>
              <Button variant="outline-danger" onClick={() => handleCloseModal()}>
                  Close
              </Button>
              <Button type='submit' variant="success">Save Changes</Button> 
              </Modal.Footer>
          </Form>        
      </Modal>
                 
    </div>
  )
}
const styles = {
    yearGrad: {
        marginLeft: '10px'
    }
}