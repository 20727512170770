import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import { DateObject } from "react-multi-date-picker";

export const EmployeeOTLayout = React.forwardRef((props, ref) => {
    const navigate = useNavigate();
    const fSize = 9;
    const rangeStart = parseInt(props.printRange) !== 3 ? 1 : 16;
    const rangeEnd = parseInt(props.printRange) !== 2 ? props.selectedDate.month.length : 15;

    const getPageMargins = () => {
        return `@page {
            size: landscape;
        }`;
    };

    const renderDays = () => {
        let dayList = [];
        let legends = ["VL", "FL", "SL", "OB", "SPL", "*SPL", "CTO", "HL", "WS", "A", "ML", "PL", "*SL", "VAWC", "SLBW", "RP", "CL", "AL", "CTO", "CQT", "CQTL"];
        for (let i = 0; i < 31; i++) {
            let actDay = i+1;
            let checkDate = new DateObject(`${props.selectedDate.year}-${props.selectedDate.month}-${actDay}`);
            let dayName = checkDate.weekDay.shortName;
            if(actDay >= rangeStart && actDay <= rangeEnd && props.printData.schedules[i]){
                let sched = props.printData.schedules[i];
                let ut = sched.undertime.split(",");
                ut = ut.map(Number)
                let utTotal = ut.reduce((acc, num) => acc + num, 0);
                let utHours = Math.floor(utTotal / 60);
                let utMin = utTotal % 60;
                const checkDT = (dt, ms = false, scDt = sched.date) => {
                    if(dt === "" || dt === null){
                        return "";
                    }
                    if(legends.includes(dt)){
                        return dt;
                    }
                    let res =  ms ? new DateObject(parseInt(dt)).format("hh:mm") :  new DateObject(`${scDt} ${dt}`).format("hh:mm");
                    return res;
                }
                let attAmIn = "";
                let attAmOut = "";
                let attPmIn = "";
                let attPmOut = "";
                let otIn = "";
                let otOut = "";

                // if(sched.ot_indicator !== 0){
                //     let otIn = new DateObject(parseInt(sched.ot_in));
                //     let otOut = new DateObject(parseInt(sched.ot_out));

                //     if(otIn.format("a") == "am"){
                //         attAmIn = checkDT(sched.ot_in, true);
                //     }else{
                //         if(checkDate.format("YYYY-MM-DD") != otIn.format("YYYY-MM-DD")){
                //             attAmIn = checkDT(sched.ot_in, true);
                //         }else{
                //             attPmIn = checkDT(sched.ot_in, true);
                //         }
                //     }

                //     if(otOut.format("a") == "am"){
                //         if(checkDate.format("YYYY-MM-DD") != otOut.format("YYYY-MM-DD")){
                //             attPmOut = checkDT(sched.ot_out, true);
                //         }else{
                //             attAmOut = checkDT(sched.ot_out, true);
                //         }
                //     }else{
                //         attPmOut = checkDT(sched.ot_out, true);
                //     }
                // }else if(sched.ot_indicator === 3){
                //     attAmIn = checkDT(sched.attend_am_in, true);
                //     attAmOut = checkDT(sched.attend_am_out, true);
                //     attPmIn = checkDT(sched.attend_pm_in, true);
                //     attPmOut = checkDT(sched.attend_pm_out, true);
                // }
                if(actDay >= props.printStartDay && actDay <= props.printEndDay){
                    if(sched.ot_indicator === 3){
                        attAmIn = checkDT(sched.attend_am_in, true);
                        attAmOut = checkDT(sched.attend_am_out, true);
                        attPmIn = checkDT(sched.attend_pm_in, true);
                        attPmOut = checkDT(sched.attend_pm_out, true);
                    }
    
                    if(sched.ot_indicator === 2 || sched.ot_indicator === 3){
                        otIn = checkDT(sched.ot_in, true);
                        otOut = checkDT(sched.ot_out, true);
                    }
                }

                dayList.push(
                <tr style={{ backgroundColor: `${dayName === "Sat" || dayName === "Sun" ? "#e3e8e8" : ""}` }} key={i}>
                    <td className="text-center p-0">{actDay}</td>
                    <td className="text-center p-0">{attAmIn}</td>
                    <td className="text-center p-0">{attAmOut}</td>
                    <td className="text-center p-0">{attPmIn}</td>
                    <td className="text-center p-0">{attPmOut}</td>
                    <td className="text-center p-0">{otIn}</td>
                    <td className="text-center p-0">{otOut}</td>
                </tr>);
            }else{
                dayList.push(
                <tr style={{ backgroundColor: `${dayName === "Sat" || dayName === "Sun" ? "#e3e8e8" : ""}` }} key={i}>
                    <td className="text-center p-0">{actDay}</td>
                    <td className="text-center p-0"></td>
                    <td className="text-center p-0"></td>
                    <td className="text-center p-0"></td>
                    <td className="text-center p-0"></td>
                    <td className="text-center p-0"></td>
                    <td className="text-center p-0"></td>
                </tr>);
            }
        }
        return (
            <>
                {dayList}
            </>
        );
    }

    return (
        <div className="m-4 page-break" style={{fontSize:`${fSize}px`}}>
            <style nonce="hris-style">{getPageMargins()}</style>
            <Row>
                <Col className="ms-3 me-3">
                    <Row>
                        <Col className="p-0">
                            CIVIL SERVICE FORM NO.48
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0" style={{fontSize:`${fSize+3}px`}}>
                            D A I L Y &nbsp;T I M E &nbsp;R E C O R D
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark text-center p-0" style={{fontSize:`${fSize+2}px`}}>
                            {props.printData.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0">
                            NAME
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="4">
                            For the month of
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="8">
                            {`Overtime for ${props.selectedDate.month.name} ${props.printStartDay}${props.printEndDay == props.printStartDay ? "" : ` - ${props.printEndDay}`}, ${props.selectedDate.year}`}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="p-0" xs="4">
                            Official hours for arrival and departure
                        </Col>
                        <Col className="ps-0" xs="8">
                            <Row className="">
                                <Col xs="5">
                                    Regular Days
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                            <Row className="">
                                <Col xs="5">
                                    Saturdays
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="">
                        <table className="emp-dtr-table">
                            <thead className="text-center">
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}} rowSpan="2">Day</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">A.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">P.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">UNDERTIME</td>
                                </tr>
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Hours</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Minutes</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderDays()}
                                <tr>
                                    <td className="p-0"></td>
                                    <td className="p-0" colSpan={4}>TOTAL</td>
                                    <td className="p-0"></td>
                                    <td className="p-0"></td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            I CERTIFY on my honor that the above is true and correct report of the hours of work performed record of which was made daily at the time of arrival and departure from office.
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            Verifies as to the prescribed office hours
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="text-center p-0 fw-bold" xs="9" style={{fontSize:`${fSize-1}px`}}>
                            In Charge
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center p-0 fw-bold" style={{fontSize:`${fSize-1}px`}}>
                            (SEE INSTRUCTIONS ON THE BACK)
                        </Col>
                    </Row>
                </Col>
                <Col className="ms-3 me-3">
                    <Row>
                        <Col className="p-0">
                            CIVIL SERVICE FORM NO.48
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0" style={{fontSize:`${fSize+3}px`}}>
                            D A I L Y &nbsp;T I M E &nbsp;R E C O R D
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark text-center p-0" style={{fontSize:`${fSize+2}px`}}>
                            {props.printData.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0">
                            NAME
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="4">
                            For the month of
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="8">
                            {`Overtime for ${props.selectedDate.month.name} ${props.printStartDay}${props.printEndDay == props.printStartDay ? "" : ` - ${props.printEndDay}`}, ${props.selectedDate.year}`}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="p-0" xs="4">
                            Official hours for arrival and departure
                        </Col>
                        <Col className="ps-0" xs="8">
                            <Row className="">
                                <Col xs="5">
                                    Regular Days
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                            <Row className="">
                                <Col xs="5">
                                    Saturdays
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="">
                        <table className="emp-dtr-table">
                            <thead className="text-center">
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}} rowSpan="2">Day</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">A.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">P.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">UNDERTIME</td>
                                </tr>
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Hours</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Minutes</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderDays()}
                                <tr>
                                    <td className="p-0"></td>
                                    <td className="p-0" colSpan={4}>TOTAL</td>
                                    <td className="p-0"></td>
                                    <td className="p-0"></td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            I CERTIFY on my honor that the above is true and correct report of the hours of work performed record of which was made daily at the time of arrival and departure from office.
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            Verifies as to the prescribed office hours
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="text-center p-0 fw-bold" xs="9" style={{fontSize:`${fSize-1}px`}}>
                            In Charge
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center p-0 fw-bold" style={{fontSize:`${fSize-1}px`}}>
                            (SEE INSTRUCTIONS ON THE BACK)
                        </Col>
                    </Row>
                </Col>
                <Col className="ms-3 me-3">
                    <Row>
                        <Col className="p-0">
                            CIVIL SERVICE FORM NO.48
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0" style={{fontSize:`${fSize+3}px`}}>
                            D A I L Y &nbsp;T I M E &nbsp;R E C O R D
                        </Col>
                    </Row>
                    <Row>
                        <Col className="border-bottom border-1 border-dark text-center p-0" style={{fontSize:`${fSize+2}px`}}>
                            {props.printData.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center fw-bold p-0">
                            NAME
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="4">
                            For the month of
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="8">
                            {`Overtime for ${props.selectedDate.month.name} ${props.printStartDay}${props.printEndDay == props.printStartDay ? "" : ` - ${props.printEndDay}`}, ${props.selectedDate.year}`}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col className="p-0" xs="4">
                            Official hours for arrival and departure
                        </Col>
                        <Col className="ps-0" xs="8">
                            <Row className="">
                                <Col xs="5">
                                    Regular Days
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                            <Row className="">
                                <Col xs="5">
                                    Saturdays
                                </Col>
                                <Col className="border-bottom border-1 border-dark p-0" xs="7">
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="">
                        <table className="emp-dtr-table">
                            <thead className="text-center">
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}} rowSpan="2">Day</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">A.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">P.M.</td>
                                    <td className="p-0" style={{width:`${(100/7) * 2}%`}} colSpan="2">UNDERTIME</td>
                                </tr>
                                <tr>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Arrival</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`, fontSize:`${fSize-1}px`}}>Departure</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Hours</td>
                                    <td className="p-0" style={{width:`${(100/7) * 1}%`}}>Minutes</td>
                                </tr>
                            </thead>
                            <tbody>
                                {renderDays()}
                                <tr>
                                    <td className="p-0"></td>
                                    <td className="p-0" colSpan={4}>TOTAL</td>
                                    <td className="p-0"></td>
                                    <td className="p-0"></td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            I CERTIFY on my honor that the above is true and correct report of the hours of work performed record of which was made daily at the time of arrival and departure from office.
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col className="border-bottom border-1 border-dark p-0">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0 fw-bold" style={{fontSize:`${fSize-2}px`}}>
                            Verifies as to the prescribed office hours
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="border-bottom border-1 border-dark p-0" xs="9">
                            &nbsp;
                        </Col>
                    </Row>
                    <Row>
                        <Col className="p-0" xs="3">
                            &nbsp;
                        </Col>
                        <Col className="text-center p-0 fw-bold" xs="9" style={{fontSize:`${fSize-1}px`}}>
                            In Charge
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center p-0 fw-bold" style={{fontSize:`${fSize-1}px`}}>
                            (SEE INSTRUCTIONS ON THE BACK)
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
});