import React from 'react'
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PDFViewer } from '@react-pdf/renderer';
import LayoutSALN from './LayoutSALN';


export default function SALNReport(props) {
  return (



    <>
       <Modal show={props.show} onHide={props.handleCloseModal} dialogClassName="modal-90w" >
     

        <Modal.Body>

            <PDFViewer style={{ width:"100%", height: "90vh"  }}>
            <LayoutSALN
              dataChild={props.dataChild}
              dataInfo={props.dataInfo}
              dataSpouse={props.dataSpouse}
              dataRealProper={props.dataRealProper}
              dataPersonalProper={props.dataPersonalProper}
              dataLiabilities={props.dataLiabilities}
              dataBusinessInter={props.dataBusinessInter}
              dataGovRelatives={props.dataGovRelatives}
              

              totalRealProper={props.totalRealProper}
              totalPersonalProper={props.totalPersonalProper}
              totalLiabilities={props.totalLiabilities}
              totalProper={props.totalProper}
              totalNetWorth={props.totalNetWorth}
            />
           </PDFViewer>

        </Modal.Body>

      </Modal>

    
    </>
           
 
      
      
 
  )
};
