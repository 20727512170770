import React, { useEffect, useState } from 'react'
import axios from '../../../../services/axios';
// import { useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';



export default function TrainingModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [fetchEmpInfo, setEmpInfo] = useState({
        IDNO: "",
    });

    const [error, setError] = useState([]);  
    // STORED IN THE DATABASE 
    const [modeldata, setModaldata] = useState({
        IDNO: '',
        TRCORS: '',
        TRNFROM: '',
        TRNTO: '',
        TRNHRS: '',
        LD: '',
        CONDUCT: ''
    });

    useEffect(() => {
       axios.get(`/api/EmpFullInfo/${props.unique}`).then((res) => {
           setEmpInfo(res.data.EmpFullDetails[0])
        })  
    }, [props.unique]);   

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "TRNHRS") {
            
            if (/^\d*$/.test(value)) {
                setModaldata(modeldata => ({ ...modeldata, [name]: value }));
            }
        }

        setModaldata(modeldata => ({ ...modeldata, [e.target.name]: e.target.value }))

    }

    const submitTraining = async (e) => {
        e.preventDefault();

        
    
        await axios.post(`/api/StoredTraining`, {

            IDNO: fetchEmpInfo.IDNO,
            TRCORS: modeldata.TRCORS,
            TRNFROM: modeldata.TRNFROM,
            TRNTO: modeldata.TRNTO,
            TRNHRS: modeldata.TRNHRS,
            LD: modeldata.LD,
            CONDUCT: modeldata.CONDUCT,
         
      
        }).then((res) => {

            if (res.data.status === 422) {
                Swal.fire({
                    icon: 'error',
                    title: 'All fields are required',
                    })
                setError(res.data.errors);
    
                }
            else {   
            
                Swal.fire({
                    icon: "success",
                    title: res.data.message,
                }).then((res) => {
                    setShow(false);
                    props.fetchDataTraining(props.unique);
                    setError('');
                    setModaldata({
                        IDNO: '',
                        TRCORS: '',
                        TRNFROM: '',
                        TRNTO: '',
                        TRNHRS: '',
                        LD: '',
                        CONDUCT: ''
                    });
                    
                })
            }
        
        })
    
    
    }



  return (
    <>
        <Button variant="primary" onClick={handleShow}>
            <i className="nav-icon fas fa-plus" />
        </Button>

        <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg">
      
        <Modal.Header>
          <Modal.Title><i className="fas fa-chalkboard"/> Create Learning Development</Modal.Title>
        </Modal.Header>
            <Form onSubmit={submitTraining}>

                <Modal.Body>
         
                    {/* <input type="hidden" name="app_id" id="appId_id" ref={appIdRef} defaultValue="1" /> */}
                    <div className="form-row">
                        <div className="form-group col-md-11">
                            <label>Title of Learning and Development Intervention/Training Program</label>
                              <input type="text" className={`form-control ${error.TRCORS ? 'is-invalid' : ''}`} name="TRCORS" id="TRCORS_id" value={modeldata.TRCORS} onChange={handleChange} />
                            <div  className="invalid-feedback"> {error.TRCORS} </div>
                        </div>              
                    </div>  
                    {/* form-row */}

                     <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>From</label>
                            <input type="date" className={`form-control ${error.TRNFROM ? 'is-invalid' : ''}`} name="TRNFROM" id="TRNFROM_id" value={modeldata.TRNFROM} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.TRNFROM} </div>
                        </div>

                        <div className="form-group col-md-3">
                            <label>To</label>
                            <input type="date" className={`form-control ${error.TRNTO ? 'is-invalid' : ''}`} name="TRNTO" id="TRNTO_id"  value={modeldata.TRNTO} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.TRNTO} </div>
                        </div>  

                    
                        <div className="form-group col-md-5">
                            <label>Type of Learning Development</label>
                            <select className={`custom-select ${error.LD ? 'is-invalid' : ''}`} name="LD" id="LD_id" value={modeldata.LD} onChange={handleChange}>
                                <option value="">Select</option>
                                <option value="foundation">FOUNDATION</option>
                                <option value="technical">TECHNICAL</option>
                                <option value="supervisional">SUPERVISIONAL</option>
                                <option value="managerial">MANAGERIAL</option>
                            </select>
                            <div  className="invalid-feedback"> {error.LD} </div>
                        </div>            
                    </div>  
                    {/* form-row */}

                    <div className="form-row">
                        <div className="form-group col-md-3">
                            <label>Number of Hours</label>
                            <input type="text" className={`form-control ${error.TRNHRS ? 'is-invalid' : ''}`} name="TRNHRS" id="hours_id" value={modeldata.TRNHRS} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.TRNHRS} </div>
                        </div>

                        <div className="form-group col-md-8">
                            <label>Conducted/Sponsored By</label>
                            <input type="text" className={`form-control ${error.CONDUCT ? 'is-invalid' : ''}`} name="CONDUCT" id="conducted_id" value={modeldata.CONDUCT} onChange={handleChange}/>
                            <div  className="invalid-feedback"> {error.CONDUCT} </div>
                        </div>              
                    </div>  
                    {/* form-row */}

                            
                            
                </Modal.Body>
                
                  <Modal.Footer>
                    <Button variant="outline-danger" onClick={() => handleClose()}>
                        Close
                    </Button>
                    <Button variant="success" type="submit" >
                        Save
                    </Button>
                </Modal.Footer>
            </Form>

        </Modal>
    </>
  )
}
